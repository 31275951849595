<!--
 <flow-merge ref="flowMerge" />
 -->
<template>
  <a-modal
    :width="1200"
    :visible="visible"
    dialogClass="mgr-operation-modal"
    @cancel="hide"
    :footer="null"
    :dialog-style="{ top: '30px' }"
  >
    <tabs-pages @onTabChange="onTabChange" :activeKey="activeKey">
      <template slot="tabPane">
        <div :key="0" tabName="流程明细"></div>
        <div :key="1" tabName="流程图"></div>
      </template>
    </tabs-pages>
    <iframe v-if="renderFlag" ref="iframe" frameborder="0" style="height: 600px;width: 100%" :src="src"></iframe>
  </a-modal>
</template>

<script>
import { ACCESS_TOKEN } from 'common/confing'
import { TabsPages } from 'common/components'
export default {
  name: 'flowMerge',
  components: {
    TabsPages
  },
  data () {
    return {
      activeKey: 0,
      visible: false,
      src: '',
      renderFlag: false,
      uuid: ''
    }
  },
  methods: {
    onTabChange (activeTag) {
      this.activeKey = activeTag
      if (activeTag === 0) {
        this.showDetail(this.uuid)
      } else if (activeTag === 1) {
        this.show(this.uuid)
      }
    },
    /**
    * 弹出对话框
    */
    showDetail (uuid) {
      this.setSrc('/wffront/system/core/workflow/flowrun/flowview/viewlist.html', uuid);
    },
    /**
     * 弹出对话框
     */
    show (uuid) {
      this.setSrc('/wffront/system/core/workflow/flowrun/flowview/viewgraph.html', uuid);
    },
    /**
     * 关闭对话框
     */
    hide () {
      this.activeKey = 0
      this.visible = false
      this.renderFlag = false
    },
    setSrc (url, uuid) {
      if(uuid){
        this.uuid = uuid
      } else {
        uuid = this.uuid
      }
      const urlPrefix = process.env.VUE_APP_BASE_API
      const token = sessionStorage.getItem(ACCESS_TOKEN)
      const env = process.env.NODE_ENV
      this.visible = true
      this.renderFlag = true
      this.src = `${urlPrefix}${url}?tokenid=${token}&id=${uuid}&_env=${env}&_urlPrefix=${urlPrefix}`
    }
  }
}
</script>

<style scoped>
.mgr-operation-modal {
  height: 700px;
}
</style>
