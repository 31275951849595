export default {
  path: '/fzzyk',
  name: 'fzzykMgr',
  redirect: '/fzzyk/enteringManage',
  meta: {
    module: 'fzzykMgr',
    title: '法治资源库',
    roles: ['admin', 'group', 'user'],
    icon: 'xitongguanli'
  },
  children: [
    {
      path: '/fzzyk/preview',
    },
    {
      path: '/fzzyk/enteringManage',
      name: '',
      redirect: '/fzzyk/enteringManage/lawEnteringManage',
      meta: {
        module: '法治资源库',
        title: '入库管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/enteringManage/lawEnteringManage',
          name: 'lawEnteringManage',
          meta: {
            module: '入库管理',
            title: '法律法规录入管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/enteringManage/lawPublicManage',
          name: 'lawPublicManage',
          meta: {
            module: '入库管理',
            title: '法律法规发布管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/enteringManage/manualEntry',
          name: 'manualEntry',
          meta: {
            module: '手动录入',
            title: '手动录入',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/enteringManage/autoEntry',
          name: 'autoEntry',
          meta: {
            module: '自动录入',
            title: '自动录入',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        // {
        //   path: '/fzzyk/enteringManage/entryDetailPage',
        //   name: 'entryDetailPage',
        //   meta: {
        //       module: '录入预览',
        //       title: '录入预览',
        //       roles: ['admin', 'group', 'user'],
        //       icon: ''
        //   }
        // },
        // {
        //   path: '/fzzyk/enteringManage/publicDetailPage',
        //   name: 'publicDetailPage',
        //   meta: {
        //       module: '发布预览',
        //       title: '发布预览',
        //       roles: ['admin', 'group', 'user'],
        //       icon: ''
        //   }
        // },
      ]
    },
    {
      path: '/fzzyk/smartTools',
      name: 'smartTools',
      meta: {
        module: '法治资源库',
        title: '智能工具',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/smartTools/licenseRecognition',
          name: 'licenseRecognition',
          meta: {
            module: '法治资源库',
            title: '智慧执照识别',
            roles: ['admin', 'group', 'user'],
            icon: ''
          },
        },
        {
          path: '/fzzyk/smartTools/compare',
          name: 'compare',
          meta: {
            module: '法治资源库',
            title: '智能版本比对',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/smartTools/compareResult',
          name: 'compare',
          meta: {
            module: '法治资源库',
            title: '比对结果',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        }
      ]
    },
    {
      path: '/fzzyk/lable',
      name: 'fzzyk_lableMgmt',
      // hidden: true, // 隐藏当前菜单
      meta: {
        module: '法治资源库',
        title: '标签管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/lable/lableClassify',
          name: 'lableClassify',
          meta: {
            module: '标签管理',
            title: '标签分类',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/lable/lableContent',
          name: 'lableContent',
          meta: {
            module: '标签管理',
            title: '标签维护',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        }, {
          path: '/fzzyk/lable/associatedLabel',
          name: 'fzzyk_lableMgmt_associated',
          meta: {
            module: '标签管理',
            title: '关联标签',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        }, {
          path: '/fzzyk/lable/labelDetail',
          name: 'labelDetail',
          hidden: true, // 隐藏当前菜单
          meta: {
            module: '标签管理',
            title: '关联标签',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        }
      ]
    },
    {
      path: '/fzzyk/zstp',
      name: 'zstp',
      // hidden: true, // 隐藏当前菜单
      meta: {
        module: '法治资源库',
        title: '知识图谱',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/fzzyk/annManagement',
      name: 'annManagement',
      meta: {
        module: '法治资源库',
        title: '公告管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/annManagement/announcement',
          name: 'fzzyk_annManagement_announcement',
          meta: {
            module: '法治资源库',
            title: '公告发布',
            roles: ['admin', 'group', 'user'],
            icon: ''
          },
        },
        {
          path: '/fzzyk/annManagement/registration',
          name: 'fzzyk_annManagement_registration',
          meta: {
            module: '法治资源库',
            title: '新建公告',
            roles: ['admin', 'group', 'user'],
            icon: ''
          },
        }
      ]
    },
    {
      path: '/fzzyk/division',
      name: 'division',
      // hidden: true, // 隐藏当前菜单
      meta: {
        module: '法治资源库',
        title: '行政区划管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
    },
    {
      path: '/fzzyk/release',
      name: 'release',
      meta: {
        module: '法治资源库',
        title: '颁布机关管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
    },
    {
      path: '/fzzyk/feedback',
      name: 'feedback',
      meta: {
        module: '法治资源库',
        title: '反馈管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/feedback/feedback',
          name: 'adminFx',
          meta: {
            module: '反馈管理',
            title: '意见反馈管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
      ]
    },
    {
      path: '/fzzyk/apply',
      name: 'apply',
      meta: {
        module: '法治资源库',
        title: '产品体验申请',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/fzzyk/lexicon',
      name: 'lexicon',
      // hidden: true, // 隐藏当前菜单
      meta: {
        module: '词库管理',
        title: '词库管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/lexicon/lexiconContent',
          name: 'lexiconContent',
          meta: {
            module: '词库管理',
            title: '词库内容管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/lexicon/lexiconType',
          name: 'lexiconType',
          meta: {
            module: '词库管理',
            title: '词库分类管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/lexicon/generalLexicon',
          name: 'generalLexicon',
          meta: {
            module: '词库管理',
            title: '通用词库',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/lexicon/legalLexicon',
          name: 'legalLexicon',
          meta: {
            module: '词库管理',
            title: '法律词汇库',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/lexicon/sameMeanLexicon',
          name: 'sameMeanLexicon',
          meta: {
            module: '词库管理',
            title: '同义词库',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/lexicon/sensitiveLexicon',
          name: 'sensitiveLexicon',
          meta: {
            module: '词库管理',
            title: '敏感词库',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
      ]
    },
    {
      path: '/fzzyk/regulatoryCodingManagement',
      name: 'regulatoryCodingManagement',
      meta: {
        module: '法治资源库',
        title: '编码管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/regulatoryCodingManagement/regulatoryCodingManagement',
          name: 'adminFx',
          meta: {
            module: '编码管理',
            title: '法规编码管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
      ]
    },
    {
      path: '/fzzyk/suoyinManage',
      name: 'suoyinManage',
      meta: {
        module: '法治资源库',
        title: '索引管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/suoyinManage/suoyinManage',
          name: '',
          meta: {
            module: '索引管理',
            title: '法规索引管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
      ]
    },
    {
      path: '/fzzyk/statisticAnalysis',
      name: 'statisticAnalysis',
      // hidden: true, // 隐藏当前菜单
      meta: {
        module: '统计分析',
        title: '统计分析',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/statisticAnalysis/adminFx',
          name: 'adminFx',
          meta: {
            module: '统计分析',
            title: '用户行为分析',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/statisticAnalysis/labelFx',
          name: 'labelFx',
          meta: {
            module: '统计分析',
            title: '标签分析',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/statisticAnalysis/lawFx',
          name: 'labelFx',
          meta: {
            module: '统计分析',
            title: '法律法规分析',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        }
      ]
    },
    {
      path: '/fzzyk/expimp',
      name: 'expimp',
      meta: {
        module: '导出导入',
        title: '导出导入',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/fzzyk/judgment',
      name: 'judgment',
      meta: {
        module: '裁判文书',
        title: '裁判文书',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/fzzyk/judgment/manage',
          name: 'judgment_manage',
          meta: {
            module: '裁判文书管理',
            title: '裁判文书管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/fzzyk/judgment/manage/modify',
          name: 'judgment_manage_modify',
          meta: {
            module: '裁判文书编辑',
            title: '裁判文书编辑',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
      ]
    },
  ]
}
