<template>
    <div class="com-upload">
        <a-config-provider :locale="zhCN">
            <div class="files-uploadtype-r-upload">
                <div class="upload-btn">
                    <z-upload
                            :file-list="fileList"
                            :accept="FileAccept"
                            multiple
                            @preview="handlePreview"
                            @rename="handleRename"
                            @change="handleChange2"
                            @selected="handleSelect"
                            @download="handleDownload"
                            :remove="handleRemove"
                            :before-upload="beforeUpload"
                            :show-upload-list="false"
                            @click.native="openFileDialogOnClick2"
                    >
                        <div v-if="!readOnly">
<!--                            <div v-if="fileList.length < limitCount && !readOnly">-->
                            <a-button type="link">
                                <i class="iconhr ico-11"></i> 上传附件
                            </a-button>
                        </div>
                    </z-upload>
                </div>
                <div class="uploaded-files-list">
                    <div v-for="item in fileList" class="uploaded-files-list-item">
                        <span class="mult-file-style" @click="handlePreviewMult(item)">{{item.name}}</span>
<!--                        <span class="mult-file-style">{{item.name}}</span>-->
                        <a class="flists-opr-btn" v-if="!readOnly && DeletePermit" @click="handleRemoveFake(item)">删除</a>
                        <a class="flists-opr-btn" v-if="item.fid && DownLoadPermit" @click="handleDownload(item)">下载</a>
                        <!--                                    <a class="flists-opr-btn">转换OFD</a>-->
                    </div>
                    <div v-if="fileList.length===0&&readOnly" class="uploaded-files-list-item"><span class="mult-file-style">暂无文件</span></div>
                </div>
            </div>
        </a-config-provider>

        <a-modal v-model="previewVisible" title="预览" width="600px" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
        <select-level :file.sync="singleFile" @handleSelLel="handleSelLel2"
                      :selfilelelVisible.sync="selfilelelVisible"></select-level>
        <rename :file.sync="singleFile" @handleRenamed="handleRenamed" :selfilelelVisible.sync="renameVisible"></rename>

        <com-img-viewer
                v-if="dialogVisible"
                :on-close="closeViewer"
                :url-list="GetSrcListByCache()"
                :initialIndex="PriviewStartIndex"
                :titles="dialogTitle"
        ></com-img-viewer>
        <com-pdf-viewer
                v-if="pdfdialogVisible"
                :title="pdfViewerTitle"
                :on-close="closePdfViewer"
                :src="pdfViewerSrc"
                :fid="singlefid"
                :mime-type="mimeType"
        ></com-pdf-viewer>
    </div>
</template>

<script>
    import ZUpload from 'common/lib/Upload/Package/zUpload'
    import SelectLevel from './SelectLevel'
    import Rename from './RenameDialog'
    import {downFile} from 'common/utils/file/downFile'
    import mixins from './mixin/utls'
    import ComPdfViewer from './preview/PdfViewer';
    import ComImgViewer from './preview/ImgViewer';
    export default {
        mixins: [mixins],
        props: {
            clickTextDown: {
                type: Boolean,
                default: false
            },
            isAutoLoad: {
                type: Boolean,
                default: false
            },
            isShowTip: {
                type: Boolean,
                default: true
            },
            limitCount: {
                type: Number,
                default: 8
            },
            limitFileType: {
                type: String,
                default: 'txt、pdf、doc、docx、xls、xlsx、ppt、jpg、png、bmp、gif、rar、zip'
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            batchDeleteAble: {
                type: Boolean,
                default: false
            },
            businesstypeId: '',
            files: {
                type: Array
            },
            isNeedLevel: true
        },
        components: {
            ZUpload,
            SelectLevel,
            Rename,
            ComPdfViewer,
            ComImgViewer
        },
        data() {
            return {}
        },
        created() {
            this.fileList = this.files
        },
        watch: {
            // businesstypeId: function (businesstypeId) {
            //     if (businesstypeId) {
            //         this.LoadDataMult(businesstypeId)
            //     } else {
            //         this.fileList = []
            //     }
            // }
            files: function (files) {
                this.fileList = files
                console.log(files)
            }
        },
        computed: {
            // 允许的文件类型
            FileAccept() {
                let acceptstr = this.limitFileType.split('、').map(item => {
                    return '.' + item
                }).join(',')
                return acceptstr
            }
        },
        methods: {
            handlePreviewMult (file) {
                console.log(file)
                if (this.clickTextDown) {
                    this.handleDownload(file)
                } else {
                    this.handlePreview(file)
                }
            },
            // 多选情况下的删除 假删除
            handleRemoveFake (file) {
                if (file.fid) {
                    this.handleRemove(file)
                } else {
                    const newFileList = this.fileList
                    newFileList.forEach((item, index) => {
                        if (file.fid === item.fid) {
                            newFileList.splice(index, 1)
                        }
                    })
                    this.fileList = newFileList;
                    const ids = this.fileList.map(item => {
                        return item.fid
                    }).join(',')
                    this.$emit('update:ids', ids)
                    this.$message.success('删除成功!');
                }
                this.$emit('fileList', this.fileList)
            },
            // 选定密级之后不需要上传
            handleSelLel2(obj) {
                console.log(this.tempFileList)
                this.fileList.forEach(item => {
                    if (item.uid === obj.file.uid) {
                        item.level = obj.file.level
                    }
                })
                this.tempFileList.forEach(item => {
                    item['status'] = 'done'
                    item['filetype'] = item.name.split('.').reverse()[0]
                    item['isSelect'] = item.isSelect === undefined ? false : item.isSelect
                    if (item.uid === obj.singleFile.uid) {
                        item.level = obj.singleFile.level
                        // item.url = this.isImgType(res.data.uploadFileResult.fileType) ? `${baseUrl}/platservice/images/getimg?fid=${res.data.uploadFileResult.fid}` : `${baseUrl}/platservice/fileupload/download?ids=${res.data.uploadFileResult.fid}`
                        // if (this.isImgType(item.filetype)) {
                        //     this.AddSrcCache(item); //添加到预览缓存
                        // }
                    }
                })
                this.fileList = this.tempFileList
                const idS = []
                this.$emit('fileList', this.fileList)
                this.fileList.forEach(item => {
                    idS.push(item.fid)
                })
                this.$emit('update:ids', idS.join(','))
                this.tempFileList = []

                if (this.isAutoLoad) {
                    this.$emit('autoUpload')
                }
                // this.handleUpload(obj)
            },
            // 上传服务器之前的操作
            beforeUpload(file) {
                console.log(file)
                if (file.size > 52428800) {
                    this.$message.error('上传附件不能超过 50M！')
                    return false
                }
                if (this.isNeedLevel === true || (window.G && window.G.ENV && window.G.ENV.networkType === 'nw')) {
                    // file.level = null
                    // 显示密级选择框
                    this.selfilelelVisible = true
                    // 传输给密级选择框
                    this.singleFile = file
                } else {
                    const fileObj = {
                        file: file,
                        singleFile: file
                    }
                    fileObj.singleFile.level = '6'
                    // this.handleSelLel2(fileObj)
                }
                return false;
            },
            // 选择文件发生改变时的钩子
            handleChange2({file, fileList}) {
                // this.fileList = fileList;
                const index = fileList.length - 1
                if (index > (this.fileList.length - 1)) {
                    fileList[index]['isSelect'] = false
                }
                this.tempFileList = fileList

                if (this.isNeedLevel === false) {
                    const fileObj = {
                        file: file,
                        singleFile: file
                    }
                    fileObj.singleFile.level = '6'
                    this.handleSelLel2(fileObj)
                }
            },
            // 附件下载
            handleDownload(file) {
                const url = '/platservice/fileupload/download'
                downFile(url, {ids: file.fid})
            },
            openFileDialogOnClick2 () {
                this.$emit('openFileDialogOnClickEvt')
            }
        }
    }
</script>

<style lang="less">
    .com-upload {
        .flists-opr-btn {
            font-size: 12px;
            padding: 0 3px;
            color: #9B9691;
        }
        .uploaded-files-list {
            padding-left: 10px;
        }
        .upload-btn,
        .uploaded-files-list,
        .uploaded-files-list-item{
            display: inline-block;
        }
        .uploaded-files-list-item {
            margin-right: 10px;
        }
        .mult-file-style {
            cursor: pointer;
        }
        .ant-upload-select-picture-card i {
            font-size: 32px;
            color: #999;
        }
        .ant-upload-list-item-info .anticon-copy {
            margin-top: 10px;
            font-size: 40px;
            color: #999 !important;
            --antd-wave-shadow-color: #999;
        }
        .ant-upload-select-picture-card .ant-upload-text {
            margin-top: 8px;
            color: #666;
        }
    }
</style>
