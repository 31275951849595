<template>
  <a-layout-sider :theme="theme"
                  v-model="collapsed"
                  :collapsible="collapsible"
                  :collapsedWidth="50"
                  :style="`overflow-y: auto; z-index: 999;padding:10px 0 0;margin-bottom:48px;background-color:rgb(0, 48, 95)`" :width="220"
                  @collapse="onCollapse">
    <sider-menu :mode="mode"
                :theme="theme"
                :collapsed="collapsed"
                :menuData="menuData"
                :openKeys="openKeys"
                @select="onSelect"
                @openChange="onOpenChange"/>
                <template slot="trigger">
                  <a-icon :type="collapsed ? 'menu-unfold' : 'menu-fold'" style="font-size:18px !important;color:#fff"/>
                </template>
                
  </a-layout-sider>
</template>

<script>
import SiderMenu from './SiderMenu'
export default {
  name: "MainSider",
  data () {
    return {
      // height: window.innerHeight - 64 - 48,
      openKeys: [],
      rootSubMenuKeys: []
    }
  },
  components: { SiderMenu },
  props: {
    collapsible: {
      type: Boolean,
      required: false,
      default: true
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    menuData: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'light'
    },
    mode: {
      type: String,
      required: false,
      default: 'inline'
    }
  },
  computed: {
    siderTheme() {
      return this.theme == 'light' ? this.theme : 'dark'
    }
  },
  created() {
    this.setActiveItem()
    if (this.menuData.length > 0) {
      console.log(this.menuData)
      // 初始化一级菜单数据
      this.menuData.forEach((item, index) => {
        this.rootSubMenuKeys.push(item.path)
      })
    }
  },
  methods: {
    setActiveItem() {
      // console.log("sider menuData >>>>>")
      // console.log(this.menuData)
    },
    onSelect (obj) {
      // 选中页面后添加到tab页
      let data = this.$store.getters.menuTop  // 二级导航
      for (var i = 0; i < data.length; i++) {
        let flg = false
        if (obj.key === data[i].path) {
          this.$store.dispatch('user/addMenutab', JSON.stringify(data[i]))
          flg = true
        } else {
          if (data[i].children) {
            for (let j = 0; j < data[i].children.length; j++) {
              if (obj.key === data[i].children[j].path) {
                this.$store.dispatch('user/addMenutab', JSON.stringify(data[i].children[j]))
                flg = true
              }
            }
          }
        }
        if (flg) {
          break
        }
      }
      sessionStorage.setItem('menutab', JSON.stringify(this.$store.getters.menuTab))
      this.$store.dispatch('user/setMenutabActive', this.$store.getters.menuTab.length - 1)
      // this.$store.dispatch('user/addMenutab', JSON.stringify(obj.key))
      this.$emit('menuSelect', obj)
    },
    onCollapse (collapsed, type) {
      // 监听折叠状态
      // console.log("onCollapse", collapsed);
      // this.collapsed = collapsed;
      this.$actions.setGlobalState({
        collapsed
      });
    },
    onOpenChange (openKeys) {
      console.log(this.menuData)
      console.log(openKeys)
      /*
      * author: kangwj
      * 解决页面一级菜单，同时只能打开一个问题（可以选择性关闭掉此功能）
      */
      let lastOpenKey = '';
      if(openKeys.length > 1){
        lastOpenKey = openKeys[openKeys.length - 1]
      }
      if(this.rootSubMenuKeys.indexOf(lastOpenKey) === -1){
        this.openKeys = openKeys;
      }else{
        this.openKeys = lastOpenKey ? [lastOpenKey] : []
      }
      console.log(lastOpenKey)
      console.log(lastOpenKey.split('/')[1])

      //设置二级菜单
      // this.menuData.map(item => {
      //   if(item.fullPath === lastOpenKey){
      //     this.$store.dispatch('user/setMenutop', JSON.stringify(item.children))
      //     // this.$store.commit('setMenutop', JSON.stringify(item.children))
      //     // sessionStorage.setItem('menu_top', JSON.stringify(item.children))
      //     // sessionStorage.setItem('menu_top_active', 0)
      //   }
      // })
    }
  }
}
</script>

<style lang="less" scoped>
@import url('../../lib/fontIcon/iconfont.css');
html *::-webkit-scrollbar-thumb{
    background: #345A7D;
}
/deep/.ant-menu{
  background: transparent !important;
}
/deep/.ant-menu-item{
  opacity: .75;
  height: 56px !important;
  line-height: 52px !important;
  margin: 0 !important;
  font-size: 16px!important;
}
/deep/.ant-menu-submenu-title{
  height: 56px!important;
  line-height: 56px!important;
  font-size: 16px!important;
}
/deep/.ant-menu-submenu-title:hover{
  color: #fff;
  background-color:#003b75
}
/deep/.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
/deep/.ant-menu-submenu-inline > .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after{
  background:#fff
}
/deep/.ant-menu-item:hover{
  opacity:1;
  background-color:#003b75
}
/deep/.ant-menu-item > a:hover{
  color: #fff;
}
/deep/.ant-menu-item-selected{
  opacity:1;
  background: linear-gradient(90deg,#198eff,#0876e2) !important;
  border-right: 6px solid #c6e3ff;
  font-weight: bold;
}
/deep/.ant-menu-item::after{
  border-right: none !important;
}
/deep/.ant-menu-submenu,/deep/.ant-menu-item > a{
  color: #fff;
}
/deep/ .ant-menu-inline{
  border: none
}
// /deep/ .ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-layout-sider{ // 一级和导航条底
  // background: #173a7c!important;
// }
/deep/ .ant-layout-sider, .ant-layout-sider-light, .ant-layout-sider-has-trigger{
  overflow-x: hidden;
  overflow-y: auto;
}

/deep/ .ant-menu-dark .ant-menu-inline.ant-menu-sub{ // 二级
  background: #1a2c5a!important;
  box-shadow: none!important;
}
// 三级hover态
/deep/ .ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-open, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover{
  background: transparent!important;
}

/deep/ .ant-layout-sider-children>.ant-menu>li.ant-menu-submenu-open>.ant-menu-submenu-title{
  background: rgba(255, 255, 255, 0.08)!important;
  /*padding: 4px 0!important;*/
}
/deep/.ant-menu-dark.ant-menu-inline .ant-menu-item {
  display: flex;
}
/deep/ .ant-layout-sider-trigger{ // 底部收缩按钮
  background: rgb(0, 48, 95)!important;
  .anticon{
    color: #fff;
    font-size: 14px!important;
  }
}
/deep/ .anticon, .iconhr{
  // color: #fff!important;
  font-size: 18px!important;
}
// 二级圆点儿
/deep/ .ant-layout-sider-children>.ant-menu>li>.ant-menu>.ant-menu-item:before,
/deep/ .ant-layout-sider-children>.ant-menu>li>.ant-menu>.ant-menu-submenu:before{
  position: relative!important;
}
/deep/ .ant-layout-sider-children>.ant-menu>li>.ant-menu>.ant-menu-item:before,
/deep/ .ant-layout-sider-children>.ant-menu>li>.ant-menu>.ant-menu-submenu:before{
  content: " ";
  display: block;
  position: absolute;
  top: 19px;
  left: 38px;
  height: 3px;
  width: 3px;
  background: rgb(91, 94, 93);
  opacity: .88;
  border-radius: 50% !important;
}
/deep/ .ant-layout-sider-children>.ant-menu>li>.ant-menu>.ant-menu-submenu:before{
  left: 27px!important;
}
  /deep/ .ant-menu-inline-collapsed{
    width: 50px;
  }
  /deep/ .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title{
    padding: 0 17px!important;
  }
    /*修复收缩状态不理想*/
    // /deep/ .ant-menu-submenu.ant-menu-submenu-vertical.ant-menu-submenu-selected{
        // background: #1E89FF!important;
    // }
  // /deep/ a.router-link-exact-active.router-link-active::before{
  //  background: #1E89FF;
  // }
</style>
