<template>
    <div class="div-drawer-sys-settings">
        <a-drawer
                placement="right"
                :closable="false"
                :visible="noticeVisible"
                :get-container="false"
                :wrap-style="{ position: 'absolute' }"
                @close="onClose"
                :width="drawerWidth"
                :maskStyle="{backgroundColor: 'transparent'}"
        >
          <a-spin :spinning="loading">
            <a-tabs class="dropdown-tabs" :tabBarStyle="{textAlign: 'center'}" :style="{width: '297px'}">
              <a-tab-pane tab="通知" key="1">
                <a-list class="tab-pane">
                  <a-list-item>
                    <a-list-item-meta title="你收到了 14 份新周报" description="一年前">
                      <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/ThXAXghbEsBCCSDihZxY.png"/>
                    </a-list-item-meta>
                  </a-list-item>
                  <a-list-item>
                    <a-list-item-meta title="你推荐的 曲妮妮 已通过第三轮面试" description="一年前">
                      <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/OKJXDXrmkNshAMvwtvhu.png"/>
                    </a-list-item-meta>
                  </a-list-item>
                  <a-list-item>
                    <a-list-item-meta title="这种模板可以区分多种通知类型" description="一年前">
                      <a-avatar style="background-color: white" slot="avatar" src="https://gw.alipayobjects.com/zos/rmsportal/kISTdvpyTAhtGxpovNWd.png"/>
                    </a-list-item-meta>
                  </a-list-item>
                </a-list>
              </a-tab-pane>
              <a-tab-pane tab="消息" key="2">
                <a-list class="tab-pane"></a-list>
              </a-tab-pane>
              <a-tab-pane tab="待办" key="3">
                <a-list class="tab-pane"></a-list>
              </a-tab-pane>
            </a-tabs>
          </a-spin>
        </a-drawer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "noticeDrawer",
        data() {
            return {
              loading: false,
              drawerWidth: 0
            }
        },
        watch: {
          noticeData: {
            immediate: true,
            handler: function (n) {
              this.drawerWidth = n ? 350 : 0
            }
          }
        },
        created () {
          this.loadding = true
        },
        computed: {
            ...mapState(['noticeVisible']),
            noticeData() {
              return this.$store.state.noticeVisible
            }
        },
        methods: {
            onClose() {
                this.$store.state.noticeVisible = false;
            }
        }
    }
</script>

<style lang="less" scoped>
  // 阴影
  /deep/ .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper{
    box-shadow: -5px 0 5px -5px rgba(24, 31, 36, .24) !important;
  }
    .item{
        display: flex;
        flex-direction: column;
        .tit{
            font-size: 16px;
            margin-bottom: 10px;
        }
        .cont{
            display: flex;
            justify-content: space-around;
            div{
                display: flex;
                flex-direction: column;
            }
        }
    }
</style>
