<template>
  <div id="subapp-viewport" />
</template>

<script>
export default {
  data() {
    return {}
  }
}
</script>

<style lang="less">
  #subapp-viewport {
    height: 100%;
    & >div {
      width: 100%;
      height: 100%;
    }
  }
</style>
