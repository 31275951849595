<template>
  <div class="table-row-oper" @click.stop>
    <ul class="row-oper-wrap" v-if="!isEdit">
      <template v-for="(item, index) in arrType">
        <!-- 有tips图标 -->
        <template v-if="getIconState(item) && isPermission(item)">
          <a-popconfirm
            @click.stop
            :title="`确认${getPopupTips(item) || item.name}?`"
            :disabled="isState(item.type) === '1'"
            :key="item.name"
            @confirm="clickEvent(item.type,  $event, item)"
            v-if="rowOper.includes(item.type) && isState(item.type) !== '0' ">
            <li
              class="row-oper-item"
              :class="{
                'drag-btn': item.type === 'rowSort' && isState(item.type) !== '1',
                'disable': isState(item.type) === '1'
              }"
              :key="index">
                <span v-if="isIcon" :class="['iconhr', item.icon]" :title="getPopupTips(item) || item.tips || item.name"></span>
                <span v-else>{{ item.name }}</span>
            </li>
          </a-popconfirm>
        </template>
        <!-- 无tips图标 -->
        <template v-else>
          <li
            class="row-oper-item"
            :class="{
                'drag-btn': item.type === 'rowSort' && isState(item.type) !== '1',
                'disable': isState(item.type) === '1'
            }"
            :key="index"
            v-if="rowOper.includes(item.type) && isState(item.type) !== '0'&& isPermission(item)"
            @click.stop="clickEvent(item.type,  $event, item)">
            <span v-if="isIcon" :class="['iconhr', item.icon]" :title="getPopupTips(item) || item.tips || item.name"></span>
            <span v-else>{{ item.name }}</span>
          </li>
        </template>
      </template>
    </ul>
    <ul class="row-oper-wrap" v-else>
      <li
        class="row-oper-item"
        @click.stop="clickEvent('rowEditConfirm',  $event)">
        <span v-if="isIcon" :class="['iconhr', 'ico-baocun']"></span>
        <span v-else>保存</span>
      </li>
      <li
          class="row-oper-item"
          @click.stop>
        <a-popconfirm title="确认取消本次操作吗?" @confirm="clickEvent('rowEditCancel',  $event)">
          <span v-if="isIcon" :class="['iconhr', 'ico-chehui']"></span>
          <span v-else>取消</span>
        </a-popconfirm>
      </li>
    </ul>
  </div>
</template>
<script>
import { getPermission } from 'common/utils/directivers/permission'
export default {
  inheritAttrs: false,
  props: {
    editState: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isIcon: true,
      isEdit: false,
      arrType: [
        {
          name: '修改',
          icon: 'ico-xiugai2',
          type: 'rowModify'
        },
        {
          name: '复制',
          icon: 'ico-fuzhi1',
          type: 'rowCopy'
        },
        {
          name: '查看',
          icon: 'ico-chakan',
          type: 'rowShow'
        },
        {
          name: '编辑',
          icon: 'ico-xiugai',
          type: 'rowEdit'
        },
        {
          name: '审批',
          icon: 'ico-shengpi',
          type: 'rowApproval'
        },
        {
          name: '撤回审批',
          icon: 'ico-quxiaoshenpi',
          type: 'rowRevokeApproval'
        },
        {
          name: '发布',
          icon: 'ico-fabu',
          type: 'rowRelease'
        },
        {
          name: '撤回发布',
          icon: 'ico-quxiaofabu',
          isPopconfirm: true,
          type: 'rowRevoke'
        },
        {
          name: '撤回',
          icon: 'ico-chehui',
          type: 'rowRecall'
        },
        {
          name: '校核',
          icon: 'ico-danweixinxixiaohe',
          type: 'rowCheck'
        },
        {
          name: '预览',
          icon: 'ico-kaohejieguoyulan',
          type: 'rowPreview'
        },
        {
          name: '提交',
          icon: 'ico-shangbao',
          type: 'rowSubmit'
        },
        {
          name: '删除',
          icon: 'ico-shanchu',
          isPopconfirm: true,
          type: 'rowDelet'
        },
        {
          name: '排序',
          tips: '拖拽排序',
          icon: 'ico-huanhang',
          type: 'rowSort'
        },
        {
          name: '上报',
          icon: 'ico-a-shangbao1',
          type: 'rowReport'
        },
        {
          name: '撤回上报',
          icon: 'ico-quxiaoshangbao1',
          isPopconfirm: true,
          type: 'rowRevokeReport'
        },
        {
          name: '确认',
          icon: 'ico-queren',
          isPopconfirm: true,
          type: 'rowOk'
        },
        {
          name: '纳入',
          icon: 'ico-biangengxieyi',
          isPopconfirm: true,
          type: 'rowAbsorb'
        },
        {
          name: '转为',
          icon: 'ico-yifahui',
          isPopconfirm: true,
          type: 'rowConvert'
        },
        {
          name: '查阅',
          icon: 'ico-shensulishi',
          type: 'rowReview'
        },
        {
          name: '分配',
          icon: 'ico-biangengxieyi',
          type: 'rowAssigned'
        },
        {
          name: '分配执法人员',
          icon: 'ico-biangengxieyi',
          type: 'onLaw'
        },
        {
          name: '操作记录',
          icon: 'ico-lishijilu',
          type: 'rowLog'
        }
      ],
      rowOper: [],
      permission: {}
    };
  },
  watch: {
    editState: {
      immediate: true,
      handler(n) {
        this.isEdit = n
      }
    }
  },
  created() {
    this.permission = this.$attrs.permission || {}
    this.rowOper = Object.keys(this.$listeners);
    if(typeof this.$attrs.showEdit !== "undefined") {
      this.isEdit = this.$attrs.showEdit
    }
  },
  methods: {
    recovery() {
      // 复原
    },
    isPermission (item) {
      if(this.permission[item.type]) {
        let permName = this.permission[item.type]
        return getPermission(permName)
      }
      return true
    },
    getIconState(item) {
      let iconState = false
      const rowOper = this.rowOper
      if(!item.isPopconfirm) {
        return false
      }
      // 判断是否有浮窗
      if(rowOper.includes(item.type)) {
        iconState =  true
      }
      return iconState
    },
    getPopupTips(item){
      let tips = undefined;
      if (this.$attrs[item.type + '_Tips']){
        tips = this.$attrs[item.type + '_Tips'];
      }
      return tips;
    },
    isState(key, str) {
      let stateNum = this.$attrs[key];
      if (typeof stateNum === 'number') {
        stateNum = this.$attrs[key].toString();
      }
      return stateNum;
    },
    clickEvent(listenersName, event, item) {
      if (item && this.isState(item.type) === '1') {
        return;
      }
      if (listenersName === 'rowEdit') {
        this.isEdit = true;
      }
      if (['rowEditConfirm', 'rowEditCancel'].includes(listenersName)) {
        this.isEdit = false;
      }
      event.$el = this
      this.$emit(listenersName, event);
    }
  }
};
</script>
<style scoped>
.table-row-oper {
    display: inline-block;
}

.table-row-oper .row-oper-wrap {
    /*display: flex;*/
    margin: 0;
    text-align: left;
}

.table-row-oper .row-oper-item {
    display: inline-block;
    width: 38px;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.table-row-oper .row-oper-item.disable .iconhr {
    color: #b3b3b3;
    cursor: no-drop;
}

.table-row-oper .row-oper-item:not(:first-child):before {
    position: absolute;
    left: 0;
    top: 50%;
    display: inline-block;
    content: '';
    width: 1px;
    height: 80%;
    background: #cccccc;
    transform: translateY(-50%);
}

</style>
