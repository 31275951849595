<template>
  <a-layout class="pst-layout">
    <a-layout-sider class="pst-lay-side" :class="{'no-space': !layoutSpace}" :width="leftWidth">
      <div class="side-top" v-if="$slots.sideTop">
        <slot name="sideTop"></slot>
      </div>
      <div class="side-main" :class="{ 'no-top': !$slots.sideTop }">
        <slot name="sideMain"></slot>
      </div>
    </a-layout-sider>
    <middle-con-layout
      :space="space"
      :layoutSpace="layoutSpace"
      :noBottom="noBottom"
      :conTopHide="conTopHide"
      :noSpaceTop="noSpaceTop"
      compose
    >
      <slot slot="contentTop" name="contentTop"></slot>
      <slot slot="contentTopLeft" name="contentTopLeft"></slot>
      <slot slot="contentTopRight" name="contentTopRight"></slot>
      <slot slot="contentMain" name="contentMain"></slot>
      <slot slot="contentBottom" name="contentBottom"></slot>
      <slot slot="contentBottomLeft" name="contentBottomLeft"></slot>
      <slot slot="contentBottomRight" name="contentBottomRight"></slot>
    </middle-con-layout>
    <slot></slot>
  </a-layout>
</template>
<script>
import MiddleConLayout from './MiddleConLayout'
export default {
  name: 'pageSideTree',
  props: {
    space: {
      type: Object,
      default: () => ({
        col: '8',
        row: '0'
      })
    },
    sideWidth: {
      default: 300
    },
    sideMaxWidth: {
      default: 300
    },
    sideMinWidth: {
      default: 200
    },
    layoutSpace: {
      default: true
    },
    noBottom: Boolean,
    conTopHide: Boolean,
    noSpaceTop: Boolean
  },
  data() {
    return {
      leftWidth: 300
    }
  },
  components: {
    MiddleConLayout
  },
  computed: {
  },
  created () {
    window.addEventListener('resize', () => {
      this.calcViewWidth()
    })
    this.calcViewWidth()
  },
  methods: {
    calcViewWidth() {
      const maxWidth = this.sideMaxWidth
      const minWidth = this.sideMinWidth
      const windowWidth = (this.sideWidth / 1920) * document.body.offsetWidth
      this.leftWidth = Math.min(Math.max(windowWidth, minWidth), maxWidth)
    }
  }
}
</script>
<style scoped>
.pst-layout {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  /* padding-top: 10px; */
}
.pst-layout .pst-lay-side {
  height: 100%;
  background: #ffffff;
  border-radius: 0 6px 6px 0;
  box-sizing: border-box;
}
.pst-layout .pst-lay-side /deep/ .ant-layout-sider-children {
  display: flex;
  flex: auto;
  flex-direction: column;
}
.pst-layout .pst-lay-side.no-space {
  border-radius: 0;
}
.pst-layout .pst-lay-side .side-top {
  display: flex;
  align-items: center;
  padding: 10px;
}
.pst-layout .pst-lay-side .side-main {
  height: 100%;
  padding-left: 10px;
  overflow: auto;
}
.pst-layout .pst-lay-side .side-main.no-top {
  display: flex;
  flex: auto;
  flex-direction: column;
  padding: 0;
}
.pst-layout .pst-lay-content {
  flex: 1;
}
</style>
