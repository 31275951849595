<template>
  <div
    class="bdc-tree-node"
    @click.stop="handleClick(node)"
    @contextmenu="($event) => this.handleContextMenu($event)"
    v-show="node.visible"
    :class="{
      'is-expanded': expanded,
      'is-current': node.isCurrent,
      'is-hidden': !node.visible,
      'is-focusable': !node.disabled,
      'is-checked': !node.disabled && node.checked
    }"
    role="treeitem"
    tabindex="-1"
    :aria-expanded="expanded"
    :aria-disabled="node.disabled"
    :aria-checked="node.checked"
    :draggable="tree.draggable"
    @dragstart.stop="handleDragStart"
    @dragover.stop="handleDragOver"
    @dragend.stop="handleDragEnd"
    @drop.stop="handleDrop"
    ref="node"
  >
    <div class="bdc-tree-node__content"
      :style="{ 'padding-left': (node.level - 1) * tree.indent + 'px' }">
<!--      <span-->
<!--        @click.stop="handleExpandIconClick"-->
<!--        :class="[-->
<!--          { 'is-leaf': node.isLeaf, expanded: !node.isLeaf && expanded },-->
<!--          'bdc-tree-node__expand-icon',-->
<!--          tree.iconClass ? tree.iconClass : 'bdc-icon-caret-right'-->
<!--        ]"-->
<!--      >-->
<!--      </span>-->
      <a-icon
        @click.stop="handleExpandIconClick"
        :type="tree.iconClass ? tree.iconClass : 'caret-right'"
        :class="[
          { 'is-leaf': node.isLeaf, expanded: !node.isLeaf && expanded },
          'bdc-tree-node__expand-icon'
        ]"
      />
      <bd-checkbox
        v-if="showCheckboxDom(node)"
        v-model="node.checked"
        :indeterminate="node.indeterminate"
        :disabled="!!node.disabled"
        @click.native.stop
        @change="handleCheckChange"
        class="Comcheckbox"
      >
      </bd-checkbox>
      <span
        v-if="node.loading"
        class="bdc-tree-node__loading-icon bdc-icon-loading">
      </span>
      <node-content :node="node"></node-content>
    </div>
    <bd-collapse-transition>
      <div
        class="bdc-tree-node__children"
        v-if="!renderAfterExpand || childNodeRendered"
        v-show="expanded"
        role="group"
        :aria-expanded="expanded"
      >
        <bd-tree-node
          :render-content="renderContent"
          v-for="child in node.childNodes"
          :render-after-expand="renderAfterExpand"
          :show-checkbox="showCheckbox"
          :specified-multi-choice="specifiedMultiChoice"
          :check-on-click-node="checkOnClickNode"
          :key="getNodeKey(child)"
          :node="child"
          @node-collapse="handleChildNodeCollapse"
          @node-expand="handleChildNodeExpand">
        </bd-tree-node>
      </div>
    </bd-collapse-transition>
  </div>
</template>

<script type="text/jsx">
  import BdCollapseTransition from 'common/lib/element/src/transitions/collapse-transition';
  import BdCheckbox from 'common/lib/element/packages/checkbox';
  import emitter from 'common/lib/element/src/mixins/emitter';
  import { getNodeKey } from './model/util';
  import node from "./model/node";
  import fa from "../../../src/locale/lang/fa";

  export default {
    name: 'BdTreeNode',

    componentName: 'BdTreeNode',

    mixins: [emitter],

    props: {
        isLinkage: {
            type: Boolean,
            default: true
        },
        expandMeth: {
            type: Boolean
        },
        specifiedMultiChoice: {
            type:String,
        },
        highest: {
            type: Boolean
        },
        checkOnClickNode: Boolean,
      node: {
        default() {
          return {};
        }
      },
      props: {},
      renderContent: Function,
      renderAfterExpand: {
        type: Boolean,
        default: true
      },
      showCheckbox: {
        type: Boolean,
        default: false
      }
    },

    components: {
      BdCollapseTransition,
      BdCheckbox,
      NodeContent: {
        props: {
          node: {
            required: true
          }
        },
        render(h) {
          const parent = this.$parent;
          const tree = parent.tree;
          const node = this.node;
          const { data, store } = node;
          return (
            parent.renderContent
              ? parent.renderContent.call(parent._renderProxy, h, { _self: tree.$vnode.context, node, data, store })
              : tree.$scopedSlots.default
                ? tree.$scopedSlots.default({ node, data })
                : <span class="bd-tree-node__label">{ node.label }</span>
          );
        }
      }
    },

    data() {
      return {
        treeExpandMeth: false,
        tree: null,
        expanded: false,
        childNodeRendered: false,
        oldChecked: null,
        oldIndeterminate: null
      };
    },

    watch: {
      'node.indeterminate'(val) {
        this.handleSelectChange(this.node.checked, val);
      },

      'node.checked'(val) {
        this.handleSelectChange(val, this.node.indeterminate);
      },

      'node.expanded'(val) {
        this.$nextTick(() => this.expanded = val);
        if (val) {
          this.childNodeRendered = true;
        }
      },
      'expandMeth'(val) {
          this.treeExpandMeth = val
      }
    },
    computed: {

    },
    methods: {
        showCheckboxDom () {

            // debugger
            // console.log(this.specifiedMultiChoice)
            var state = false
            // if (this.specifiedMultiChoice === undefined) {
            if (this.showCheckbox) {
                if (this.specifiedMultiChoice === undefined) {
                    if (!this.highest) {
                        if (this.node.level === 1) {
                            state = false
                        } else {
                            state =  true
                        }
                    } else {
                        state =  true
                    }
                } else {
                    // console.log(this.specifiedMultiChoice)
                    // console.log(this.node.hasOwnProperty(this.specifiedMultiChoice))
                    if (this.node.data.hasOwnProperty(this.specifiedMultiChoice)) {
                        state = true
                    } else {
                        state = false
                    }
                }

            } else {
                state =  false
            }
            return state
        },
      getNodeKey(node) {
        return getNodeKey(this.tree.nodeKey, node.data);
      },
      handleSelectChange(checked, indeterminate) {
        if (this.oldChecked !== checked && this.oldIndeterminate !== indeterminate) {
          this.tree.$emit('check-change', this.node.data, checked, indeterminate);
        }
        this.oldChecked = checked;
        this.indeterminate = indeterminate;
      },

      handleClick(e) {
          this.$emit('update:defaultCheckedKeys', [])
        const store = this.tree.store;
        store.setCurrentNode(this.node);
        this.tree.$emit('current-change', store.currentNode ? store.currentNode.data : null, store.currentNode);
        this.tree.currentNode = this;
          //  此处由下面挪到上面
          if (this.tree.checkOnClickNode && !this.node.disabled) {
              this.handleCheckChange(null, !e.checked);
          }
        if (this.tree.expandOnClickNode) {
          // this.handleExpandIconClick();
            if (e.expandMeth){
                if (!this.expandMeth) {
                    if (this.node.isLeaf) return;
                    // if (this.expanded) {
                    //     this.node.collapse();
                    // } else {
                    //     this.node.expand();
                    // }
                } else {
                    this.handleExpandIconClick();
                }
            } else {
                if (!this.treeExpandMeth) {
                    if (this.node.isLeaf) return;
                    // if (this.expanded) {
                    //     this.node.collapse();
                    // } else {
                    //     this.node.expand();
                    // }
                } else {
                    this.handleExpandIconClick();
                }
            }

        }

        // if (this.tree.checkOnClickNode && !this.node.disabled) {
        //     console.log(e)
        //   // this.handleCheckChange(null,null, {
        //   //   target: { checked: !this.node.checked }
        //   // });
        //   this.handleCheckChange(null, !e.checked);
        // }
        this.tree.$emit('node-click', this.node.data, this.node, this);
      },

      handleContextMenu(event) {
        if (this.tree._events['node-contextmenu'] && this.tree._events['node-contextmenu'].length > 0) {
          event.stopPropagation();
          event.preventDefault();
        }
        this.tree.$emit('node-contextmenu', event, this.node.data, this.node, this);
      },

      handleExpandIconClick() {
        if (this.node.isLeaf) return;
        if (this.expanded) {
          //  注释掉的代码 会影响  默认展开树节点的闭合   如果对其他组件有影响再放开
          // this.tree.$emit('node-collapse', this.node.data, this.node, this);
          this.$emit('node-collapse', this.node.data, this.node, this);
          this.node.collapse();
        } else {
          this.node.expand();
          this.$emit('node-expand', this.node.data, this.node, this);
        }
      },
        checkChildNodeCheck (node) {
            let isHasCheck = false
            let isHasNotCheck = false
            if (node.isLeaf === false && node.childNodes && node.childNodes.length > 0) {
                node.childNodes.forEach(item => {
                    // item.checked = e
                    if (item.checked){
                        isHasCheck = true
                    } else {
                        isHasNotCheck = true
                    }
                    this.checkChildNodeCheck(item)
                })
            }
            return {
                isHasCheck: isHasCheck,
                isHasNotCheck: isHasNotCheck
            }
        },
        childNodeCheck1 (node) {
            if (node.isLeaf === false && node.childNodes && node.childNodes.length > 0) {
                node.childNodes.forEach(item => {
                    item.checked = true
                    this.childNodeCheck1(item, true)
                })
            }
        },
        childNodeCheck (node, e) {
            if (node.indeterminate === true) {
                this.childNodeCheck1(node)
                node.indeterminate = false
                node.checked = true
                return
            } else {
                if (node.isLeaf === false && node.childNodes && node.childNodes.length > 0) {
                    node.childNodes.forEach(item => {
                        item.checked = e
                        this.childNodeCheck(item, e)
                    })
                }
            }
        },
      checkbox (data, node, e) {
          var then = this.$parent
          if (then.isLinkage) {
               this.childNodeCheck(node, e)
              const {isHasCheck,isHasNotCheck } = this.checkChildNodeCheck(node.parent)
              if (isHasCheck === true && isHasNotCheck === true) {
                  node.parent.indeterminate = true
              } else if (isHasCheck === false && isHasNotCheck === true) {
                  node.parent.indeterminate = false
              }else if (isHasCheck === true && isHasNotCheck === false) {
                  node.parent.indeterminate = false
                  node.parent.checked = true
              }
          }
          if (then.nodeCheckbox === undefined) {
              if (then.$parent.nodeCheckbox === undefined) {
                  then.$parent.$parent.nodeCheckbox(data,this.oldChecked,node)
              } else {
                  then.$parent.nodeCheckbox(data,this.oldChecked,node)
              }
          } else {
              this.$parent.nodeCheckbox(data,this.oldChecked,node)
          }
          // console.log(then.$parent)

      },
      handleCheckChange(value, ev) {
        this.checkbox(this.node.data, this.node, ev)
        this.node.setChecked(ev.target? ev.target.checked: ev, !this.tree.checkStrictly);
        this.$nextTick(() => {
          const store = this.tree.store;
          this.tree.$emit('check', this.node.data, {
            checkedNodes: store.getCheckedNodes(),
            checkedKeys: store.getCheckedKeys(),
            halfCheckedNodes: store.getHalfCheckedNodes(),
            halfCheckedKeys: store.getHalfCheckedKeys(),
          }, ev.target? ev.target.checked: ev);
        });
      },
      handleChildNodeCollapse(nodeData, node, instance) {
        this.broadcast('BdTreeNode', 'tree-node-collapse', node);
        this.$emit('node-collapse', nodeData, node, instance);
      },
      handleChildNodeExpand(nodeData, node, instance) {
        this.broadcast('BdTreeNode', 'tree-node-expand', node);
        this.tree.$emit('node-expand', nodeData, node, instance);
      },

      handleDragStart(event) {
        if (!this.tree.draggable) return;
        this.tree.$emit('tree-node-drag-start', event, this);
      },

      handleDragOver(event) {
        if (!this.tree.draggable) return;
        this.tree.$emit('tree-node-drag-over', event, this);
        event.preventDefault();
      },

      handleDrop(event) {
        event.preventDefault();
      },

      handleDragEnd(event) {
        if (!this.tree.draggable) return;
        this.tree.$emit('tree-node-drag-end', event, this);
      }
    },

    created() {
      const parent = this.$parent;

      if (parent.isTree) {
        this.tree = parent;
      } else {
        this.tree = parent.tree;
      }

      const tree = this.tree;
      if (!tree) {
        console.warn('Can not find node\'s tree.');
      }

      const props = tree.props || {};
      const childrenKey = props['children'] || 'children';

      this.$watch(`node.data.${childrenKey}`, () => {
        this.node.updateChildren();
      });

      if (this.node.expanded) {
        this.expanded = true;
        this.childNodeRendered = true;
      }

      if(this.tree.accordion) {
        this.$on('tree-node-expand', node => {
          if(this.node !== node) {
            this.node.collapse();
          }
        });
      }
    }
  };
</script>
<style lang="less" scoped>
    /*/deep/.showCheckbox {*/
    /*    margin-right: 20px !important;*/
    /*}*/
    .Comcheckbox{
        margin-right: 5px !important;
    }
</style>
