<!--设置表头tabs选项卡-->
<template>
    <div class="setHeader">
        <!--设置表头tabs-->
        <table-com tableName="three" key="3" :loading.sync="loading" :columns="columns" @refresh="refresh"
                   :data="tableData"  ref='child'>
<!--            @saveData="saveDataFn"-->
            <div class="bdSetHeader-btn" slot="bdSetHeader-btn">
                <a-button @click="addColumnVisi=true" class="btn">
                    新增
                </a-button>
                <!--                <a-button type="primary" @click="saveFn" class="btn">-->
                <!--                    保存-->
                <!--                </a-button>-->
            </div>
        </table-com>
        <!--添加列-->
        <a-modal title="添加列" :visible="addColumnVisi"  dialog-class="transfor-model"
                 @cancel="closeAddColumnFn($event)" @ok="closeAddColumnFn($event)" width="750px">
            <div style="padding-bottom: 1rem">
                信息集：
                <a-select @change="XxtableListChange" style="width: calc( 100% - 62px )">
                    <a-select-option :key="index" :value="item.tblcod" v-for="(item,index) in CotabList">
                        {{`${item.tblcodalias}(${item.tblcod})`}}
                    </a-select-option>
                </a-select>
            </div>
            <ComTransfer
                    type="single"
                    :data-source="dataSource"
                    :defaultTargetKeys="targetKeys"
                    checkedName="信息项"
                    @change="transferChange3"
            ></ComTransfer>
            <a-button @click="closeAddColumnFn($event)" slot="footer">
                取消
            </a-button>
            <a-button type="primary" @click="closeAddColumnFn($event), addCustom()" slot="footer">
                确认
            </a-button>
        </a-modal>
    </div>
</template>

<script>
    import {req} from 'common/api/index'
    import data from './data.js'
    import TableCom from './TableCom'
    import { ComTransfer } from '../../ComTransfer/index'

    export default {
        name: 'AddCondition',
        components: {
            TableCom,
            ComTransfer
        },
        props: {
            tableName: { // 当前的数据集名称
                type: String
            }
        },
        data() {
            return {
                // 树形穿梭框
                dataSource: [],
                targetKeys: [],
                addColumnVisi: false,
                CotabList: [], // 信息集列表
                columns: data.columns,
                loading: false,
                tableData: [],
                tranferSelectedRows3: []
            }
        },
        created() {
            this.getCotabList()
            this.loadData()
        },
        methods: {
            setReq(list) {
                return new Promise((resolve, reject) => {
                    const treeData = list.map(item => {
                        const obj = {
                            title: `${item.fldcpt}(${item.fldcod})`,
                            key: item.fldcod,
                            ...item
                        }
                        return obj
                    })
                    resolve(treeData)
                })
            },
            transferChange3(e) {
                // 返回穿梭后的数据
                console.log(e)
                this.tranferSelectedRows3 = e
            },
            // 触发子组件的保存
            saveFn() {
                // this.$refs.child.saveFn()
                const tempData = this.tableData.map(item => {
                    return {
                        ...item,
                        ismultivalued: item.ismultivalued===true?1:0
                    }
                })
                this.$emit('getTableData', tempData)
            },
            // 加载datae3的数据
            loadData() {
                const self = this
                self.loading = true
                req('post_sym_tablequery_findByTable', {tablename: this.tableName}, true).then(res => {
                    if (res.code === 200) {
                        const tableData = []
                        res.data.forEach((item, index) => {
                            if (item.showQuery === 1) {
                                tableData.push({
                                    ...item,
                                    recordId: item.fldcod,
                                    showTable: item.showTable === 1,
                                    showQuery: item.showQuery === 1,
                                    editenable: item.editenable === 1,
                                    ismultivalued: item.ismultivalued === 1

                                })
                            }
                        })
                        self.tableData = tableData.map((item, ind) => {
                            return {
                                ...item,
                                num: ind + 1,
                            }
                        })
                    }
                    self.loading = false
                })
            },
            // 接收修改的数据并保存
            // saveDataFn(data) {
            //     let postData = []
            //     postData = data.map((item) => {
            //         const obj = {
            //             defaultValue: item.defaultValue,
            //             dmgrp: item.dmgrp,
            //             editenable: item.editenable === true ? 1 : 0,
            //             fldcod: item.fldcod,
            //             fldcodTitle: item.fldcodTitle,
            //             fldtyp: item.fldtyp,
            //             id: item.id,
            //             placeholder: item.placeholder,
            //             queryName: item.queryName,
            //             queryType: item.queryType,
            //             showQuery: item.showQuery === true ? 1 : 0,
            //             showTable: item.showTable === true ? 1 : 0,
            //             tableName: item.tableName,
            //             tblcod: item.tblcod
            //         }
            //         return obj
            //     })
            //     req('post_sym_qycdtion_updateCustom', {
            //         delTableQueryIdList: [],
            //         updateTableQueryList: postData
            //     }, true).then(res => {
            //         console.log(res)
            //     }).catch(() => {
            //     })
            // },
            // 关闭设置表头弹窗
            closeAddColumnFn(val) {
                this.addColumnVisi = false
            },
            addCustom() {
                this.tranferSelectedRows3.forEach(e => {
                    this.tableData.push({
                        ...e,
                        num: e.key,
                        fldcodTitle: e.fldcpt,
                        placeholder: '',
                        queryName: e.fldcpt,
                        queryType: e.fldtyp,
                        defaultVaule: '',
                        recordId: e.fldcod,
                        showTable: false,
                        showQuery: true,
                        editenable: true,
                        ismultivalued: e.ismultivalued===1 ? true : false
                    })
                })
                // this.targetKeys = []
            },
            // 穿梭框编辑
            handleChange(targetKeys, direction, moveKeys) {
                this.targetKeys = targetKeys
            },
            // 查询条件定义 信息集数据
            getCotabList() {
                this.targetKeys = []
                req('post_sym_qurycodtionlist', {
                    tablename: this.tableName
                }, true).then(res => {
                    this.CotabList = res.data
                })
            },
            async XxtableListChange(tblcod) {
                this.dataSource = []
                await req('post_sym_queryinfo_getlist', {code: tblcod}, true).then(res => {
                    this.setReq(res.data).then(res => {
                        this.dataSource = res
                    })
                })
            },
            // 删除刷新查询条件定义列表方法
            refresh(record) {
                console.log(record)
                console.log(this.tableData)
                const index = this.tableData.findIndex(item => {
                    return  item.key === record.key
                })
                console.log(index)
                if (index !== -1) {
                    this.tableData.splice(index, 1)
                }
                // if (state === 200) {
                //     // this.loadData()
                //
                // }
            },
        }
    }
</script>

<style lang="less" scoped>
    /deep/ .iconhr.ico-huanhang.drag-btn {
        display: none;
    }

    /deep/ .ant-modal.transfor-model .ant-modal-body {
        height: 450px;
        padding-bottom: 60px;
    }

    /*第三个表格过长需滚动x*/
    /deep/ .ant-table-content {
        width: 100% !important;
        height: 100% !important;
        position: relative !important;
        overflow: scroll !important;
    }

    /deep/ .ant-tabs-tabpane:nth-child(4) .ant-table-body {
        overflow: scroll !important;
    }

    /deep/ .ant-tabs-tabpane:nth-child(4) .ant-spin-container {
        height: 400px !important;
        overflow: auto;

        &::-webkit-scrollbar { //整体样式
            height: 5px;
        }

        &::-webkit-scrollbar-thumb { //滑动滑块条样式
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            // background: #00aaff;
            background: #d6e9fa;
        }
    }

    /deep/ .ant-tabs-tabpane:nth-child(3) .ant-table-body {
        width: 2000px !important;
        overflow: scroll !important;
    }

    /deep/ .ant-tabs-tabpane:nth-child(3) .ant-spin-container {
        height: 400px !important;
        overflow: auto;

        &::-webkit-scrollbar { //整体样式
            height: 5px;
        }

        &::-webkit-scrollbar-thumb { //滑动滑块条样式
            border-radius: 4px;
            -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
            // background: #00aaff;
            background: #d6e9fa;
        }
    }

    /deep/ .ant-tabs-tabpane:nth-child(3) .ant-table-content {
        /*height:500px;*/
        width: 2000px !important;
    }

    // tabs选项卡样式
    /deep/ .ant-tabs-bar.ant-tabs-top-bar.ant-tabs-default-bar {
        border-bottom-width: 0 !important;
        background-color: #fff;
        border-radius: 8px;
    }

    /deep/ .ant-tabs-nav .ant-tabs-tab {
        color: #191F24;
        margin: 0 20px;
        padding: 0 0 18px 0;
        font-size: 16px;
    }

    /deep/ .ant-tabs-tab.ant-tabs-tab-disabled {
        color: #999;
    }

    /deep/ .ant-tabs-extra-content {
        line-height: 55px;
        margin-right: 18px;
    }

    /deep/ .ant-tabs-nav .ant-tabs-tab:hover {
        color: #6B85AC !important;
    }

    /deep/ .ant-tabs-nav .ant-tabs-tab-active {
        color: #6B85AC !important;
    }

    /deep/ .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
        background-color: #6B85AC !important;
        height: 3px;
    }
    /deep/ .checkbox-list .list-item,
    /deep/ .simple-list .list-item>span{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
