export default {
    path: '/apm',
    name: 'apm',
    redirect: '/apm/functionSystemMgmt/BasicInfMgmt',
    meta: {
        module: 'apm',
        title: '应用平台管理',
        roles: ['admin', 'group', 'user'],
        icon: 'yingyongpingtaiguanli'
    },
    children: [
        {
            path: '/apm/functionSystemMgmt',
            name: 'functionSystemMgmt',
            redirect: '/apm/functionSystemMgmt/tableCustomization',
            meta: {
                module: '应用平台管理',
                title: '信息体系管理',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },
            children: [
                {
                    path: '/apm/functionSystemMgmt/BasicInfMgmt',
                    name: 'BasicInfMgmt',
                    meta: {
                        module: '信息体系管理',
                        title: '基础信息管理',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/functionSystemMgmt/codeTableMgmt',
                    name: 'codeTableMgmt',
                    meta: {
                        module: '信息体系管理',
                        title: '代码表管理',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/functionSystemMgmt/BusinessInfMgmt',
                    name: 'BusinessInfMgmt',
                    meta: {
                        module: '信息体系管理',
                        title: '业务信息管理',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                }
            ]
        },
        {
            path: '/apm/misFunction',
            name: 'misFunction',
            meta: {
                module: '应用平台管理',
                title: '模块功能注册',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/apm/functionModuleRegister',
            name: 'functionModuleRegister',
            meta: {
                module: '应用平台管理',
                title: '模块功能注册',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/apm/option-set',
            name: 'optionSet',
            meta: {
                module: '应用平台管理',
                title: '系统信息配置',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        // {
        //     path: '/apm/menuu',
        //     name: 'menuu',
        //     meta: {
        //         module: '应用平台管理',
        //         title: '移动端菜单',
        //         roles: ['admin', 'group', 'user'],
        //         icon: ''
        //     }
        // },
        {
            path: '/apm/menu',
            name: 'menu',
            meta: {
                module: '应用平台管理',
                title: '移动端菜单',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        // {
        //     path: '/apm/menuGroups',
        //     name: 'menuGroups',
        //     meta: {
        //         module: '应用平台管理',
        //         title: '移动端菜单组',
        //         roles: ['admin', 'group', 'user'],
        //         icon: ''
        //     }
        // },
        {
            path: '/apm/tableCustomise',
            name: 'apm_tableCustomise',
            meta: {
                module: '应用平台管理',
                title: '系统定制',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },
            children: [
                {
                    path: '/apm/tableCustomise/maintainTemplateGroupMgmt',
                    name: 'maintainTemplateGroupMgmt',
                    meta: {
                        module: '系统定制',
                        title: '维护模板组管理',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/tableCustomise/tableCustomise',
                    name: 'tableCustomise',
                    meta: {
                        module: '系统定制',
                        title: '表单模板定制',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/tableCustomise/funTemplateCustomise',
                    name: 'funTemplateCustomise',
                    meta: {
                        module: '系统定制',
                        title: '功能模块定制',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                // {
                //     path: '/apm/functionSystemMgmt/tableCustomization',
                //     name: 'apm_functionSystemMgmt_tableCustomization',
                //     hidden: true, // 隐藏当前菜单
                //     meta: {
                //         module: '系统定制',
                //         title: '表格定制',
                //         roles: ['admin', 'group', 'user'],
                //         icon: ''
                //     }
                // },
                {
                    path: '/apm/functionSystemMgmt/tableCustomPreview',
                    name: 'apm_functionSystemMgmt_tableCustomPreview',
                    meta: {
                        module: '系统定制',
                        title: '表格定制预览',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/functionSystemMgmt/uploaddemo',
                    name: 'apm_functionSystemMgmt_uploaddemo',
                    meta: {
                        module: '系统定制',
                        title: '表格定制预览',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/biaogeDZ/dengjibiaoDZ',
                    name: 'dengjibiaoDZ',
                    meta: {
                        module: '系统定制',
                        title: '登记表定制',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/biaogeDZ/huamingceDZ',
                    name: 'huamingceDZ',
                    meta: {
                        module: '系统定制',
                        title: '花名册定制',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/biaogeDZ/tongjiBBDZ',
                    name: 'tongjiBBDZ',
                    meta: {
                        module: '系统定制',
                        title: '统计报表定制',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                }
            ]
        },
        // {
        //     path: '/apm/biaogeDZ',
        //     name: 'apm_iaogeDZ',
        //     meta: {
        //         module: '应用平台管理',
        //         title: '表格定制',
        //         roles: ['admin', 'group', 'user'],
        //         icon: ''
        //     },
        //     children: [
        //         {
        //             path: '/apm/biaogeDZ/dengjibiaoDZ',
        //             name: 'dengjibiaoDZ',
        //             meta: {
        //                 module: '表格定制',
        //                 title: '登记表定制',
        //                 roles: ['admin', 'group', 'user'],
        //                 icon: ''
        //             }
        //         },
        //         {
        //             path: '/apm/biaogeDZ/huamingceDZ',
        //             name: 'huamingceDZ',
        //             meta: {
        //                 module: '表格定制',
        //                 title: '花名册定制',
        //                 roles: ['admin', 'group', 'user'],
        //                 icon: ''
        //             }
        //         },
        //         {
        //             path: '/apm/biaogeDZ/tongjiBBDZ',
        //             name: 'tongjiBBDZ',
        //             meta: {
        //                 module: '表格定制',
        //                 title: '统计报表定制',
        //                 roles: ['admin', 'group', 'user'],
        //                 icon: ''
        //             }
        //         }
        //     ]
        // },
        {
            path: '/apm/parameterSet',
            name: 'parameterSet',
            meta: {
                module: '应用平台管理',
                title: '参数设置',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },
            children: [
                {
                    path: '/apm/parameterSet/systemParameters',
                    name: 'systemParameters',
                    meta: {
                        module: '参数设置',
                        title: '系统参数设置',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    },
                    children: [
                        {
                            path: '/apm/parameterSet/systemParameters/groupParameters',
                    name: 'groupParameters',
                    meta: {
                            module: '系统参数设置',
                            title: '集团参数设置',
                            roles: ['admin', 'group', 'user'],
                            icon: ''
                        }
                        },
                        {
                            path: '/apm/parameterSet/systemParameters/unitParameters',
                            name: 'unitParameters',
                            meta: {
                                module: '系统参数设置',
                                title: '单位参数设置',
                                roles: ['admin', 'group', 'user'],
                                icon: ''
                            }
                        }
                    ]
                },
                {
                    path: '/apm/parameterSet/businessParameters',
                    name: 'businessParameters',
                    meta: {
                        module: '参数设置',
                        title: '业务参数设置',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    },
                    children: [
                        {
                            path: '/apm/parameterSet/businessParameters/groupParameters',
                            name: 'groupParameters',
                            meta: {
                                module: '业务参数设置',
                                title: '集团参数设置',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                            }
                        },
                        {
                            path: '/apm/parameterSet/businessParameters/unitParameters',
                            name: 'unitParameters',
                            meta: {
                                module: '业务参数设置',
                                title: '单位参数设置',
                                roles: ['admin', 'group', 'user'],
                                icon: ''
                    }
                        }
                        ]
                }
            ]
        },
        {
            path: '/apm/SystemInit',
            name: 'SystemInit',
            meta: {
                module: '应用平台管理',
                title: '系统初始化',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/apm/OrgDefinite',
            name: 'OrgDefinite',
            meta: {
                module: '应用平台管理',
                title: '组织结构定义',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/apm/OrgPrivateMgmt',
            name: 'OrgPrivateMgmt',
            meta: {
                module: '应用平台管理',
                title: '组织私有云管理',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },
            children: [
                {
                    path: '/apm/OrgPrivateMgmt/OrgPrivateCloudConfig',
                    name: 'OrgPrivateCloudConfig',
                    meta: {
                        module: '组织私有云管理',
                        title: '组织私有云配置',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/OrgPrivateMgmt/OrgPrivateCloudNew',
                    name: 'OrgPrivateCloudNew',
                    hidden: true, // 隐藏当前菜单
                    meta: {
                        module: '组织私有云管理',
                        title: '私有云开通',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/OrgPrivateMgmt/zuzhiSYYJK',
                    name: 'zuzhiSYYJK',
                    meta: {
                        module: '组织私有云管理',
                        title: '组织私有云监控',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                }
            ]
        },
        {
            path: '/apm/jiekouGL',
            name: 'jiekouGL',
            meta: {
                module: '应用平台管理',
                title: '接口管理',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },
            children: [
                {
                    path: '/apm/jiekouGL/duiwaiSJST',
                    name: 'duiwaiSJST',
                    meta: {
                        module: '接口管理',
                        title: '对外数据视图',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/jiekouGL/shujuDR',
                    name: 'shujuDR',
                    meta: {
                        module: '接口管理',
                        title: '数据导入',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                }
            ]
        },
        {
            path: '/apm/tongxunGL',
            name: 'tongxunGL',
            meta: {
                module: '应用平台管理',
                title: '通讯管理',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },
            children: [
                {
                    path: '/apm/tongxunGL/youjianJK',
                    name: 'youjianJK',
                    meta: {
                        module: '通讯管理',
                        title: '邮件接口',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/tongxunGL/duanxinJK',
                    name: 'duanxinJK',
                    meta: {
                        module: '通讯管理',
                        title: '短信接口',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/tongxunGL/weixinJK',
                    name: 'weixinJK',
                    meta: {
                        module: '通讯管理',
                        title: '微信接口',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                }
            ]
        },
        {
            path: '/apm/peizhiGL',
            name: 'peizhiGL',
            meta: {
                module: '应用平台管理',
                title: '配置管理',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },
            children: [
                {
                    path: '/apm/peizhiGL/xinxiTBGZSZ',
                    name: 'xinxiTBGZSZ',
                    meta: {
                        module: '配置管理',
                        title: '信息同步规则设置',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                },
                {
                    path: '/apm/peizhiGL/shujuTB',
                    name: 'shujuTB',
                    meta: {
                        module: '配置管理',
                        title: '数据同步',
                        roles: ['admin', 'group', 'user'],
                        icon: ''
                    }
                }
            ]
        }
    ]
}
