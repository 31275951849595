import client from 'webpack-theme-color-replacer/client'
import generate from '@ant-design/colors/lib/generate'
const isProd = process.env.NODE_ENV === 'production'

export default {
  getAntdSerials(color) {
    // 淡化（即less的tint）
    const lightens = new Array(9).fill().map((t, i) => {
      return client.varyColor.lighten(color, i / 10)
    })
    // colorPalette变换得到颜色值
    const colorPalettes = generate(color)
    const rgb = client.varyColor.toNum3(color.replace('#', '')).join(',')
    return lightens.concat(colorPalettes).concat(rgb)
  },
  changeColor(newColor) {
    const location = window.location.href
    const href = location.split('#')[0]
    // console.log(href, 'href')
    const CssUrl = (url) => {
      if (isProd) {
        return `${href}${url}`
      } else {
        return `/${url}`
      }
    }
    var options = {
      newColors: this.getAntdSerials(newColor), // new colors array, one-to-one corresponde with `matchColors`
      changeUrl(cssUrl) {
        // console.log(cssUrl)
        // return `${href}${cssUrl}` // while router is not `hash` mode, it needs absolute path
        return CssUrl(cssUrl)
      }
    }
    return client.changer.changeColor(options, Promise)
  }
}
