<template>
  <div class='custom-query-container' id='ceshiid'>
    <a-form class='form'
      :form='form'
      v-bind='formConfig'>
      <slot />
    </a-form>
    <a-row
      type='flex'
      justify='center'
    >
      <a-button @click="$emit('add')" style='margin-right: 20px;'>添加条件</a-button>
      <a-button @click="$emit('reset')" style='margin-right: 20px;'>重置</a-button>
      <a-button @click="$emit('save')">查询</a-button>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'ComCustomQueryContainer',
  props: {
    formConfig: {
      type: Object,
      default: () => ({
        layout: 'inline',
        labelAlign: 'left',
        selfUpdate: true,
        labelCol: { span: 5 },
        wrapperCol: { span: 19 }
      })
    },
    form: {
      type: null
    }
  }
}
</script>

<style lang='less'>
.custom-query-container{
  display: flex;
  flex-flow: column nowrap;
  padding: 20px;
  .form{
    width: 100%;
  }
}
</style>