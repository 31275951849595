<template>
  <div class="checkbox-list">
    <ul class="list-warp" v-if="listData && listData.length">
      <li class="list-item"  @click="checkboxAll">
        <a-checkbox
            :checked="checkedAll"
            :indeterminate="indeterminate"
        >{{checkedName}}</a-checkbox>
      </li>
      <li
        class="list-item"
        v-for="(item, index) in listData"
        :key="index"
        @click="itemClick(item)"
      >
        <a-checkbox
          @click.native.stop
          @change="itemClick(item)"
          :checked="checkedKeys.includes(item[replaceFields.key])"
        >{{item[replaceFields.title]}}</a-checkbox>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    checkedName: {
      default: '岗位名称'
    },
    dataSource: {
      default: () => []
    },
    defaultChecked: {
      default: () => []
    },
    replaceFields: {
      default: () => ({
        title:'title',
        key: 'key'
      })
    }
  },
  data() {
    return {
      checkedAll: false,
      indeterminate: false,
      listData: [],
      checkedKeys: []
    }
  },
  created () {
    this.listData = [...this.dataSource]
  },
  methods: {
    checkboxAll() {
      let allKeys = []
      this.listData.forEach(item => {
        allKeys.push(item[this.replaceFields.key])
        // this.checkboxKeys.push(item.key)
      })
      if(allKeys.sort().toString() === this.checkedKeys.sort().toString()) {
        this.checkedKeys = []
        this.indeterminate = false
        this.checkedAll = false
      } else {
        this.checkedKeys = allKeys
        this.indeterminate = false
        this.checkedAll = true
      }
      this.$emit('checked', this.checkedKeys)
    },
    itemClick(item) {
      if(this.checkedKeys.includes(item[this.replaceFields.key])) {
        const keysIndex = this.checkedKeys.indexOf(item[this.replaceFields.key])
        this.checkedKeys.splice(keysIndex, 1)
      } else {
        this.checkedKeys.push(item[this.replaceFields.key])
      }
      if(this.checkedKeys.length && this.checkedKeys.length < this.listData.length) {
        this.indeterminate = true
      } else {
        this.indeterminate = false
        this.checkedAll = true
      }
      if(this.checkedKeys.length === 0) {
        this.checkedAll = false
      }
      this.$emit('checked', this.checkedKeys)
    }
  },
  watch: {
    dataSource(n) {
      this.listData = [...n]
    },
    defaultChecked(n) {
      this.checkedKeys = [...n]
      let allKeys = []
      this.listData.forEach(item => {
        allKeys.push(item[this.replaceFields.key])
      })
      if(allKeys.sort().toString() === this.checkedKeys.sort().toString()) {
        this.indeterminate = false
        this.checkedAll = true
      } else {
        this.indeterminate = true
        this.checkedAll = false
      }
      if(n.length === 0) {
        this.indeterminate = false
        this.checkedAll = false
      }
    },
  }
}
</script>
<style scoped>
.checkbox-list {
  width: 100%;
  height: 100%;
}
.checkbox-list .list-warp {

}
.checkbox-list .list-warp .list-item {
  height: 44px;
  line-height: 44px;
  padding-left: 36px;
  cursor: pointer;
}
.checkbox-list .list-warp .list-item:nth-child(even) {
  background: #f6f6f6;
}
.checkbox-list .list-warp .list-item:hover {
  background: #e6f7ff;
}
</style>
