import globalStore from './globalStore'
const ip = process.env.NODE_ENV === 'production'
  ? G.ENV.microIp
  : ''
// const getTime = () => {
//   return ''
// }
const microApps = [
  {
    name: 'subapp_apm',
    entry: ip + process.env.VUE_APP_APM,
    activeRule: '/apm'
  },
  {
    name: 'subapp_sym',
    entry: ip + process.env.VUE_APP_SYM,
    activeRule: '/sym'
  },
  {
    name: 'subapp_base',
    entry: ip + process.env.VUE_APP_BASE,
    activeRule: '/base'
  },
  {
    name: 'subapp_inspect',
    entry: ip + process.env.VUE_APP_INSPECT,
    activeRule: '/inspect'
  },
  {
    name: 'subapp_conf',
    entry: ip + process.env.VUE_APP_CONF,
    activeRule: '/conf'
  },
  {
    name: 'subapp_wf',
    entry: ip + process.env.VUE_APP_WF,
    activeRule: '/wf'
  },
  {
    name: 'subapp_fzzyk',
    entry: ip + process.env.VUE_APP_FZZYK,
    activeRule: '/fzzyk'
  },
  {
    name: 'subapp_dxalk',
    entry: ip + process.env.VUE_APP_DXALK,
    activeRule: '/dxalk'
  }
]

const apps = microApps.map(item => {
  return {
    ...item,
    container: '#subapp-viewport', // 子应用挂载的div
    props: {
      routerBase: item.activeRule, // 下发基础路由
      getGlobalState: globalStore.getGlobalState // 下发getGlobalState方法
    }
  }
})

export default apps
