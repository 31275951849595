<!--设置表头示例-->
<template>
    <div class="bdSetHeader">
        <a-table class="bdSetHeader-table" ref="xTable" rowKey="recordId" :columns="columns"
                 :data-source="setTHData" :pagination="false">
            <template :slot="col" slot-scope="text, record"
                      v-for="col in ['fldcodTitle','groupname','fldcodWidth','groupMerged','outerTable','outerFldcod','fields','content','fieldType','queryParam','default','sortOrder']">
                <div :key="col">
                    <a-input style="margin: -5px 0" :value="text"
                             @change="e => handleChange(e.target.value, record, col)"/>
                </div>
            </template>
            <a-select slot="align" slot-scope="text, record" default-value="left" style="width: 120px"
                      @change="e => handleChange(e, record, 'alignMent')">
                <a-select-option value="left">
                    左对齐
                </a-select-option>
                <a-select-option value="right">
                    右对齐
                </a-select-option>
                <a-select-option value="center">
                    居中
                </a-select-option>
            </a-select>
            <a-select slot="queryType" slot-scope="text, record" default-value="" style="width: 120px"
                      @change="e => handleChange(e, record, 'queryType')">
                <a-select-option v-for="item in queryTypeList" :value="item.typedesc" :key="item.datatype">
                    {{ item.typedesc }}
                </a-select-option>
            </a-select>
            <template slot="groupMerged" slot-scope="text, record">
                <a-input-number class="test-setheader-num" style="width: 80px" :value="text" :min="1" :max="20"
                                @change="e => handleChange3(e, record, 'groupMerged')"/>
            </template>
            <a-switch :checked="text" :default-checked="text" @change="e => switchChange(e, record, 'masterTable')"
                      slot="setUp" slot-scope="text, record"/>
            <a-switch slot="fixed" :default-checked="text" slot-scope="text, record"
                      @change="e => switchChange(e, record, 'lockColumn')" :disabled="record.groupMerged>0"
                      :checked="text"/>
            <template slot="drag" slot-scope="text, record, index">
                <span class="drag-btn">
                  <a href="javascript:;"><span class="iconhr ico-huanhang"></span></a>
                  <a-divider type="vertical"/>
                  <a-popconfirm v-if="data.length" title="确认删除？" @confirm="() => onDelete(record, index)">
                    <a href="javascript:;"><span class="iconhr ico-shanchu"></span></a>
                  </a-popconfirm>
                </span>
            </template>
        </a-table>
        <slot class="bdSetHeader-btn" name="bdSetHeader-btn">
        </slot>
    </div>
</template>
<script>
    import {req} from 'common/api/index'
    import Sortable from 'sortablejs'

    const temp = {}; // 当前重复的值,支持多列
    const mergeCellKey = (text, array, columns) => {
        let i = 1;
        if (text && text !== temp[columns]) {
            temp[columns] = text;
            array.forEach((item) => {
                if (item[columns] === temp[columns]) {
                    i += 1;
                }
            });
        }
        return i;
    }

    export default {
        props: {
            data: {
                type: Array
            },
            tableName: String
        },
        data() {
            const columns = [
                {
                    title: '序号',
                    dataIndex: 'num',
                    width: '6.25%',
                    align: 'center',
                    scopedSlots: {customRender: 'num'}
                },
                {
                    title: '指标名称',
                    dataIndex: 'fldcod',
                    width: '12.5%',
                    align: 'center',
                    scopedSlots: {customRender: 'fldcod'}
                },
                {
                    title: '显示名称',
                    dataIndex: 'fldcodTitle',
                    width: '12.5%',
                    align: 'center',
                    scopedSlots: {customRender: 'fldcodTitle'}
                },
                {
                    title: '对齐方式',
                    dataIndex: 'alignMent',
                    width: '12.5%',
                    align: 'center',
                    scopedSlots: {customRender: 'align'}
                },
                {
                    title: '列宽',
                    dataIndex: 'fldcodWidth',
                    width: '12.5%',
                    align: 'center',
                    scopedSlots: {customRender: 'fldcodWidth'}
                },
                {
                    title: '合并分组',
                    dataIndex: 'groupMerged',
                    width: '6.25%',
                    align: 'center',
                    scopedSlots: {customRender: 'groupMerged'}
                },
                {
                    title: '分组名称',
                    dataIndex: 'groupname',
                    width: '6.25%',
                    align: 'center',
                    scopedSlots: {customRender: 'groupname'}
                },
                {
                    title: '锁列',
                    dataIndex: 'lockColumn',
                    width: '6.25%',
                    align: 'center',
                    scopedSlots: {customRender: 'fixed'}
                },
                {
                    title: '操作',
                    dataIndex: 'drag',
                    width: '6.25%',
                    align: 'center',
                    scopedSlots: {customRender: 'drag'}
                }
            ]
            // this.cacheData = data.map(item => ({ ...item }))
            return {
                checkData: [],
                count: 0,
                editingKey: '',
                value: this.text,
                editable: false,
                setTHData: [],
                resetData: [],
                queryTypeList: [],
                columns: columns
            }
        },
        created() {
            this.setTHData = JSON.parse(JSON.stringify(this.data))
            // this.setTHData = this.data
            this.resetData = JSON.parse(JSON.stringify(this.data))
            this.currentDataNum()
        },
        watch: {
            data(n) {
                this.setTHData = n
            }
        },
        mounted() {
            this.dragdrop()
        },
        methods: {
            // 获取查询类型参数
            getQueryType() {
                req('post_getFieldTypeList', {}, true).then(res => {
                    this.queryTypeList = res.data
                })
            },
            // 序号重新渲染
            currentDataNum() {
                for (let i = 0; i < this.setTHData.length; i++) {
                    this.setTHData[i].num = i + 1
                    this.count = this.setTHData.length
                }
            },
            // 监听change
            handleChange(value, key, column) {
                key[column] = value
                this.setTHData[this.getArrIndex(key)] = key
            },
            handleChange3(value, key, column) {
                key[column] = value
                key.lockColumn = false
                this.setTHData[this.getArrIndex(key)] = key
            },
            switchChange(value, key, col) {
                key[col] = !key[col]
                this.setTHData[this.getArrIndex(key)] = key
                this.$emit('setMainTable', {value: value, index: this.getArrIndex(key)})
            },
            getArrIndex(key) {
                let q
                this.setTHData.map((item, index) => {
                    if (item === key) q = index
                })
                return q
            },
            // 锁定
            fixed(value, key, column) {
                if (value) {
                    for (let i = 0; i < key; i++) {
                        this.setTHData[i].fixed = true
                    }
                } else {
                    for (let i = this.setTHData.length - 1; i > key; i--) {
                        this.setTHData[i].fixed = false
                    }
                }
                this.handleChange(value, key, column)
            },
            // 删除
            onDelete(key, index) {
                this.setTHData[index].ishide = 1
                const ajaxDate = {
                    customTableColumnList: this.setTHData.map(item => ({
                        ...item,
                        lockColumn: item.lockColumn === true ? 1 : 0,
                        editenable: item.editenable === true ? 1 : 0,
                        showOverflow: item.showOverflow === true ? 1 : 0
                    }))
                }
                req('post_dycustom_updateCustom', ajaxDate, true).then(res => {
                    // 这里不能用filter 因为vue 更改数组不能直接渲染页面,splice可以直接修改页面，避免使用$set
                    this.setTHData.splice(index, 1)
                    this.$message.success('操作成功！')
                })
                // this.$set(this.setTHData, this.setTHData)
                // this.$emit('updateList')
            },
            dragdrop() {
                const xTable = this.$refs.xTable
                this.sortable = Sortable.create(xTable.$el.querySelector('tbody'), {
                    handle: '.drag-btn',
                    onEnd: ({newIndex, oldIndex}) => {
                        // 存放拖拽之前的数据
                        const tempArr = cloneDeep(that.setTHData)
                        const tempEles = xTable.$el.querySelector('tbody').childNodes
                        tempEles.forEach((el, index) => {
                            el.childNodes[0].innerHTML = index + 1
                            let tempObj = null
                            tempObj = tempArr.find(item => item.fldcod === el.childNodes[1].innerHTML)
                            that.setTHData[index] = tempObj
                            that.setTHData[index].num = index + 1
                        })
                    }
                })
            },
            saveFn() {
                this.$emit('saveData', this.setTHData)
            }
        }
    }
</script>
<style lang="less" scoped>
    .bdSetHeader {
        h1 {
            font-size: 16px;
            font-weight: bold;
        }

        .bdSetHeader-table {
            /deep/ .ant-table-thead > tr > th {
                background: #EEF2F9 !important;
                padding: 17px 8px !important;
            }

            /deep/ .ant-table-bordered .ant-table-thead > tr > th:not(:last-child) {
                border-right-color: #fff !important;
            }

            /deep/ .ant-table-tbody > tr > td {
                padding: 8px !important;
                color: #191f24;
            }

            /deep/ .ant-select-selection.ant-select-selection--single {
                border-radius: 0px !important;
                color: #191f24;
            }

            /deep/ .ant-input {
                border-radius: 0px !important;
                color: #191f24;
            }

            /deep/ .ant-select.ant-select-enabled {
                width: 100% !important;
            }

            /deep/ .ant-switch-checked {
                background-color: #6c86ae;
            }
        }

        /deep/ a {
            color: #6c86ae !important;
        }

        .bdSetHeader-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;

            .btn {
                margin: 0 10px;
            }
        }
    }

    /deep/ .bdSetHeader.setHeader1 .ant-table-tbody > tr > td:nth-last-child(2) {
        border-right-color: transparent !important;
    }

    /deep/ .ant-table-body {
        overflow-x: auto !important;
    }
</style>
