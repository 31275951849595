import { asyncRouter, constantRoutes } from '@/router'

/**
 * 确认权限存在
 * */
export function hasPermission(roles, route) {
  if (route.meta && route.meta.roles) {
    return roles.some(item => route.meta.roles.includes(item))
  } else {
    return true
  }
}
/**
 * 筛选权限对应的路由
 * */
export function asyncRoutesFilter(routes, roles) {
  const res = []
  routes.forEach(item => {
    const tmp = { ...item }
    if (hasPermission(roles, tmp)) {
      if (tmp.children) {
        tmp.children = asyncRoutesFilter(tmp.children, roles)
      }
      res.push(tmp)
    }
  })
  return res
}

const state = {
  routes: [],
  addRoutes: []
}

const mutations = {
  setRoute(state, routes) {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  }
}

const actions = {
  /**
   * 通过权限设置路由表
   * */
  getAsyncRouter({ commit }, roles) {
    return new Promise(resolve => {
      const accessibleRouter = asyncRoutesFilter(asyncRouter, roles)
      commit('setRoute', accessibleRouter)
      resolve(accessibleRouter)
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
