import {ACCESS_USERINFO} from 'common/confing'

export const getPermission = (value) => {
    const limits = JSON.parse(localStorage.getItem(ACCESS_USERINFO)).roles
    if (value) {
        return limits.some(limit => {
            return limit === value
            // return value.includes(limit)
        })
    } else {
        throw new Error(`请正确输入权限设置值`)
    }
}

export default {
    inserted(el, binding) {
        const {value} = binding
        const limits = JSON.parse(localStorage.getItem(ACCESS_USERINFO)).roles
        if (value) {
            let hasPermission = false
            if (value instanceof Array) {
                if (value.length === 1) {
                    hasPermission = limits.some(limit => {
                        return limit === value[0]
                    })
                } else if (value.length > 1){
                    for (let li of value) {
                        const isHas = limits.some(limit => {
                            return limit === li
                        })
                        if (isHas) {
                            hasPermission = isHas
                            break
                        }
                    }
                }
            } else {
                if (value.includes(',')) {
                    const limitValue = value.split(',')
                    for (let li of limitValue) {
                        const isHas = limits.some(limit => {
                            return limit === li
                        })
                        if (isHas) {
                            hasPermission = isHas
                            break
                        }
                    }
                } else {
                    hasPermission = limits.some(limit => {
                        return limit === value
                    })
                }
            }
            const permissionRoles = value
            // const hasPermission = limits.some(limit => {
            //     return limit === permissionRoles
            //     // return permissionRoles.includes(limit)
            // })
            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el)
            }
        } else {
            throw new Error(`need limits! Like v-permission="'test'"`)
        }
    }
}
