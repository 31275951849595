<!--设置表头示例-->
<template>
    <div class="bdSetHeader">
        <a-table class="bdSetHeader-table" ref="xTable" :rowKey='record=>record.id' :columns="columns"
                 :loading="loading" :data-source="setTHData" :pagination="pagination">
            <template slot="index" slot-scope="text, record ,index">{{(index+1)}}</template>
            <template :slot="col" slot-scope="text, record"
                      v-for="col in ['fldcodTitle','fldcodWidth','groupMerged','outerTable','outerFldcod','fields','placeholder','fieldType','queryName','sortOrder']">
                <div :key="col">
                    <a-input style="margin: -5px 0" :value="text"
                             @change="e => handleChange(e.target.value, record, col)"/>
                </div>
            </template>
            <template slot="defaultValue" slot-scope="text, record">
                <a-input style="margin: -5px 0" :value="text" :disabled="record.ismultivalued===true" @change="e => handleChange(e.target.value, record, 'defaultValue')"/>
            </template>
            <a-select slot="orderBy" slot-scope="text, record" :default-value="text" style="width: 120px"
                      @change="e => handleChange(e, record, 'orderBy')">
                <a-select-option value="asc">
                    升序
                </a-select-option>
                <a-select-option value="desc">
                    降序
                </a-select-option>
            </a-select>
            <a-select slot="align" slot-scope="text, record" default-value="left" style="width: 120px"
                      @change="e => handleChange(e, record, 'alignMent')">
                <a-select-option value="left">
                    左对齐
                </a-select-option>
                <a-select-option value="right">
                    右对齐
                </a-select-option>
                <a-select-option value="center">
                    居中
                </a-select-option>
            </a-select>
            <a-select slot="queryType" slot-scope="text, record" :default-value="text" style="width: 120px"
                      @change="e => handleChange(e, record, 'queryType')">
                <a-select-option v-for="item in queryTypeList" :value="item.dbcode" :key="item.datatype">
                    {{ item.typedesc }}
                </a-select-option>
            </a-select>
            <a-switch slot="ismultivalued" :disabled="record.id?true:false" v-show="record.dmgrp" :default-checked="text"  slot-scope="text, record" @change="e => switchChangeisms(e, record, 'ismultivalued')" :checked="text"/>
            <a-switch slot="maintainable" :default-checked="text" slot-scope="text, record"
                      @change="e => switchChange(e, record, 'editenable')" :checked="text"/>
            <a-switch slot="ellipsis" :default-checked="text" slot-scope="text, record"
                      @change="e => handleChange(e, record, 'showOverflow')" :checked="text"/>
            <a-switch slot="showTable" :default-checked="text" slot-scope="text, record"
                      @change="e => handleChange(e, record, 'showTable')" :checked="text"/>
            <a-switch slot="showQuery" :default-checked="text" slot-scope="text, record"
                      @change="e => handleChange(e, record, 'showQuery')" :checked="text"/>
            <a-switch :checked="text" :default-checked="text" @change="e => switchChange(e, record, 'masterTable')"
                      slot="setUp" slot-scope="text, record"/>
            <template slot="delete" slot-scope="text, record">
                <a-popconfirm title="确认删除？" @confirm="() => onDelete(record)">
                    <a href="javascript:;" v-if="!record.id">
                        <bd-icon name="ico-shanchu"/>
                    </a>
                </a-popconfirm>
            </template>
        </a-table>
        <slot class="bdSetHeader-btn" name="bdSetHeader-btn">
        </slot>
    </div>
</template>
<script>
    import Sortable from 'sortablejs'
    import {req} from 'common/api/index'

    export default {
        props: {
            columns: {
                type: Array
            },
            data: {
                type: Array
            },
            tableName: String,
            loading: Boolean
        },
        data() {
            // this.cacheData = data.map(item => ({ ...item }))
            return {
                checkData: [],
                count: 0,
                editingKey: '',
                value: this.text,
                editable: false,
                setTHData: [],
                resetData: [],
                pagination: false,
                queryTypeList: []
            }
        },
        created() {
            this.setTHData = JSON.parse(JSON.stringify(this.data))
            // this.setTHData = this.data
            this.resetData = JSON.parse(JSON.stringify(this.data))
            this.currentDataNum()
            this.getQueryType()
        },
        watch: {
            data(n) {
                this.setTHData = n
            }
        },
        mounted() {
            this.dragdrop()
        },
        methods: {
            switchChangeisms (value, key, col) {
                key[col] = !key[col]
                if (value) {
                    key.defaultValue = null
                }
                this.setTHData[this.getArrIndex(key)] = key
                // this.$emit('setMainTable', { value: value, index: this.getArrIndex(key) })
            },
            // 获取查询类型参数
            getQueryType() {
                req('post_sym_getFieldTypeList', {}, true).then(res => {
                    this.queryTypeList = res.data
                })
            },
            // 序号重新渲染
            currentDataNum() {
                for (let i = 0; i < this.setTHData.length; i++) {
                    this.setTHData[i].num = i + 1
                    this.count = this.setTHData.length
                }
            },
            // 监听change
            handleChange(value, key, column) {
                key[column] = value
                this.setTHData[this.getArrIndex(key)] = key
            },
            switchChange(value, key, col) {
                key[col] = !key[col]
                this.setTHData[this.getArrIndex(key)] = key
                this.$emit('setMainTable', {value: value, index: this.getArrIndex(key)})
            },
            getArrIndex(key) {
                let q
                this.setTHData.map((item, index) => {
                    if (item === key) q = index
                })
                return q
            },
            // 删除
            onDelete(key) {
                this.$emit('refresh',key)
                // this.$http('post_customTabQueUser_deleteTableQuery', {id: key.id}, true).then(res => {
                //     if (res.code === 200) {
                //         console.log('删除3==>' + JSON.stringify(res))
                //         this.$message.success(res.message)
                //         this.$emit('refresh', res.code)
                //     }
                // })
            },
            // 添加行
            handleAdd() {
                const {count, data} = this
                const newData = {
                    key: count,
                    num: count + 1,
                    name: '岗位/职务名称',
                    align: '',
                    displayName: '岗位/职务名称',
                    width: 200,
                    groupMerging: '分组合并'
                }
                this.setTHData = [...data, newData]
                this.count = count + 1
                this.currentDataNum()
            },
            // 拖拽排序3
            dragdrop() {
                const xTable = this.$refs.xTable
                this.sortable = Sortable.create(xTable.$el.querySelector('tbody'), {
                    handle: '.drag-btn',
                    onEnd: ({newIndex, oldIndex}) => {
                        const currRow = this.setTHData.splice(oldIndex, 1)[0] // 旧的删掉一个，存上
                        this.setTHData.splice(newIndex, 0, currRow) // 新的位置不删，放上旧的
                        this.currentDataNum()
                    }
                })
            },
            // 我设置好啦
            //
            // saveFn() {
            //     this.$emit('saveData', this.setTHData)
            // },

            handleButtonClick(e) {
                /* console.log('click left button', e) */
            },
            handleMenuClick(e) {
                /* console.log('click', e.key) */
            }
        },
        computed: {}
    }
</script>
<style lang="less" scoped>
    .bdSetHeader {
        h1 {
            font-size: 16px;
            font-weight: bold;
        }

        .bdSetHeader-table {
            /deep/ .ant-table-thead > tr > th {
                background: #EEF2F9 !important;
                padding: 17px 8px !important;
            }

            /deep/ .ant-table-bordered .ant-table-thead > tr > th:not(:last-child) {
                border-right-color: #fff !important;
            }

            /deep/ .ant-table-tbody > tr > td {
                padding: 8px !important;
                color: #191f24;
            }

            /deep/ .ant-select-selection.ant-select-selection--single {
                border-radius: 0px !important;
                color: #191f24;
            }

            /deep/ .ant-input {
                border-radius: 0px !important;
                color: #191f24;
            }

            /deep/ .ant-select.ant-select-enabled {
                width: 100% !important;
            }

            /deep/ .ant-switch-checked {
                background-color: #6c86ae;
            }
        }

        /deep/ a {
            color: #6c86ae !important;
        }

        .bdSetHeader-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 0;

            .btn {
                margin: 0 10px;
            }
        }
    }

    /deep/ .bdSetHeader.setHeader1 .ant-table-tbody > tr > td:nth-last-child(2) {
        border-right-color: transparent !important;
    }

    /deep/ .ant-table-body {
        overflow-x: auto !important;
    }
</style>
