<template>
  <a-layout class="lsc-layout">
    <a-layout-sider class="lsc-lay-side" :class="{'no-space': !layoutSpace}" :width="leftWidth" v-if="$slots.sideMain" >
      <div class="side-top" v-if="$slots.sideTop">
        <slot name="sideTop"></slot>
      </div>
      <div class="side-main" :class="{ 'no-top': !$slots.sideTop }">
        <slot name="sideMain"></slot>
      </div>
    </a-layout-sider>
    <a-layout class="main-con" :class="{'no-space': !layoutSpace, 'no-left': !$slots.sideMain }">
      <div class="lsc-layout-content">
        <slot name="mainCon"></slot>
      </div>
    </a-layout>
    <slot></slot>
  </a-layout>
</template>
<script>

  export default {
    name: 'pageSideTree',
    props: {
      space: {
        type: Object,
        default: () => ({
          col: '8',
          row: '0'
        })
      },
      sideWidth: {
        default: 300
      },
      sideMaxWidth: {
        default: 300
      },
      sideMinWidth: {
        default: 200
      },
      layoutSpace: {
        default: true
      }
    },
    data() {
      return {
        leftWidth: 300
      }
    },
    components: {},
    computed: {
    },
    created () {
      window.addEventListener('resize', () => {
        this.calcViewWidth()
      })
      this.calcViewWidth()
    },
    methods: {
      calcViewWidth() {
        const maxWidth = this.sideMaxWidth
        const minWidth = this.sideMinWidth
        const windowWidth = (this.sideWidth / 1920) * document.body.offsetWidth
        this.leftWidth = Math.min(Math.max(windowWidth, minWidth), maxWidth)
      }
    }
  }
</script>
<style scoped>
  .lsc-layout {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    /* padding-top: 10px; */
  }
  .lsc-layout .lsc-lay-side {
    height: 100%;
    background: #ffffff;
    border-radius: 0 6px 6px 0;
    box-sizing: border-box;
  }
  .lsc-layout .lsc-lay-side.no-space {
    border-radius: 0;
  }
  .lsc-layout .lsc-lay-side .side-top {
    display: flex;
    align-items: center;
    padding: 10px;
  }
  .lsc-layout .lsc-lay-side .side-main {
    height: 100%;
    padding-left: 10px;
    overflow: auto;
  }
  .lsc-layout .lsc-lay-side .side-main.no-top {
    display: flex;
    flex: auto;
    flex-direction: column;
    padding: 0;
  }

  .lsc-layout .lsc-lay-content {
    flex: 1;
  }
  .lsc-layout .main-con {
    width: 100%;
    height: 100%;
    padding-left: 10px;
    background: transparent;
  }
  .lsc-layout .main-con.no-left {
    padding-left: 0px
  }
  .lsc-layout.no-space .lsc-layout-content {
    border-radius: 0;
  }
  .lsc-layout .lsc-layout-content {
    display: flex;
    flex: auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 6px 0 0 6px;
  }
  .lsc-layout.is-compose .lsc-layout-content{
    border-radius: 6px 6px 0 0;
  }
</style>
