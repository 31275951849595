import {req} from 'common/api/index'
export default {
    data() {
        return {
            dataSource: {},
            codeDataSource: {}

        }
    },
    methods: {
        async getCodeDataList(names) {
            return new Promise((resolve, reject) => {
                req('post_common_listDictionaryBatch', {name: names}).then(res => {
                    if (res.code===200) {
                        this.dataSource = res.data
                        this.codeDataSource = res.data
                        resolve(res)
                    } else {
                        reject(res)
                    }
                }).catch(err => {
                    reject(err)
                })
            })
        }
    }
}
