<template>
    <div class="table-base">
        <a-table :customRow="rowClick" :scroll="{ x: scrollX, y: tableScrollYValue }" :rowKey="(record,index)=>index"
                 :loading=loading :row-selection="rowSelection"
                 :columns.sync="columns ? columns : tempColumn" :data-source="TableData"
                 :pagination="{ pageSize: Pager.defaultPageSize,current: Pager.defaultCurrent, position: 'top'}">
            <template slot="index" slot-scope="text, record ,index">
                {{((Pager.defaultCurrent-1)*Pager.defaultPageSize)+index+1}}
            </template>
            <template :slot="item"
                      v-for="item in ['alink0', 'alink1', 'alink2', 'alink3', 'alink4', 'alink5', 'alink6', 'alink7', 'alink8', 'alink9']"
                      slot-scope="text, record, index">
                <slot :name="item + 'parent'" :text="text" :record="record" :index="index"></slot>
            </template>
            <a slot="customopr" slot-scope="text,record,index">
                <slot name="opr" :text="text" :record="record" :index="index"></slot>
            </a>
        </a-table>
    </div>
</template>

<script>
    import {tableMixin} from 'common/utils/mixins'
    import {req} from 'common/api/index'
    import {cloneDeep} from 'lodash'

    export default {
        name: 'DynamicTable',
        props: {
            nestMapStr: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            // 是否自动加载数据
            autoLoad: Boolean,
            // 开发人员需要传的默认值
            defaultParams: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            columns: Array,
            tableName: String, // 必传根据tableName查询表格表头
            pagination: Object,
            searchParams: Object, // 导出参数,
            scrollX: {
                default: 800
            },
            scrollY: {
                default: null
            },
            indexCheckType: {
                default: 'checkbox'
            },
            isNotMerge: {
                default: false
            }
        },
        components: {
            // px
        },
        mixins:[tableMixin],
        data() {
            const  setScrollY = this.setScrollY
            return {
                tableScrollYValue: setScrollY,
                tempColumn: [],
                RealnestMapStr: '',
                AcceptDefaultParams: {},
                reqcounter: 0,
                TableData: [],
                loading: false,
                searchData: {
                    enablePage: 1,
                    pageNum: 1,
                    pageSize: 10,
                    // paramMap: '',
                    tableName: ''
                },
                Pager: {
                    defaultPageSize: 15,
                    defaultCurrent: 1
                },
                rowClick: (record, index) => ({
                    // 事件
                    on: {
                        click: () => {
                            const {indexCheckType} = this;
                            if(indexCheckType === 'index') {
                                this.selectedRowKeys = [index]
                                this.selectedRows = [record]
                            } else {
                                // 点击改行时要做的事情
                                const  FlagIndex = this.selectedRowKeys.findIndex(item => item === index)
                                if (FlagIndex > -1) {
                                    this.selectedRowKeys.splice(FlagIndex,1)
                                    this.selectedRows.splice(FlagIndex,1)
                                } else {
                                    this.selectedRowKeys.push(index)
                                    this.selectedRows.push(record)
                                }
                            }
                            this.savesss(record)
                            // ......
                            // console.log(record, 'record')
                        }
                    }
                }),
                selectedRowKeys: [], // 行选择
                selectedRows: [] // 行选择数据
            }
        },
        computed: {
            rowSelection() {
                const {selectedRowKeys, indexCheckType, isNotMerge} = this;
                return {
                    type: indexCheckType === 'index' ? 'checkbox' : indexCheckType,
                    selectedRowKeys,
                    showIndex: indexCheckType === 'index' ? true : false,
                    isNotMerge: indexCheckType === 'index' ? true : isNotMerge,
                    onChange: (selectedRowKeys, selectedRows) => {
                        // console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows)
                        this.selectedRowKeys = selectedRowKeys;
                        this.selectedRows = selectedRows
                        if(indexCheckType === 'index') {
                            console.log(selectedRows)
                        }
                        this.$emit('selectRow', selectedRows)
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.name === 'Disabled User', // Column configuration not to be checked
                            name: record.name
                        }
                    })
                }
            }
        },
        created() {
            if (this.scrollY) {
                this.tableScrollYValue = this.scrollY
            } else {
                this.tableScrollYValue = this.setScrollY
                console.log(this.tableScrollYValue)
            }
            this.RealnestMapStr = this.nestMapStr
            this.AcceptDefaultParams = this.defaultParams
            this.searchData.tableName = this.tableName
            this.post_dwLoadList()
        },
        mounted() {
        },
        watch: {
            // defaultParams(val) {
            //     console.log(val)
            //     this.AcceptDefaultParams = val
            // },
            defaultParams: {
                // value 需要深度监听及默认先执行handler函数
                handler: function (val) {
                    // if (val) {
                    // }
                    console.log(val)
                    this.AcceptDefaultParams = cloneDeep(val)
                },
                immediate: true,
                deep: true
            },
            nestMapStr: {
                // this.RealnestMapStr = val
                // value 需要深度监听及默认先执行handler函数
                handler: function (val) {
                    // if (val) {
                    // }
                    console.log(val)
                    this.RealnestMapStr = cloneDeep(val)
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            savesss(record) {
                this.$emit('rowClick', record)
                this.$emit('selectRow', this.selectedRows)
            },
            isNullOrWhiteSpace(o) {
                return o === undefined || o === null || (typeof (o) === 'string' && o.trim() === '')
            },
            post_dwLoadList() {
                req('post_sys_customtablecolumn', {tableName: this.tableName}).then((res) => {
                    if (res.code === 200) {
                        const resData = cloneDeep(res.data)
                        if (this.autoLoad) {
                            this.getData()
                        }
                        const tempColumns = [] // 存放父级表头
                        const tempColumns2 = [] // 存放子级表头
                        resData.forEach((el, index) => {
                            if (!this.isNullOrWhiteSpace(el.groupname) && !this.isNullOrWhiteSpace(el.groupMerged)) {
                                tempColumns.push(el)
                                tempColumns2.push(el)
                            }
                            if (this.isNullOrWhiteSpace(el.groupname) && !this.isNullOrWhiteSpace(el.groupMerged)) {
                                tempColumns2.push(el)
                                // resData.splice(index,1)
                            }
                        })
                        const tempColumns3 = resData.filter(item => {
                            return !(this.isNullOrWhiteSpace(item.groupname) && !this.isNullOrWhiteSpace(item.groupMerged))
                        })
                        tempColumns.forEach(item => {
                            item.children = []
                            tempColumns2.forEach(item2 => {
                                if (item.groupMerged === item2.groupMerged) {
                                    item.children.push(item2)
                                }
                            })
                        })
                        tempColumns3.forEach(e => {
                            tempColumns.forEach(l => {
                                if (e.groupname === l.groupname) {
                                    e = l
                                }
                            })
                        })
                        const tempcolumms = []
                        tempColumns3.forEach((ele, index) => {
                            if (this.isNullOrWhiteSpace(ele.children)) {
                                tempcolumms.push({
                                    title: ele.fldcodTitle,
                                    dataIndex: ele.fldcodTitle,
                                    align: ele.alignMent,
                                    ellipsis: ele.showOverflow === 1,
                                    width: ele.fldcodWidth,
                                    fixed: ele.lockColumn === 1,
                                    // colSpan: ele.groupMerged
                                })
                            } else {
                                tempcolumms.push({
                                    title: ele.groupname,
                                    children: []
                                })
                                const w = ''
                                ele.children.forEach(elem => {
                                    tempcolumms[index].children.push({
                                        title: elem.fldcodTitle,
                                        dataIndex: elem.fldcodTitle,
                                        // align: elem.alignMent,
                                        align: 'center',
                                        ellipsis: elem.showOverflow === 1,
                                        width: elem.fldcodWidth,
                                        fixed: elem.lockColumn === 1,
                                        // colSpan: elem.groupMerged
                                    })
                                })
                            }
                        })

                        // tempcolumms.unshift({
                        //     title: '序号',
                        //     dataIndex: 'index',
                        //     align: 'center',
                        //     fixed: true,
                        //     width: '60px',
                        //     scopedSlots: {customRender: 'index'}
                        // })
                        this.tempColumn = tempcolumms
                        this.$emit('CustomColumns', tempcolumms)
                    } else {
                        this.$message.error(res.message)
                    }
                })

            },
            quickSearch(params) {

            },
            // 清除选中行
            clearSelectKeys() {
                this.selectedRowKeys = []
                // this.selectedRows = []
            },
            getData(params, pager, isReload) {
                this.loading = true
                if (JSON.stringify(params) !== '{}' && params !== null && params !== undefined) {
                    if (isReload) {
                        // console.log(params)
                        if (this.AcceptDefaultParams.fixed) {
                            const tempObjd = cloneDeep(this.AcceptDefaultParams)
                            delete tempObjd.fixed
                            this.searchData.paramMapStr = Object.assign(params, tempObjd)
                        } else {
                            this.searchData.paramMapStr = Object.assign(params, this.AcceptDefaultParams)
                        }
                    }
                    this.searchData.paramMapStr = JSON.stringify(params)
                } else {
                    this.searchData = {
                        enablePage: 1,
                        pageNum: 1,
                        pageSize: 10,
                        tableName: this.tableName
                    }
                }
                if (pager) {
                    this.searchData = Object.assign(this.searchData, pager)
                }
                // pager
                // 判断是否有默认值
                if (this.reqcounter === 0 && JSON.stringify(this.AcceptDefaultParams) !== '{}' && Object.values(this.AcceptDefaultParams).length > 0) {
                    console.log(this.AcceptDefaultParams)
                    const keys = Object.keys(this.AcceptDefaultParams)
                    keys.forEach(key => {
                        if (this.AcceptDefaultParams[key] instanceof Array && this.AcceptDefaultParams[key].length > 0) {
                            this.AcceptDefaultParams[key] = this.AcceptDefaultParams[key].join(',')
                        }
                    })
                    if (this.searchData.paramMapStr) {
                        this.searchData.paramMapStr = JSON.stringify(Object.assign(JSON.parse(this.searchData.paramMapStr), this.AcceptDefaultParams))
                    } else {
                        const tempDefaultPartms = cloneDeep(this.AcceptDefaultParams)
                        if (tempDefaultPartms.fixed !== null || tempDefaultPartms.fixed !== undefined) {
                            delete tempDefaultPartms.fixed
                        }
                        this.searchData.paramMapStr = JSON.stringify(tempDefaultPartms)
                    }
                    this.reqcounter++
                }
                const temphasfixkeys = Object.keys(this.AcceptDefaultParams)
                const fixedfld = temphasfixkeys.find(item => item === 'fixed')
                const fixObj = {}
                if (fixedfld) {
                    this.AcceptDefaultParams[fixedfld].split(',').forEach(el => {
                        fixObj[el] = this.AcceptDefaultParams[el]
                    })
                    if (this.reqcounter > 0 && this.searchData.paramMapStr) {
                        this.searchData.paramMapStr = JSON.stringify(Object.assign(JSON.parse(this.searchData.paramMapStr), fixObj))
                    } else if (this.reqcounter > 0 && !this.searchData.paramMapStr) {
                        this.searchData.paramMapStr = JSON.stringify(fixObj)
                    }
                }

                // 判断是否有 RealnestMapStr
                if (JSON.stringify(this.RealnestMapStr) !== '{}' && Object.values(this.RealnestMapStr).length > 0) {

                    this.searchData.nestMapStr = JSON.stringify(this.RealnestMapStr)
                }
                req('post_sys_customtabledata', this.searchData).then((res) => {
                    if (res.code === 200) {
                        this.Pager = {
                            // 数据总数
                            total: res.data.total,
                            // 用于显示数据总量和当前数据顺序
                            showTotal: total => `共 ${total} 条`,
                            // 是否可以改变pageSize
                            showSizeChanger: true,
                            // 是否可以快速跳转至某页
                            showQuickJumper: true,
                            // show less page items
                            showLessItems: true,
                            // 指定每页可以显示多少条
                            pageSizeOptions: ['10', '20', '50'],
                            // 默认的每页条数
                            defaultPageSize: res.data.pageSize,
                            // 默认的当前页数
                            defaultCurrent: res.data.pageNum,
                            // 只有一页时是否隐藏分页器
                            hideOnSinglePage: false
                        }
                        this.$emit('update:pagination', this.Pager)
                        this.$emit('update:searchParams', this.searchData)
                        // const dasdfdsf = cloneDeep(res.data.list)
                        // res.data.list[0].姓名 = '关羽'
                        // res.data.list[1].姓名 = '张飞'
                        // res.data.list[2].姓名 = '黄忠'
                        // res.data.list[3].姓名 = '胃炎'
                        // dasdfdsf[0].children = [res.data.list[0],res.data.list[1]]
                        // dasdfdsf[0].children[0].children = [res.data.list[2],res.data.list[3]]
                        // console.log(dasdfdsf)
                        // this.isHasChildren(dasdfdsf)
                        // console.log(dasdfdsf)

                        this.TableData = cloneDeep(res.data.list)
                        // 解决在第二页检索返回数据为空问题
                        if(this.TableData.length === 0 && res.data.total !== 0 && this.Pager.defaultCurrent > 1) {
                            this.Pager.defaultCurrent = 1
                            this.getData(params, this.Pager, true)
                        }
                        this.loading = false
                    } else {
                        this.$message.error(res.message)
                        this.loading = false
                    }
                })
            },
            isHasChildren(arr, Ind) {
                const pageSize = this.Pager.defaultPageSize
                const current = this.Pager.defaultCurrent
                arr.forEach((el, index) => {
                    el.index = ((typeof Ind === 'string') ? (Ind + '-') : '') + ((typeof Ind === 'string') ? (index + 1) : (((current - 1) * pageSize) + index + 1))
                    if (el.children && el.children.length) {
                        this.isHasChildren(el.children, el.index)
                    }
                })
            }
        }
    }
</script>
<style lang="less" scoped>
</style>
