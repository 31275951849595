<template>
  <div class="colorSet">
    <h2>预设颜色：</h2>
    <div class="colorSet-colorList">
      <div class="colorSet-colorList-item"
           v-for="item in colorList" :key="item.color"
           :style="{backgroundColor: item.color}"
           @click="clickColorItem(item.color)">
        <a-icon type="check" v-if="selectColor === item.color" />
      </div>
    </div>
    <h2>自定义颜色：</h2>
    <a-input type="color" style="width: 150px;" v-model="selectColor" @change="customColor"></a-input>
  </div>
</template>

<script>
export default {
  name: 'ColorSet',
  data() {
    return {
      colorList: [
        {
          key: '薄暮', color: '#F5222D'
        },
        {
          key: '火山', color: '#FA541C'
        },
        {
          key: '日暮', color: '#FAAD14'
        },
        {
          key: '明青', color: '#13C2C2'
        },
        {
          key: '极光绿', color: '#52C41A'
        },
        {
          key: '拂晓蓝（默认）', color: '#1890FF'
        },
        {
          key: '极客蓝', color: '#2F54EB'
        },
        {
          key: '酱紫', color: '#722ED1'
        }
      ],
      selectColor: ''
    }
  },
  computed: {
  },
  watch: {},
  created() {},
  mounted() {
  },
  methods: {
    /**
     * 自定义颜色设置
     * */
    customColor() {
      const color = this.selectColor
      this.setThemeColor(color)
    },
    /**
     * 选择预设颜色
     * */
    clickColorItem(color) {
      this.selectColor = color
      this.setThemeColor(color)
    },
    /**
     * 设置 theme 颜色
     * */
    setThemeColor(newPrimaryColor) {
      this.$store.dispatch('user/getThemeColor', newPrimaryColor).then(
        this.$message.success('颜色变更完毕')
      ).catch(error => {
        console.log(error)
        this.$message.success('颜色变更失败')
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .colorSet{
    position: relative;
    width: 100%;
    height: 100%;
    h2{
      clear: both;
    }
    .colorSet-colorList{
      position: relative;
      width: 100%;
      margin-bottom: 40px;
      .colorSet-colorList-item{
        float: left;
        width: 20px;
        height: 20px;
        margin-right: 8px;
        color: #fff;
        font-weight: 700;
        text-align: center;
        border-radius: 2px;
        cursor: pointer;
      }
      .colorSet-colorList-item:hover{
        box-shadow: 2px 2px 2px #8E9193;
      }
    }
  }
</style>
