<template>
  <a-select
    allowClear
    :placeholder='placeholder'
    v-model='inValue'
    @change='change'
    :maxTagCount='2'
    :mode='mode'
    style='width: 68%;'
    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
  >
    <a-select-option
      v-for='(v, i) in options' :key='i'
      :value='v.value'
    >
      {{v.label}}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  name: 'ComCustomQuerySelect',
  props: {
    placeholder: {
      type: [String, Number],
      default: '请选择'
    },
    mode: {
      type: String,
      default: 'multiple'
    },
    defaultValue: {
      type: null,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({
        value: [],
        label: ''
      })
    }
  },
  data () {
    return {
      inValue: ''
    }
  },
  watch: {
    value: {
      handler  (n) {
        this.inValue = n.value
      },
      immediate: true
    }
  },
  methods: {
    change (e) {
      this.$emit('change', { value: e, label: e.join(',') })
    }
  }
}
</script>