<template>
  <div class="headerNav">
    <button class="headerNav-item" @click="personalClick" :class="showDetails === 'personal' ? 'headerNav-active' : 'headerNav-unActive'">
      个人工作台
    </button>
    <a-divider type="vertical" />
    <button class="headerNav-item" @click="manageClick" :class="showDetails === 'management' ? 'headerNav-active' : 'headerNav-unActive'">
      管理工作台
    </button>
  </div>
</template>

<script>
export default {
  name: 'HeaderNav',
  props: {
    showDetails: {
      type: String,
      required: true,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    personalClick() {
      const vm = this
      vm.$router.push({ name: 'personal' })
    },
    manageClick() {
      const vm = this
      vm.$router.push({ path: '/portal' })
    }
  }
}
</script>

<style lang="less" scoped>
 @import '~ant-design-vue/lib/style/themes/default.less';
  .headerNav{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .headerNav-item{
      position: relative;
      width: 50%;
      height: 100%;
      border: none;
      background: none;
      outline: none;
      border-radius: 0;
      font-size: @largeFontSize;
    }
    .headerNav-active{
      font-weight: bold;
      border-bottom: 2px solid #2A343C;
      color: #2A343C;
    }
    .headerNav-unActive{
      cursor: pointer;
    }
  }
</style>
