<template>
  <a-layout class="pst-layout">
    <a-layout-sider class="pst-lay-side" width="226px">
      <div class="side-top" v-if="$slots.sideTop">
        <slot name="sideTop"></slot>
      </div>
      <div class="side-main">
        <slot name="sideMain"></slot>
      </div>
    </a-layout-sider>
    <page-table-paging compose>
      <slot slot="contentTop" name="contentTop"></slot>
      <slot slot="contentTopLeft" name="contentTopLeft"></slot>
      <slot slot="contentTopRight" name="contentTopRight"></slot>
      <slot slot="contentMain" name="contentMain"></slot>
      <slot slot="contentBottom" name="contentBottom"></slot>
      <slot slot="contentBottomLeft" name="contentBottomLeft"></slot>
      <slot slot="contentBottomRight" name="contentBottomRight"></slot>
    </page-table-paging>
  </a-layout>
</template>
<script>
import pageTablePaging from './pageTablePaging'
export default {
  name: 'pageSideTree',
  created () {
    console.log(this.$slots)
  },
  components: {
    pageTablePaging
  }
}
</script>
<style scoped>
.pst-layout {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}
.pst-layout .pst-lay-side {
  height: 100%;
  background: #ffffff;
  border-radius: 0 8px 8px 0;
  box-sizing: border-box;
}
.pst-layout .pst-lay-side .side-top {
  padding: 10px;
}
.pst-layout .pst-lay-content {
  flex: 1;
}
</style>
