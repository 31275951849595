import './treeList.less'
export default {
  props: {
    dataSource: {
      default: () => ([])
    },
    selectKeys: {
      default: () => ([])
    },
    replaceFields: {
      default: () => ({
        title: 'title',
        key: 'key'
      })
    },
    loadData: {
      default: ''
    }
  },
  data() {
    return {
      listData: [],
      targetKeys: [],
      expandedKeys: [],
      defaultLoadedKeys: [],
      indicator: (<a-icon type="loading" style="font-size: 24px" spin />)
    }
  },
  mounted () {
    this.listData = this.dataSource
    this.setSelectedData(this.selectKeys)
  },
  methods: {
    setSelectedData (data) {
      const newData = data.concat()
      this.targetKeys = newData
      // newData.forEach(item => {
      //   this.targetKeys.push(item[this.replaceFields.key])
      // })
    },
    getIndeterminate (itemData) {
      const newArr = []
      const getData = (data) => {
        data.forEach(item => {
          newArr.push(item[this.replaceFields.key])
          if(item.children && item.children.length) {
            getData(item.children)
          }
        })
      }
      getData(itemData)
      return newArr
    },
    childRender(data, childClass) {
      const { targetKeys, replaceFields, expandedKeys } = this;
      let list = []

      data.forEach(item => {
        const isChildren = item.children && item.children.length
        let indeterminate = false
        item.checked = targetKeys.includes(item[replaceFields.key]) || false
        if(isChildren) {
          let getKeyList = this.getIndeterminate(item.children)
          for (let i = 0; i < getKeyList.length; i++) {
            const itemKey = getKeyList[i]
            if(targetKeys.includes(itemKey)) {
              indeterminate = true
              break;
            }
          }
          const checkAll = []
          getKeyList.forEach(elKey => {
            checkAll.push(targetKeys.includes(elKey) || false)
          })
          if(!checkAll.includes(false)) {
            item.checked = true
            indeterminate = false
          }
        }
        let isExpanded = false
        if(expandedKeys.includes(item[replaceFields.key])) {
          isExpanded = true
        }
        const itemClick = () => {
          if(isChildren) {
            if(item.checked) {
              item.children.forEach(itemData => {
                const key = itemData[replaceFields.key]
                const keyIndex = targetKeys.indexOf(key)
                targetKeys.splice(keyIndex, 1)
              })
            } else {
              item.children.forEach(el => {
                if(!targetKeys.includes(el[replaceFields.key])) {
                  targetKeys.push(el[replaceFields.key])
                }
              })
            }
            // if(item.children.length === targetKeys.length) {
            //   targetKeys.splice(0, targetKeys.length)
            // } else {
            //   item.children.forEach(el => {
            //     if(!targetKeys.includes(el[replaceFields.key])) {
            //       targetKeys.push(el[replaceFields.key])
            //     }
            //   })
            // }

          } else {
            if(targetKeys.includes(item[replaceFields.key])) {
              const keyIndex = targetKeys.indexOf(item[replaceFields.key])
              targetKeys.splice(keyIndex, 1)
            } else {
              targetKeys.push(item[replaceFields.key])
            }
          }
          this.$emit('select', targetKeys)
          event.stopPropagation()
        }
        const expandedClick = () => {
          if(item.isLeaf) {
            return;
          }
          if(expandedKeys.includes(item[replaceFields.key])) {
            let keyIndex = expandedKeys.indexOf(item[replaceFields.key])
            expandedKeys.splice(keyIndex, 1)
          } else {
            expandedKeys.push(item[replaceFields.key])
          }
          if(this.loadData) {
            const key = item[this.replaceFields.key]
            if(this.defaultLoadedKeys.includes(key)) {
              return
            }
            this.$set(item, 'spinning', true)
            this.loadData({
              dataRef: item
            }).then(res => {
              this.defaultLoadedKeys.push(key)
              this.$set(item, 'spinning', false)
              delete item.spinning
            }).catch(error => {
              this.$set(item, 'spinning', false)
              delete item.spinning
            })
          }
          event.stopPropagation()
        }
        const delItem = () => {
          if(item.checked) {
            item.children.forEach(itemData => {
              const key = itemData[replaceFields.key]
              const keyIndex = targetKeys.indexOf(key)
              targetKeys.splice(keyIndex, 1)
            })
          }
          this.$emit('delItem', item, targetKeys)
        }
        list.push(
          <a-spin spinning={!!item.spinning} indicator={this.indicator}>
            <li class='item'>
              <p class='item-text' onClick={expandedClick}>
                {!item.isLeaf && <a-icon
                  type='caret-down'
                  vOn:click_stop={expandedClick}
                  class={[
                    'carte-ico',
                    {
                      'ico_expanded': expandedKeys.includes(item[replaceFields.key])
                    }
                  ]}
                />}
                <span vOn:click_stop={() => {}}>
                  <a-checkbox onClick={itemClick} checked={item.checked} class={isChildren || 'no_child' } indeterminate={indeterminate} >
                    {item[replaceFields.title]}
                  </a-checkbox>
                </span>
                { !childClass ? <span vOn:click_stop={delItem} class="iconhr ico-8 item-del" ></span> : ''}
              </p>
              {
                (isChildren && isExpanded)
                ? this.childRender(item.children, 'child') : ''
              }
            </li>
        </a-spin>)
      })
      return (
        <ul class={['list', {'child': childClass}]}>
          {list}
        </ul>
      )
    },
    setEvenBgColor () {
      let itemText = this.$refs.customTree.querySelectorAll('.item-text')
      for(let i = 1; i < itemText.length; i+=2) {
        itemText[i].className += ' even-row'
      }
    }
  },
  watch: {
    dataSource(n) {
      this.listData = n
      this.$nextTick(() => {
        this.setEvenBgColor()
      })
    },
    selectKeys(n) {
      this.setSelectedData(n)
    }
  },
  render() {
    return (<div class='custom-tree tree-list3' ref='customTree'>
      {this.childRender(this.listData)}
    </div>)
  }
};
