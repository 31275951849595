<template>
  <a-layout :class="['main-layout']" style="height: 100%;">
    <main-header v-show="!isSmartTools" :menuData="headerMenuData"/>

    <a-layout class="ant-layout-has-sider" style="height: 100%;position: relative; z-index: 1;">
      <!-- <main-sider v-if="layout === 'side' || layout === 'mix'" :menuData="sideMenuData"/> -->
      <!-- <a-menu theme="dark" style="width:200px" mode="vertical" @click="changetab" :selectedKeys="selectedKeys">
        <a-menu-item v-for="(item, index) in topMenulist" :key="index">{{item.title}} </a-menu-item>
      </a-menu> -->
      <!-- 动态tabs展示 -->
      <main-sider v-show="!isSmartTools" v-if="layout === 'side' || layout === 'mix'" :menuData="topMenulist"/>
      <a-layout :class="{ 'paddingTop': !showBreadcrumb}">
        <!-- <div class="tabs" v-if="tablist.length > 0">
          <a-tabs type="editable-card" @edit="onEdit" @change="changepage" :activeKey="activetab" :hideAdd = true>
            <a-tab-pane v-for="(item, index) in tablist" :key="index" :tab="item.title">
            </a-tab-pane>
            <span style="cursor:pointer">
              <a-icon type="minus" slot="tabBarExtraContent" style="paddingRight:10px; cursor:pointer" @click="onEdit" />
            </span>
          </a-tabs>
        </div> -->
        <!-- <div class="main-top-con" v-if="showBreadcrumb">
          <breadcrumb :nowWorkArea="'management'"/>
          <span class="select-mj-wrap">
            当前页面密级
            <a-select class="select-mj" :default-value="secretLevelOptions[0].value" :options="secretLevelOptions" style="width: 80px" @change="handleChange"></a-select>
          </span>
        </div> -->
        <main-content style="height:100%"/>
      </a-layout>
      <noticeDrawer></noticeDrawer>
      <main-drawer :menuData="headerMenuData"></main-drawer>
    </a-layout>
  </a-layout>
</template>

<script>
import MainHeader from './header/MainHeader'
import MainSider from './sider/MainSider'
import MainContent from './content/MainContent'
import noticeDrawer from './drawer/noticeDrawer'
import MainDrawer from './drawer/MainDrawer'
import breadcrumb from './breadcrumb/breadcrumb'
import { mapState, mapGetters } from 'vuex'
import { LOGIN_USER_TYPE } from 'common/confing'

export default {
  name: "MainLayout",
  components: {MainSider, MainHeader, MainContent, noticeDrawer, MainDrawer, breadcrumb },
  data(){
    return{
      isSmartTools:false,
      mjOptions: [
          { value: 'JM', label: 'JM' },
          { value: 'MM', label: 'MM' },
          { value: 'NB', label: 'NB' },
          { value: 'HXSM', label: 'HXSM' },
          { value: 'PTSM', label: 'PTSM' },
          { value: '公开', label: '公开' }
      ],
      selectedKeys: ['1'],
      active: null
    }
  },
  watch: {
    $route(val) {
      this.setActivated(val)
    },
    layout() {
      this.setActivated(this.$route)
    }
  },
  created () {
    console.log(this.$route.path)
    this.isSmartTools = this.$route.path.includes('smartTools') || frames.length !== parent.frames.length //当被iframe标签嵌套时
    // if(this.$route && this.$route.path){
    //   let path = this.$route.path;
    //   let rootPath = path.substring(0, path.indexOf('/', 1));
    //   this.selectedKeys.push(rootPath);
    //   this.selectedKeys.push(path);
    //   this.sOpenKeys.push(rootPath);
    //   this.sOpenKeys.push(path);
    // }
    
  },
  computed: {
    ...mapState(['layout', 'lang', 'menuData', 'activatedFirst']),
    ...mapGetters(['firstMenu', 'subMenu']),
    // 通过global获取user的信息
    ...mapState('global', {
      mainMenu: state => state.menu
    }),
    topMenulist () {
      // this.selectedKeys = []
      return this.$store.getters.menuTop || []
    },
    tablist () {
      // let arr = []
      // if (this.$store.getters.menuTop.length > 0) {
      //   if (this.$store.getters.menuTop[this.$store.getters.menuTopActive].children) {
      //     arr = this.$store.getters.menuTop[this.$store.getters.menuTopActive].children
      //   }
      // }
      // console.log('00000000000')
      // console.log(arr)
      // return arr
      return this.$store.getters.menuTab || []
    },
    activetab () {
      return this.$store.getters.menuTabActive || 0
    },
    headerMenuData() {
      // const {layout, menuData, firstMenu, mainMenu} = this
      const {layout, mainMenu} = this
      let firstMenu = []
      if (layout === 'mix') {
        firstMenu = mainMenu.map(item => {
          const menuItem = { ...item }
          if (menuItem.path === '/index') {

          } else {
            delete menuItem.children
          }
          return menuItem
        })
      }
      return layout === 'mix' ? firstMenu : mainMenu
    },
    sideMenuData() {
      // const {layout, menuData, subMenu, mainMenu} = this
      const {layout, mainMenu, activatedFirst} = this
      console.log(mainMenu)
      // 过滤掉 首页的子菜单
      const sideMenu = mainMenu.filter(item => item.name !== '首页')
      let subMenu = []
      if (layout === 'mix') {
        const current = sideMenu.find(menu => menu.fullPath === activatedFirst)
        if (current) {
          subMenu = current && current.children ? current.children : []
        } else {
          subMenu = sideMenu[0].children
        }
      }
      return layout === 'mix' ? subMenu : sideMenu
    },
    secretLevelOptions () {
      if (sessionStorage.getItem(LOGIN_USER_TYPE) + '' === '1') { // avic项目使用
        return [
          { value: 'PTSM', label: 'PTSM' },
          { value: '公开', label: '公开' }
        ]
      }

      return this.mjOptions
    },
    showBreadcrumb () {
      return true // 暂时改为不需要判断 全部打开
      return sessionStorage.getItem(LOGIN_USER_TYPE) + '' === '1' // avic项目登录使用
    }
  },
  methods: {
    setActivated(route) {
      if (this.layout === 'mix') {
        let matched = route.matched
        matched = matched.slice(0, matched.length - 1)
        const {mainMenu} = this
        const sideMenu = mainMenu.filter(item => item.name !== '首页')
        for (let menu of sideMenu) {
          if (matched.findIndex(item => item.path === menu.fullPath) !== -1) {
            this.$store.state.activatedFirst = menu.fullPath
            break
          }
        }
      }
    },
    handleChange(data) {
      console.log(data)
    },
    // 切换二级菜单
    changetab(item){
      // this.active = 0
      if (this.$store.getters.menuTop[item.key].children) {
        this.$store.dispatch('user/setMenutab', JSON.stringify(this.$store.getters.menuTop[item.key].children))
        this.$router.push({ path: this.$store.getters.menuTab[0].path })
      } else {
        this.$store.dispatch('user/cleartab')
        this.$router.push({ path: this.$store.getters.menuTop[item.key].path })
      }
      
    },
    // 三级菜单跳转页面
    changepage (item) {
      // this.active = item
      this.$store.dispatch('user/setMenutabActive', item)
      this.$router.push({ path: this.$store.getters.menuTab[item].path })
    },
    onEdit(targetKey, action) {
      if (this.$store.getters.menuTab.length > 1 && this.$store.getters.menuTabActive === targetKey) {
        this.$router.push({ path: this.$store.getters.menuTab[targetKey - 1].path })
        this.$store.dispatch('user/setMenutabActive', targetKey - 1)
      }
      this.$store.dispatch('user/removeMenutab', targetKey)
    }
  }
}
</script>

<style scoped>
.ant-layout.paddingTop {
  padding-top: 10px;
}
.main-top-con {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  border-bottom: 1px solid #e2e2e2;
}
.select-mj {
  margin: 0 10px;
}
.select-mj /deep/ .ant-select-selection {
  background-color: transparent;
  border: none;
  color: #f51300;
  /*font-weight: bold;*/
}
.select-mj-wrap /deep/ .ant-select-open .ant-select-selection,
.select-mj-wrap /deep/ .ant-select-focused .ant-select-selection,
.select-mj-wrap /deep/ .ant-select-selection:active,
.select-mj-wrap /deep/ .ant-select-selection:focus {
  border: none;
  box-shadow: none;
}
</style>
