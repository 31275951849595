export default {
  path: '/conf',
  name: 'generalLink',
  redirect: '/conf/generalLink',
  meta: {
    module: 'conf',
    title: '配置管理',
    roles: ['admin', 'group', 'user'],
    icon: ''
  },
  children: [
    {
      path: '/conf/flow',
      redirect: '/conf/flow/generalLink',
      name: 'confFlow',
      meta: {
        module: '配置管理',
        title: '业务流程',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/conf/flow/generalLink',
          name: 'generalLink',
          meta: {
            module: '业务流程',
            title: '通用环节配置',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/conf/flow/linkMeas',
          name: 'linkMeas',
          meta: {
            module: '业务流程',
            title: '通用子流程',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/conf/flow/confProcessi',
          name: 'confProcessi',
          meta: {
            module: '业务流程',
            title: '流程配置',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/conf/flow/processLink',
          name: 'processLink',
          meta: {
            module: '业务流程',
            title: '流程环节配置',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/conf/flow/measureManagement',
          name: 'measureManagement',
          meta: {
            module: '业务流程',
            title: '环节子流程配置',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
          path: '/conf/flow/measureInstrument',
          name: 'measureInstrument',
          meta: {
            module: '业务流程',
            title: '措施文书管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        }
      ]
    },
    {
      path: '/conf/mobile',
      // redirect: '/conf/mobile',
      name: 'mobile',
      meta: {
        module: '配置管理',
        title: '业务流程',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        // {
        //   path: '/conf/flow/generalLink',
        //   name: 'generalLink',
        //   meta: {
        //     module: '业务流程',
        //     title: '通用环节配置',
        //     roles: ['admin', 'group', 'user'],
        //     icon: ''
        //   }
        // }
      ]
    }
    // {
    //   path: '/conf/linkMeas',
    //   name: 'linkMeas',
    //   meta: {
    //     module: '业务流程',
    //     title: '通用子流程',
    //     roles: ['admin', 'group', 'user'],
    //     icon: ''
    //   }
    // },
    // {
    //   path: '/conf/confProcessi',
    //   name: 'confProcessi',
    //   meta: {
    //     module: '业务流程',
    //     title: '流程配置',
    //     roles: ['admin', 'group', 'user'],
    //     icon: ''
    //   }
    // },
    // {
    //   path: '/conf/processLink',
    //   name: 'processLink',
    //   meta: {
    //     module: '业务流程',
    //     title: '流程环节配置',
    //     roles: ['admin', 'group', 'user'],
    //     icon: ''
    //   }
    // },
    // {
    //   path: '/conf/measureManagement',
    //   name: 'measureManagement',
    //   meta: {
    //     module: '业务流程',
    //     title: '环节子流程配置',
    //     roles: ['admin', 'group', 'user'],
    //     icon: ''
    //   }
    // },
    // {
    //   path: '/conf/measureInstrument',
    //   name: 'measureInstrument',
    //   meta: {
    //     module: '业务流程',
    //     title: '措施文书管理',
    //     roles: ['admin', 'group', 'user'],
    //     icon: ''
    //   }
    // }
  ]
}
