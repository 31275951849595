<template>
  <a-layout class="ptp-layout" :class="{ 'is-compose': !compose }">
    <div class="ptp-layout-content">
      <div class="ptp-layout-top"
        :class="{
          'is-lr_ele': $slots.contentTopLeft ||  $slots.contentTopRight
        }"
      >
        <div class="ptp-ctslot-left" v-if="$slots.contentTopLeft">
          <slot name="contentTopLeft"></slot>
        </div>
        <div class="ptp-ctslot-right" v-if="$slots.contentTopRight">
          <slot name="contentTopRight"></slot>
        </div>
        <slot name="contentTop" v-if="$slots.contentTop && (!$slots.contentTopLeft &&  !$slots.contentTopRight)"></slot>
      </div>
      <div class="ptp-layout-main">
        <slot name="contentMain">
          <div></div>
        </slot>
      </div>
      <div class="ptp-layout-bottom"
        :class="{
          'is-lr_ele': $slots.contentBottomLeft ||  $slots.contentBottomRight
        }">
        <slot name="contentBottom" v-if="$slots.contentBottom && (!$slots.contentBottomLeft && !$slots.contentBottomRight)"></slot>
        <div class="ptp-cbslot-left" v-if="$slots.contentBottomLeft">
          <slot name="contentBottomLeft"></slot>
        </div>
        <div class="ptp-cbslot-right" v-if="$slots.contentBottomRight">
          <slot name="contentBottomRight"></slot>
        </div>
      </div>
    </div>
  </a-layout>
</template>
<script>
export default {
  name: 'pageTablePaging',
  props: {
    compose: Boolean,
    space: {
      type: Object,
      default: () => ({
        col: '0',
        row: '0'
      })
    }
  },
  mounted () {
    this.setSpace()
  },
  methods: {
    setSpace() {
      this.$slots.contentTopLeft.forEach(item => {
        if(item.componentInstance.$el) {
          item.componentInstance.$el.style.marginRight = this.space.col || '0'
        }
      })
      this.$slots.contentTopRight.forEach(item => {
        if(item.componentInstance.$el) {
          item.componentInstance.$el.style.marginLeft = this.space.col || '0'
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
@padding: 20px; // 设置内容的边距

.ptp-layout {
  width: 100%;
  height: 100%;
  padding-left: 10px;
  background: transparent;
}
.ptp-layout.is-compose {
  padding-right: 10px;
}
.ptp-layout .ptp-layout-content {
  display: flex;
  flex: auto;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 6px 0 0 6px;
}
.ptp-layout.is-compose .ptp-layout-content{
  border-radius: 6px 6px 0 0;
}
.ptp-layout .ptp-layout-content .ptp-layout-top {
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  height: 54px;
  padding: 0 @padding;
  line-height: 54px;
}
.ptp-layout .ptp-layout-top.is-lr_ele {
  display: flex;
}
.ptp-layout .ptp-layout-top .ptp-ctslot-left {
  display: flex;
  align-items: center;
}
.ptp-layout .ptp-layout-top .ptp-ctslot-right {
  position: absolute;
  right: @padding;
}
.ptp-layout .ptp-layout-content .ptp-layout-main {
  flex: auto;
  width: 100%;
  overflow: auto;
  padding: 0 @padding;
}
.ptp-layout .ptp-layout-content .ptp-layout-bottom {
  flex: 0 0 auto;
  position: relative;
  width: 100%;
  height: 40px;
  line-height: 40px;
  padding: 0 @padding;
}
.ptp-layout .ptp-layout-content .ptp-layout-bottom.is-lr_ele {
  display: flex;
  height: 41px;
  border-top: 1px solid #f5f5f5;
}
.ptp-layout .ptp-layout-bottom .ptp-cbslot-left {
  height: 40px;
}
.ptp-layout .ptp-layout-bottom .ptp-cbslot-right {
  position: absolute;
  right: @padding;
  height: 40px;
}
</style>
