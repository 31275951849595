import antTable from './mixins/antTable'
import ComPagination from '../ComPagination'
import AntTable from '../ComTable/AntTable'
import { LeftSideLayout } from '../BaseLayout'
import './TplStyle.css'
export default {
  props: {
    space: {
      type: Object,
      default: () => ({
        col: '8',
        row: '0'
      })
    },
    tableColumns: {
      default: () => ([])
    },
    treeData: {
      default: ''
    },
    tableReqUrl: {
      default: ''
    },
    tableDataReq: {
      default: ''
    },
    rowSelection: {
      default: () => ({})
    },
    tableRowKey: {
      default: 'key'
    },
    layoutSpace: {
      default: true
    },
    conTopHide: Boolean,
    noBottom: Boolean,
    manualTableReq: Boolean,
    noSpaceTop: Boolean
  },
  components: {
    LeftSideLayout,
    ComPagination
  },
  mixins: [antTable],
  data() {
    return {
      treeData_: []
    }
  },
  created() {
    this.$emit('getInitFun', {
      treeInitFun: this.getTreeData,
      tableInitFun: this.getData,
      resetPagination: this.resetPagination,
      onGotPageData: this.onGotPageData,
      onSetPageData: this.onSetPageData
    })
    if(!this.manualTableReq) {
      this.getData()
    }
    this.getTreeData()
  },
  mounted() {

  },
  methods: {
    getData () {
      // 拼装查询条件
      const params = {}
      this.addPageInfo(params)
      this.searchData.pageNum = params.currentPage // 页数
      this.searchData.pageSize = params.pageSize // 页大小
      if(!this.tableDataReq) {
        return
      }
      this.tableDataReq(this.searchData).then(res => {
        this.onGotPageData(res.data)
      })
    },
    onLoadData (treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve()
          return
        }
        setTimeout(() => {
          treeNode.dataRef.children = [
            { title: 'Child Node', key: `${treeNode.eventKey}-0` },
            { title: 'Child Node', key: `${treeNode.eventKey}-1` }
          ]
          this.treeData = [...this.treeData]
          resolve()
        }, 100)
      })
    },
    getTreeData() {
      if(this.treeData === '') return this.treeData_ =[]
      if(this.treeData instanceof Array) {
        return this.treeData_ = this.treeData
      }
      if(this.treeData instanceof Function) {
        this.treeData().then(res => {
          this.treeData_ = res.data
        })
      }
    }
  },
  watch: {
    treeData_() {
      // console.log(this.treeData_)
    }
  },
  render() {
    return (
    <left-side-layout
      space={this.space}
      layoutSpace={this.layoutSpace}
      conTopHide={this.conTopHide}
      noBottom={this.noBottom}
      class="page-left-side"
    >
      <template slot="sideTop">
      {
        this.$slots.sideTop && this.$slots.sideTop
      }
      </template>
      <template slot="sideMain">
        {
            this.$slots.sideMain
          ? this.$slots.sideMain
          : <a-tree
            default-selected-Keys={['0-0']}
            show-line={true}
            show-icon={true}
            default-expanded-keys={['0-1-1']}
            onSelect={() => {}}
            tree-data={this.treeData_}
          ></a-tree>
        }
      </template>
      <template slot="contentTopLeft">
        {
          this.$slots.contentTopLeft
            &&  this.$slots.contentTopLeft
        }
      </template>
      <template slot="contentTopRight">
        {
          this.$slots.contentTopRight
          ?  this.$slots.contentTopRight
          : [ <a-button>
              新增
            </a-button>,
            <a-button>
              复制
            </a-button>
          ]
        }
      </template>
      <template slot="contentMain">
        {
          this.$scopedSlots.contentMain
          ? this.$scopedSlots.contentMain({columns: this.tableColumns, tableData: this.tableData})
          : <AntTable
            style='width: 100%; height: 98%;'
            scroll={{ x: 1300, y: 'calc(100% - 70px)' }}
            pagination={false}
            columns={this.tableColumns}
            dataSource={this.tableData}
            rowSelection={this.rowSelection}
            rowKey={this.tableRowKey}
            loading={this.loading}
            bordered
          ></AntTable>
        }
      </template>
      <template slot="contentBottomLeft">
        {
          this.$slots.contentBottomLeft
        }
      </template>
      <template slot='contentBottomRight'>
        {
          this.$scopedSlots.contentBottomRight
            ? this.$scopedSlots.contentBottomRight()
            : <com-pagination
              onChange={this.handlePageChange}
              pagination={this.pagination}
            ></com-pagination>
        }
      </template>
      { this.$slots.default && this.$slots.default}
    </left-side-layout>)
  }
}


