<template>
  <div class="com-pagination">
      <span v-if="simple">共 {{pagination.total}} 条</span>
    <a-config-provider :locale="zhCN">
      <a-pagination
        class="cum-pagein"
        size="small"
        :simple="simple"
        :total="pagination.total"
        :showTotal="pagination.showTotal"
        :showSizeChanger="pagination.showSizeChanger"
        :showQuickJumper="false"
        :showLessItems="pagination.showLessItems"
        :pageSizeOptions="pagination.pageSizeOptions"
        :defaultPageSize="pagination.defaultPageSize"
        :defaultCurrent="pagination.defaultCurrent"
        :hideOnSinglePage="pagination.hideOnSinglePage"
        :pageSize="pageSize"
        :current="pagination.defaultCurrent"
        @change="change"
        @showSizeChange="showSizeChange"
      >
      </a-pagination>
    </a-config-provider>
      <span v-if="simple">{{pageSize}}条/页</span>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
export default {
  props: {
    simple: {
      type: Boolean,
      default: false
    },
    pagination: {
      default: {
        // 数据总数
        total: 100,
        // // 分页尺寸
        // size: '',
        // 用于显示数据总量和当前数据顺序
        showTotal: total => `共 ${total} 条`,
        // 是否可以改变pageSize
        showSizeChanger: true,
        // 是否可以快速跳转至某页
        showQuickJumper: true,
        // show less page items
        showLessItems: true,
        // 指定每页可以显示多少条
        pageSizeOptions: ['10', '20', '50'],
        // 用于自定义页码的结构，可用于优化 SEO
        // itemRender:
        // 默认的每页条数
        defaultPageSize: 20,
        // 默认的当前页数
        defaultCurrent: 1,
        // 只有一页时是否隐藏分页器
        hideOnSinglePage: false
      }
    }
  },
  data() {
    return {
      zhCN,
      pageSize: 10
    }
  },
  created() {

  },
  mounted() {
    this.pageSize = this.pagination.defaultPageSize
  },
  methods: {
    paginationChange(page, pageSize) {
      this.pageSize = pageSize
      this.$emit('change', {current: page, pageSize: pageSize})
    },
    change(page, pageSize) {
      this.pageSize = pageSize
      this.paginationChange(page, pageSize)
    },
    showSizeChange(current, size) {
      this.pageSize = size
      this.$emit('change', {current: current, pageSize: size})
    }
  },
  watch: {
    pagination: {
      deep: true,
      handler (n) {
        this.pageSize = n.defaultPageSize
      }
    }
  }
}
</script>

<style scoped>
.com-pagination {
  display: inline-block;
}
.com-pagination .cum-pagein {
  display: flex;
  justify-items: center;
}
.com-pagination /deep/ .ant-pagination.mini .ant-pagination-options-quick-jumper {
  line-height: initial;
}

</style>
