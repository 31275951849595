export default {
  path: '/examples',
  name: 'examples',
  component: () => import('@/examples'),
  children: [
    {
      path: 'report',
      name: 'examples_report',
      meta: {
        title: 'report'
      },
      component: () => import('@/examples/report')
    },
    {
      path: 'spreadjs',
      name: 'examples_spreadjs',
      meta: {
        title: 'spreadjs'
      },
      component: () => import('@/examples/spreadjs/index.vue')
    },
    {
      path: 'demo',
      name: 'examples_demo',
      meta: {
        title: '示例'
      },
      component: () => import('@/examples/demo'),
      children: [
        {
          path: 'treeSelect',
          name: 'examples_treeSelect',
          meta: {
            title: 'treeSelect'
          },
          component: () => import('@/examples/demo/treeSelect.vue')
        }
      ]
    }
  ]
}
