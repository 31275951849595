<!--
  <flow-revoke-modal ref="flowRevokeModal" /> 工作流弹窗（收回）
-->
<template>
  <a-modal
    :visible="show"
    :closable="false"
    :mask="true"
    :maskClosable="true"
    @ok="modalOk"
    @cancel="modalCancel">
    <div style="min-height: 40px">
      <a-spin :spinning="!visibleFlowRevoke" v-if="!visibleFlowRevoke" class="spin-loading"></a-spin>
      <flow-revoke ref="refFlowRevoke" :visible="visibleFlowRevoke"></flow-revoke>
    </div>
  </a-modal>
</template>

<script>
  import FlowRevoke from './components/FlowRevoke'
  export default {
    name: 'flowRevokeModal',
    components: {
      FlowRevoke
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      flowId: {
        type: String,
        default: ''
      },
      flowAlias: {
        type: String,
        default: ''
      }
    },
    computed: {
      show: {
        get () {
          this.visibleFlowRevoke = this.visible && this.innerShow
          return this.visible && this.innerShow
        },
        set () {}
      }
    },
    data () {
      return {
        innerShow: true,
        visibleFlowRevoke: false
      }
    },
    methods: {
      init () {
        this.innerShow = true
        this.$refs.refFlowRevoke.init({
          id: this.flowId,
          onBeforeRevoke: this.onBeforeRevoke, // 收回前执行的操作
          onAfterRevoke: this.onAfterRevoke // 收回后执行的操作
        }).then(res => {
          this.visibleFlowRevoke = true
          this.revokeWorkFlowData = this.$refs.refFlowRevoke.onRevoke
        })
      },
      modalOk () {
        this.revokeWorkFlowData()
      },
      modalCancel () {
        this.innerShow = false
      },
      revokeWorkFlowData () {

      },
      onBeforeRevoke () {
        const that = this
        return new Promise((resolve, reject) => {
          let paramsData = { isPass: true, noPassMsg: '' }
          that.$emit('beforeRevoke', returnData => {
            paramsData = returnData
          }); // 此处建议返回{ isPass: false, noPassMsg: '错误消息' }、或者不返回
          resolve(paramsData)
        })
      },
      onAfterRevoke (isSuccess, res) {
        this.$emit('afterRevoke', isSuccess, res)
      },

    }
  }
</script>

<style scoped lang="less">
</style>
