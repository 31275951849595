<template>
  <a-layout-header :class="[headerTheme, 'main-header']" @click.capture="closeDrawer" style="display:flex;padding: 0px 20px 0 32px; height: 54px; line-height: 54px; background: linear-gradient(rgb(255, 255, 255), rgb(236, 246, 255))">
      <div style="font-size: 24px; color: #fff; float: left; font-weight:bold;  letter-spacing:1px;color:#00276f;display:flex;align-items:center;height:100%">
        <img v-show="form.frontShowLogo" class="side-logo" style="height:80%;margin-right: 16px;" :src="form.systemLogo||'../../components/layout/img/logo.png'"/>
        {{form.systemName}}
        <span v-show="form.frontNameSuffix" class="extra">{{form.frontNameSuffix}}</span>
      </div>
    <div class="main-header-menu" style="flex:1">
      <a-menu style="background: none; border: none;font-size:16px" mode="horizontal" @click="changetab" v-model="selectedKeys">
        <a-menu-item 
        v-for="(item, index) in topMenu"
        :key="index"
         >{{item.title}} 
         </a-menu-item>
      </a-menu>
    </div>
    <div class="main-header-right" style="color:#fff">
        <div>
            <a-icon type="home" style="font-size: 18px; color: #000; margin:18px 10px 0 0" @click="onHomeClick"/>
        </div>
        <a-menu slot="overlay">
            <a-menu-item v-for=" item in indexMenu" :key="item.path"  @click="val => setLang(item)">{{item.name}}</a-menu-item>
        </a-menu>
        <header-avatar/>
    </div>
  </a-layout-header>
</template>

<script>
// import HeaderNotice from './HeaderNotice'
import HeaderAvatar from './HeaderAvatarNew'
import SiderMenu from '../sider/SiderMenu'
import { mapState } from 'vuex'
const selectedKeys = []
selectedKeys.push(parseInt(sessionStorage.getItem('menu_active')) - 1)
export default {
  name: "MainHeader",
  props: ['menuData'],
  components: {HeaderAvatar, SiderMenu},
  data() {
    return {
        form:{
          systemName:'',
          frontNameSuffix:'',
          frontShowLogo:0,
          systemLogo:''
        },
        ssList: [
        {key: 'personalWorkbench', name: '个人工作台', path: '/ss/personalWorkbench/homePage'},
        // {key: 'mgmtWorkbench', name: '管理工作台', path: '/ss/mgmtWorkbench/mgmtHomePage'},
        {key: 'hrWorkbench', name: 'HR工作台', path: '/ss/hrWorkbench/hrHomePage'}
      ],
        langList: [
        {key: 'CN', name: '简体中文', alias: '简体'},
        {key: 'HK', name: '繁體中文', alias: '繁體'},
        {key: 'US', name: 'English', alias: 'English'}
      ],
      searchActive: false,
      selectedKeys: selectedKeys || [1]
    }
  },
  created () {
    let data = this.menuData[sessionStorage.getItem('menu_active') || 1].children
    this.$store.dispatch('user/setMenutop', JSON.stringify(data))
    this.getData()
  },
  computed: {
    ...mapState(['layout', 'lang']),
    topMenulist () {
      return this.$store.getters.menuTop || []
    },
    topMenu () {
      return this.menuData.filter(item => item&&item.title !== '首页')
    },
    ssMenu () {
      return this.menuData.filter(item => item&&item.name === '个人自助')
    },
    indexMenu () {
      let indexMenu = this.menuData.filter(item => item&&item.name === '首页')
      if ((indexMenu instanceof Array) && indexMenu.length > 0) {
        return indexMenu[0].children
      } else {
        return []
      }
    },
    headerTheme () {
      return 'light'
    },
    loginUserType () { // 判断当前用户类型，1表示avic项目
      return this.$store.state.global.loginUserType || ''
    },
    langAlias() {
      let lang = this.langList.find(item => item.key == this.lang)
      return '简体'
    },
    menuWidth() {
      const headWidth = '100%'
      const logoWithName = '300px'
      const rightHeaderWidth = '260px'
      return `calc(${headWidth} - ${logoWithName} - ${rightHeaderWidth})`
    }
  },
  methods: {
    getData(){
      this.$http('post_showSystemConfig').then(res=>{
        this.form = res.data
      })
    },
    onHomeClick () {
      const data = {
        path: '/home',
        title: '首页'
      }
      this.$store.dispatch('user/addMenutab', JSON.stringify(data))
      this.$router.push({
        // path: this.$store.state.defaultPage
        path: '/home'
      })
    },
    onSelect (obj) {
      this.$emit('menuSelect', obj)
    },
    setLang(e) {
		// if (e.name === '个人工作台') {
		// 	this.$store.state.isLimitss = true
		// } else {
		// 	this.$store.state.isLimitss = false
		// }
        this.$router.push({ path: e.path })
        // path: '../../apm/OrgDefinite'
    },
    closeDrawer () {
      this.$store.state.noticeVisible = false
      this.$store.state.drawerVisible = false
    },
    changetab (item) {
      sessionStorage.setItem('menu_active', item.key + 1)
      this.$store.dispatch('user/setMenutop', JSON.stringify(this.menuData[item.key + 1].children))
    }
  }
}
</script>

<style lang="less" scoped>
@import "index";
.extra{
  font-size:14px;
  line-height:24px;
  padding: 0 8px;
  text-align: center;
  margin: 8px 0 0 5px;
  background-color: rgb(204, 86, 86);
  border-radius: 5px;
  color: #fff;
}
/deep/ .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left{
  border: 0;
}
/deep/.ant-menu, .ant-menu-horizontal, .ant-menu-root, .ant-menu-light {
  color: #333;
  font-weight: bold;
}
/deep/.ant-menu-horizontal > .ant-menu-item:hover, .ant-menu-horizontal > .ant-menu-submenu:hover, .ant-menu-horizontal > .ant-menu-item-active, .ant-menu-horizontal > .ant-menu-submenu-active, .ant-menu-horizontal > .ant-menu-item-open, .ant-menu-horizontal > .ant-menu-submenu-open, .ant-menu-horizontal > .ant-menu-item-selected, .ant-menu-horizontal > .ant-menu-submenu-selected{
  border-bottom: 2px solid transparent;
  color: #1B82E2;
  background: #D3E9FF;
}
/deep/.ant-menu-horizontal > .ant-menu-item, .ant-menu-horizontal > .ant-menu-submenu{
  height: 54px;
  line-height: 54px;
  position: relative;
  top: 1px;
  display: inline-block;
  vertical-align: bottom;
  border-bottom: 2px solid transparent;
}
// /deep/.ant-menu-item:hover, .ant-menu-item-active, .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .ant-menu-submenu-active, .ant-menu-submenu-title:hover{
//   // color:#fff;
// }
/deep/.header-avatar[data-v-79b01b24]{
    width: auto;
    height: 100%;
    position: relative;
    line-height: 54px;
    text-align: center;
    color: #000;
}
</style>
