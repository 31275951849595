<template>
  <sideHeadeLayout v-if="getLayout === 'sideHeadeLayout'"></sideHeadeLayout>
  <sideHeadeLayout v-else-if="getLayout === 'sideLayout'"></sideHeadeLayout>
</template>

<script>
import sideHeadeLayout from "./sideHeadeLayout";
import sideLayout from "./sideLayout";
export default {
  components: {
    sideHeadeLayout,
    sideLayout
  },
  computed: {
    getLayout() {
      return 'sideLayout'
    }
  },
  created() {
    // /pm/implement/tree_type  /pm/implement/waterfall_type /pm/implement/tile_type
    console.log(this.$route.path)
  }
}
</script>

<style lang="less" scoped>
</style>
