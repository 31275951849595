<template>
    <div class="com-upload">
        <a-config-provider :locale="zhCN">
            <!--            <upload-list ></upload-list>-->
            <!--            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"-->
            <div class="com-upload-box">
                <div class="com-upload-isBatchDel" v-if="!readOnly&&batchDeleteAble&&DeletePermit">
                    <a-button @click="handleBatchDel">批量删除</a-button>
                </div>
                <div class="com-upload-file">
                    <z-upload
                            v-if="fileList.length>=0"
                            list-type="picture-card"
                            :file-list="fileList"
                            multiple
                            :accept="FileAccept"
                            @preview="handlePreview"
                            @rename="handleRename"
                            @change="handleChange"
                            @selected="handleSelect"
                            @download="handleDownload"
                            :remove="handleRemove"
                            :before-upload="beforeUpload"
                            :show-upload-list="{
                    showPreviewIcon: true,
                    showRemoveIcon: DeletePermit,
                    showDownloadIcon: readOnly && DownLoadPermit
                }"
                    >
                        <div v-if="fileList.length < limitCount && !readOnly">
                            <a-icon type="plus"/>
                            <div class="ant-upload-text">
                                点击上传或拖拽到此框内
                            </div>
                        </div>
                    </z-upload>
                    <div v-if="readOnly && fileList.length===0" class="ant-file-no-data">
                        <!--                        <a-icon type="file-excel" /> -->
                        暂无数据
                    </div>
                </div>
                <div class="com-upload-tips" v-if="!readOnly && isShowTip">
                    <h4>上传说明</h4>
                    <p>1.支持扩展名: {{limitFileType}}</p>
                    <p>2.每个附件不超过 50 M</p>
                </div>
            </div>
        </a-config-provider>

        <a-modal v-model="previewVisible" title="预览" width="600px" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
        <select-level :file.sync="singleFile" @handleSelLel="handleSelLel" :LevelfileList.sync="LevelfileList"
                      :selfilelelVisible.sync="selfilelelVisible"></select-level>
        <rename :file.sync="singleFile" @handleRenamed="handleRenamed" :selfilelelVisible.sync="renameVisible"></rename>

        <com-img-viewer
                v-if="dialogVisible"
                :on-close="closeViewer"
                :url-list="GetSrcListByCache()"
                :initialIndex="PriviewStartIndex"
                :titles="dialogTitle"
        ></com-img-viewer>
        <com-pdf-viewer
                v-if="pdfdialogVisible"
                :title="pdfViewerTitle"
                :on-close="closePdfViewer"
                :src="pdfViewerSrc"
                :fid="singlefid"
                :mime-type="mimeType"
        ></com-pdf-viewer>
    </div>
</template>

<script>
    import ZUpload from 'common/lib/Upload/Package/zUpload'
    import SelectLevel from './SelectLevel'
    import Rename from './RenameDialog'
    import {downFile} from 'common/utils/file/downFile'
    import mixins from './mixin/utls'
    import ComPdfViewer from './preview/PdfViewer';
    import ComImgViewer from './preview/ImgViewer';

    export default {
        mixins: [mixins],
        props: {
            isShowTip: {
                type: Boolean,
                default: true
            },
            limitCount: {
                type: Number,
                default: 8
            },
            limitFileType: {
                type: String,
                default: 'txt、pdf、doc、docx、xls、xlsx、ppt、jpg、png、bmp、gif、rar、zip'
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            batchDeleteAble: {
                type: Boolean,
                default: false
            },
            ids: ''
        },
        components: {
            ZUpload,
            SelectLevel,
            Rename,
            ComPdfViewer,
            ComImgViewer
        },
        data() {
            return {
                LevelfileList: []
            }
        },
        created() {
            if (this.ids) {
                this.LoadData(this.ids)
            }
        },
        watch: {
            ids: function (ids) {
                if (ids) {
                    this.LoadData(ids)
                } else {
                    this.fileList = []
                }
            }
        },
        computed: {
            // 允许的文件类型
            FileAccept() {
                let acceptstr = this.limitFileType.split('、').map(item => {
                    return '.' + item
                }).join(',')
                return acceptstr
            }
        },
        methods: {
            // 上传服务器之前的操作
            beforeUpload(file, fileList) {
                if (file.size > 52428800) {
                    this.$message.error('上传附件不能超过 50M！')
                    return false
                } else if (file.size === 0) {
                    this.$message.error('不能上传空内容的文件')
                    return false
                } else {
                    this.showTextTips = ''
                }
                file.level = null
                if (window.G && window.G.ENV && window.G.ENV.networkType === 'nw') {
                    // 显示密级选择框
                    this.selfilelelVisible = true
                    // 传输给密级选择框
                    this.singleFile = file
                    this.LevelfileList = fileList
                } else {
                    // 非内网去掉密级
                    this.LevelfileList = fileList
                    const fileObj = {
                        file: file,
                        singleFile: file
                    }
                    fileObj.singleFile.level = '6'
                    this.handleUpload(fileObj)
                }
                return false;
            },
            // 附件下载
            handleDownload(file) {
                const url = '/platservice/fileupload/download'
                downFile(url, {ids: file.fid})
            },
        }
    }
</script>

<style lang="less">
    .com-upload {
        .ant-upload-list-picture-card-container {
            position: relative;
        }

        .ant-upload-select-picture-card i {
            font-size: 32px;
            color: #999;
        }

        .ant-upload-list-item-info .anticon-copy {
            margin-top: 10px;
            font-size: 40px;
            color: #999 !important;
            --antd-wave-shadow-color: #999;
        }

        .ant-upload-select-picture-card .ant-upload-text {
            margin-top: 8px;
            color: #666;
        }

        .ant-upload-list-picture-card-container, .ant-upload-list-picture-card .ant-upload-list-item, .ant-upload.ant-upload-select-picture-card {
            width: 136px;
            height: 136px;
        }


        .ant-upload-list-picture-card .ant-upload-list-item-thumbnail > div {
            height: 100%;
        }

        .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img,
        .ant-upload-list-picture-card .ant-upload-list-item-info::before,
        .file-options-top {
            height: 70%;
        }

        .ant-upload-list-picture-card .file-options-top .ant-upload-list-item-icon {
            position: relative;
            color: #999;
            top: unset;
            left: unset;
            transform: unset;

        }

        .ant-upload-list-picture-card .ant-upload-list-item-info::before {
            background-color: rgba(145, 148, 150, 0.5);
        }

        .ant-upload-list-picture-card .ant-upload-list-item-filename,
        .ant-upload-list-item-thumbnail .ant-upload-list-item-filename {
            color: #999;
            font-size: 14px;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }

        .ant-upload-list-picture-card .ant-upload-list-item-actions {
            width: 90%;
            /*height: 62%;*/
            height: 70%;
            top: 37%;
        }

        .ant-upload-list-picture-card-sybol {
            position: absolute;
            left: 0;
            background-color: #ff8200;
            border-radius: 2px;
            color: #fff;
            padding: 2px 6px;
            z-index: 1;
            font-size: 12px;
            opacity: 100%;
        }

        .ant-upload-list-item-onlyread {
            display: flex;
            margin-top: 32px;
        }

        .ant-upload-list-item-editable {
            display: flex;
            flex-wrap: wrap;
            padding: 5px;
            height: 100%;
        }

        .ant-upload-list-item-onlyread .item-onlyread {
            width: 33%;
            text-align: center;
        }

        .ant-upload-list-item-editable .item-edit {
            border: 1px solid #ccc;
            padding-top: 5px;
            width: 50%;
            text-align: center;
        }

        .ant-upload-list-item-editable .item-edit:nth-of-type(1) {
            border-top: none;
            border-left: none;
        }

        .ant-upload-list-item-editable .item-edit:nth-of-type(2) {
            border-top: none;
            border-right: none;
        }

        .ant-upload-list-item-editable .item-edit:nth-of-type(3) {
            border-bottom: none;
            border-left: none;
        }

        .ant-upload-list-item-editable .item-edit:nth-of-type(4) {
            border-bottom: none;
            border-right: none;
        }

        .item-onlyread .anticon,
        .item-edit .anticon,
        .custom-delete-pic,
        .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
        .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
        .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
            font-size: 24px;
            color: #F9F9F9;
        }

        .custom-delete-pic.iconhr {
            cursor: pointer;
            color: #F9F9F9;
        }

        .custom-delete-pic.iconhr:hover {
            color: #F9F9F9;
        }

        .ant-file-no-data {
            text-align: center;
        }

        //.com-upload-file .anticon.anticon-file-excel {
        //  color: #888;
        //}
        .item-onlyread .anticon:hover,
        .item-edit .anticon:hover {
            color: rgba(255, 255, 255, 0.85);
        }

        .com-upload-box {
            border: 1px solid #ccc;
            border-bottom: none;
            border-top: none;
            border-radius: 4px;
            //padding: 15px;
        }

        .com-upload-isBatchDel,
        .com-upload-file,
        .com-upload-tips {
            padding: 15px;
        }

        .com-upload-isBatchDel {
            border-top: 1px solid #ccc;
        }

        .com-upload-tips {
            border-bottom: 1px solid #ccc;
        }

        .com-upload-isBatchDel {
            text-align: right;
        }

        .com-upload-file {
            border-top: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
        }

        .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item.ant-upload-list-item-list-isSelect {
            border: 1px solid #1E89FF;
        }

        .ant-upload-list-picture .ant-upload-list-item, .ant-upload-list-picture-card .ant-upload-list-item.ant-upload-list-item-list-isSelect:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            width: 20px;
            height: 21px;
            background-image: url("../../components/ComUpload/imgs/check.png");
        }

        .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye-o,
        .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
        .ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete {
            width: auto;
            margin: 0;
        }

        //.ant-upload-list-item-info .anticon-copy

    }

</style>
