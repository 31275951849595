import {TreeSelect} from 'ant-design-vue';

const PROP_TYPE_MAP = {
  tree: 'tree',
  treeSelect: 'treeSelect',
  select: 'select'
};
const PROPS_MAP = {
  tree: {
    blockNode: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array
    },
    replaceFields: {
      type: Object,
      default: () => ({
        key: 'dmcod',
        title: 'b0101',
        children: 'children',
        parentKey: 'dmparentcod'
      })
    },
    autoExpandParent: {
      type: Boolean,
      default: true
    },
    checkable: {
      type: Boolean,
      default: false
    },
    checkedKeys: {
      type: Array,
      default: () => ([])
    },
    checkStrictly: {
      type: Boolean,
      default: false
    },
    defaultCheckedKeys: {
      type: Array,
      default: () => ([])
    },
    defaultExpandAll: {
      type: Boolean,
      default: false
    },
    defaultExpandedKeys: {
      type: Array,
      default: () => ([])
    },
    defaultExpandParent: {
      type: Boolean,
      default: true
    },
    defaultSelectedKeys: {
      type: Array,
      default: () => ([])
    },
    disabled: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: false
    },
    expandedKeys: {
      type: Array,
      default: ()=> ([])
    },
    filterTreeNode: {
      type: Function
    },
    loadData: {
      type: Function
    },
    loadedKeys: {
      type: Array,
      default: () => ([])
    },
    multiple: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: true
    },
    selectedKeys: {
      type: Array
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    showLine: {
      type: Boolean,
      default: false
    }
  },
  treeSelect: {
    allowClear: {
      type: Boolean,
      default: false
    },
    defaultValue: {
      type: String | Array
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dropdownClassName: {
      type: String
    },
    dropdownMatchSelectWidth: {
      type: Boolean,
      default: true
    },
    dropdownStyle: {
      type: Object,
      default: () => ({})
    },
    filterTreeNode: {
      type: Boolean | Function,
      default: false
    },
    getPopupContainer: {
      type: Function
    },
    labelInValue: {
      type: Boolean,
      default: true
    },
    loadData: {
      type: Function
    },
    maxTagCount: {
      type: Number
    },
    maxTagPlaceholder: {
      type: Function
    },
    multiple: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String
    },
    searchPlaceholder: {
      type: String
    },
    searchValue: {
      type: String
    },
    treeIcon: {
      type: Boolean,
      default: true
    },
    showCheckedStrategy: {
      type: String,
      default: TreeSelect.SHOW_CHILD
    },
    showSearch: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'default'
    },
    suffixIcon: {
      type: Object
    },
    treeCheckable: {
      type: Boolean,
      default: false
    },
    treeCheckStrictly: {
      type: Boolean,
      default: false
    },
    treeData: {
      type: Array,
      default: () => ([])
    },
    replaceFields: {
      type: Object,
      default: () => ({
        key: 'dmcod',
        title: 'b0101',
        value: 'dmcod',
        children: 'children',
        parentKey: 'dmparentcod'
      })
    },
    treeDataSimpleMode: {
      type: Boolean | Array,
      default: false
    },
    treeDefaultExpandAll: {
      type: Boolean,
      default: false
    },
    treeDefaultExpandedKeys: {
      type: Array
    },
    treeExpandedKeys: {
      type: Array
    },
    treeNodeFilterProp: {
      type: String,
      default: 'value'
    },
    treeNodeLabelProp: {
      type: String,
      default: 'title'
    },
    value: {
      type: String | Array,
      default: ''
    },
  },
  select: {}
};
export default function getDefaultProps(type, callback) {
  callback = callback || function (obj) { return obj;};
  const result = PROPS_MAP[PROP_TYPE_MAP[type]];
  callback(result)
  return result;
}
