<!--设置表头tabs选项卡-->
<template>
  <div class="setHeader">
    <!--设置表头tabs-->
    <bdSetHeader key="1" :data="data" @saveData="saveDataFn" class="setHeader1" ref='child' @updateList="setTableClo(tableName)">
      <div class="bdSetHeader-btn" slot="bdSetHeader-btn">
        <a-button @click="addColumnVisi=true, getXxtabList(tableName)" class="btn">
          添加列
        </a-button>
        <a-button @click="saveFn" class="btn">
          保存
        </a-button>
      </div>
    </bdSetHeader>
    <a-modal dialog-class="transfor-model" :title="addColumnTit" :visible="addColumnVisi" @cancel="closeAddColumnFn($event)" @ok="closeAddColumnFn($event)" width="600px">
      <ComTransfer
        type="single"
        checkedName="列名称"
        :data-source="dataSource"
        :defaultTargetKeys="targetKeys"
        @change="transferChange"
      ></ComTransfer>
    <a-button @click="closeAddColumnFn($event)" slot="footer">
      取消
    </a-button>
      <a-button type="primary" @click="closeAddColumnFn($event), addCustom('1')" slot="footer">
        确认
      </a-button>
  </a-modal>
  </div>
</template>

<script>
import bdSetHeader from './setTable'
import {req} from 'common/api/index'
import { ComTransfer } from 'common/components/ComTransfer'
export default {
  name: 'setHeader',
  components: {
    bdSetHeader,
    ComTransfer
  },
  props: {
    tableName: { // 当前的数据集名称
      type: String
    }
  },
  data () {
    return {
      // 添加列弹窗
      addColumnTit: '添加列',
      addColumnTit2: '添加表',
      addColumnVisi: false,
      // 穿梭框
      dataSource: [],
      targetKeys: [],
      data: [],
      tranferSelectedRows: [] // 穿梭框右侧选中数据
    }
  },
  created() {
    this.setTableClo(this.tableName)
  },
  methods: {
    // 触发子组件的保存
    saveFn () {
      this.$refs.child.saveFn()
    },
    // 接收修改的数据并保存
    saveDataFn (data) {
      var ajaxDate = {
        customTableColumnList: data.map(item => ({
          ...item,
          lockColumn: item.lockColumn === true ? 1 : 0
        }))
      }
      req('post_dycustom_updateCustom', ajaxDate, true).then(res => {
        this.$message.success('保存成功！')
      })
    },
    // 关闭设置表头弹窗
    closeAddColumnFn (val) {
      this.addColumnVisi = false
    },
    addCustom (carb) {
      let ajaxDate = {}
      console.log(this.tableName)
      ajaxDate = {
        customTableColumnList: this.tranferSelectedRows.map(item => {
          item.fldtyp = item.fldtyp == null ? 'String' : item.fldcod
          item.ishide = 0
          return item
        })
      }
      req('post_dycustom_updateCustom', ajaxDate, true).then(res => {
        this.setTableClo(this.tableName)
      })
    },
    // 穿梭框编辑
    handleChange (targetKeys, direction, moveKeys) {
      this.targetKeys = targetKeys
    },
    // post_dycustom_gettableHead: '/sym/customtablecolumn/findByTable', // 设置表头列查询
    // post_col_getList: '/sym/infoitem/getList', // 设置表头列查询
    // post_getTableList: '/sym/customFuction/findUserSet', // 左侧树-引入信息集-列表查询
    // post_updateCustom: {
    //   url: '/sym/customtablecolumn/updateCustom', // 列表-保存表头设置
    //   headers: {
    //     'Content-Type': 'application/json;charset=UTF-8'
    //   }
    // },
    setTableClo (text) {
      const self = this
      req('post_dycustom_gettableHead', { tablename: text }, true).then(res => {
        const tempData1 = res.data.filter(item => item.ishide === 0)
        const tempData = tempData1.map((item, index) => {
          const obj = {
            ...item,
            num: index + 1,
            lockColumn: item.lockColumn === 1
          }
          return obj
        })
        self.data = tempData
      })
    },
    getXxtabList (tableName) {
      this.targetKeys = []
      req('post_dycustom_gettableHead',  { tablename: tableName } , true).then(res => {
        this.setReq(res.data).then(res => {
          this.dataSource = res
        })
      })
    },
    setReq (list) {
      return new Promise((resolve, reject) => {
        const treeData1 = list.filter(item => item.ishide === 1)
        const treeData = treeData1.map(item => {
          const obj = {
            ...item,
            title: item.fldcodTitle,
            key: item.recordId
          }
          return obj
        })
        resolve(treeData)
      })
    },
    transferChange (e) {
      // 返回穿梭后的数据
      console.log(e)
      this.tranferSelectedRows = e
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/  .ant-modal.transfor-model .ant-modal-body{
    height: 450px;
  }
  /*第三个表格过长需滚动x*/
  /deep/ .ant-table-content{
    width: 100% !important;
    height: 100% !important;
    position: relative !important;
    overflow: scroll !important;
  }
  /deep/ .ant-tabs-tabpane:nth-child(4) .ant-table-body{
    overflow: scroll !important;
  }
  /deep/ .ant-tabs-tabpane:nth-child(4) .ant-spin-container{
    height: 400px !important;
    overflow: auto;
    &::-webkit-scrollbar {//整体样式
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {//滑动滑块条样式
      border-radius: 4px;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      // background: #00aaff;
      background: #d6e9fa;
    }
  }

</style>
