import Vue from 'vue'
import Router from 'vue-router'
// import NProgress from 'nprogress'
import store from '@/store'
import examples from './examples'
// import { setDefaultMountApp } from 'qiankun'
import mainLayout from 'common/components/layout/mainLayout'
import MicroFrontLayout from '@/layout/MicroFrontLayout'
import newLayout from 'common/layouts/MainLayout'
const menuData = store.state.menuData
Vue.use(Router)

let routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    redirect: process.env.VUE_APP_LOGIN_TYPE === 'sso' ? '/loginSso' : '/loginPwd'
  },
  // sso登录
  {
    path: '/loginSso',
    name: '登录',
    meta: {
      module: '',
      withoutLayout: true,
      title: '用户登录'
    },
    component: () => import('@/views/login/loginSso')
  },
  {
    path: '/loginPwd',
    name: '登录',
    meta: {
      module: '',
      withoutLayout: true,
      title: '用户登录'
    },
    component: () => import('@/views/login')
  },
  {
    path: '/demo',
    name: '断点续传',
    meta: {
      module: '',
      withoutLayout: true,
      title: '断点续传'
    },
    component: () => import('@/views/demo')
  },
  {
    path: '/GM_login',
    name: '光明登录',
    meta: {
      module: '',
      withoutLayout: true,
      title: ''
    },
    component: () => import('@/views/login/login_GM.vue')
  },
  {
    path: '/demos/chunkupload',
    name: '文件上传',
    meta: {
      module: '',
      withoutLayout: true,
      title: '文件上传'
    },
    component: () => import('@/views/demos/chunkUploads.vue')
  },
  { // avic项目使用
    path: '/zhlogin',
    name: '登录',
    meta: {
      module: '',
      withoutLayout: true,
      title: '用户登录'
    },
    component: () => import('@/views/login_new')
  },
  // { // fwhtml 临时展示路由，应该在接口配置
  //   path: '/wfhtml',
  //   name: 'wfhtml',
  //   meta: {
  //     module: '',
  //     withoutLayout: true,
  //     title: '工作流'
  //   },
  //   component: newLayout,
  //   children: [
  //     {
  //       path: '/',
  //       component: () => import('@/views/portalHome')
  //     }
  //   ]
  // },
  { // fwhtml 临时展示路由，应该在接口配置
    path: '/home',
    name: 'home',
    meta: {
      module: '',
      withoutLayout: true,
      title: '首页'
    },
    component: newLayout,
    children: [
      {
        path: '/',
        component: () => import('@/views/portalHome')
      }
    ]
  },
  {
    path: '/portal',
    name: 'portal',
    component: newLayout,
    redirect: '/portal/home',
    meta: {
      module: '',
      withoutLayout: true,
      title: '平台'
    },
    children: [
      {
        path: 'home',
        name: 'portal_home',
        meta: {
          module: '',
          withoutLayout: true,
          title: '管理工作台'
        },
        component: () => import('@/views/portalHome')
      }
    ]
  },
  examples,
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/404')
  }
]

// 根据菜单栏目生成路由信息
const generatorRouter = (data, isOne) => {
  data.forEach(route => {
    if (route.path) {
      if (isOne) {
        route.component = newLayout
      } else {
        route.component = MicroFrontLayout
      }
    }
    if (route.children && route.children.length) {
      generatorRouter(route.children)
    }
  })
}
var newMenuData = JSON.parse(JSON.stringify(menuData))
generatorRouter(newMenuData, true)
// console.log(newMenuData)
routes = routes.concat(newMenuData)
// routes.push({
//   path: '*',
//   redirect: '/404'
// })
// const childRoute = ['/react15', '/react16']
// router.beforeEach((to, from, next) => {
//   NProgress.start()
//   if (childRoute.includes(to.path)) {
//     next()
//     setDefaultMountApp(to.path)
//   } else {
//     next()
//   }
//   NProgress.done()
// })
export const asyncRouter = []
export const constantRoutes = []
export default new Router({
  base: '',
  mode: 'history',
  routes
})

