<template>
    <div class="over-table-conditon">
        <div class="over-table-conditonlist" v-for="(item, index) in showTableList" :key="index">
            <!--输入框-->
            <a-input-search v-if="filterType(item)===6" :placeholder="`请输入${item.title}`" v-model="item.value"
                            style="width: 200px" class="curren-page-common"
                            @search="(e) => handleSearch(e,index)" @change="e => handleChangeSer(e,index)"/>
            <!--日期选择器-->
            <a-range-picker v-if="filterType(item)===7" style="width: 220px" :value="item.value"
                            format="YYYY-MM-DD" value-format="YYYY-MM-DD"
                            @change="(e) => handleSelDate(e, item, index)" class="curren-page-common"
            />
            <!--日期时间选择器-->
            <!--            <a-date-picker v-if="filterType(item)===8"-->
            <!--                           :value="item.value" value-format="YYYY-MM-DD HH:mm:ss"-->
            <!--                           :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"-->
            <!--                           style="width: 350px" class="curren-page-common" @change="onSearch"-->
            <!--            />-->
            <a-range-picker v-if="filterType(item)===8" style="width: 350px" :value="item.value"
                            format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
                            :show-time="{
                                hideDisabledOptions: true,
                                defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
                              }"
                            @change="(e) => handleSelDate(e, item, index)" class="curren-page-common"
            />
            <a-select v-if="filterType(item)===9"
                      @change="(e) => handleSelChange(e, item, index)" class="curren-page-common"
                      @popupScroll="handlePopupScroll($event, item, item.dataZ,index)"
                      :placeholder="`请选择${item.title}`" :allow-clear="true" v-model="item.value"
                      style="width: 200px" show-search option-filter-prop="children" :filter-option="filterOption">
                <a-select-option v-for="(itemC,indexC) in item.frontDataZ" :value="itemC.id" :key="indexC">
                    {{itemC.dmcpt}}
                </a-select-option>
            </a-select>
            <!--多选-->
            <a-select v-if="filterType(item)===10" mode="multiple"
                      :placeholder="`请选择${item.title}`" class="curren-page-common"
                      @popupScroll="handlePopupScroll($event, item, item.dataZ,index)"
                      :default-value="item.defaultValue"
                      v-model="item.value" style="width: 200px" show-search option-filter-prop="children"
                      :filter-option="filterOption">
                <a-select-option v-for="(itemC,indexC) in item.frontDataZ" :value="itemC.id" :key="indexC">
                    {{itemC.dmcpt}}
                </a-select-option>
            </a-select>
            <!--输入框-->

        </div>
    </div>
</template>

<script>
    import {req} from 'common/api/index'
    import moment from 'moment'
    // 代码表
    import codetable from 'common/components/ComCodeTable/CodTabl/index.js'

    export default {
        name: 'Funnel',
        // 设置代码表
        mixins: [codetable],
        props: {
            tableName: {// 必传根据tableName查询表格表头
                type: String,
                default: ''
            },
            reqUrl: {
                type: String,
                default: 'post_sys_getSearchcondition'
            },
            defaultParams: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                moment,
                showTableList: []
            }
        },
        created() {
            if (this.tableName) {
                this.getOverTableCondition()
            }
        },
        mounted() {
            // this.$refs.searchList.initData()
        },
        methods: {
            handleChange(e, item, index) {
                this.showTableList[index].value = e.target.value
                this.onSearch()
            },
            //下拉选择回调
            handleSelChange(e, item, index) {
                this.showTableList[index].value = e
                this.onSearch()
            },
            // 选择时间方法回调
            handleSelDate(e, item, index) {
                this.showTableList[index].value = e
                this.onSearch()
            },
            handleChangeSer (e, index) {
                this.showTableList[index].value = e.target.value
                this.emitEffectVaule()
            },
            emitEffectVaule () {
                const effectiveValueArr = this.showTableList.filter(item => item.value)
                const params = {}
                effectiveValueArr.forEach(item => {
                    if (item.value instanceof Array && item.value.length > 0) {
                        params[item.name] = item.value.join(',')
                    } else if (item.value instanceof Array && item.value.length === 0) {
                    } else {
                        params[item.name] = item.value
                    }
                })
                this.$emit('getOverPms', params)
                return params
            },
            handleSearch (e, index) {
                this.showTableList[index].value = e
                this.onSearch()
            },
            onSearch() {
                const params = this.emitEffectVaule()
                this.$emit('searchFun', params)
            },
            filterType(item) {
                if (item.queryType === 'F') {
                    return 7
                }
                if (item.queryType === 'T') {
                    return 8
                }
                if (item.dmgrp && item.ismultivalued === 0) {
                    return 9
                }
                if (item.dmgrp && item.ismultivalued === 1) {
                    return 10
                }
                return 6
            },
            // 获取表格上方查询条件
            getOverTableCondition() {
                const that = this
                req('post_sys_getTableQuery', {tableName: this.tableName}).then(res => {
                    if (res.code === 200) {
                        const keys = Object.keys(this.defaultParams)
                        this.showTableList = res.data.map(item => {
                            const obj = keys.find(ele => ele === item.fldcod)
                            if (obj) {
                                item.defaultValue = this.defaultParams[obj]
                            }
                            return {
                                title: item.queryName,
                                name: item.fldcod,
                                value: (this.filterType(item) === 7 || this.filterType(item) === 8) ? (item.defaultValue === null ? undefined : item.defaultValue.split(',')) : (item.defaultValue === null ? undefined : item.defaultValue),
                                defaultValue: item.defaultValue,
                                editenable: item.editenable,
                                dmgrp: item.dmgrp,
                                frontDataZ: [],
                                dataZ: [],
                                ...item
                            }
                        })
                        const dmtlcodsArr = that.showTableList.filter(item => item.dmgrp).map(res => {
                            return res.dmgrp
                        })
                        if (dmtlcodsArr.length > 0) {
                            const dmgrpStr = dmtlcodsArr.join(',')
                            console.log(dmgrpStr)
                            that.getCodeDataList(dmgrpStr).then(res => {
                                let data = res.data
                                that.showTableList.forEach(e => {
                                    let dmgrpList = data[e.dmgrp]
                                    if (e.dmgrp && dmgrpList) {
                                        e.dataZ = dmgrpList
                                        e.frontDataZ = dmgrpList.slice(0, 100)
                                    }
                                })
                                console.log(that.showTableList)
                            })
                        }
                        this.emitEffectVaule()
                    }
                })
            },

            handlePopupScroll(e, item, dataSource, ind) {
                const {target} = e
                const scrollHeight = (target.scrollHeight - target.scrollTop).toFixed() - 0
                const clientHeight = target.clientHeight
                // 下拉框不下拉的时候
                if (scrollHeight === 250 && clientHeight === 250) {
                    this.showTableList[ind].scrollPage = 1
                    console.log(this.showTableList[ind].scrollPage)
                } else {
                    // if (this.showTableList[ind].frontDataZ.length === 0) {
                    //     this.showTableList[ind].frontDataZ = dataSource.slice(0, 100)
                    // } else {
                    // }

                    // 当下拉框滚动条到达底部的时候
                    if (scrollHeight < clientHeight + 5) {
                        this.showTableList[ind].scrollPage = this.showTableList[ind].scrollPage + 1
                        const scrollPage = this.showTableList[ind].scrollPage// 获取当前页
                        const treePageSize = this.showTableList[ind].treePageSize * (scrollPage || 1)// 新增数据量
                        const newData = [] // 存储新增数据
                        let max = '' // max 为能展示的数据的最大条数
                        if (this.showTableList[ind].dataZ.length > treePageSize) {
                            // 如果总数据的条数大于需要展示的数据
                            max = treePageSize
                        } else {
                            // 否则
                            max = this.showTableList[ind].dataZ.length
                        }
                        // 判断是否有搜索
                        if (this.showTableList[ind].SearchvalueData) {
                            this.showTableList[ind].allDataZ.forEach((items, index) => {
                                if (index < max) { // 当data数组的下标小于max时
                                    newData.push(items)
                                }
                            })
                        } else {
                            this.showTableList[ind].dataZ.forEach((iteme, index) => {
                                if (index < max) { // 当data数组的下标小于max时
                                    newData.push(iteme)
                                }
                            })
                        }

                        this.showTableList[ind].frontDataZ = newData // 将新增的数据赋值到要显示的数组中
                    }
                }
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
            }
        }
    }
</script>
<style lang="less" scoped>
    .over-table-conditon,
    .over-table-conditonlist {
        display: inline-flex;
    }

    .radio-style-tcdion {
        line-height: 30px;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding-left: 5px;
        padding-right: 5px;
    }

    .radio-style-tcdion:hover {
        border-color: #40a9ff
    }

    .curren-page-common {
        margin-right: 10px;
    }

    .curren-page-common /deep/ .ant-select-selection--multiple {
        height: 32px;
        overflow-y: auto;
    }
</style>
