<template>
  <div style="display: flex; justify-content: flex-end; align-items: center;margin-left:10px;">
    <a-dropdown trigger="click">
      <span class="header-avatar">
        {{ users.realName }}，您好<a-icon style="margin-left: 5px;" type="down"/>
      </span>
      <a-menu slot="overlay" @click="clickMenu">
        <a-menu-item key="1">
          <a-icon type="user" class="icon"/>
          <span style="margin-left: 10px;">修改密码</span>
        </a-menu-item>
        <a-menu-item key="2">
          <a-icon type="poweroff" class="icon"/>
          <span style="margin-left: 10px;">退出登录</span>
        </a-menu-item>
      </a-menu>
    </a-dropdown>
    <!-- <div class="header-avatar" @mouseleave="() => { avatarVisi = true; floatCardVisi = false}"> -->
    <!-- <a-avatar :class="floatCardVisi === true ? 'avatarAnimation' : 'avatarLeaveAnimation'" class="avatar" shape="circle" :src="user.avatar" @mouseenter="() => { floatCardVisi = true }"/> -->
    <!-- <div @mouseenter="() => { floatCardVisi = true }"></div>
    <div class="floatCard" :class="floatCardVisi === true ? 'floatCardAnimation' : 'floatCardLeaveAnimation'">
          <div class="floatCard-cont">
            <div style="width: 100%; padding-top: 25px;">
                <div class="floatCard-item" @click="changePassword" >
                    <a-icon type="user" class="icon"/>
                    <span>修改密码</span>
                    <div style="width: 14px; height: 1px;"></div>
                </div>
                <div class="floatCard-item floatCard-item-footer" @click="logout">
                    <a-icon type="poweroff" class="icon"/>
                    <span>退出登录</span>
                    <div style="width: 14px; height: 1px;"></div>
                </div>
            </div>
          </div>
  </div> -->
    <!-- </div> -->
    <a-modal :confirmLoading="ploading" v-model="visibleSs" title="修改密码" @ok="handleOk">
      <a-input-password placeholder="请输入原密码" v-model="oldPassword"/>
      <a-input-password style="margin-top:20px;" placeholder="请输入新密码" v-model="newPassword"/>
      <a-input-password style="margin-top:20px;" placeholder="请再次确认密码" v-model="twoPassword"/>
    </a-modal>
    <!-- 权限托管弹窗-->
    <a-modal :key="timer" :maskClosable="false" v-model="visible" title="权限托管" on-ok="handleOk" :footer="null"
             :width="600">
      <div>
        <component :is="showAllot" @cancleModal="cancleModal"></component>
      </div>
    </a-modal>
  </div>
</template>

<script>
// import {mapGetters} from 'vuex'
import authTruModal from './authTrusteeship/authTruModal'

export default {
  components: {
    authTruModal
  },
  name: 'HeaderAvatarNew',
  data() {
    return {
      ploading: false,
      visibleSs: false,
      oldPassword: '',
      newPassword: '',
      twoPassword: '',
      avatarVisi: true,
      floatCardVisi: false,
      user: {
        name: '北小软',
        avatar: require('./img/user-default.png')
      },
      users: {},
      timer: 0,
      visible: false,
      showAllot: 'authTruModal'
    }
  },
  computed: {
    // ...mapGetters('account', ['user']),
  },
  created() {
    this.users = JSON.parse(sessionStorage.getItem("access_userInfo")) //设置键的值
  },
  methods: {
    changePassword() {
      this.visibleSs = true;
      this.oldPassword = ''
      this.newPassword = ''
    },
    handleOk(e) {
      this.ploading = true
      // console.log(this.oldPassword)
      // console.log(this.newPassword)
      this.$http('post_updatePassword', (encrypted) => {
        // console.log(encrypted(this.oldPassword))
        // console.log(encrypted(this.newPassword))
        return {
          oldPassword: encrypted(this.oldPassword),
          newPassword: encrypted(this.newPassword),
        }
      }).then(res => {
        if (res.code === 200) {
          this.$message.success("修改成功")
          this.visibleSs = false;
        }
        this.ploading = false
      }).catch(err => {
        console.error(err)
        this.ploading = false
      })

    },
    async logout() {
      // this.$router.push('/login')
      localStorage.clear();
      sessionStorage.clear();
      await this.$store.dispatch('user/userLogout')
      await this.$router.push('/login')
    },
    showDrawer() {
      this.$store.state.drawerVisible = true
      this.$store.state.noticeVisible = false
    },
    authTrusteeShip() {
      this.timer = new Date().getTime()
      this.visible = true
    },
    cancleModal() {
      this.visible = false
    },
    clickMenu({key}) {
      // 点击菜单级别
      if (key === '1') {
        this.changePassword()
      } else if (key === '2') {
        this.logout()
      }
    }
  }
}
</script>

<style lang="less" scoped>
.floatCard-item {
  height: 25px;
  line-height: 25px;
  margin: 10px 0;
  cursor: pointer;

  .icon {
    margin-right: 8px;
  }

  &:hover {
    color: #1E89FF;

    .icon {
      color: #1E89FF;
    }
  }
}

.floatCard-item-footer {
  padding-top: 10px;
  border-top: 1px solid rgba(24, 31, 36, .24);
}

// 头像特效
.header-avatar {
  cursor: pointer;
  user-select: none;
  width: auto;
  height: 100%;
  position: relative;
  line-height: 54px;
  text-align: center;
  color: #7C97B5;

  .avatar {
    cursor: pointer;
    width: 30px;
    height: 30px;
    z-index: 999;
  }
}

/deep/ .ant-dropdown-menu-item {
  text-align: center;
}

.avatarAnimation {
  animation: avatarAnimation .2s forwards;
  -webkit-animation: avatarAnimation .2s forwards; /* Safari 和 Chrome */
}

.avatarLeaveAnimation {
  animation: avatarLeaveAnimation .3s forwards;
  -webkit-animation: avatarLeaveAnimation .3s forwards; /* Safari 和 Chrome */
}

.floatCardAnimation {
  display: block !important;
  animation: floatCardAnimation .5s forwards;
  -webkit-animation: floatCardAnimation .5s forwards; /* Safari 和 Chrome */
}

.floatCardLeaveAnimation {
  /*display: none!important;*/
  animation: floatCardLeaveAnimation .3s forwards;
  -webkit-animation: floatCardLeaveAnimation .3s forwards; /* Safari 和 Chrome */
}

@keyframes floatCardAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes floatCardLeaveAnimation {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    height: 0;
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes avatarAnimation {
  0% {
    width: 30px;
    height: 30px;
  }
  100% {
    width: 50px;
    height: 50px;
    transform: translate(0, 27px); /**下移元素**/
    -ms-transform: translate(0, 27px);
    -webkit-transform: translate(0, 27px);
  }
}

@keyframes avatarLeaveAnimation {
  0% {
    width: 50px;
    height: 50px;
    transform: translate(0, 27px); /**上移元素**/
    -ms-transform: translate(0, 27px);
    -webkit-transform: translate(0, 27px);
  }
  100% {
    width: 30px;
    height: 30px;
  }
  /*之前的*/
  /*100%{*/
  /*    width: 50px;*/
  /*    height: 50px;*/
  /*    transform:translate(-10px, 27px);!**下移元素**!*/
  /*    -ms-transform:translate(-10px, 27px);*/
  /*    -webkit-transform:translate(-10px, 27px);*/
  /*}*/
}

.floatCard {
  /*display: none;*/
  width: 150px;
  position: absolute;
  top: 30px;
  left: calc(~"(-50% - 20px)");

  .floatCard-avatar {
    height: 50px;
    width: 50px;
    float: left;
    margin-left: calc(~"(50% - 25px)");
  }

  .floatCard-cont {
    background-color: #fff;
    margin-top: 25px;
    box-shadow: 0 2px 5px rgba(24, 31, 36, .34);
    padding: 0 0 15px 0;
    z-index: 999;
  }
}

/*之前的*/
/*.floatCard{*/
/*    display: none;*/
/*    width: 150px;*/
/*    position: absolute;*/
/*    top: 30px;*/
/*    left:  calc(~"(-50px - 8px + 1px)");*/
/*    .floatCard-avatar{*/
/*        height: 50px;*/
/*        width: 50px;*/
/*        float: left;*/
/*        margin-left: calc(~"(50% - 25px)");*/
/*    }*/
/*    .floatCard-cont{*/
/*        background-color: #fff;*/
/*        margin-top: 25px;*/
/*        box-shadow: 0 2px 5px rgba(24, 31, 36, .34);*/
/*        padding: 0 0 15px 0;*/
/*    }*/
/*}*/
</style>
