import { reqMethods } from 'common/utils/request'
import { pathMap } from './reqPathConfig'
const reqExample = reqMethods({
  baseUrl: process.env.VUE_APP_BASE_API, // 设置请求基础url路径
  pathMap
})
export const req = reqExample.req
export default {
  install: (Vue) => {
    Vue.use(reqExample)
  }
}
