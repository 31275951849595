<template>
  <div class="manageHeader">
    <div class="manageHeader-nav">
      <header-nav :showDetails="showDetails" />
    </div>
    <div class="manageHeader-message">
      <a-badge :count="badgeNumber" class="manageHeader-message-badge">
        <a-icon theme="filled" type="bell" class="manageHeader-message-icon" />
      </a-badge>
      <span class="manageHeader-message-text">消息</span>
    </div>
    <template>
      <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
      <!--<p style="margin: 0">{{ userName }}</p>
      <a-button type="link" style="color: #2c3e50" @click="userLogout">
        <a-icon type="logout" />
      </a-button>-->
      <a-dropdown :trigger="['click']" class="manageHeader-dropdown">
        <a class="ant-dropdown-link" @click="e => e.preventDefault()">
          <a-icon type="caret-down" />
        </a>
        <a-menu slot="overlay">
          <a-menu-item key="0" @click="authTrusteeShip">
            <a-icon type="user"/>
            <span>权限托管</span>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="1">
            <a-icon type="deployment-unit" />
            <span>个人设置</span>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="2">
            <a-icon type="lock" />
            <span>修改密码</span>
          </a-menu-item>
          <a-menu-divider />
          <a-menu-item key="3" @click="userLogout">
            <a-icon type="logout" />
            <span>退出登录</span>
          </a-menu-item>
        </a-menu>
      </a-dropdown>
    </template>
    <a-modal :key="timer"  :maskClosable="false" v-model="visible" title="权限托管" on-ok="handleOk" :footer="null" :width="600">
      <div>
        <component :is="showAllot" @cancleModal="cancleModal"></component>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import headerNav from 'common/components/headerNav/headerNav'
import themeColor from 'common/utils/config/themeColor'
import authTruModal from './authTrusteeship/authTruModal'
export default {
  name: 'ManageHeader',
  components: {
    headerNav,
    authTruModal
  },
  data() {
    return {
      badgeNumber: 15,
      timer: 0,
      showDetails: 'management',
      visible: false,
      showAllot: 'authTruModal'
    }
  },
  computed: {
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    /**
     * 登出
     * */
    async userLogout() {
      themeColor.changeColor('#1890FF').finally(t => {})
      await this.$store.dispatch('user/userLogout')
      await this.$router.push('/login')
    },
    authTrusteeShip () {
      this.timer = new Date().getTime()
      this.visible = true
    },
    cancleModal () {
      this.visible = false
    }
  }
}
</script>

<style lang="less" scoped>
  .manageHeader{
    position: relative;
    width: auto;
    height: 100%;
    min-width: 200px;
    margin-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    .manageHeader-nav{
      margin-right: 35px;
      width: 220px;
      height: 100%;
      display: flex;
    }
    .manageHeader-message{
      margin-right: 35px;
      /deep/ .manageHeader-message-badge{
        cursor: pointer;
        .ant-badge-count{
          min-width: 16px;
          height: 16px;
          font-size: 10px;
          line-height: 16px;
        }
      }
      .manageHeader-message-icon{
        font-size: 22px;
      }
      .manageHeader-message-text{
        font-size: @largeFontSize;
        cursor: pointer;
      }
    }
    .manageHeader-dropdown{
      color: #2A343C;
      //min-width: 70px;
      font-size: @normalFontSize;
    }
  }
</style>
