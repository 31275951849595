import {getUrlWithTokenParam} from '../token/tokenUtil'
import {message} from 'ant-design-vue'
import qs from 'common/lib/qs'
import axios from 'axios'

function parseUrlParam(param) {
    let paramStr = ''
    if (!param) {
        return paramStr
    }
    if (param instanceof String) {
        return param
    } else if (param instanceof Object) {
        paramStr = qs.stringify(param, {indices: false})
    }
    return paramStr
}

/**
 *
 * @param url 下载url
 * @param param 请求参数
 */
export function downFile(url, param) {
    let requestUrl = getUrlWithTokenParam(url)
    if (param) {
        let paramStr = parseUrlParam(param)
        if (paramStr) {
            requestUrl = requestUrl + '&' + paramStr
        }
    }
    const baseUrl = process.env.VUE_APP_BASE_API
    // window.open(baseUrl + requestUrl)
    location.href = baseUrl + requestUrl
}

/**
 *
 * @param url 下载url
 * @param fileName 下载显示文件名称
 * @param type 响应类型（非必填）
 * @param method 默认get
 * @param param
 */
export function downLoadWithAxios(url, fileName, type, method = 'get', param = {},queryParam = {}) {
    if (!type) {
        type = 'application/octet-stream'
    }
    if (!url) {
        const msg = '接口请求url不能为空'
        message.error(msg, 5)
        return
    }
    if (!method) {
        method = 'get'
    } else {
        method = method.toLowerCase()
    }

    url = getUrlWithTokenParam(url)
    const baseUrl = process.env.VUE_APP_BASE_API
    if (!url.startsWith(baseUrl)) {
        url = baseUrl + '/' + url
    }

    function requestFail(msg = "请求失败") {
        message.error(msg, 5)
    }

    function handleBlobData(res) {
        if (!res) {
            const msg = '接口返回数据空'
            message.error(msg, 5)
            return
        }
        console.log(res)
        const blob = new Blob([res.data], {
            type
        })
        // res就是接口返回的文件流了
        const objectUrl = URL.createObjectURL(blob)
        const aLink = document.createElement('a')
        aLink.download = fileName // 下载文件名称,
        aLink.style.display = 'none'
        aLink.href = objectUrl
        document.body.appendChild(aLink)
        aLink.click()
        URL.revokeObjectURL(aLink.href) // 释放URL 对象
        document.body.removeChild(aLink)
    }

    // 请求数据
    if (method === 'get') {
        axios.get(url, param)
            .then(function (response) {
                handleBlobData(response);
            })
            .catch(function (error) {
                requestFail(error);
            });
    } else {
        axios({
            url,
            method:'post',
            responseType:'blob',
            data:param,
            params:queryParam
        }).then(function (response) {
            handleBlobData(response);
        })
        .catch(function (error) {
            requestFail(error);
        });
    }
}
