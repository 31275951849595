<template>
  <div class="com-trans-wrap">
    <div class="com-trans-left">
      <div class="trans-header">
        <a-select
                show-search
                placeholder="请输入搜索内容"
                option-filter-prop="children"
                style="width: 228px"
                :value="selectVal"
        >
          <a-icon slot="suffixIcon" type="search" class="select-icon" />
          <a-select-option
                  v-for="item in selectData"
                  :key="item[replaceFields['key']]"
                  :value="item[replaceFields['key']]"
                  @click="selectClick(item, $event)"
          >{{item[replaceFields.title]}}</a-select-option>
        </a-select>
      </div>
      <div class="trans-body">
        <!-- 2021-01-08 添加 leftParentClick (黎镖) -->
        <checkboxList
                :dataSource="separateDataSource().leftDataSource"
                :checkedName="checkedName"
                :replaceFields="replaceFields"
                :defaultChecked="targetKeys"
                @checked="leftSelect"
        ></checkboxList>
      </div>
    </div>
    <div class="com-trans-right">
      <div class="trans-header">
        <div class="selected-num">已选{{selectedNum}}项</div>
        <a-button class="selected-alldel" @click="delAll">删除全部</a-button>
      </div>
      <div class="trans-body">
        <simpleList
                :type="type"
                :checkedName="checkedName"
                @delItem="rightDelItem"
                @sortItem="rightSortItem"
                :replaceFields="replaceFields"
                :isSort="isSort"
                :dataSource="separateDataSource().rightDataSource"
        ></simpleList>
      </div>
    </div>
  </div>
</template>
<script>
  import simpleList from './simpleList.vue'
  import checkboxList from './checkboxList'
  export default {
    props: {
      type: {
        default: 'single'
      },
      dataSource: {
        default: () => ([])
      },
      dataTarget: {
        default: () => ([])
      },
      defaultTargetKeys: {
        default: () => ([])
      },
      replaceFields: {
        default: () => ({
          title:'title',
          key: 'key'
        })
      },
      checkedName: {
        default: '岗位名称'
      },
      isSort: {
        default: true
      }
    },
    components: {
      simpleList,
      checkboxList
    },
    data () {
      return {
        leftData: [],
        rightData: [],
        selectVal: undefined,
        selectData: [],
        selectedNum: 0,
        leftDataSource: [],
        rightDataSource: [],
        sourceSelectedKeys: [],
        targetSelectedKeys: [],
        rightSelectedKeys: [],
        targetKeys: [],
        selectedRowKeys: [],
        tableRightLoading: true
      }
    },
    created () {
      this.setDefaultTargetKeys(this.defaultTargetKeys)

      this.setDataSource(this.dataSource)
    },
    methods: {
      setDataSource(n) {
        this.leftData = n.concat()
        this.handleSelectData(n)
      },
      setDataTarget(data) {
        this.rightData = data.concat()
        data.forEach(item => {
          this.selectedRowKeys.push(item.key)
        })
        this.selectedNum = this.rightData.length
        if(data && data.length) {
          this.tableRightLoading = false
        } else {
          setTimeout(() => {
            this.tableRightLoading = false
          }, 1000)
        }
      },
      setDefaultTargetKeys(n) {
        this.calcTargetCheckboxListKeys(n, this.leftData)
      },
      separateDataSource() {
        const { type } = this.$props
        const { leftData, targetKeys = [] } = this;
        const leftDataSource = leftData;
        const newTargetKeys = targetKeys
        let rightDataSource = []
        const getData = (data) => {
          data.forEach(record => {
            if(newTargetKeys.includes(record[this.replaceFields['key']])) {
              rightDataSource.push(record)
            }
            if(record.children && record.children.length) {
              getData(record.children)
            }
          });
        }
        getData(leftData)
        return {
          leftDataSource,
          rightDataSource
        };
      },
      leftSelect (e, p) {
        this.targetKeys = e
        this.emitChange(this.targetKeys, this.type)
      },
      selectClick (data, e) {
        if(!(data)) {
          return
        }
        if(!this.targetKeys.includes(data[this.replaceFields['key']])) {
          this.targetKeys.push(data[this.replaceFields['key']])
          if(this.type !== 'codeOpt') {
              this.emitChange(this.targetKeys, this.type)
          }
        }
      },
      // 搜索栏下拉条件
      handleSelectData(treeData) {
        let newData = []
        treeData.forEach(item => {
          newData.push(item)
        })
        this.selectData = newData
      },
      // 删除全部
      delAll() {
        this.targetKeys = []
        this.emitChange([], this.type)
      },
      rightDelItem(data) {
        const keyIndex = this.targetKeys.indexOf(data[this.replaceFields['key']])
        this.targetKeys.splice(keyIndex, 1)
        this.emitChange(data,this.type)
      },
      rightSortItem() {
        this.$emit('change', this.rightData)
      },
      getTargetData(leftData, targetKey) {
        let newTargetAll = []
        const handleData = (data) => {
          data.forEach(item => {
            if(targetKey.includes(item[this.replaceFields['key']])) {
              newTargetAll.push(item)
            }
            if(item.children && item.children.length) {
              handleData(item.children)
            }
          })
        }
        handleData(leftData)
        return newTargetAll
      },
      emitChange (data, type) {
        let dataPool = this.leftData
        const targetData = this.getTargetData(dataPool, this.targetKeys)
        this.selectedNum = this.targetKeys.length
        this.$emit('change', targetData)
      },
      calcTargetCheckboxListKeys(n, leftData) {
        const normalData = []
        this.selectedNum = n.length
        leftData.forEach(item => {
          if(n.includes(item[this.replaceFields['key']])) {
            normalData.push(item[this.replaceFields['key']])
          }
        })
        this.targetKeys = normalData
      }
    },
    watch: {
      dataSource(n) {
        this.setDataSource(n)
      },
      dataTarget(n) {
        this.setDataTarget(n)
      },
      defaultTargetKeys(n) {
        this.setDefaultTargetKeys(n)
      }
    }
  }
</script>
