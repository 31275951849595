<template>
  <a-layout-content >
    <div style="position: relative" :style="`height: 100%;`">
      <router-view/>
    </div>
  </a-layout-content>
</template>

<script>
export default {
  name: "MainContent",
  data () {
    return {
    }
  }
}
</script>

<style scoped>

</style>
