<template>
  <div>
    <a-button
      size='small'
      @click='reset'
    >
      重置
    </a-button>
    <a-radio-group
      v-model='inValue'
      :options='_optionsMap'
      @change='change'
    />
  </div>
</template>

<script>
import { deepClone } from '../'
export default {
  name: 'ComCustomQueryRadio',
  props: {
    defaultValue: {
      type: null,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({
        value: '',
        label: ''
      })
    }
  },
  data () {
    return {
      inValue: []
    }
  },
  watch: {
    value: {
      handler  (n) {
        this.inValue = n.value
      },
      immediate: true
    }
  },
  computed: {
    _optionsMap () {
      return deepClone(this.options)
    }
  },
  methods: {
    reset () {
      this.inValue = ''
      this.change({ target: { value: '' } })
    },
    change (e) {
      this.$emit('change', { value: e.target.value, label: e.target.value })
    }
  }
}
</script>