import Vue from 'vue'
import Vuex from 'vuex'
import { req } from '@/api'
// 引入 vuex 的 getters
import getters from '@/store/getters'
import config from '@/config'
import { getMenuData } from 'common'
// 引入 vuex 的插件实现页面刷新时存储部分状态值
import { sessionSaveUser, localSavaColor } from '@/store/plugins/saveData'
import { mutations } from '@/store/modules/user'

Vue.use(Vuex)
/**
 * 自动将 modules 文件下的 js 文件引入
 * */
const modulesFiles = require.context('./modules', true, /\.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  // set './app.js' => 'app'
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  modules[moduleName] = value.default
  return modules
}, {})

export default new Vuex.Store({
  state: {
    ...config,
    noticeVisible: false,
    activatedFirst: undefined,
    menuData: getMenuData('portal')
  },
  mutations,
  modules,
  getters,
  plugins: [sessionSaveUser, localSavaColor]
})
