export default {
  path: '/sym',
  name: 'sysMgmt',
  redirect: '/sym/authorityMgmt/userMgmt',
  meta: {
    module: 'sysMgmt',
    title: '系统管理',
    roles: ['admin', 'group', 'user'],
    icon: 'xitongguanli'
  },
  children: [
    {
      path: '/sym/xitongCSH',
      name: 'sym_xitongCSH',
      hidden: true, // 隐藏当前菜单
      meta: {
        module: '系统管理',
        title: '系统初始化',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
    },
    {
      path: '/sym/zuzhisiyouYGL',
      name: 'sym_zuzhisiyouYGL',
      redirect: '/zuzhisiyouYGL/zuzhijigouSYY',
      hidden: true, // 隐藏当前菜单
      meta: {
        module: '系统管理',
        title: '组织私有云管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/sym/zuzhisiyouYGL/zuzhijigouDY',
          name: 'sym_zuzhisiyouYGL_zuzhijigouDY',
          hidden: true, // 隐藏当前菜单
          meta: {
            module: '组织私有云管理',
            title: '组织机构定义',
            roles: ['admin', 'group', 'user'],
            icon: ''
          },
        },
        {
          path: '/sym/zuzhisiyouYGL/zuzhijigouSYY',
          name: 'sym_zuzhisiyouYGL_zuzhijigouSYY',
          hidden: true, // 隐藏当前菜单
          meta: {
            module: '组织私有云管理',
            title: '组织机构私有云',
            roles: ['admin', 'group', 'user'],
            icon: ''
          },
        }
      ]
    },
    {
      path: '/sym/authorityMgmt',
      redirect: '/sym/authorityMgmt/userMgmt',
      name: 'sym_authorityMgmt',
      meta: {
        module: '系统管理',
        title: '权限管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
          path: '/sym/authorityMgmt/userMgmt',
          name: 'sym_authorityMgmt_userMgmt',
          meta: {
            module: '权限管理',
            title: '用户管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          },
        },
        {
          path: '/sym/authorityMgmt/roleMgmt',
          name: 'sym_authorityMgmt_roleMgmt',
          meta: {
            module: '权限管理',
            title: '角色管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          },
        },
        {
          path: '/sym/authorityMgmt/ahorizationMgmt',
          name: 'sym_authorityMgmt_ahorizationMgmt',
          meta: {
            module: '权限管理',
            title: '授权管理',
            roles: ['admin', 'group', 'user'],
            icon: ''
          },
        },
        {
          path: '/sym/authorityMgmt/dataRangePermissions',
          name: 'sym_authorityMgmt_dataRangePermissions',
          meta: {
            module: '权限管理',
            title: '数据范围权限',
            roles: ['admin', 'group', 'user'],
            icon: ''
          },
        },
        {
          path: '/sym/authorityMgmt/roleNew',
          name: 'sym_authorityMgmt_roleNew',
          hidden: true, // 隐藏当前菜单
          meta: {
           module: '权限管理',
           title: '角色新增',
           roles: ['admin', 'group', 'user'],
           icon: ''
         },
      }
      ]
    },
    {
      path: '/sym/logManagement',
      redirect: '/sym/logManagement/logSetting',
      name: 'logManagement',
      meta: {
        module: '系统管理',
        title: '日志管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
        children: [
            {
                path: '/sym/logManagement/logSetting',
                name: 'logSetting',
                meta: {
                    module: '日志管理',
                    title: '日志设置',
                    roles: ['admin', 'group', 'user'],
                    icon: ''
                }
            },
            {
                path: '/sym/logManagement/loginLog',
                name: 'loginLog',
                meta: {
                    module: '日志管理',
                    title: '登录日志',
                    roles: ['admin', 'group', 'user'],
                    icon: ''
                }
            },
            {
                path: '/sym/logManagement/operationLog',
                name: 'operationLog',
                meta: {
                    module: '日志管理',
                    title: '操作日志',
                    roles: ['admin', 'group', 'user'],
                    icon: ''
                }
            }
        ]
    },
    // {
    //   path: '/sym/processMgmt',
    //   name: 'sym_processMgmt',
    //   meta: {
    //     module: '系统管理',
    //     title: '流程管理',
    //     roles: ['admin', 'group', 'user'],
    //     icon: ''
    //   },
    // },
    // {
    //   path: '/sym/infSysMgmt',
    //   name: 'sym_infSysMgmt',
    //   meta: {
    //     module: '系统管理',
    //     title: '信息体系管理',
    //     roles: ['admin', 'group', 'user'],
    //     icon: ''
    //   },
    // },
    // {
    //   path: '/sym/userDefined',
    //   name: 'sym_userDefined',
    //   meta: {
    //     module: '系统管理',
    //     title: '用户自定义',
    //     roles: ['admin', 'group', 'user'],
    //     icon: ''
    //   },
    // },
      {
          path: '/sym/sysParamSetting',
          name: 'sym_sysParamSetting',
          redirect: '/sym/sysParamSetting/groupParamSetting',
          meta: {
              module: '参数设置',
              title: '系统参数设置',
              roles: ['admin', 'group', 'user'],
              icon: ''
          },
          children: [
              {
                  path: '/sym/sysParamSetting/groupParamSetting',
                  name: 'sym_sysParamSetting_groupParamSetting',
                  meta: {
                      module: '系统参数设置',
                      title: '集团参数设置',
                      roles: ['admin', 'group', 'user'],
                      icon: ''
                  },
              },
              {
                  path: '/sym/sysParamSetting/unitParamSetting',
                  name: 'sym_sysParamSetting_unitParamSetting',
                  meta: {
                      module: '系统参数设置',
                      title: '单位参数设置',
                      roles: ['admin', 'group', 'user'],
                      icon: ''
                  },
              }
          ]
      },
      {
          path: '/sym/businessParamSetting',
          name: 'sym_businessParamSetting',
          redirect: '/sym/businessParamSetting/bsGroupParamSetting',
          meta: {
              module: '参数设置',
              title: '业务参数设置',
              roles: ['admin', 'group', 'user'],
              icon: ''
          },
          children: [
              {
                  path: '/sym/businessParamSetting/bsGroupParamSetting',
                  name: 'sym_businessParamSetting_bsGroupParamSetting',
                  meta: {
                      module: '业务参数设置',
                      title: '集团参数设置',
                      roles: ['admin', 'group', 'user'],
                      icon: ''
                  },
              },
              {
                  path: '/sym/businessParamSetting/bsUnitParamSetting',
                  name: 'sym_businessParamSetting_bsUnitParamSetting',
                  meta: {
                      module: '业务参数设置',
                      title: '单位参数设置',
                      roles: ['admin', 'group', 'user'],
                      icon: ''
                  },
              }
          ]
      }
  ]
}
