/**
 * 定义公共请求，直接在 common/utils/request/reqMapSet.js 引入
 * @type {{post_userTrusteeship_get: string}}
 */
import { pathMap } from './reqPathConfig'
import { reqMethods } from 'common/utils/request'
export const req = reqMethods({
    // baseUrl: 'http://192.168.1.153:18802', // 设置请求基础url路径
    pathMap
}).req;
