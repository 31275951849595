import { debounce, cloneDeep } from 'lodash';
import './VirtualSelect.less';

const HEIGHT = 32;

export default {
  inheritAttrs: true,
  model: {
    prop: 'value',
    event: 'select'
  },
  props: {
    options: {
      type: Array,
      default: () => ([])
    },
    filterOption: {
      type: Boolean | Function,
      default: false
    },
    optionFilterProp: {
      type: String
    },
    replaceFields: {
      type: Object,
      default: () => ({
        key: 'key',
        title: 'title',
        value: 'value'
      })
    }
  },
  data() {
    return {
      select: '',
      cur: 0,
      size: 50,
      y: 0,
      cls: `select-${new Date().getTime() + Math.floor(Math.random() * 10)}`,
      keyMap: {},
      selectSpace: null,
      isSearch: false,
      searchData: [],
      onSearch: debounce(this.search, 300)
    };
  },
  computed: {
    _bindProps() {
      const props = {
        ...this.$props,
        ...this.$attrs,
        dropdownClassName: this.cls,
        dropdownMenuStyle: this.dropdownMenuStyle,
        options: this.visibleData,
        filterOption: false
      };
      return props;
    },
    _bindEvents() {
      return {
        filter: this.onFilter,
        ...this.$listeners,
        popupScroll: this.onScroll,
        search: this.onSearch,
        focus: this.onFocus
      };
    },
    _key() {
      return this.replaceFields.key || 'key';
    },
    _title() {
      return this.replaceFields.title || 'title';
    },
    _value() {
      return this.replaceFields.value || this._key;
    },
    dropdownMenuStyle() {
      return {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        maxHeight: 'unset',
        transform: `translateY(${this.y * HEIGHT}px)`
      };
    },
    visibleData() {
      const {
        cur,
        size,
        isSearch,
        _key,
        _title,
        _value
      } = this;
      const result = isSearch ? this.searchData : this.options.slice(cur, cur + size);
      return result.map(opt => {
        const {
          [_key]: key,
          [_title]: title,
          [_value]: value
        } = opt;
        return {
          ...opt,
          key,
          title,
          value
        };
      });
    }
  },
  methods: {
    getHeight(list) {
      let height;
      if (list.length === 0) {
        height = 100;
      } else if (list.length - 18 <= 0) {
        height = list.length * HEIGHT;
      } else {
        height = (list.length - 18) * HEIGHT;
      }
      return `${height}px`;
    },
    onScroll(e) {
      const top = e.target.scrollTop;
      const count = Math.floor(top / HEIGHT);
      if (count >= 10) {
        this.cur = count - 10;
        this.y = count - 10;
      } else {
        this.cur = 0;
        this.y = 0;
      }
      this.$emit('popupScroll', e);
    },
    onFilter(str, node) {
      const optionFilterProp = this.optionFilterProp || this._title;
      return node[optionFilterProp].indexOf(str) > -1;
    },
    search(str) {
      console.log(str);
      if (!str) {
        this.$nextTick(() => {
          this.isSearch = false;
        });
        return;
      }
      this.isSearch = true;
      this.$nextTick(() => {
        this.searchData = cloneDeep(Object.values(this.keyMap)).filter(node => this._bindEvents.filter(str, node));
        this.selectSpace.style.height = this.getHeight(this.searchData);
      });
    },
    onFocus() {
      if (this.selectSpace) {
        this.isSearch = false;
        this.searchData = [];
        this.selectSpace.style.height = this.getHeight(this.options);
        return;
      }
      this.$nextTick(() => {
        const select = document.querySelector(`.${this.cls}`);
        const space = document.createElement('div');
        space.classList.add('select-space');
        space.style.height = this.getHeight(this.options);
        select.querySelector('.ant-select-dropdown-content').append(space);
        this.selectSpace = space;
      });
    }
  },
  watch: {
    options: {
      handler(nv) {
        const { _key } = this;
        nv.forEach(item => {
          this.keyMap[item[_key]] = item;
        });
        if (this.selectSpace) {
          this.selectSpace.style.height = this.getHeight(nv);
        }
      },
      immediate: true
    }
  },
  render() {
    const params = {
      props: this._bindProps,
      on: this._bindEvents
    };
    return (
      <a-select{...params} />
    );
  }
};
