<template>
  <div class="com-upload-img">
    <img v-if="imageUrl" :src="imageUrl" alt="avatar" @click="clickToShowImgModal" :style="{cursor: isDisabled ? 'not-allowed' : 'pointer'}" @error="handleImgLoadError"/>
    <div v-else :style="{textAlign: 'center', color: 'black', cursor: (isDisabled) ? 'not-allowed' : 'pointer', height: '100%', background: '#F5F5F5'}"
         @click="clickToShowImgModal">
      <img style="width: 100%; height: 113%"
           :src="require('./img/upload_pic2.svg')"/>
      <div class="ant-upload-text" v-if="xinxixiugai1">
        {{options.placeholder}}
      </div>
    </div>
    <div v-if="imageUrl && options.isShowBottomStyle" class="img-bottom-info">
      <a-icon v-show="!notShow || !notShow" type="sync" :style="{color: 'white', cursor: isDisabled ? 'not-allowed' : 'pointer'}" @click="clickToShowImgModal"/>
      <a-icon @click="lookImg" type="eye" style="color: white; cursor: pointer"/>
      <a-icon @click="downloadImg" type="download" style="color: white; cursor: pointer"/>
    </div>
    <div style="display: none" class="images">
      <img v-for="src in [imageUrl]" :src="src" :key="src" :data-original="src">
    </div>

    <a-modal
            title="上传照片"
            :visible="imgCoverShow"
            :width="625"
            centered
            :bodyStyle="{height: '550px'}"
            @ok="saveCutImg"
            @cancel="handleCancel"
          >
      <template slot="footer">
        <a-button type="primary" :disabled="!newImgData.imgUrl"
                  @click="$refs.fileSelectHandle.click()">重新上传</a-button>
        <a-button
                @click="handleCancel">取消</a-button>
        <a-button type="primary"
                  @click="saveCutImg">确定</a-button>
      </template>
      <div class="cut-img-content">
        <div class="left-cut-area" style="text-align: center;">
          <input
                  v-if="isShowImgInput"
                  accept="image/*"
                  style="display: none"
                  type="file"
                  ref="fileSelectHandle"
                  @change="uploadImg"/>
          <div v-if="!newImgData.imgUrl" style="margin-top: 160px;">
            <a-button @click="$refs.fileSelectHandle.click()">选择图片</a-button>
            <p style="margin: 20px; text-align: left; margin-left: 80px;">支持格式：JPG、PNG</p>
            <p style="margin: 20px; text-align: left; margin-left: 80px;">文件大小：{{imgSize.min}}~{{imgSize.max}}，请上传高清近照</p>
            <p style="margin: 20px; text-align: left; margin-left: 80px;">照片比例：4:5（宽度:高度）</p>
          </div>
          <div v-else style="height: 100%">
            <img :src="newImgData.imgUrl" style="width: 100%; height: 100%; display: block;" ref="image"
                 alt=""/>

          </div>
        </div>
        <div class="right-img-info">
          <div class="img-content1">
            <h1>实例说明</h1>
            <img :src="require('./img/example.jpg')"/>
          </div>
          <div class="img-content2" v-if="imageUrl">
            <h1>原图效果</h1>
            <img :src="imageUrl" style="width: 120px; height: 150px"/>
          </div>
        </div>
      </div>
      <div class="bottom-error-info">

        <div style="display: flex" v-if="newImgData.imgUrl">
          <a-slider
                  style="width: 80%;display: inline-block;margin-left: 10px; margin-right: 10px"
                  :min="0"
                  :max="10"
                  :step="0.01"
                  :value="slideValue"
                  @change="slideHandleChange" />
          <div style="width: 20%; margin-top: 10px">
            <a-icon type="zoom-in" style="margin-right: 5px; cursor: pointer;"
                    @click="iconChangeSize(0.1)"/>
            <a-icon type="zoom-out" style="margin-right: 5px; cursor: pointer" @click="iconChangeSize(-0.1)"/>
<!--            <a-icon type="sync" style="cursor: pointer" @click="$refs.fileSelectHandle.click()" />-->
          </div>

        </div>
        <span v-if="errorMessageState === 1">照片主区域被裁切或留白</span>
        <span v-else-if="errorMessageState === 2">照片大小小于{{imgSize.min}}或大于{{imgSize.max}}，请重新上传</span>
        <span v-else-if="errorMessageState === 3">仅支持JPG、GIF、PNG，请重新选择</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { downFile } from "../../utils/file/downFile";
import Cropper from './cropper/cropper.min';
import './cropper/cropper.min.css';
import './viewer/viewer.min.css';
import Viewer from './viewer/viewer.esm';
export default {
  name: "ComUploadImg",
  props: {
    parentDisabled: false,
    options: {
  		default() {
  		  return {
					imgName: '',
					placeholder: "上传图片",
					disabled: true,
					value: "",
          isShowBottomStyle: false
				}
      }
    },
    notShow: Boolean
  },
  computed: {
    isDisabled() {
      if(this.options.disabled === true) {
        return true
      } else {
        return this.parentDisabled
      }
    }
  },
  mounted () {
    this.$http('post_common_getImageRange').then(r => {
      if (r.code === 200) {
        this.imgSize = r.data
        var max = (Math.floor(this.imgSize.maxSize / 1024) || 10240)
        var min = (Math.ceil(this.imgSize.minSize / 1024) || 50)
        this.imgSize.max = max < 1024 ? max + 'k' :(Math.floor((this.imgSize.maxSize / 1024 || 10240) / 1024) ) + 'M'
        this.imgSize.min = min < 1024 ? min + 'k' :(Math.ceil((this.imgSize.minSize / 1024 || 50) / 1024)) + 'M'
      }
    })
  },
  data() {
  	this.viewer = null
    return {
			value: "",
      action: '',
      previewImageUrl: '',
      previewVisible: false,
      isShowImgInput: false,
      eduIsRules: false,
      errorMessageState: -1,
      errMessage: false,
      wAndHScaleVerify: true,
      sizeVerify: true,
      slideValue: 5,
      imgSize: {},
      isShowImgBtn: false,
      option: { img: '', size: NaN, outputType: 'jpg' },
      originImgData: { imgUrl: '', width: '', height: '', size: '' },
      newImgData: { imgUrl: '', width: '', height: '', size: '' },
      imgCoverShow: false,
      imgCoverShow2: false,
      xinxixiugai1: true,
      imageUrl: '',
    };
  },
  watch: {
    'newImgData.imgUrl': {
      handler (value) {
        if (value) {
          this.editImgHandle()
        }
      }
    },
    'options.value': {
    	handler (value) {
				if (value) {
					this.value = value;
					this.getPersonImgUrl()
        } else {
          this.imageUrl = ''
        }
      }
    },
    'options': {
      handler (value) {
        if (value) {
          console.log(value)
          // this.value = value;
          // this.getPersonImgUrl()
        } else {
          // this.imageUrl = ''
        }
      }
    }
  },
  created() {
    if (this.options.value) {
			this.value = this.options.value;
      this.getPersonImgUrl()
    }
  },
  methods: {
		handleImgLoadError () {
			this.imageUrl = ''
    },
    // 根据人员id获取照片信息
    getPersonImgUrl () {
      this.imageUrl = location.origin + process.env.VUE_APP_BASE_API + '/platservice/fileupload/previewImage?fid=' + this.options.value
    },
    editImgHandle () {
      this.imgCoverShow2 = true
      this.$nextTick(() => {
        this.init()
      })
    },
    init () {
      if (this.myCropper) {
        this.myCropper.destroy()
      }
      this.myCropper = new Cropper(this.$refs.image, {
        viewMode: 0,
        dragMode: 'move',
        minCropBoxWidth: 376,
        minCropBoxHeight: 470,
        background: true,
        cropBoxMovable: false,
        cropBoxResizable: false,
        zoomOnWheel: false,
        ready: () => {
          this.myCropper.zoomTo()
          const { left, top } = this.myCropper.getCanvasData()
          this.imgTop = top
          this.imgLeft = left
        },
        cropend: () => {
          const { left, top } = this.myCropper.getCanvasData()
          this.imgTop = top
          this.imgLeft = left
        }
      })
      const intime = setInterval(() => {
        for (var i=0;i<10000;i++) {
          if (!this.myCropper) return
           const { width, height, top, left } = this.myCropper.getCanvasData()
           
          this.imgLeft = left
          this.imgTop = top
          const widthDiff = width + this.imgLeft
          const heightDiff = height + this.imgTop
          // console.log(this.imgTop > 1 || this.imgLeft > 1 || widthDiff < 289 || heightDiff < 359)
          // console.log(this.imgTop , this.imgLeft, widthDiff,heightDiff)
          if (this.imgTop > 1 || this.imgLeft > 1 || widthDiff < 289 || heightDiff < 359) {
            this.iconChangeSize(0.1)
          } else {
            clearInterval(intime)
            break
          }
        }
      },10)
    },
    iconChangeSize (value) {
      if ((this.slideValue <= 0 && value < 0) || (this.slideValue >= 100 && value > 0)) return
      this.slideValue += value
      this.myCropper.zoom(value)
      const { left, top } = this.myCropper.getCanvasData()
      const { x, y } = this.myCropper.getData(true)
      this.imgTop = (x - top).toFixed(0)
      this.imgLeft = (y - left).toFixed(0)
    },
		download (href, name) {
			const eleLink = document.createElement('a')
			eleLink.download = name
			eleLink.href = href
			eleLink.click()
			eleLink.remove()
		},
    downloadImg () {
      // this.downloadByBlob(this.imageUrl, this.imgName || 'pic');
			const url = '/platservice/fileupload/download'
			let fid = null
			if (this.value) {
				fid = this.value
			} else {
				return;
			}
			downFile(url, { ids: fid })
    },
    // 下载图片相关
    downloadByBlob (url, name) {
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.src = url
      image.onload = () => {
        const canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        const ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
          const url = URL.createObjectURL(blob)
          thats.download(url, name)
          // 用完释放URL对象
          URL.revokeObjectURL(url)
        })
      }
    },
    slideHandleChange (value) {
      if (this.slideValue - value > 0) {
        this.myCropper.zoom(-0.1)
      } else {
        this.myCropper.zoom(0.1)
      }
      this.slideValue = value
    },
    // 将base64转换为file
    base64ToFile (data) {
      // 将base64 的图片转换成file对象上传 atob将ascii码解析成binary数据
      const binary = atob(data.split(',')[1])
      const mime = data.split(',')[0].match(/:(.*?);/)[1]
      const array = []
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i))
      }
      const fileData = new Blob([new Uint8Array(array)], {
        type: mime
      })

      const file = new File([fileData], `${new Date().getTime()}.png`, { type: mime })

      return file
    },
    //保存图片
    saveCutImg () {
      if (!this.newImgData.imgUrl) {
        this.imgCoverShow = false
        return
      }
      const { width, height, top, left } = this.myCropper.getCanvasData()
      this.imgLeft = left
      this.imgTop = top
      const widthDiff = width + this.imgLeft
      const heightDiff = height + this.imgTop
      if (this.imgTop > 1 || this.imgLeft > 1 || widthDiff < 289 || heightDiff < 359) {
        this.errorMessageState = 1
      } else {
        const imageUrl = this.myCropper.getCroppedCanvas({
          imageSmoothingQuality: 'high',
			}).toDataURL('image/jpeg')
        this.imgCoverShow = false
        this.errorMessageState = -1
        this.newImgData = {
          width: '',
          height: '',
          size: '',
          imgUrl: ''
        }
        const file = this.base64ToFile(imageUrl, 'image/jpeg')
        this.slideValue = 5
        this.submitPic(file)
      }
    },
    lookImg () {
      // const viewer = this.$el.querySelector('.images').$viewer
      // viewer.show()
      if(this.viewer) this.viewer.destroy()
			this.viewer = new Viewer(document.getElementById('app'), {
				url: 'data-original',
				navbar: false,
				title: false
			});
			this.viewer.show()
    },
    // 图片提交事件。把file格式转为formData格式进行提交。
    async submitPic (file) {
			if (this.myCropper) {
				this.myCropper.destroy()
			}
      const form = new FormData()

      form.append('files', file) // 设置发送到后台的文件参数名
      try {
        const result = await this.$http('file_common_uploadFile', form)
        if (result.code === 200) {
          this.$message.info('上传图片成功')
          this.value = result.data.uploadFileResultList[0].fid;
					this.imageUrl = location.origin + process.env.VUE_APP_BASE_API + '/platservice/fileupload/previewImage?fid=' + this.value
          this.$emit('changeImgInfo', result.data.uploadFileResultList[0].fid)
        } else {
          this.$message.error('上传图片失败')
        }
      } catch (e) {
        this.$message.error('服务器端错误')
      }
    },
    clickToShowImgModal () {
      if (this.isDisabled) return
      if (this.notShow) {
        this.lookImg()
      } else {
        this.imgCoverShow = !this.imgCoverShow
        this.isShowImgInput = true
      }
    },
    // 选择上传照片的回调
    uploadImg (e) {
      const _this = this

      // 获得文件路径
      const file = e.target.files[0]
			const name = file.name.toLowerCase();
      if (name.endsWith('.jpg') || name.endsWith('.png') || name.endsWith('.gif') || name.endsWith('jpeg')) {
        let url = ''
        var reader = new FileReader()
        reader.readAsDataURL(file)
        const that = this
        reader.onload = function (e) {
          url = this.result.substring(this.result.indexOf(',') + 1)

          // 设置图片宽高
          const image = new Image()
          image.src = e.target.result
          image.onload = function () {
            _this.newImgData.width = this.width
            _this.newImgData.height = this.height
            _this.newImgData.size = (file.size / 1024).toFixed(0)
            if (file.size / 1024 < (_this.imgSize.minSize / 1024 || 50) || file.size / 1024 > (_this.imgSize.maxSize / 1024 || 10240)) {
							if (_this.myCropper) {
								_this.myCropper.destroy()
							}
              _this.sizeVerify = false
              _this.errorMessageState = 2
              _this.newImgData = {
                height: '',
                width: '',
                size: '',
                imgUrl: ''
              }
							// that.newImgData.imgUrl = 'data:image/png;base64,' + url
            } else {
							that.newImgData.imgUrl = 'data:image/png;base64,' + url
              _this.errorMessageState = -1
            }
            if ((this.width / this.height).toFixed(1) !== 0.8) {
              _this.wAndHScaleVerify = false
            }
          }
        }
      } else if(file) {
				this.myCropper.destroy()
        this.errorMessageState = 3
      }
    },
    handleCancel () {
			if (this.myCropper) {
				this.myCropper.destroy()
			}
      this.imgCoverShow = false
      this.errorMessageState = -1
      this.imgCoverShow = false
      this.newImgData = {
        width: '',
        height: '',
        size: '',
        imgUrl: ''
      }
      this.slideValue = 5
      this.isShowImgInput = false
    },
  }
};
</script>
<style lang="less" scoped>
  .com-upload-img {
    width: 160px;
    max-height: 213px;
    padding: 2px;
    float: right;
    position: relative;
    border: 1px solid #ffffff;
    box-shadow: 1px 0px 4px #ddd;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }

    > .img-bottom-info {
      display: flex;
      justify-content: space-between;
      left: 0;
      z-index: 10;
      bottom: 0;
      height: 0;
      position: absolute;
      overflow: hidden;
      width: 100%;
      background: rgba(0, 0, 0, 0.4);
      transition: all 0.3s ease-in;
    }

    &:hover > .img-bottom-info {
      height: 30px;
      padding: 10px 20px;
    }
  }
  .cut-img-content {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 470px;

    .left-cut-area {
      flex-grow: 0;
      width: 376px;
      height: 470px;
      margin-right: 10px;
      background: rgb(242, 242, 245);

      img {
        width: 150px;
      }
    }

    .right-img-info {
      width: 189px;
      flex-grow: 0;
      padding-left: 20px;
      color: rgb(124, 128, 131);

      .img-content1, img-content2 {
        height: 50%;
      }
    }
  }
  .bottom-error-info {
    display: inline-block;
    width: 316px;
    color: red;
    margin: 2px 30px 0;
  }

  .ant-upload-text {
    width: 100%;
    text-align: center;
    color: #666;
    position: absolute;
    bottom: 20px;
  }

  .img-content {
    tr, td {
      vertical-align: middle;
    }
  }
</style>
