<template>
    <a-tree
            v-model="checkedKeys"
            :disabled="disable"
            checkable
            :expanded-keys="expandedKeys"
            :replaceFields="replaceFields"
            :auto-expand-parent="autoExpandParent"
            :defaultExpandedKeys="defaultExpandedKeys"
            :selected-keys="selectedKeys"
            :tree-data="listData"
            @expand="onExpand"
            @select="onSelect"
            @check="onCheck"
    />
</template>
<script>
    export default {
        props: {
            dataSource: {
                default: () => ([])
            },
            defaultChecked: {
                default: () => ([])
            },
            defaultExpandedKeys: {
                default: () => ([])
            },
            loadedKeys: {
                default: () => ([])
            },
            replaceFields: {
                default: () => ({
                    title:'title',
                    key: 'key'
                })
            },
            loadData: {
                default: ''
            },
            disable: {
                default: false
            }
        },
        data() {
            return {
                expandedKeys: [],
                autoExpandParent: true,
                checkedKeys: [],
                selectedKeys: [],
                listData:[],
                selectKey: []
            };
        },
        created () {
            this.listData = [...this.dataSource]
        },
        watch: {
            checkedKeys(val) {
                this.selectKey = [...this.defaultChecked]
                // console.log('onCheck1', val, this.defaultSelectedKeys);
            },
            defaultChecked(n) {
                this.checkedKeys = JSON.parse(JSON.stringify(n))
            },
            dataSource(n) {
                this.listData = [...n]
                this.$nextTick(() => {
                    this.setEvenBgColor()
                })
            }
        },
        mounted() {
            this.checkedKeys = JSON.parse(JSON.stringify(this.defaultChecked))
        },
        methods: {
            setEvenBgColor () {
                console.log('树数据yayayya111', this.listData, this.defExpandedKeys)
            },
            onExpand(expandedKeys) {
                console.log('onExpand', expandedKeys);
                this.expandedKeys = expandedKeys;
                this.autoExpandParent = false;
            },
            onCheck(es, info) {
                console.log('onCheck', es, info);
                if(this.disable) {
                    return
                }
                let e = info.node.dataRef
                let key = []
                // 有孩子节点
                if (e.children) {
                    key = e[this.replaceFields.key]
                    this.$emit('checked', key, e, this.checkedKeys)
                    // e.children.forEach(item=>{
                    //   this.$emit('checked', item[this.replaceFields.key], item, this.checkedKeys)
                    // })
                } else {
                    key = e[this.replaceFields.key]
                    this.$emit('checked', key, e, this.checkedKeys)
                }
            },
            onSelect(selectedKeys, info) {
                console.log('onSelect', info);
                this.selectedKeys = selectedKeys;
            }
        }
    };
</script>
