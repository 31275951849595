<!-- 人员变动审批-调动审批 -->
<template>
  <div class="div-full">
    <a-spin :spinning="loading" v-if="loading" class="spin-loading"></a-spin>
    <middle-con-layout noBottom v-show="!loading">
      <template slot="contentTopLeft">
          <span style="font-weight: 700">
            流水号: {{flowInfo.flowSerialId}} {{flowInfo.workTitle}} {{flowInfo.flowStartTime}}
          </span>
      </template>
      <template slot="contentTopRight">
        <a-button v-if="isShowAudit" @click="onClick_SubmitData" type="primary" >提交</a-button>
        <a-button v-if="isShowProcessAll" @click="onClick_ShowProcessAll">流程跟踪</a-button>
        <a-button v-if="isShowProcessDetail" @click="onClick_ShowProcessDetail">流程明细</a-button>
        <a-button v-if="isShowProcessChart" @click="onClick_ShowProcessChart">流程图</a-button>
        <a-dropdown v-if="isShowExportAll" >
          <a-button style="margin-left: 8px">输出<a-icon type="down"/></a-button>
          <a-menu slot="overlay" @click="onClick_HandleExport">
            <a-menu-item key="1" v-if="isShowExportPrint"><!--<a-icon type="user"/>-->打印</a-menu-item>
            <a-menu-item key="2" v-if="isShowExportExport"><!--<a-icon type="user"/>-->导出</a-menu-item>
          </a-menu>
        </a-dropdown>
        <slot name="contentTopRight"></slot>
        <a-button @click="onClick_BackBeforePage">返回</a-button>
      </template>
      <template slot="contentMain">
        <!-- 主题部分主要内容 --->
        <right-side-con-layout :side-width="300" :side-min-width="300" :side-max-width="350"  :show-side="isShowSide">
            <template slot="mainCon">
                <!-- 如：表格等主要内容的放置区域 -->
                <div class="content-left div-full" id="contentArea" ref="contentArea">
                    <slot name="contentItem"></slot>
                </div>
            </template>
            <template slot="sideMain">
                <div class="approve">
                    <flow-audit ref="refFlowAudit" :visible="isReady"></flow-audit>
                    <flow-modal ref="refFlowModal"></flow-modal>
                    <flow-merge ref="refFlowMerge"></flow-merge>
                    <flow-steps ref="refFlowSteps" :id="flowId" :visible="isReady && false"></flow-steps>
                </div>
            </template>
        </right-side-con-layout>
      </template>
    </middle-con-layout>
  </div>
</template>

<script>
import MiddleConLayout from 'common/components/BaseLayout/MiddleConLayout'
import RightSideConLayout from 'common/components/BaseLayout/RightSideConLayout'
import FlowAudit from './components/FlowAudit'
import FlowModal from './components/FlowModal'
import FlowSteps from './components/FlowSteps'
import FlowMerge from './components/FlowMerge'
  export default {
    name: 'AuditView',
    components: {
      MiddleConLayout,
      RightSideConLayout,
      FlowAudit,
      FlowModal,
      FlowMerge,
      FlowSteps
    },
    props: {
      showType: {
        type: String,
        default: 'look'
      },
      flowId: {
        type: String,
        required: true,
        default: ''
      },
      flowAlias: {
        type: String,
        required: true,
        default: ''
      },
      // ['all','detail','chart']
      showProcessArr: {
        type: Array,
        default: () => { return ['all'] }
      },
      // ['all','print','export']
      showExportArr: {
        type: Array,
        /*default: () => { return ['all'] }*/
        default: () => { return [] }
      }
    },
    data () {
      return {
        loading: false,
        isReady: false,
        isCanAudit: false,
        flowInfo: {
          flowId: '',
          busId: '',
          flowSerialId: '',
          workTitle: '',
          flowStartTime: ''
        },
        submitWorkFlowData: () => {},
      }
    },
    watch: {

    },
    computed: {
      isShowAudit () {
        return this.isCanAudit && this.isShowSide
      },
      isShowSide () {
        return this.showType !== 'look'
      },
      isShowProcessAll () {
        return (this.isReady) && (this.showProcessArr && this.showProcessArr.indexOf('all') > -1)
      },
      isShowProcessDetail () {
        return (this.isReady) && (this.showProcessArr && this.showProcessArr.indexOf('detail') > -1)
      },
      isShowProcessChart () {
        return (this.isReady) && (this.showProcessArr && this.showProcessArr.indexOf('chart') > -1)
      },
      isShowExportAll () {
        return this.isShowExportPrint || this.isShowExportPrint
      },
      isShowExportPrint () {
        return (this.isReady) && (this.showExportArr && (this.showExportArr.indexOf('all') > -1 || this.showExportArr.indexOf('print') > -1))
      },
      isShowExportExport () {
        return (this.isReady) && (this.showExportArr && (this.showExportArr.indexOf('all') > -1 || this.showExportArr.indexOf('export') > -1))
      }
    },
    created () {
      this.findFlowBaseInfo()
    },
    mounted () {
      this.initFlowAudit()
    },
    methods: {
      initFlowAudit () {
        this.loading = true
        this.isReady = false
        this.isCanAudit = false
        const paramsData = {
          id: this.flowId,
          flowAlias: this.flowAlias,
          hideSubmitBtn: true, // 隐藏组件中的提交按钮
          onBeforeValid: this.onBeforeValid,  // 内置表单验证前操作（本项目无，所以需要在这里验证提交的表单数据）
          onBeforeSubmit: this.onBeforeSubmit, // 提交前执行的操作（此处推荐进行业务数据提交）
          onAfterSubmit: this.onAfterSubmit // 提交后执行的操作 （此处推荐进行提交完成后的操作eq:页面跳转）
        }
        this.$refs.refFlowAudit.init(paramsData).then(res => {
          this.loading = false
          this.isReady = true
          this.flowInfo.state = this.$refs.refFlowAudit.state
          this.isCanAudit = this.flowInfo.state.canAudit
          this.submitWorkFlowData = this.$refs.refFlowAudit.onSubmit
          this.afterLoad()
        })
      },
      findFlowBaseInfo () {
        this.$http("post_findFlowBaseInfo", { flowId: this.flowId }, true).then(res => {
          if (res.code === 200) {
            this.flowInfo = res.data
            this.$emit('loadFlowInfo', this.flowInfo)
          }
        })
      },
      onBeforeValid () {
        console.log("onBeforeValid", this.flowId)
        const that = this
        return new Promise((resolve, reject) => {
            let paramsData = { isPass: true, noPassMsg: '' }
            that.$emit('beforeValid', returnData => {
                paramsData = returnData
            }); // 此处建议返回{ isPass: false, noPassMsg: '错误消息' }、或者不返回
            resolve(paramsData)
        })
      },
      onBeforeSubmit () {
        console.log("onBeforeSubmit", this.flowId)
        const that = this
        return new Promise((resolve, reject) => {
            let paramsData = { isPass: true, noPassMsg: '' }
            that.$emit('beforeSubmit', returnData => { paramsData = returnData }); // 此处建议返回{ isPass: false, noPassMsg: '错误消息' }、或者不返回
            this.saveFlowStep()
            resolve(paramsData)
        })
      },
      onAfterSubmit (isSuccess, goOrBackType, res) {
        console.log("onAfterSubmit", this.flowId, isSuccess, goOrBackType, res)
        this.$emit('afterSubmit', isSuccess, goOrBackType, res)
        this.backToLook()
      },
      onClick_SubmitData () {
          this.submitWorkFlowData()
      },
      onClick_ShowProcessAll () {
        this.$refs.refFlowMerge.showDetail(this.flowId)
      },
      onClick_ShowProcessDetail () {
        this.$refs.refFlowModal.showDetail(this.flowId)
      },
      onClick_ShowProcessChart () {
        this.$refs.refFlowModal.show(this.flowId)
      },
      // 返回前一个页面
      onClick_BackBeforePage () {
        this.backToLook();
      },
      // 导出处理
      onClick_HandleExport (event) {
        switch (event.key) {
          case "1":
            this.printFile()
            break;
          case "2":
            this.exportFile()
            break;
        }
      },
      printFile () {
        this.$message.info('功能正在建设中，敬请期待')
      },
      exportFile () {
        this.$message.info('功能正在建设中，敬请期待')
      },
      saveFlowStep () {

      },
      queryFlowStep () {

      },
      // 返回到查看模式
      backToLook (index) {
        this.$emit('switchShowView', 'content');
      },
      afterLoad () {
          this.$emit('afterLoad', this.flowInfo);
      },
      getFlowInfo(){
        return this.flowInfo
      }
    }
  }
</script>

<style lang="less" scoped>
    .content-left{
        background-color: #ffffff;
    }
</style>
