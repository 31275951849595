<template>
  <div class="com-trans-wrap">
    <div class="com-trans-left">
      <div class="trans-header">
        <slot name="header"></slot>
<!--        <a-select-->
<!--          show-search-->
<!--          placeholder="请输入搜索内容"-->
<!--          option-filter-prop="children"-->
<!--          style="width: 228px"-->
<!--          :value="selectVal"-->
<!--        >-->
<!--          <a-icon slot="suffixIcon" type="search" class="select-icon" />-->
<!--          <a-select-option-->
<!--            v-for="item in selectData"-->
<!--            :key="item[replaceFields['key']]"-->
<!--            :value="item[replaceFields['key']]"-->
<!--            @click="selectClick(item, $event)"-->
<!--          >{{item[replaceFields.title]}}</a-select-option>-->
<!--        </a-select>-->
      </div>
      <div class="trans-body">
        <collapseList
          :dataSource="leftData"
          :defaultChecked="targetKeys"
          :replaceFields="replaceFields"
          :defaultExpandedKeys="defaultExpandedKeys"
          :loadData="leftLoadData"
          @checked="leftSelect"
          @itemExpand="leftExpand"
          @leftParentClick="(e) => {$emit('leftParentClick', e)}"
          checkable
        ></collapseList>
      </div>
    </div>
    <div class="com-trans-right">
      <div class="trans-header">
        <div class="selected-num">已选{{selectedNum}}项</div>
        <a-button class="selected-alldel" @click="delAll">删除全部</a-button>
      </div>
      <div class="trans-body">
        <treeList
          ref="codeOpt"
          @select="emitChange"
          @delItem="rightDelItem"
          :loadData="rightLoadData"
          :replaceFields="replaceFields"
          :selectKeys="selectKeys"
          :dataSource="rightData"
        ></treeList>
      </div>
    </div>
  </div>
</template>
<script>
  import collapseList from './collapseList.vue'
  import treeList from './treeList3.jsx'
  export default {
    props: {
      dataSource: {
        default: () => ([])
      },
      dataTarget: {
        default: () => ([])
      },
      replaceFields: {
        default: () => ({
          title:'title',
          key: 'key'
        })
      },
      leftLoadData: {
        default: ''
      },
      rightLoadData: {
        default: ''
      },
      selectKeys: {
        default: () => ([])
      }
    },
    components: {
      collapseList,
      treeList
    },
    data () {
      return {
        leftData: [],
        rightData: [],
        selectVal: '',
        selectedNum: 0,
        selectData: [],
        targetKeys: [],
        defaultExpandedKeys: []
      }
    },
    beforeCreate() {
    },
    created () {
      this.setLeftData(this.dataSource)
      this.setRightData(this.dataTarget)
      this.setSelectedNum(this.selectKeys)
    },
    mounted () {
      this.tableLoading = true
    },
    methods: {
      setLeftData(data) {
        this.leftData = data
      },
      setRightData(data) {
        // this.rightData = data
        this.targetKeys = []
        this.rightData = []
        data.forEach(item => {
          const key = item[this.replaceFields.key]
          if(!this.targetKeys.includes(key)) {
            this.targetKeys.push(key)
            this.rightData.push(item)
          }
        })
      },
      leftSelect (key, obj) {
        if(this.targetKeys.includes(key)) {
          let keyIndex = this.targetKeys.indexOf(key)
          this.targetKeys.splice(keyIndex, 1)
          this.rightData.splice(keyIndex, 1)
        } else {
          this.targetKeys.push(key)
          this.rightData.push(obj)
        }
      },
      delAll () {
        this.targetKeys.push(key)
        this.rightData.push(obj)
        this.selectKeys = []
        this.setSelectedNum(this.selectKeys)
        this.$emit('change', this.selectKeys)
      },
      emitChange (change) {
        this.setSelectedNum(change)
        this.$emit('change', change)
      },
      rightDelItem (e, data) {
        const key = e[this.replaceFields.key]
        let keyIndex = this.targetKeys.indexOf(key)
        this.targetKeys.splice(keyIndex, 1)
        this.rightData.splice(keyIndex, 1)
        this.emitChange(data)
      },
      leftExpand (e) {
        console.log(e)
      },
      setSelectedNum(data) {
        this.selectedNum = data.length
      }
    },
    watch: {
      dataSource (n) {
        this.setLeftData(n)
      },
      dataTarget (n) {
        this.setRightData(n)
      },
      selectKeys (n) {
        this.setSelectedNum(n)
      }
    }
  }
</script>
