<template>
  <a-tree-select
    v-model="value"
    :style="customStyle"
    showSearch
    :filterTreeNode="filterTreeNode"
    :disabled="disabled"
    :labelInValue="labelInValue"
    allow-clear
    :tree-checkable="treeCheckable"
    :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
    :tree-data="treeData"
    :replaceFields="replaceFields"
    :[isFilterTreeNode]="false"
    :[isTreeNodeFilterProp]="'title'"
    :treeDefaultExpandAll="treeDefaultExpandAll"
    :maxTagCount="maxTagCount"
    :placeholder="placeholder"
    searchPlaceholder="请输入搜索内容"
    :[isLoadData]="onLoadData"
    @search="search"
    @change="change"
    @click="click"
    :multiple="multiple"
    :defaultValue="defaultValue"
    :tree-check-strictly="treeCheckStrictly"
    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
  >
  </a-tree-select>
</template>
<script>
import { debounce } from "lodash";
// import { req } from 'common/api/index';
import { deepClone } from "./deepClone";
import { AllData } from './tempCache'
export default {
  data() {
    return {
      value: undefined,
      treeData: [],
      // treeDataBack: [],
      disabled: false,
      dmcpt: "",
      dataZ: [],
      scrollPage: 1,
      treePageSize: 100,
      SearchvalueData: "",
      onSearch: debounce(this.loadFLData, 500),
      filterTreeNodeDa: false,

    };
  },
  computed: {
    isLoadData() {
      if (this.LazyLoad) {
        return "load-data";
      }
      return null;
    },
    isFilterTreeNode() {
      if (this.LazyLoad) {
        return "filterTreeNode";
      }
      return null;
    },
    isTreeNodeFilterProp() {
      if (!this.LazyLoad) {
        return "treeNodeFilterProp";
      }
      return null;
    }
  },
  model: {
    prop: "id", //这里使我们定义的v-model属性,叫做modelVal,这里我们命名为id
    event: "change",
  },
  props: {
    getCustomLabel: {
      type: Function,
      default: () => {}
    },
    customStyle: {
      type: String,
      default: "width: 100%",
    },
    placeholder: {
      type: String,
      default: "请选择",
    },
    loadSerchType: {
      type: Boolean,
      default: true,
    },
    maxTagCount: {
      type: Number,
      default: 999999999999,
    },
    requestType: {
      type: Boolean,
      default: true,
    },
    Echo: {
      type: String,
      default: "dmabr1",
    },
    Disabled: {
      type: Boolean,
      default: false,
    },
    labelInValue: {
      type: Boolean,
      default: false,
    },
    replaceFields: {
      type: Object,
      default: () => ({
        children: "children",
        title: "dmcpt",
        key: "id",
        value: "dmcod",
        pId: "parentid",
      })
    },
    dmgrp: String,
    LazyLoad: {
      type: [Boolean, Number],
      default: false,
    },
    id: [String, Array, Object],
    // isMultiple: {
    //   type: Boolean,
    //   default: false,
    // },
    treeCheckable: {
      type: Boolean,
      default: false,
    },
    treeDefaultExpandAll: {
      type: Boolean,
      default: false,
    },
    multiple: Boolean,
    defaultValue: {
      type: [String, Array],
      default: undefined
    },
      treeCheckStrictly: {
          type: Boolean,
          default: false,
      },
    filterTreeNode: {
      type: Function,
      default: (v, n) => {
        let obj = n.data.props.dataRef;
        let dmcpt = obj.dmcpt;
        let dmhnd = obj.dmhnd;
        if(typeof v === 'string') {
          v = v.toLocaleLowerCase();
        }
        if(typeof dmhnd === 'string') {
          dmhnd = obj.dmhnd.toLocaleLowerCase();
        }
        // 获取所有值 进行检查
        // let d = Object.values(obj)
        //  .map(v => typeof v === 'string' ? v.toLocaleLowerCase() : '')
        //  .filter(v => v)
        //  let bool = d.includes(v)
        if(dmhnd) {
          return dmcpt.includes(v) || dmhnd.includes(v)
        }
        return dmcpt.includes(v);
      },
    },
  },
  watch: {
    // value(value) {
    //   this.$emit("change", value);
    // },
    id(val) {
      this.value = val;
    },
    Disabled(val) {
      this.disabled = val;
    },
  },
  created() {
    this.value = this.id;
    if (this.requestType) {
      this.loadFLData();
    }
    this.disabled = this.Disabled;
  },
  methods: {
    /*
    group(data) {
      const result = [];
      let groupItem;
      for (let i = 0; i < data.length; i++) {
        if (i % 100 == 0) {
          groupItem != null && result.push(groupItem);
          groupItem = [];
        }
        groupItem.push(data[i]);
      }
      result.push(groupItem);
      // result.forEach(item => {
      //         if (item.hasOwnProperty('iscanuse')) {
      //             console.log(item.iscanuse);
      //         } else {
      //             console.log(1);
      //         }
      //     })
      return result;
    },
    */
    click() {
      if (this.treeDataBack && this.treeDataBack.length) {
        this.treeData = this.treeDataBack;
      } else {
        this.loadFLData(null);
      }
      /*
      if (!this.treeData.length || this.LazyLoad) {
        this.loadFLData(null);
      }
      */
    },
    loadFLData(val) {
      if (!val && AllData[this.dmgrp] && !this.LazyLoad) {
        this.treeDataBack = deepClone(AllData[this.dmgrp])
        this.treeData = deepClone(AllData[this.dmgrp]);
        return
      }
      const { children, key, pId } = this.replaceFields;
      const nodeIdMap = {};
      const parentIdMap = {};
      this.$http(
        "post_codeTable_select",
        {
          dmgrp: this.dmgrp,
          dmcpt: val ? val : "",
          parentid: "",
          lazyLoad: (!val && this.LazyLoad) ? 1 : 0,
          // lazyLoad: this.LazyLoad ? 1 : 0,
        },
        true
      ).then((res) => {
        //  此处方法用于表单定制缓存平面选项数据；
        let tempData = [...this.treeData]
        if(tempData.length) {
          tempData = [...this.treeData, ...res.data]
        } else {
          tempData = [...res.data]
        }
        this.$emit('setOpts', tempData)
      // 添加对象数组去重方法 ---开始
      //   let hash = {}
      //   let arr = []
      //   res.data = res.data.reduce(function(item, next) {
      //   hash[next.id] ? '' : hash[next.id] = true && item.push(next)
      //   return item
      //   }, [])
        // 添加对象数组去重方法 ---结束
        const result = [];
        const getCustomLabel = this.getCustomLabel
        const forEachHandler = (pid) => {
          return (node) => {
            const { [children]: c, [key]: k, [pId]: p } = node;
            nodeIdMap[k] = node;
            if (c && c.length) {
              c.forEach(forEachHandler(k));
            }
            if (getCustomLabel(node)) {
              node.dmcpt = getCustomLabel(node)
            }
            if (p) {
              parentIdMap[k] = p;
            } else if (pid) {
              parentIdMap[k] = pid;
            }
            if (node.hasOwnProperty("iscanuse")) {
              node.disabled = node.iscanuse === 0;
            }
          };
        };
        res.data.forEach(forEachHandler());
        const parentIds = Object.values(parentIdMap);
        // console.log('[parentIds]', parentIds)
        res.data.forEach((node) => {
          const { [pId]: p } = node;
          const hasParent = [p] in nodeIdMap && parentIds.includes(p);
          if (!hasParent) {
            result.push(node);
            return;
          }
          nodeIdMap[p][children] = nodeIdMap[p][children] || [];
          nodeIdMap[p][children].push(node);
          nodeIdMap[p].isLeaf = false;
        });
        this.$nextTick(() => {
          this.treeData = result;
          if(!val) {
            this.treeDataBack = deepClone(this.treeData);
          }
          AllData[this.dmgrp] = deepClone(this.treeData)
          // console.log('treeData', result)
        });
      });
    },
    /*
    genTreeNode(parentId, isLeaf = false) {
      const random = Math.random().toString(36).substring(2, 6);
      return {
        id: random,
        pId: parentId,
        value: random,
        dmcpt: isLeaf ? "Tree Node" : "Expand to load",
        isLeaf,
      };
    },
    */
    onLoadData(treeNode) {
      return new Promise((resolve) => {
        const { id } = treeNode.dataRef;
        // console.log(treeNode.dataRef)
        this.$http(
          "post_codeTable_select",
          {
            dmgrp: this.dmgrp,
            dmcpt: this.dmcpt,
            parentid: id,
            lazyLoad: this.LazyLoad ? 1 : 0,
          },
          true
        ).then((res) => {
          //  此处方法用于表单定制缓存平面选项数据；
            let tempData = [...this.treeData]
            if(tempData.length) {
                tempData = [...this.treeData, ...res.data]
            } else {
                tempData = [...res.data]
            }
          this.$emit('setOpts', tempData)
          const items = res.data.map(function (obj, index) {
            obj.key = `${obj.id}${obj.dmcpt}${index}`;
            obj.value = obj.dmcod;
            obj.pId = id;
            return obj;
          });
          items.forEach((item) => {
            if (this.getCustomLabel(item)) {
              node.dmcpt = this.getCustomLabel(item)
            }
            if (item.iscanuse === 0) {
              item.disabled = true;
            } else {
              item.disabled = false;
            }
          });
          treeNode.dataRef.children = items;
          this.treeData = [...this.treeData];
          this.treeDataBack = deepClone(this.treeData);
          setTimeout(() => {
            resolve();
          }, 100);
        });
      });
    },
    search(val) {
      if (this.loadSerchType && this.LazyLoad) {
        this.onSearch(val);
      } else {
        // this.treeData = []
        this.filterTreeNodeDa = false;
      }
      // if (this.treeData[0].parentid === null && this.treeData[0].childrenNum === null) {
      //     this.filterTreeNodeDa = function (inputValue,treeNode) {
      //       if (treeNode.data.props.dmcpt.includes(val) || treeNode.data.props.dmhnd.search(val)!= -1) {
      //         return treeNode.data.props
      //       }
      //     }
      // } else {
      //   this.treeData = []
      //   this.filterTreeNodeDa = false
      // }
      this.$emit("onSearchData", val);
    },
    change(value, label, extra) {
      // console.log('[change]', value, label, extra)
      if (typeof this.value === "object") {
        if (extra.triggerNode && extra.triggerNode.dataRef) {
          this.value.label = extra.triggerNode.dataRef[this.Echo];
        }
      }
      this.$emit('change', value, label, extra)
    },

    //下拉框下滑事件
    handlePopupScroll(e) {
      const { target } = e;
      const scrollHeight =
        (target.scrollHeight - target.scrollTop).toFixed() - 0;
      const clientHeight = target.clientHeight;
      // 下拉框不下拉的时候
      // console.log(scrollHeight)
      if (scrollHeight === 250 && clientHeight === 250) {
        this.scrollPage = 1;
      } else {
        // if (this.tj1left[ind].frontDataZ.length === 0) {
        //     this.tj1left[ind].frontDataZ = dataSource.slice(0, 100)
        // } else {
        // }

        // 当下拉框滚动条到达底部的时候
        if (scrollHeight < clientHeight + 5) {
          this.scrollPage = this.scrollPage + 1;
          const scrollPage = this.scrollPage; // 获取当前页
          const treePageSize = this.treePageSize * (scrollPage || 1); // 新增数据量
          const newData = []; // 存储新增数据
          let max = ""; // max 为能展示的数据的最大条数
          if (this.dataZ.length > treePageSize) {
            // 如果总数据的条数大于需要展示的数据
            max = treePageSize;
          } else {
            // 否则
            max = this.dataZ.length;
          }
          // 判断是否有搜索
          if (this.SearchvalueData) {
            this.dataZ.forEach((items, index) => {
              if (index < max) {
                // 当data数组的下标小于max时
                newData.push(items);
              }
            });
          } else {
            this.dataZ.forEach((iteme, index) => {
              if (index < max) {
                // 当data数组的下标小于max时
                newData.push(iteme);
              }
            });
          }

          this.frontDataZ = newData; // 将新增的数据赋值到要显示的数组中
        }
      }
    },
  },
};
</script>
<style>
.ant-select ul.ant-select-selection__rendered {
  /*display: flex;    zj*/
  display: block;
}
</style>
