import { render, staticRenderFns } from "./collapseList.vue?vue&type=template&id=72ff874e&scoped=true&"
import script from "./collapseList.vue?vue&type=script&lang=js&"
export * from "./collapseList.vue?vue&type=script&lang=js&"
import style0 from "./collapseList.vue?vue&type=style&index=0&id=72ff874e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../portal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72ff874e",
  null
  
)

export default component.exports