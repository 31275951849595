<template>
  <div class="side-nav">
    <div class="side-warp" :style="`width: ${sideWidth}px`" @mouseleave="mouseLeaveItem">
      <div class="side-con" :style="`width: ${sideWidth}px`" @mouseenter="mouseEnterItem()" ref="listWrap">
        <div class="side-top" >
          <img class="side-logo" src="./img/logo.svg"/>
          <span class="sys-title">人力资源管理系统</span>
        </div>
        <div class="nav-con">
          <ul class="nav-list" >
            <li
              v-for="(item, index) in navData"
              :key="index"
              @click="itemClick(item)"
            >
              <div class="nav-item" :class="{'active': item.meta.title === childTitle}">
                <a-tooltip
                  v-if="sideWidth === 48 && drawerLeft !== 40"
                  placement="rightTop"
                  :mouseLeaveDelay="0"
                >
                  <template slot="title">
                    <span>{{item.meta.title}}</span>
                  </template>
<!--                  <a-icon class="nav-item-icon" :type="item.meta.icon" />-->
                  <span class="nav-item-icon">
                    <i class="iconhr" :class="item.meta.icon"></i>
                  </span>

                </a-tooltip>
<!--                <a-icon v-else class="nav-item-icon" :type="item.meta.icon" />-->
                <span v-else class="nav-item-icon">
                  <i class="iconhr" :class="item.meta.icon"></i>
                </span>
                <span class="nav-item-text">{{item.meta.title}}</span>
              </div>
            </li>
          </ul>
        </div>
        <a-icon class="shrink" type="menu-unfold" @click="shrinkBtn"/>
      </div>
      <div class="nav-drawer" :style="`left: ${drawerLeft}px`" >
        <div class="nav-item-title">{{childTitle}}</div>
        <div class="nav-child-wrap">
          <ChildNav
            :navStore="navStore"
            :collapsed="collapsed"
            :menu="childNav"
            theme="light"
            @select="onSelect"
            @getCrumbData="getCrumbData"
          ></ChildNav>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildNav from './ChildNav'
export default {
  components: {
    ChildNav
  },
  props: {
    navData: {
      default: () => []
    }
  },
  data() {
    return {
      sideWidth: 48,
      drawerLeft: -200,
      isNavDrawer: false,
      navActive: '',
      childNav: [],
      childTitle: '',
      collapsed: true,
      selectItem: '',
      navStore: '',
      flaseNav: {}
    }
  },
  watch: {
    $route() {
      this.setActiveItem()
    }
  },
  created() {
    this.setActiveItem()
  },
  methods: {
    setActiveItem() {
      for (let i = 0; i < this.navData.length; i++) {
        const item = this.navData[i]
        const reg = new RegExp(`^${item.path}`)
        if (reg.test(this.$route.path)) {
          this.childTitle = item.meta.title
          this.selectItem = item.children
          this.sideWidth = 48
          break
        } else {
          this.childTitle = ''
          this.selectItem = ''
          this.flaseNav = {}
        }
      }
    },
    itemClick(navItem) {
      this.navStore = navItem
      this.childNav = navItem.children
      this.childTitle = navItem.meta.title
      this.isNavDrawer = true
      if (this.selectItem === '') {
        this.drawerLeft = 200
      } else {
        this.drawerLeft = 48
      }
    },
    mouseEnterItem() {
      if (this.selectItem === '') {
        this.sideWidth = 200
        // this.drawerLeft = 200
      }
    },
    mouseLeaveItem() {
      this.sideWidth = 48
      this.drawerLeft = -200
      this.isNavDrawer = false
      if (this.flaseNav.meta) {
        this.childTitle = this.flaseNav.meta.title
      }
    },
    onSelect(e, nav) {
      this.selectItem = e.key
      this.flaseNav = nav
      if (this.selectItem === '') {
        this.drawerLeft = 200
      } else {
        this.sideWidth = 48
        this.drawerLeft = 48
      }
    },
    getCrumbData() {

    },
    shrinkBtn() {},
    getPopupContainer(triggerNode) {
      return this.$refs.listWrap
    }
  }
}
</script>

<style lang="less" scoped>
.side-nav {
  position: relative;
  z-index: 2000;
  width: 48px;
  height: 100%;
}
.side-warp {
  position: relative;
  width: 48px;
  height: 100%;
}
.side-nav .side-con {
  position: relative;
  width: 48px;
  height: 100%;
  transition: all 0.5s;
  background: #3b4965;
  overflow: hidden;
}
.side-nav .side-top {
  display: flex;
  height: 48px;
  align-items: center;
}
.side-nav .side-logo {
  width: 40px;
  margin-left: 4px;
}
.side-nav .shrink {
  position: absolute;
  bottom: 0;
  display: inline-block;
  width: 48px;
  height: 48px;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}
.side-nav .sys-title {
  color: #ffffff;
  font-size: 16px;
  margin-left: 10px;
  font-weight: bold;
  white-space: nowrap;
}
.side-nav .nav-item-icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  font-size: 20px;
  color: #ffffff;
  vertical-align: top;
  line-height: 48px;
  text-align: center;
}
.side-nav .nav-item-icon .iconhr {
  color: #ffffff;
  font-size: 18px;
}
.side-nav .nav-item {
  height: 48px;
  line-height: 48px;
  white-space: nowrap;
}
.side-nav .nav-item.active {
  background: #0FB9EF;
}
.side-nav .nav-item:hover {
  background: #0FB9EF;
  cursor: pointer;
}
.side-nav .nav-item-text {
  font-size: 14px;
  color: #ffffff;
}
.side-nav .nav-drawer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 200px;
  height: 100%;
  background: #F4F6FA;
  transition: all 0.5s;
  box-shadow: 3px 0 8px -4px #000;
  z-index: -1;
  /*transform: translateX(100%);*/
}
.side-nav .nav-item-title {
  color: #3A8DFF;
  height: 48px;
  line-height: 48px;
  border-bottom: 1px solid #DDE4EB;
  font-size: 14px;
  padding-left: 10px;
  font-width: 500;
  background: #F4F6FA;
  cursor: auto;
}
.side-nav .nav-child-wrap {
  height: calc(~"100% - 48px");
  overflow: auto;
}
.side-nav .nav-item-title:before {
  content: '';
  display: inline-block;
  width: 3px;
  height: 16px;
  background: rgba(58,141,255,1);
  margin-right: 8px;
  vertical-align: -3px;
}
.side-nav /deep/ .ant-menu {
  background: transparent;
}
.side-nav /deep/ .ant-menu-item {
  border-bottom: 1px solid #DDE4EB;
  height: 45px;
  line-height: 45px;
}
.side-nav /deep/ .ant-menu-item:hover {
  background: #51A2EE;
}
.side-nav /deep/ .ant-menu-item > a:hover {
  color: #ffffff;
}
.side-nav /deep/ .ant-menu-submenu-title {
  margin: 0;
  border-bottom: 1px solid #DDE4EB;
}
.side-nav /deep/ .ant-menu-submenu-title:hover {
  background: #51A2EE;
  color: #ffffff;
}
.side-nav /deep/ .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 45px;
  line-height: 45px;
}
.side-nav /deep/ .ant-menu-sub.ant-menu-inline > .ant-menu-item {
  height: 45px;
  line-height: 45px;
  margin: 0;
}
.side-nav /deep/ .ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0;
  margin-top: 0;
}
.side-nav /deep/ .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #89c3fa;
}
.side-nav /deep/ .ant-menu-item-selected > a {
  color: #ffffff;
}
.side-nav /deep/ .ant-menu-submenu-selected > .ant-menu-submenu-title {
  background: #51A2EE;
  color: #ffffff;
}
.side-nav /deep/ .ant-menu-inline .ant-menu-item {
  margin: 0;
}
</style>
