import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/index.css'

export default {
    install (Vue) {
        Vue.use(VXETable)
    }
}
// VXETable.setup({
//   table: {
//     scrollX: {
//       gt: 60
//     },
//     scrollY: {
//       gt: 60
//     }
//   },
//   list: {
//     scrollY: {
//       gt: 60
//     }
//   }
// })