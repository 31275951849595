import { registerMicroApps, runAfterFirstMounted, start } from 'qiankun'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import microApps from './micro'
import actions from './micro/globalStore'
import api from '@/api'
// reset.css
import 'common/style/index.less'
// Vue filter
import 'common/utils/filters/index'
// ant design vue
// import Antd from 'ant-design-vue'
// // import 'ant-design-vue/dist/antd.css'
import AntDVue from 'common/lib/antdVue'
import 'nprogress/nprogress.css'
import echarts from 'echarts'
// import BdHrUi from '@pkuse/hr-ui'
// import '@pkuse/hr-ui/lib/theme-chalk/index.css'
import { store as commonStore } from 'common'

// 复制 localStorage 信息到 sessionStorage
var keys = Object.keys(localStorage);
keys.forEach(function(key) {
    var value = localStorage.getItem(key);
    sessionStorage.setItem(key, value);
});


Vue.prototype.$eventBus = new Vue()
Vue.config.productionTip = false
Vue.use(AntDVue)
// Vue.use(BdHrUi)
Vue.use(api)
Vue.config.productionTip = false
Vue.prototype.$actions = actions
Vue.prototype.$echarts = echarts
commonStore.globalRegister(store)
const userRoutersStr = sessionStorage.getItem('userRouters')
if (userRoutersStr) {
  const userRouters = JSON.parse(sessionStorage.getItem('userRouters'))
  if (userRouters) {
    store.commit('global/SET_MENU', userRouters)
  }
}
const instance = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#mainapp')

// 定义loader方法，loading改变时，将变量赋值给App.vue的data中的isLoading
function loader(loading) {
  if (instance && instance.$children) {
    // instance.$children[0] 是App.vue，此时直接改动App.vue的isLoading
    instance.$children[0].isLoading = loading
  }
}

// 给子应用配置加上loader方法
const apps = microApps.map(item => {
  return {
    ...item,
    loader
  }
})
/**
 * 注册子应用
 */

registerMicroApps(apps,
  {
    beforeLoad: [
      app => {
        console.log('[LifeCycle] before load %c%s', 'color: green;', app.name)
      }
    ],
    beforeMount: [
      app => {
        console.log(app)
        // commonStore.globalRegister(store, app.props)
        // store.state.global.routerArr = [
        //   {
        //     name: '12'
        //   }
        // ]
        // console.log('[LifeCycle] before mount %c%s', 'color: green;', app.name)
      }
    ],
    afterUnmount: [
      // app => {
      //   // console.log('[LifeCycle] after unmount %c%s', 'color: green;', app.name)
      // }
    ]
  }
)

start()

runAfterFirstMounted(() => {
  // console.log('[MainApp] first app mounted')
})
