<template>
  <div class="custom-layout" :class="'bg-color-white'" >
    <!-- 左侧导航 -->
    <sideNav :navData="menuData" v-if="hidden"></sideNav>
    <!-- 右侧显示区域 -->
    <div class="custom-layout-content">
      <!-- 右侧 header 区域 -->
      <div class="custom-layout-content-header" v-if="hidden">
        <manage-header  v-if="hidden"/>
      </div>
      <!-- 右侧 content 区域 -->
      <div class="custom-layout-content-main">
        <!--        <div class="custom-layout-content-main-breadcrumb">-->
        <!--          <breadcrumb :nowWorkArea="'management'"></breadcrumb>-->
        <!--        </div>-->
        <div class="custom-layout-content-main-view  scroller" @mouseenter="onMouseLeaveChildNav()">
          <router-view />
          <!-- <div id="subapp-viewport" /> -->
        </div>
      </div>
    </div>
    <!-- 右侧抽屉区域 -->
    <div>
      <!-- CSS 样式被放置在了 App.vue 内 -->
      <!--      <div :class="drawerShow ? 'drawer-show-button-active' : 'drawer-show-button-normal'">-->
      <!--        <a-button type="primary" :icon="drawerShow ? 'close' : 'setting'" size="large" @click="showRightDrawer()" />-->
      <!--      </div>-->
      <a-drawer
        title="色彩设置"
        width="300"
        placement="right"
        :closable="false"
        :visible="drawerShow"
        @close="onClose">
        <color-set></color-set>
      </a-drawer>
    </div>
  </div>
</template>

<script>
  // import navChildren from '@/components/navChildren/navChildren'
  import colorSet from 'common/components/drawerColorSet/colorSet'
  import breadcrumb from 'common/components/breadcrumb/breadcrumb'
  import manageHeader from 'common/components/manageHeader/manageHeader'
  import sideNav from 'common/components/sideNav'
  import { mapState } from 'vuex'
  import routerConf from 'common/src/routeConf'
  var routerNav = []
  Object.keys(routerConf).forEach(route => {
    routerNav.push(routerConf[route])
  })
  export default {
    name: 'CustomLayout',
    components: {
      // navChildren,
      colorSet,
      breadcrumb,
      manageHeader,
      sideNav
    },
    data() {
      return {
        inside: false, // false 表示导航目录不缩回
        hover: false, // false 表示悬浮的导航目录不显示
        showChildNav: false, // false 表示子导航目录不显示
        userMenu: routerNav,
        navChildData: {},
        drawerShow: false,
        activeMenu: '',
        hidden: true
      }
    },
    computed: {
      menuData() {
        return this.$store.state.menuData
      }
    },
    watch: {
      $route() {
        this.activeNavMenu()
      }
    },
    created() {
      if (this.$route.meta.fullScreen) this.hidden = false
      if(this.$route.path.includes('/pm/implement/tree_type')) {
        this.hidden = false
      }
      if(this.$route.path.includes('/pm/implement/waterfall_type')) {
        this.hidden = false
      }
      if(this.$route.path.includes('/pm/implement/tile_type')) {
        this.hidden = false
      }
      if (this.$route.query.onlyContent === '1') {
        this.hidden = false
      }
      if (this.$route.query.onlyContent === '2') {
        this.hidden = false
      }
    },
    mounted() {
      this.showRouteList()
      this.activeNavMenu()
      // 通过 eventBus 接收子导航目录的关闭请求
      this.closeChildNav()
      window.onresize = () => {
        this.widthResize()
      }
    },
    methods: {
      /**
       * 监听页面宽度设置导航目录显隐
       * */
      widthResize() {
        const width = document.body.getBoundingClientRect().width
        if (width < 1000) {
          this.inside = true
        } else if (width > 1000) {
          this.inside = false
        }
      },
      /**
       * 改变左侧导航目录宽度
       * */
      changeSideWidth() {
        this.inside = !this.inside
      },
      /**
       * 鼠标进入在左侧导航目录内
       * */
      onMouseEnterNav() {
        if (this.inside === false) {
          // 导航未收缩，不进行其他操作
          return false
        } else if (this.inside === true) {
          // 导航收缩了，让导航恢复
          this.hover = true
        }
      },
      /**
       * 鼠标离开在左侧导航目录内
       * */
      onMouseLeaveNav() {
        const self = this
        if (this.inside === true) {
          // 导航理应是收缩状态，所以要恢复
          self._timer = setTimeout(function() {
            self.hover = false
          }, 250)
        }
      },
      /**
       * 鼠标悬浮在主导航目录
       * */
      onMouseEnterChilNav(item) {
        if (item.children && item.children.length > 0) {
          clearTimeout(this._timer)
          this.navChildData = item
          this.showChildNav = true
        } else {
          this.navChildData = {}
          this.showChildNav = false
        }
      },
      /**
       * 鼠标点击主导航目录
       * */
      clickMainNavItem() {
        // if (item.path) this.$router.push(item.path)
        // this.showChildNav = false
      },
      /**
       * 进入子级导航目录
       * */
      onMouseEnterChildNav() {
        clearTimeout(this._timer)
      },
      /**
       * 离开子级导航目录
       * */
      onMouseLeaveChildNav() {
        const self = this
        if (this.inside === true) {
          self._timer = setTimeout(function() {
            self.showChildNav = false
            self.hover = false
          }, 250)
        } else {
          self._timer = setTimeout(function() {
            self.showChildNav = false
          }, 250)
        }
      },
      /**
       * 通过 eventBus 接收子导航目录的关闭请求
       * */
      closeChildNav() {
        const self = this
        this.$eventBus.$on('closeNav', () => {
          self.showChildNav = false
          if (self.inside === true) {
            self.hover = false
          }
        })
      },
      /**
       * 计算显示的导航菜单
       * */
      showRouteList() {
      },
      /**
       * 展开右侧抽屉
       * */
      showRightDrawer() {
        this.drawerShow = !this.drawerShow
      },
      /**
       * 关闭右侧抽屉
       * */
      onClose() {
        this.drawerShow = false
      },
      /**
       * 获取当前处于激活状态的导航目录
       * */
      activeNavMenu() {
        this.activeMenu = this.$route.fullPath.split('/')[2]
      }
    }
  }
</script>

<style lang="less" scoped>
  @import '~ant-design-vue/lib/style/themes/default.less';
  .custom-layout{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    .custom-layout-side{
      position: relative;
      width: 200px;
      min-width: 200px;
      height: 100%;
      background-color: rgba(75, 86, 114, 1);
      color: #ffffff;
      z-index: 25;
      .custom-layout-side-normal{
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
      }
      .custom-layout-side-hover{
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        height: 100%;
        display: flex;
        flex-direction: column;
        background-color: rgba(75, 86, 114, 1);
        transition: 0.35s;
        opacity: 0;
        visibility: hidden;
        z-index: 0;
      }
      .custom-layout-side-hover-show{
        opacity: 1;
        visibility: visible;
        z-index: 20;
      }
      .custom-layout-side-header{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 4px 5px;
        .custom-layout-side-header-img{
          width: 28px;
          height: 28px;
        }
        .custom-layout-side-header-text{
          font-size: @largeFontSize;
          padding: 0;
          margin: 0 5px;
        }
      }
      .custom-layout-side-nav{
        position: relative;
        overflow: hidden;
        width: 100%;
        margin-top: 15px;
        flex: 1;
        box-sizing: border-box;
        .custom-layout-side-nav-ul{
          position: relative;
          overflow: hidden;
          overflow-y: auto;
          width: 100%;
          height: 100%;
          min-height: 660px;
          padding: 0;
          margin: 0;
          list-style-type: none;
          .custom-layout-side-nav-ul-list{
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .nav-ul-list-box{
              background: none;
              flex: 1;
              margin: 4px 5px;
              height: 42px;
              display: flex;
              align-items: center;
              justify-content: flex-start;
              border-radius: 6px;
              .ul-list-icon{
                font-size: 20px;
                margin: 0 10px 0 15px;
              }
              .ul-list-p{
                font-size: @largeFontSize;
                flex: 1;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
              }
              p {
                margin: 0;
              }
              &:hover{
                background-color: @primary-color;
              }
            }
            .nav-ul-list-box-active{
              background-color: @primary-color;
            }
          }
          .list-insideStyle{
            justify-content: center;
            .ul-list-icon{
              font-size: 20px;
              margin: 0 10px 0 0;
            }
          }
          .nav-active{
            background-color: @primary-color;
          }
        }
      }
      .custom-layout-side-footer{
        position: relative;
        width: 100%;
        height: 40px;
        cursor: pointer;
        .trigger{
          font-size: 18px;
          line-height: 40px;
          padding: 0 21px;
          cursor: pointer;
          transition: color 0.3s;
          &:hover {
            color: #1890ff;
          }
        }
      }
      .custom-layout-side-children{
        position: fixed;
        top: 0;
        left: 200px;
        width: auto;
        height: 100vh;
        z-index: 100;
      }
    }
    .insideStyle{
      width: 70px;
      min-width: 70px;
    }
    .custom-layout-content{
      position: relative;
      width: ~"calc(100% - 48px)";
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
      .custom-layout-content-header{
        position: relative;
        width: 100%;
        height: 49px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-shadow: 0 5px 5px #f5f5f5;
        border-bottom: 1px solid #F6F7F8;
        z-index: 15;
      }
      .custom-layout-content-main{
        position: relative;
        overflow: hidden;
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        .custom-layout-content-main-breadcrumb{
          position: relative;
          width: 100%;
          height: 45px;
          box-shadow: 0 3px 3px #DFE3E9;
          z-index: 5;
        }
        .custom-layout-content-main-view{
          position: relative;
          overflow: hidden;
          overflow-y: auto;
          flex: 1;
        }
      }
    }
  }
  .custom-layout .drawer-show-button-normal{
    position: absolute;
    top: 240px;
    right: 5px;
    z-index: 500;
    transition: 0.3s;
    button {
      width: 45px;
      height: 45px;
      padding: 0 0 2px 0;
    }
  }
  .custom-layout .drawer-show-button-active{
    position: absolute;
    top: 240px;
    right: 305px;
    z-index: 1005;
    transition: 0.3s;
    button {
      width: 45px;
      height: 45px;
      padding: 0 0 2px 0;
    }
  }
  .scroller {
    overflow-y: scroll;
    scrollbar-color: transparent transparent;
    scrollbar-track-color: transparent;
    -ms-scrollbar-track-color: transparent;
  }
</style>
