import "./TplStyle.css";

export default {
  name: 'TabsPages',
    model: {
        prop: 'activeKey', // 自定义属性名
        event: 'onChange' // 自定义事件名
    },
    props: {
      type:{
        default: 'line'
      },
      animated:{
        type: Boolean,
        default: false
      },
      activeKey: {
          default: 0
      },
      forceRender:{
        type: Boolean,
        default: true
      }
    },
    data() {
        return {
            tabsAcitveIndex: null
        }
    },
  methods: {
    setTabPane(data) {
      const tabPaneArr = [];
      if(!data || !data.length) return
      data.forEach((item, index) => {
        if (item.data && item.data.attrs) {
            if (item.data.attrs.disabledState=="fisable"){
                tabPaneArr.push(
                    <a-tab-pane key={index} tab={item.data.attrs.tabName || ("tab" + index)} disabled>
                        {item}
                    </a-tab-pane>
                );
            }else
                {
                tabPaneArr.push(
                    <a-tab-pane key={index} tab={item.data.attrs.tabName || ("tab" + index)}>
                        {item}
                    </a-tab-pane>
                );
            }

        }
      });
      return tabPaneArr;
    }
  },
  watch: {
    activeKey() {
      this.tabsAcitveIndex = this.activeKey
    }
  },
  created() {
    this.tabsAcitveIndex = this.activeKey
  },
  render() {
    const callback = (val) => {
        this.tabsAcitveIndex =  val
        this.$emit('onTabChange', val)
        this.$emit('tabChange', val)
    };
    return (
      <div class="tabs-pages">
        <a-tabs type={'card'} animated={false}  onChange={callback} activeKey={this.tabsAcitveIndex} forceRender={this.forceRender}>
          {this.setTabPane(this.$slots.tabPane)}
        </a-tabs>
      </div>
    );
  }
};
