<template>
  <div>
    <a-input-number
      placeholder='请输入'
      :min='0'
      :precision='0'
      v-model='value1'
      allowClear
      style='width: 80px;'
      @change='change'
    />
    至
    <a-input-number
      placeholder='请输入'
      :min='0'
      :precision='0'
      v-model='value2'
      allowClear
      style='width: 80px;'
      @change='change'
    />
    截止日期
    <a-date-picker
      allowClear
      @change='change'
      @panelChange='change'
      :format="['YYYY-MM-DD']"
      v-model='value3'
      type='date'
      placeholder='请选择时间'
      style='width: 35%;'
      value-format="YYYY-MM-DD"
    />
  </div>
</template>

<script>
export default {
  name: 'ComCustomQueryInputNumber',
  props: {
    defaultValue: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({
        value: [],
        label: ''
      })
    }
  },
  data () {
    return {
      value1: '',
      value2: '',
      value3: ''
    }
  },
  watch: {
    value: {
      handler  (n) {
        const value = Array.isArray(n.value) ? n.value : [,,n.value]
        const [value1 = '', value2 = '', value3 = ''] = value
        Object.assign(this, { value1, value2, value3 })
      },
      immediate: true
    }
  },
  methods: {
    change (e) {
      this.$emit('change', {
        value: [this.value1, this.value2, this.value3],
        label: this.value3
      })
    }
  }
}
</script>