import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import {req} from "../../../api";
import {downFile} from "../../../utils/file/downFile";
import {getPermission} from 'common/utils/directivers/permission'

export default {
    props: {
        DelePmt: {
            type: String,
            default: 'null'
        },
        DownLoadPmt: {
            type: String,
            default: 'null'
        },
        // 是否需要密级设置
        isNeedLevel: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            zhCN,
            showUploadList: {
                showPreviewIcon: true,
                showRemoveIcon: true,
                showDownloadIcon: false
            },
            singleFile: {},
            rules: {
                level: [{required: true, message: '请选择密级!', trigger: 'change'}]
            },
            fileList: [ // 上传文件列表
                // {
                //     uid: '-1',
                //     name: 'image.png',
                //     status: 'done',
                //     level: '公开',
                //     isSelect: false,
                //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     originFileObj: {
                //         level: '公开',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     }
                // },
                // {
                //     uid: '-2',
                //     name: 'image.png',
                //     status: 'done',
                //     level: '公开',
                //     isSelect: false,
                //     url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     originFileObj: {
                //         level: '公开',
                //         url: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
                //     }
                // }
            ],
            previewVisible: false, // 预览对话框
            selfilelelVisible: false, // 选择文件级别
            previewImage: '', // 预览问价地址
            tempFileList: [],
            SelectItems: [], // 批量删除选中的数据
            renameVisible: false, // 重命名

            dialogVisible: false, // 显示预览-图片
            pdfdialogVisible: false, // pdf
            pdfViewerSrc: "",
            pdfViewerTitle: "",
            mimeType: "",
            PriviewStartIndex: 0, //预览index
            StorageKey: "_ImgViewSrcCache",
            dialogTitle: "", // 预览标题
            singlefid: ''
        }
    },
    computed: {
        // 是否删除权限
        DeletePermit() {
            if (this.DelePmt !== 'null') {
                return getPermission(this.DelePmt)
            }
            return true
        },
        // 是否有下载权限
        DownLoadPermit() {
            if (this.DownLoadPmt !== 'null') {
                return getPermission(this.DownLoadPmt)
            }
            return true
        }
    },
    methods: {
        // 判断是否图片
        isImgType: function (filetype) {
            let ctypeArr = [
                "png",
                "image",
                "jpeg",
                "jpg",
                "gif",
                "tiff",
                "x-icon",
                "x-bmp",
                "jiff",
            ];

            if (ctypeArr.indexOf(filetype) >= 0) {
                return true;
            } else {
                return false;
            }
        },
        // 判断是可做PDF预览的 .doc/.docx之类
        isCanPreviewPDFType: function (filetype) {
            let ctypeArr = [
                "application/pdf", //.pdf
                "application/msword", //.doc|.docx
                "application/vnd.ms-excel", //.xls|.xlsx
                "application/vnd.ms-powerpoint", //ppt
                // "text/plain" //.txt
            ];
            if (ctypeArr.indexOf(filetype) >= 0) {
                return true;
            } else {
                return false;
            }
        },
        // 判断文件列表是否存在过
        fileListContainId: function (id) {
            // 判断指定ID是否在fileList里面
            let that = this;
            for (let i = 0; i < that.fileList.length; i++) {
                if (that.fileList[i].fid === id) {
                    return true;
                }
            }
            return false;
        },
        // 加载附件
        LoadData(ids) {
            const that = this
            let idArr = []
            if(typeof ids === 'string') {
              idArr = ids.split(",");
            } else {
              idArr = ids
            }
            let loadIds = [];
            // 先判断id 是否加载过了
            idArr.forEach(function (id) {
                if (!that.fileListContainId(id)) {
                    loadIds.push(id);
                }
            });
            // http://localhost:7000/dev-api/sym/customtableF/findCustomeList
            if (loadIds.length > 0) {
                loadIds.forEach(item => {
                    const ishasLoad = this.fileList.findIndex(idItem => idItem.fid===item)
                    if (item && ishasLoad===-1){
                        req('post_common_upload_getfile', {fid: item}).then(res => {
                            if (res.code === 200) {
                                if (res.data === null) { return  }
                                // newFileList.splice(index, 1);
                                // this.fileList = newFileList;
                                const fileInfo = res.data
                                fileInfo.secretlevel = String(fileInfo.secretlevel)
                                if (fileInfo === null) {
                                    return
                                }
                                const baseUrl = process.env.VUE_APP_BASE_API
                                // let url = (fileInfo.filetype === 'jpg' || fileInfo.filetype === 'png') ? `${baseUrl}/platservice/fileupload/previewImage?fid=${fileInfo.fid}` : null
                                let url = that.isImgType(fileInfo.filetype) ? `${baseUrl}/platservice/fileupload/previewImage?fid=${fileInfo.fid}` : `${baseUrl}/platservice/fileupload/download?ids=${fileInfo.fid}`
                                const fileObj = {
                                    uid: fileInfo.fid,
                                    fid: fileInfo.fid,
                                    name: fileInfo.filename,
                                    status: 'done',
                                    level: fileInfo.secretlevel,
                                    isSelect: false,
                                    url: url,
                                    thumbUrl: that.isImgType(fileInfo.filetype) ? url : null,
                                    originFileObj: {
                                        level: fileInfo.secretlevel,
                                        url: url,
                                    },
                                    size: fileInfo.filesize,
                                    filetype: fileInfo.filetype
                                }

                                if (this.isImgType(fileObj.filetype)) {
                                    this.AddSrcCache(fileObj); //添加到预览缓存
                                }
                                this.fileList.push(fileObj)
                            }
                        })
                    }
                })

            }
        },
        // 文件预览
        async handlePreview(file) {
            if (this.isImgType(file.filetype)) {
                // if (!file.url && !file.preview) {
                //     file.preview = await getBase64(file.originFileObj);
                // }
                // this.previewImage = file.url || file.preview;
                // this.previewVisible = true


                console.log(file)
                this.dialogVisible = true;
                this.PriviewStartIndex = this.GetIndexByCache(file.fid);


            } else if (file.filetype === 'pdf') {
                console.log(file)

                this.singlefid = file.fid
                // const url = '/platservice/fileupload/download'
                // downFile(url, {ids: file.fid})
                if (file.level === 1) {
                    this.handlePreviewPdf(file.url, "application/pdf", file.name);
                } else {
                    this.$message.warning('保密文件不支持在线预览!')
                }
            } else {
                this.$confirm({
                    title: '当前文件非PDF，不支持在线预览，请下载后查看',
                    content: '',
                    okText: '下载',
                    cancelText: '取消',
                    icon: 'null',
                    onOk() {
                        return new Promise((resolve, reject) => {
                            const url = '/platservice/fileupload/download'
                            downFile(url, {ids: file.fid})
                            done()
                        }).catch(() => console.log('Oops errors!'));
                    },
                    onCancel() {},
                });
            }
        },
        // 选定密级之后直接上传
        handleSelLel(obj) {
            this.fileList.forEach(item => {
                if (item.uid === obj.file.uid) {
                    item.level = obj.file.level
                }
            })
            console.log(obj)
            this.handleUpload(obj)
        },
        handleRenamed(file) {
            console.log(file)
            this.fileList.forEach(item => {
                if (item.uid === file.file.uid) {
                    item.name = file.file.name
                }
            })
            this.reNamePost(file)
        },
        reNamePost(file) {
            const formData = {
                fid: file.file.fid,
                newFileName: file.file.name
            }
            req('post_common_filerename', formData, true).then(res => {
                if (res.code === 200) {
                    this.$message.success(res.message);
                    this.$emit('fileList', this.fileList)
                    this.tempFileList = []
                } else {
                    this.$message.error('上传失败');
                }
            })
        },
        handleUpload(file) {
            const formData = new FormData();
            formData.append('businessId', null);
            formData.append('file', file.singleFile);
            formData.append('secretLevel', file.singleFile.level);
            req('file_common_upload', formData, true).then(res => {
                if (res.code === 200) {
                    const baseUrl = process.env.VUE_APP_BASE_API
                    this.$message.success('上传成功');
                    // this.fileList = [...this.fileList, file.file];
                    console.log(this.tempFileList)
                    this.tempFileList.forEach(item => {
                        item['status'] = 'done'
                        item['isSelect'] = item.isSelect === undefined ? false : item.isSelect
                        if (item.uid === file.singleFile.uid) {
                            item.level = file.singleFile.level
                            item.fid = res.data.uploadFileResult.fid
                            item.filetype = res.data.uploadFileResult.fileType
                            item.url = this.isImgType(res.data.uploadFileResult.fileType) ? `${baseUrl}/platservice/fileupload/previewImage?fid=${res.data.uploadFileResult.fid}` : `${baseUrl}/platservice/fileupload/download?ids=${res.data.uploadFileResult.fid}`
                            if (this.isImgType(item.filetype)) {
                                this.AddSrcCache(item); //添加到预览缓存
                            }
                        }
                    })
                    this.fileList = this.tempFileList
                    const idS = []
                    this.$emit('fileList', this.fileList)
                    this.fileList.forEach(item => {
                        idS.push(item.fid)
                    })
                    this.$emit('update:ids', idS.join(','))
                    this.$emit('uploadSuccess', res)
                    if (this.LevelfileList && this.LevelfileList.length<2){
                        this.tempFileList = []
                    }
                } else {
                    this.$message.error('上传失败');
                }
            })
        },
        //预览PDF
        handlePreviewPdf(url, mimeType = "application/pdf", title = "") {
            let that = this;
            if (that.pdfdialogVisible) {
                // console.log("已经打开了 先关闭 0.2后打开");
                that.pdfdialogVisible = false;
                that.pdfViewerSrc = "";
                setTimeout(() => {
                    //不这么搞是不能重复打开的.by Z.C
                    that.pdfdialogVisible = true;
                    that.pdfViewerSrc = url;
                    that.mimeType = mimeType;
                    that.pdfViewerTitle = title;
                }, 200);
            } else {
                that.pdfdialogVisible = true;
                that.pdfViewerSrc = url;
                that.mimeType = mimeType;
                that.pdfViewerTitle = title;
            }
        },
        // 关闭图片预览
        closeViewer() {
            this.dialogVisible = false;
        },
        //关闭PDF预览
        closePdfViewer() {
            this.pdfdialogVisible = false;
            this.pdfViewerSrc = "";
        },

        //添加到预览缓存组中
        AddSrcCache: function (item) {
            let id = item.fid;
            let src = item.url;
            let that = this;
            let cacheData = JSON.parse(sessionStorage.getItem(that.StorageKey));
            if (!cacheData) {
                cacheData = new Object();
                cacheData[that.group] = {};
            }
            //保存
            let obj = cacheData[that.group];
            if (!obj) {
                obj = new Object();
            }
            //判斷存在不
            obj[id] = {src: src, title: item.name};
            cacheData[that.group] = obj;
            sessionStorage.setItem(that.StorageKey, JSON.stringify(cacheData));
        },
        RemoveSrcCache: function (item) {
            let id = item.id;
            let src = item.orgurl;
            let that = this;
            for (let a in that.fileList) {
                if (that.fileList[a].id == item.id) {
                    that.fileList.splice(a, 1);
                    return false;
                }
            }

            let cacheData = JSON.parse(sessionStorage.getItem(that.StorageKey));
            if (cacheData) {
                let obj = cacheData[that.group];
                if (obj) {
                    if (obj[id]) {
                        delete obj[id];
                        cacheData[that.group] = obj;
                        sessionStorage.setItem(that.StorageKey, JSON.stringify(cacheData));
                    }
                }
            }
        },
        //获取地址所在index
        GetIndexByCache: function (id) {
            let that = this;
            let cacheData = JSON.parse(sessionStorage.getItem(that.StorageKey));
            if (cacheData) {
                let obj = cacheData[that.group];
                if (obj) {
                    let i = 0;
                    for (let key in obj) {
                        if (key == id) {
                            return i;
                        }
                        i++;
                    }
                    // for (let i = 0; i < obj.length; i++) {
                    //   if (obj.id == id) {
                    //     return i;
                    //   }
                    // }
                }
            }
            return -1;
        },
        //获取当前分组的缓存列表
        GetSrcListByCache: function () {
            let that = this;
            let list = [];
            let titleArr = [];
            let cacheData = JSON.parse(sessionStorage.getItem(that.StorageKey));
            if (cacheData) {
                let obj = cacheData[that.group];
                if (obj) {
                    for (let a in obj) {
                        list.push(obj[a].src);
                        titleArr.push(obj[a].title);
                    }
                }
            }
            this.dialogTitle = titleArr;
            return list;
        },

        // 关闭文件预览
        handleCancel() {
            this.previewVisible = false;
        },
        handleRename(file) {
            console.log(file)
            this.singleFile = file
            this.renameVisible = true
        },
        handleSelect(file) {
            if (this.batchDeleteAble) {
                const fileIndex = this.fileList.findIndex(item => item.uid === file.uid)
                this.fileList[fileIndex].isSelect = !this.fileList[fileIndex].isSelect
                const selIndex = this.SelectItems.findIndex(item => item.uid === file.uid)
                if (this.SelectItems.length > 0 && selIndex !== -1) {
                    this.SelectItems.splice(selIndex, 1)
                } else {
                    this.SelectItems.push(file)
                }
            }
        },
        // 选择文件发生改变时的钩子
        handleChange({fileList}) {
            // this.fileList = fileList;
            const index = fileList.length - 1
            if (index > (this.fileList.length - 1)) {
                fileList[index]['isSelect'] = false
            }
            this.tempFileList = fileList
        },
        // 文件移除方法
        handleRemove(file) {
            // const index = this.fileList.indexOf(file);
            // const newFileList = this.fileList;
            this.handleBatchDelete(file.fid, [file.fid])
            // req('post_common_upload_delete', {fid:newFileList[index].fid}).then(res => {
            //     if (res.code === 200) {
            //         newFileList.splice(index, 1);
            //         this.fileList = newFileList;
            //         this.$message.success('删除成功!');
            //     } else {
            //         this.$message.error('删除失败!');
            //     }
            // })
        },
        // 批量删除
        handleBatchDel() {
            const ids = []
            this.SelectItems.forEach(item => {
                ids.push(item.fid)
            })
            const idsString = ids.join(',')
            this.handleBatchDelete(idsString, ids)
            this.SelectItems = []
        },
        handleBatchDelete(ids, idsArr) {
            const newFileList = this.fileList
            req('post_common_upload_batchdelete', {fids: ids}).then(res => {
                if (res.code === 200) {
                    idsArr.forEach(fid => {
                        newFileList.forEach((item, index) => {
                            if (fid === item.fid) {
                                newFileList.splice(index, 1)
                            }
                        })
                    })
                    this.fileList = newFileList;
                    const ids = this.fileList.map(item => {
                        return item.fid
                    }).join(',')
                    this.$emit('update:ids', ids)
                    this.$emit('fileList', this.fileList)
                    this.$emit('delEvnt', res)
                    this.$message.success('删除成功!');
                } else {
                    this.$message.error('删除失败!');
                }
            })
        }
    }
}
