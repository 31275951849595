const files = require.context("./", true, /^\.\/router_[^/]+\.js$/);
// 临时使用 做排序处理
const sortMenu = ["subapp_apm", "subapp_sym", "subapp_fzzyk", "subapp_dxalk"]
let asyncRouterMap = {};
files.keys().forEach(key => {
    // asyncRouterMap = asyncRouterMap.concat(files(key).default)
    // asyncRouterMap[key]
    var sysName = key.match(/router_([^.]+)/);
    asyncRouterMap["subapp_" + sysName[1]] = files(key).default;
});

const getMenuData = (sysName) => {
    if (sysName === "portal") {
        var portalMenu = [];
        // Object.keys(asyncRouterMap).forEach(item => {
        //     portalMenu.push(asyncRouterMap[item]);
        // });
        sortMenu.forEach(item => {
            if (asyncRouterMap[item]) {
                portalMenu.push(asyncRouterMap[item]);
            }
        });
        // console.log(portalMenu)
        return portalMenu;
    }
    return [asyncRouterMap[sysName]];
};
export default getMenuData;
