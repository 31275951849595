import ComVirtualTree from './ComVirtualTree';
export default {
  props: {
    enableVirtualTree: {
      type: Boolean,
      default: false
    },
    height: {
      type: String | Number
    },
    iconType: {
      default: () => ({})
    },
    expandMeth: {
      type: Boolean,
      default: false
    },
    // ant自带属性
    blockNode: false,
    treeData: {
      default: []
    },
    autoExpandParent: {
      default: false
    },
    checkable: Boolean,
    checkedKeys: {
      default: () => []
    },
    checkStrictly: {
      default: false
    },
    defaultCheckedKeys: {
      default: () => []
    },
    defaultExpandAll: {
      default: false
    },
    defaultExpandedKeys: {
      default: () => []
    },
    defaultExpandParent: {
      default: true
    },
    defaultSelectedKeys: {
      default: () => []
    },
    disabled: {
      default: false
    },
    draggable: {
      default: false
    },
    expandedKeys: {
      default: () => []
    },
    filterTreeNode: {
      default: () => {}
    },
    loadData: {
      default: () => {}
    },
    loadedKeys: {
      default: () => []
    },
    multiple: {
      default: false
    },
    selectable: {
      default: true
    },
    selectedKeys: {
      default: () => []
    },
    showIcon: {
      default: true
    },
    switcherIcon: {
      default: ''
    },
    showLine: {
      default: false
    },
    defaultIcon: Boolean,
    typeField: {
      default: 'type'
    },
    replaceFields: {
      default: () => ({children: 'children', title: 'title', key: 'key'})
    }
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    // 设置树形图图标
    setIcon(item) {
      const type = item[this.typeField]
      if (this.iconType[type]) {
        return this.iconType[type]
      } else if (item.icon) {
        return item.icon
      } else {
        return ''
      }
    },
    renderRecursion(data) {
      var children = []
      data.forEach(el => {
        children.push(this.renderTreeNode(el))
      })
      return children
    },
    renderTreeNode(data) {
      var childrenEl = []
      if (data.children && data.children.length) {
        if (this.defaultIcon) {
          data[this.typeField] = 'folder'
        }
        data.children.forEach(el => {
          childrenEl.push(this.renderTreeNode(el))
        })
      } else {
        if (this.defaultIcon) {
          data[this.typeField] = 'datasheet'
        }
        // 此处2021.3.18 解决 组织机构树懒加载不显示折叠按钮
        // childrenEl.push(<span slot='switcherIcon'></span>)
      }
      const title = data[this.replaceFields['title'] || 'title']
      const key = data[this.replaceFields['key'] || 'key']
      const selectable = data[this.replaceFields['selectable'] || 'selectable']
      const getNode = (event) => {
        console.log(event)
      }
      return (<a-tree-node is-leaf={data.isLeaf} title={title} key={key} selectable={selectable} dataRef={data}>
        {
          this.setIcon(data)
            ? <i slot={'icon'} class={'iconhr' + ' ' + this.setIcon(data)}></i>
            : ''
        }
        { childrenEl }
      </a-tree-node>)
    },
    renderTree(data) {
      var treeNode = []
      data.forEach(item => {
        treeNode.push(this.renderTreeNode(item))
      })
      return treeNode
    },
    // onIonEx(e) {
    //   console.log(1)
    // },
    // antd 自带事件
    check(checkedKeys, e) {
      this.$emit('check', checkedKeys, e)
    },
    dragend(e) {
      this.$emit('dragend', e)
    },
    dragenter(e) {
      this.$emit('dragenter', e)
    },
    dragleave(e) {
      this.$emit('dragleave', e)
    },
    dragover(e) {
      this.$emit('dragover', e)
    },
    dragstart(e) {
      this.$emit('dragstart', e)
    },
    drop(e) {
      this.$emit('drop', e)
    },
    expand(expandedKeys, e) {
      // if (!this.expandMeth) {
      //   if (e.nativeEvent.target.localName === "path" || e.nativeEvent.target.localName === "svg") {
      //     this.$emit('expand', expandedKeys, e)
      //     this.$emit('update:expandedKeys', expandedKeys)
      //   } else if(e.nativeEvent.target.localName === "span") {
      //       if(e.nativeEvent.target.className === "ant-tree-switcher ant-tree-switcher_open" || e.nativeEvent.target.className === "ant-tree-switcher ant-tree-switcher_close") {
      //           console.log(1)
      //           this.$emit('expand', expandedKeys, e)
      //           this.$emit('update:expandedKeys', expandedKeys)
      //       }
      //   }
      // } else {
        this.$emit('expand', expandedKeys, e)
        this.$emit('update:expandedKeys', expandedKeys)
      // }
    },
    load(loadedKeys, e) {
      this.$emit('load', loadedKeys, e)
    },
    rightClick(e) {
      this.$emit('rightClick', e)
    },
    select(selectedKeys, e) {
      this.$emit('update:selectedKeys', selectedKeys, e)
      this.$emit('onSelect', selectedKeys, e)
      this.$emit('select', selectedKeys, e)
    }
  },
  render() {
    const { check, dragend, dragenter, dragleave, dragover, dragstart, drop, expand, load, rightClick, select } = this;
    const {
      blockNode,
      expandedKeys,
      replaceFields,
      autoExpandParent,
      checkable,
      checkedKeys,
      checkStrictly,
      defaultCheckedKeys,
      defaultExpandAll,
      defaultExpandedKeys,
      defaultExpandParent,
      defaultSelectedKeys,
      disabled,
      draggable,
      filterTreeNode,
      loadData,
      loadedKeys,
      multiple,
      selectable,
      selectedKeys,
      showIcon,
      switcherIcon,
      showLine
    } = this;
    const props = {
      multiple,
      defaultExpandAll,
      expandedKeys,
      blockNode,
      replaceFields,
      autoExpandParent,
      checkable,
      checkedKeys,
      checkStrictly,
      defaultCheckedKeys,
      defaultExpandedKeys,
      defaultExpandParent,
      defaultSelectedKeys,
      disabled,
      draggable,
      filterTreeNode,
      loadData,
      loadedKeys,
      selectable,
      selectedKeys,
      showIcon,
      switcherIcon,
      showLine
    };
    // 清除没必要的传参
    Object.keys(props).forEach(key => {
      var prop = props[key]
      // if (Array.isArray(prop) && prop.length === 0) {
      //   delete props[key]
      // }
      if (prop === '') {
        delete props[key]
      }
    })
    const on = {
      check, dragend, dragenter, dragleave, dragover, dragstart, drop, expand, load, rightClick, select
    }
    const treeNode = this.renderTree(this.treeData)
    if(multiple) {
      return (<a-tree {...{ props, on }}
      >
          {treeNode}
      </a-tree>)
    }
    return (<a-directory-tree {...{ props, on }}
    >{treeNode}</a-directory-tree>)
  }
}
