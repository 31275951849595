import './virtualTree.less';
import getDefaultProps from 'common/confing/defaultProps';
import { cloneDeep } from 'lodash';

// 树每个节点高度
const HEIGHT = 32;
const keysWatchHandler = function (key) {
  return function (nv, ov) {
    if (nv instanceof Array) {
      if (!ov && this[key].length) nv = this[key];
      this[key] = nv;
    }
  };
};
// 从map对象里获取部分属性
const getMapProps = (map, type) => {
  return Array.from(map[type]());
};
const getValues = map => {
  return getMapProps(map, 'values');
};
const getKeys = map => {
  return getMapProps(map, 'keys');
};
const getEntries = map => {
  return getMapProps(map, 'entries');
};
export default {
  inheritAttrs: true,
  model: {
    prop: 'checkedKeys',
    event: 'check'
  },
  props: Object.assign(
    {},
    getDefaultProps('tree'),
    // custom props
    {
      keywords: {
        type: String,
        default: ''
      },
      height: {
        type: String,
        default: '100%'
      },
      iconType: {
        default: () => ({
          '1': 'ico-jianzhu03',
          'A': 'ico-danwei',
          '2': 'ico-renyuanjieshao'
        })
      },
      typeField: {
        default: 'dwtype'
      },
      defaultIcon: {
        type: Boolean,
        default: false
      },
      // 点击label是否展开节点
      isParentNodeClickExpandable: {
        type: Boolean,
        default: false
      },
      // 父节点是否可选
      isParentNodeSelectable: {
        type: Boolean,
        default: false
      },
      // 父节点是否展示checkbox
      isParentNodeCheckable: {
        type: Boolean,
        default: false
      },
      // 父节点是否可用
      isParentNodeDisable: {
        type: Boolean,
        default: false
      },
      // 请求地址mapping
      searchRequest: {
        type: String,
        default: 'post_orgtree_data'
      },
      // 请求参数
      searchParams: {
        type: Object,
        default: () => ({})
      },
      searchHandler: {
        type: Function,
        default: (res) => res
      }
    }
  ),
  data() {
    return {
      y: 0,
      // id 到 节点 的映射
      dataIdMap: new Map(),
      // id 到 父节点 的映射
      parentMap: new Map(),
      // id 到 子节点的映射
      childrenMap: new Map(),
      // 展开的节点
      treeExpandedKeys: [],
      // 勾选的节点
      treeCheckedKeys: [],
      // 选中的节点
      treeSelectedKeys: [],
      // 可见节点的 keys
      visibleDataKeys: [],
      // 当前从哪个节点开始渲染
      cur: 0,
      // 渲染节点数量
      size: 50,
      // 唯一class
      cls: `tree-${new Date().getTime() + Math.floor(Math.random() * 10)}`,
      // 撑开的高度
      spaceHeight: 0,
      // 是否显示搜索结果
      isSearch: false,
      // 搜索状态
      searchFetching: false,
      // 搜索结果
      searchData: []
    };
  },
  computed: {
    _loadData () {
      return this.isSearch? null : this.loadData
    },
    // 绑定的props
    _bindProps() {
      const props = {
        ...this.$props,
        ...this.$attrs,
        autoExpandParent: false,
        defaultExpandParent: false,
        loadData: this._loadData,
        selectedKeys: this.treeSelectedKeys,
        expandedKeys: this.treeExpandedKeys,
        checkedKeys: this.treeCheckedKeys
      };
      delete props.treeData;
      return props;
    },
    // 绑定的事件
    _bindEvents() {
      return {
        check: this.check,
        dragend: this.dragend,
        dragenter: this.dragenter,
        dragleave: this.dragleave,
        dragover: this.dragover,
        dragstart: this.dragstart,
        drop: this.drop,
        expand: this.expand,
        load: this.load,
        rightClick: this.rightClick,
        select: this.select
      };
    },
    // node prop mapping
    _class() {
      return this.replaceFields.class || 'class';
    },
    _style() {
      return this.replaceFields.style || 'style';
    },
    _checkable() {
      return this.replaceFields.checkable || 'checkable';
    },
    _disableCheckbox() {
      return this.replaceFields.disableCheckbox || 'disableCheckbox';
    },
    _disabled() {
      return this.replaceFields.disabled || 'disabled';
    },
    _isLeaf() {
      return this.replaceFields.isLeaf || 'isLeaf';
    },
    _key() {
      return this.replaceFields.key || 'dmcod';
    },
    _selectable() {
      return this.replaceFields.selectable || 'selectable';
    },
    _title() {
      return this.replaceFields.title || 'b0101';
    },
    _children() {
      return this.replaceFields.children || 'children';
    },
    _parentKey() {
      return this.replaceFields.parentKey || 'dmparentcod';
    },
    treeWrapperStyle() {
      return {
        height: this.height
      };
    },
    treeSpaceStyle() {
      return {
        height: `${this.spaceHeight}px`
      };
    },
    treeStyle() {
      return {
        transform: `translateY(${this.y * HEIGHT}px)`
      };
    },
    // 将已展开的树状结构拉平
    flattenTree() {
      const result = [];
      const { _key, _children } = this;
      const helper = list => {
        if (!list || !list.length) return;
        list.forEach(i => {
          const tmpItem = { ...i };
          delete tmpItem[_children];
          result.push(tmpItem);
          if (this.treeExpandedKeys.includes(i[_key])) {
            helper(i[_children]);
          }
        });
      };
      helper(this.isSearch ? this.searchData : this.treeData);

      // 预留所有节点展开时的高度
      this.spaceHeight = HEIGHT * result.length;
      return result;
    },
    // 可视区域数据
    visibleData() {
      const { visibleData, visibleDataKeys } = this.genVisibleData(this.cur);
      this.visibleDataKeys = visibleDataKeys;
      return visibleData;
    }
  },
  methods: {
    // 生成可视区域数据。从computed里抽离出来是为了滚动时计算下一帧的可视数据
    genVisibleData(cur) {
      const {
        size,
        flattenTree,
        dataIdMap,
        parentMap,
        _checkable,
        _disabled,
        _isLeaf,
        _key,
        _selectable,
        _children,
        isParentNodeSelectable,
        isParentNodeCheckable,
        isParentNodeDisable
      } = this;
      const tmpMap = new Map();
      const tmpList = [];
      const keys = [];
      const parentKeys = getValues(parentMap);

      // 没有treeData时返回空数组
      if (!dataIdMap.size) return {
        visibleData: tmpList,
        visibleDataKeys: keys
      };

      // 记录节点id，用于去重
      const idSet = new Set();
      // 计算从cur到cur + size的节点
      // 并且保留节点的祖宗节点
      for (let i = cur; i < cur + size; i++) {
        const item = flattenTree[i];
        // flattenTree来不及计算时跳过
        if (!item) continue;

        // 获取当前节点的所有祖宗节点（包括自身）
        const parents = this.getParentList(item[_key]);
        parents.forEach(item => {
          // 懒加载时会有item为undefined的情况
          // 第二次计算的时候会全部计算进来
          if (!item) return;

          const {
            [_key]: key,
            [_isLeaf]: isLeaf,
            [_children]: children
          } = item;

          // 祖宗节点去重
          if (!idSet.has(key)) {
            idSet.add(key);
            const curNode = Object.assign(cloneDeep(item), { isLeaf: true });
            tmpMap.set(key, curNode);
            // 判断当前节点是否为父节点：item配置isLeaf为true 或者 item配置了不为空的children 或者 parentKeys里有这个节点的key
            const isParent = ([_isLeaf] in item && !isLeaf) || (children && children.length > 0) || parentKeys.includes(key);
            if (isParent) {
              curNode[_isLeaf] = false;
              // 重置children，后续对children赋值
              curNode[_children] = [];

              // 优先使用node配置的selectable、 checkable、 disabled
              if (!item.hasOwnProperty(_selectable)) {
                curNode[_selectable] = isParentNodeSelectable;
              }
              if (!item.hasOwnProperty(_checkable)) {
                curNode[_checkable] = isParentNodeCheckable;
              }
              if (!item.hasOwnProperty(_disabled)) {
                curNode[_disabled] = isParentNodeDisable;
              }
            }
          }
        });
      }
      // 构建可视节点的树结构
      getEntries(tmpMap).forEach(([key, item]) => {
        const parent = this.parentMap.get(key);
        if (!parent || !this.dataIdMap.get(parent)) {
          tmpList.push(item);
          return;
        }
        const tmpParent = tmpMap.get(parent);
        tmpParent[_children] = tmpParent[_children] || [];
        tmpParent[_children].push(item);
      });
      // 重新遍历一遍树节点，为了按顺序拿到节点key
      this.treeTraverseHandler(tmpList, node => {
        keys.push(node[this._key]);
      });
      return {
        visibleData: tmpList,
        visibleDataKeys: keys
      };
    },
    // 生成树结构
    genTree(list) {
      const { _key, _children, parentMap, dataIdMap } = this;
      const result = [];
      list.forEach(node => {
        const { [_key]: key } = node;
        const parentKey = parentMap.get(key);
        const parent = dataIdMap.get(parentKey);
        if (!parentKey || !parent) {
          result.push(node);
          return;
        }
        parent[_children] = parent[_children] || [];
        parent[_children].push(node);
      });
      return result;
    },
    // 树深度优先遍历
    treeTraverseHandler(list, handler) {
      if (!list || !list.length) return;
      handler = handler || function () {};
      const childrenKey = this._children;
      list.forEach(node => {
        const { [childrenKey]: children } = node;
        handler(node);
        if (children && children.length) {
          this.treeTraverseHandler(children, handler);
        }
      });
    },
    // 构建 节点ID映射关系、子节点到父节点、父节点到子节点的映射关系
    genMap(list = this.treeData) {
      this.dataIdMap = new Map();
      this.parentMap = new Map();
      this.childrenMap = new Map();
      const { _key, _children, _parentKey } = this;
      const helper = (list, parent) => {
        list.forEach(node => {
          const {
            [_key]: key,
            [_children]: children,
            [_parentKey]: parentKey
          } = node;
          // parentKey以节点自带的为主
          const p = parentKey || parent || '';
          // 构建id映射关系
          this.dataIdMap.set(key, node);
          // 上层传下来的parentKey
          if (p) {
            const childrenMap = this.childrenMap.get(p) || [];
            childrenMap.push(key);
            // 构建父到子的关系
            this.childrenMap.set(p, childrenMap);
            // 构建子到父的关系
            this.parentMap.set(key, p);
          }
          if (children && children.length) {
            helper(children, key);
          }
        });
      };
      helper(list);
    },
    // 滚动监听
    onScroll(e) {
      const top = e.target.scrollTop;
      const count = Math.floor(top / HEIGHT);
      // 滚动总量超过20个单位
      if (count > 20) {
        const cur = count - 10;
        // 相对滚动不超过10个单位时，不执行下述逻辑
        if (Math.abs(cur - this.cur) < 10) return;
        let sum = 0;
        // 所有节点的key
        const allNodeKeys = this.flattenTree.map(node => node[this._key]);
        // 可视部分节点的key
        const visibleNodeKeys = this.genVisibleData(count - 10).visibleDataKeys;
        // 可视的最后一个节点的key
        const lastVisibleNodeKey = visibleNodeKeys[visibleNodeKeys.length - 1];
        // 计算从第一个节点开始到可视最后一个节点中间隐藏的节点数量
        // 由于滚动过程中，子节点存在时，祖宗节点都会保留，需要通过这个方法来进行计算树的偏移量
        allNodeKeys.findIndex(key => {
          if (!visibleNodeKeys.includes(key)) sum++;
          return key === lastVisibleNodeKey;
        });
        this.cur = cur;
        this.y = sum;
      } else {
        this.cur = 0;
        this.y = 0;
      }
    },
    // 从自身节点开始反向构建树节点列表
    getParentList(value) {
      const parent = this.parentMap.get(value);
      if (parent) {
        return [...this.getParentList(parent), this.dataIdMap.get(value)];
      }
      return [this.dataIdMap.get(value)];
    },
    getIcon(node) {
      const type = node[this.typeField];
      return this.iconType[type] || node.icon || '';
    },
    renderTree() {
      return this.visibleData.map(node => this.renderNode(node));
    },
    strongSearchVal(title) {
      // 给搜索结果加重颜色
      let keywords = this.keywords
      if(keywords && title.indexOf(keywords) > -1) {
        return (<span>
          {title.substr(0, title.indexOf(keywords)) }
          <span style="color: #176DD2">{ keywords }</span>
          { title.substr(title.indexOf(keywords) + keywords.length) }
        </span>)
      }
      return title
    },
    renderNode(node) {
      const {
        defaultIcon,
        typeField,
        _class,
        _style,
        _checkable,
        _disableCheckbox,
        _disabled,
        _isLeaf,
        _key,
        _selectable,
        _title,
        _children
      } = this;
      const {
        [_class]: cls,
        [_style]: style,
        [_checkable]: checkable,
        [_disableCheckbox]: disableCheckbox,
        [_disabled]: disabled,
        [_isLeaf]: isLeaf,
        [_key]: key,
        [_selectable]: selectable,
        [_title]: title,
        [_children]: children
      } = node;
      const childrenEl = [];
      if (children && children.length) {
        if (defaultIcon) {
          node[typeField] = 'folder';
        }
        node[_children].forEach(el => {
          childrenEl.push(this.renderNode(el));
        });
      } else {
        if (defaultIcon) {
          node[typeField] = 'datasheet';
        }
      }
      const icon = this.getIcon(node);
      return (
        <a-tree-node
          class={cls}
          style={style}
          checkable={checkable}
          disableCheckbox={disableCheckbox}
          disabled={disabled}
          isLeaf={isLeaf}
          key={key}
          selectable={selectable}
          dataRef={this.dataIdMap.get(key)}
        >
          {
            icon
              ? <i slot={'icon'} class={'iconhr' + ' ' + icon}></i>
              : null
          }
          <span slot={'title'}>{this.strongSearchVal(title)}</span>
          {childrenEl}
        </a-tree-node>
      );
    },
    renderEmpty() {
      if (this.visibleData.length) return null;
      return <a-skeleton loading={this.searchFetching} active={true}>
        <a-empty />
      </a-skeleton>;
    },
    check(checkedKeys, e) {
        let result = checkedKeys
        if (!this.checkStrictly) {
            const keys = new Set()
            const {childrenMap} = this
            while (checkedKeys.length) {
                const key = checkedKeys.shift()
                if (!key) continue
                keys.add(key)
                const childrenKeys = childrenMap.get(key)
                if (!childrenKeys) continue
                checkedKeys.push(...childrenKeys)
            }
            result = [...keys]
        }
        this.treeCheckedKeys = result;
        this.$emit('check', result, e);
    },
    dragend(e) {
      this.$emit('dragend', e);
    },
    dragenter(e) {
      this.$emit('dragenter', e);
    },
    dragleave(e) {
      this.$emit('dragleave', e);
    },
    dragover(e) {
      this.$emit('dragover', e);
    },
    dragstart(e) {
      this.$emit('dragstart', e);
    },
    drop(e) {
      this.$emit('drop', e);
    },
    expand(expandedKeys, e) {
      const { isParentNodeClickExpandable } = this;
      // 设置不允许展开时 直接返回
      if (
        !isParentNodeClickExpandable &&
          e.nativeEvent.currentTarget && !e.nativeEvent.currentTarget.classList.contains('ant-tree-switcher')
      ) {
        return;
      }
      this.treeExpandedKeys = expandedKeys;
      this.$emit('expand', expandedKeys, e);
      this.$emit('update:expandedKeys', expandedKeys, e);
    },
    load(loadedKeys, e) {
      this.$emit('load', loadedKeys, e);
    },
    rightClick(e) {
      this.$emit('rightClick', e);
    },
    select(selectedKeys, e) {
      console.log(e)
      this.treeSelectedKeys = selectedKeys;
      this.$emit('select', selectedKeys, e);
      this.$emit('update:selectedKeys', selectedKeys, e);
    },
    // 前端搜索title的判断方式
    checkNodeTitle(node) {
      const title = node[this._title];
      return title.indexOf(this.keywords) > -1;
    },
    handleSearch() {
      this.$emit('searchStart');
      this.isSearch = true;
      this.searchFetching = true;
      this.$nextTick(() => {
        // 请求参数覆盖
        const params = Object.keys(this.searchParams).length === 0 ? { b0101: this.keywords } : this.searchParams;
        // loadData用来判断是否异步加载
        // 异步加载时使用searchRequest进行请求
        const searchPromise =
          typeof this.loadData === 'function'
            ? this.$http(this.searchRequest, params)
            : Promise.resolve({
              data: cloneDeep(getValues(this.dataIdMap)).filter(i => this.checkNodeTitle(i)).map(i => {
                delete i.children
                return i
              })
            });
        // 静态、动态搜索统一处理
        searchPromise.then(res => {
          const handlerResult = this.searchHandler(cloneDeep(res)) || res
          // // 重新构建树节点的映射
          this.genMap(handlerResult.data);
          // // 构建搜索节点的树结构
          // console.log(handlerResult.data)
          this.searchData = this.genTree(handlerResult.data);
          // // 把所有父节点展开
          this.treeExpandedKeys = getValues(this.parentMap);
        }).catch(err => {
          console.error(err);
        }).finally(() => {
          this.$nextTick(() => {
            this.$emit('searchEnd');
            this.searchFetching = false;
          });
        });
      });
    },
    outputMethods() {
      return {
        getNodesByKey: key => {
          let exp = 'multi';
          if (typeof key === 'string') {
            key = [key];
            exp = 'single';
          }
          if (!(key instanceof Array)) {
            console.error('Param of getNodesByKey is illegal');
            return;
          }
          const nodes = cloneDeep(key.map(k => this.dataIdMap.get(k)));
          return exp === 'single' ? (nodes[0] || '') : nodes;
        }
      };
    }
  },
  watch: {
    expandedKeys: {
      handler: keysWatchHandler('treeExpandedKeys'),
      immediate: true
    },
    checkedKeys: {
      handler: keysWatchHandler('treeCheckedKeys'),
      immediate: true
    },
    selectedKeys: {
      handler: keysWatchHandler('treeSelectedKeys'),
      immediate: true
    },
    // 对treeData进行监听，构建映射关系
    // 解决异步获取数据、懒加载等失去映射关系的问题
    treeData: {
      handler(nv, ov) {
        this.genMap();
        // 初次加载
        if (nv && !ov) {
          // 默认展开第一级的第一个节点
          if (!this.defaultExpandedKeys.length) {
            this.$set(this.defaultExpandedKeys, 0, nv[0][this._key]);
          }

          if (this.defaultExpandAll) {
            // 默认展开全部节点
            this.treeExpandedKeys = getValues(this.parentMap);
          } else {
            // 展开默认展开节点的祖宗家节点
            if (this.defaultExpandParent || this.autoExpandParent) {
              const keys = new Set([...this.defaultExpandedKeys, ...this.expandedKeys])
              keys.forEach(key => {
                const parentKey = this.parentMap.get(key);
                if (!parentKey) return
                keys.add(parentKey);
              });
              this.treeExpandedKeys = [...keys];
            }
          }
        }
      },
      deep: true,
      immediate: true
    },
    keywords: {
      handler(nv, ov) {
        // 清空keywords的情况
        if (!nv && ov) {
          // 恢复之前展开的节点
          this.treeExpandedKeys = this._treeExpandedKeys;
          this.isSearch = false;
          this.genMap();
          this.$nextTick(() => {
            this.searchData = [];
          });
        } else if (nv) {
          // 缓存搜索前的展开节点
          this._treeExpandedKeys = this.treeExpandedKeys;
          this.handleSearch();
        }
      },
      immediate: true
    }
  },
  render() {
    const nodes = this.renderTree();

    return (
      <div class="com-org-tree">
        <div class="org-tree-con virtual-org-tree">
          <div
            class="ant-tree-wrapper"
            style={this.treeWrapperStyle}
            onScroll={this.onScroll}
          >
            <div
              class="ant-tree-space"
              style={this.treeSpaceStyle}
            />
            {this.renderEmpty()}
            <a-directory-tree
              class="bd-ant-tree"
              {...{ props: this._bindProps, on: this._bindEvents }}
              style={this.treeStyle}
            >
              {nodes}
            </a-directory-tree>
          </div>
        </div>
      </div>
    );
  }
};
