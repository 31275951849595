<template>
  <a-row>
    <a-button
      size='small'
      @click='checkAll'
    >
      全选
    </a-button>
    <a-checkbox-group
      v-model='inValue'
      :options='_optionsMap'
      @change='change'
    />
  </a-row>
</template>

<script>
import { deepClone } from '../'
export default {
  name: 'ComCustomQueryCheckbox',
  props: {
    defaultValue: {
      type: null,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({
        value: [],
        label: ''
      })
    }
  },
  watch: {
    value: {
      handler  (n) {
        this.inValue = n.value
      },
      immediate: true
    }
  },
  data () {
    return {
      inValue: []
    }
  },
  computed: {
    _optionsMap () {
      return deepClone(this.options)
    }
  },
  methods: {
    checkAll () {
      this.inValue = (this.inValue.length === this._optionsMap.length)
        ? [] : this._optionsMap.map(v => v.value)
      this.change(this.inValue)
    },
    change (e) {
      this.$emit('change', { value: e, label: e.join(',') })
    }
  }
}
</script>