import { debounce } from 'lodash'
export default {
  data() {
    return {
      opRefTable: null,
      tableScrollY: this.setScrollY,
      headHeight: 64
    }
  },
  computed: {
    setScrollY() {
      return `calc(100% - ${this.headHeight}px)`
    }
  },
  watch: {
    opRefTable: {
      handler(tbl) {
        if(tbl){
          this.reSizeScrollY()
        }
      }
    }
  },
  created () {
    this.getBodyHeight();
  },
  destroyed() {
    window.removeEventListener('resize',  this.resizeEvent)
    window.removeEventListener('resize', this.reSizeScrollY)
  },
  mounted() {
    this.resizeEvent = debounce(this.getBodyHeight, 100)
    window.addEventListener('resize',  this.resizeEvent)
    window.addEventListener('resize', this.reSizeScrollY)
  },
  methods: {
    getBodyHeight() {
      if(window.innerWidth >= 1920 ) {
        this.headHeight = 64
      } else {
        this.headHeight = 42
      }
    },
    getScrollY: function (ref, a) {
      if(ref) {
        const tableEl = ref.$el
        const tableHeader = tableEl.querySelector('.ant-table-thead');
        if(tableHeader) {
          const headerHeight = tableHeader.clientHeight
          return `calc(100% - ${headerHeight}px)`
        }
        // const tableParentHeight = parseInt(window.getComputedStyle(tableEl.parentElement).height)
        // return tableParentHeight - this.headHeight
      } else{
        return this.setScrollY
      }
    },
    reSizeScrollY () {
      if(this.opRefTable){
        this.tableScrollY = this.getScrollY(this.opRefTable)
      }
    },
  }
};
