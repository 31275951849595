<template>
  <div class="com-trans-wrap">
    <div class="com-trans-left">
      <div class="tabs-right" v-if="type === 'tabCollapse'">
        <div
          class="tabs-btn"
          :class="{'active': tabsAcitveIndex === index}"
          v-for="(item, index) in tabsData"
          :key="index"
          @click="tabsSwitch(index)"
        >{{item.title}}</div>
      </div>
      <div class="trans-header">
        <a-select
          show-search
          placeholder="请输入搜索内容"
          option-filter-prop="children"
          style="width: 228px"
          :value="selectVal"
        >
          <a-icon slot="suffixIcon" type="search" class="select-icon" />
          <a-select-option
            v-for="item in selectData"
            :key="item[replaceFields['key']]"
            :value="item[replaceFields['key']]"
            @click="selectClick(item, $event)"
          >{{item[replaceFields.title]}}</a-select-option>
        </a-select>
      </div>
      <div class="trans-body" :class="{'is-tab': type === 'tabCollapse'}">
        <!-- 2021-01-08 添加 leftParentClick (黎镖) -->
        <collapseList
          v-if="['collapse', 'codeOpt', 'tabCollapse'].includes(type)"
          :dataSource="separateDataSource().leftDataSource"
          :defaultChecked="targetKeys"
          :replaceFields="replaceFields"
          :defaultExpandedKeys="getExpandedNode(separateDataSource().leftDataSource)"
          @checked="leftSelect"
          @leftParentClick="(e) => {$emit('leftParentClick', e)}"
          checkable
        ></collapseList>
        <checkboxList
          v-if="['single'].includes(type)"
          :dataSource="separateDataSource().leftDataSource"
          :checkedName="checkedName"
          :replaceFields="replaceFields"
          :defaultChecked="targetKeys"
          @checked="leftSelect"
        ></checkboxList>
        <a-table
          v-if="type === 'table'"
          :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
          :columns="leftColumns"
          :customRow="onClickRowMulti"
          :pagination="{ position: 'bottom' }"
          :data-source="leftData"
          :rowKey="tableRowKey"
          :loading="tableLeftLoading"
          :scroll="{ x: tableScrollX, y: 'calc(100% - 64px)' }"
        />
      </div>
    </div>
    <div class="com-trans-right">
      <div class="trans-header">
        <div class="selected-num">已选{{selectedNum}}项</div>
        <a-button class="selected-alldel" @click="delAll">删除全部</a-button>
      </div>
      <div class="trans-body">
        <simpleList
          v-if="['collapse', 'single', 'tabCollapse'].includes(type)"
          :type="type"
          :checkedName="checkedName"
          @delItem="rightDelItem"
          @sortItem="rightSortItem"
          :replaceFields="replaceFields"
          :dataSource="separateDataSource().rightDataSource"
        ></simpleList>
        <treeList
          v-if="type === 'codeOpt'"
          ref="codeOpt"
          @select="(targetData) => emitChange(targetData, 'codeOpt')"
          @delItem="rightDelItem"
          :replaceFields="replaceFields"
          :defaultTargetKeys="defaultTargetKeys"
          :dataSource="separateDataSource().rightDataSource"
        ></treeList>
        <a-table
          v-if="type === 'table'"
          :columns="rightColumns"
          :pagination="{ total: 20,  }"
          :data-source="rightData"
          :rowKey="tableRowKey"
          :loading="tableRightLoading"
          :scroll="{ x: tableScrollX, y: 'calc(100% - 64px)' }"
        >
          <span slot="action" slot-scope="text, record, index">
            <span class="btn-del" @click="deleteAction(text, record, index)"><a-icon type="delete" style="font-size: 18px" /></span>
          </span>
        </a-table>

      </div>
    </div>
  </div>
</template>
<script>
import collapseList from './collapseList.vue'
import simpleList from './simpleList.vue'
import treeList from './treeList2.jsx'
import checkboxList from './checkboxList'
export default {
  props: {
    type: {
      default: 'collapse'
    },
    tableRowKey: {
      default: 'key'
    },
    dataSource: {
      default: () => ([])
    },
    dataTarget: {
      default: () => ([])
    },
    defaultTargetKeys: {
      default: () => ([])
    },
    tabsData: {
      default: () => ([])
    },
    replaceFields: {
      default: () => ({
        title:'title',
        key: 'key'
      })
    },
    checkedName: {
      default: '岗位名称'
    },
    columns: {
      default: () => ([])
    },
    tableScrollX: {
      default: '100%'
    }
  },
  components: {
    collapseList,
    simpleList,
    treeList,
    checkboxList
  },
  data () {
    return {
      leftData: [],
      rightData: [],
      tabsDataTotal: [],
      selectVal: undefined,
      selectData: [],
      selectedNum: 0,
      leftDataSource: [],
      rightDataSource: [],
      sourceSelectedKeys: [],
      targetSelectedKeys: [],
      rightSelectedKeys: [],
      targetKeys: [],
      tabsAcitveIndex: 0,
      leftColumns: [],
      rightColumns: [],
      selectedRowKeys: [],
      tableLeftLoading: true,
      tableRightLoading: true
    }
  },
  beforeCreate() {
    this.tableLoading = true
  },
  created () {
    if(this.type === 'table') {
      this.setColumns(this.columns)
      this.setDataTarget(this.dataTarget)
    } else {
      this.setDefaultTargetKeys(this.defaultTargetKeys)
    }
    if(this.type === 'tabCollapse') {
      this.setTabsData(this.tabsData)
    } else {
      this.setDataSource(this.dataSource)
    }
  },
  mounted () {
    this.tableLoading = true
    // this.targetKeys = targetKeys.concat(defaultTargetKeys || [])
  },
  methods: {
    setColumns(data) {
      this.leftColumns = [...data]
      this.rightColumns = data.concat([
        {
          title: '操作',
          scopedSlots: { customRender: 'action' }
        }
      ])
      if(data && data.length) {
        this.tableLeftLoading = false
      } else {
        setTimeout(() => {
          this.tableLeftLoading = false
        }, 1000)
      }
    },
    setDataSource(n) {
      this.leftData = n.concat()
      this.handleSelectData(n)
    },
    setDataTarget(data) {
      if(this.type !== 'table') {
        return
      }
      console.log(this.dataSource)
      this.rightData = data.concat()
      data.forEach(item => {
        this.selectedRowKeys.push(item.key)
      })
      this.selectedNum = this.rightData.length
      if(data && data.length) {
        this.tableRightLoading = false
      } else {
        setTimeout(() => {
          this.tableRightLoading = false
        }, 1000)
      }
    },
    setTabsData(n) {
      let totalArr = []
      n.forEach(item => {
        totalArr.push(...item.dataSource)
      })
      this.tabsDataTotal = totalArr
      this.leftData = n[this.tabsAcitveIndex].dataSource
      if(['codeOpt'].includes(this.type)) {
        this.calcTargetKeys(this.defaultTargetKeys, totalArr)
      } else {
        this.calcTargetSimpleKeys(this.defaultTargetKeys, totalArr)
      }
    },
    setDefaultTargetKeys(n) {
      if(['codeOpt'].includes(this.type)) {
        this.calcTargetKeys(n, this.leftData)
      } else if(['single'].includes(this.type)) {
        this.calcTargetCheckboxListKeys(n, this.leftData)
      } else {
        this.calcTargetSimpleKeys(n, this.leftData)
      }
    },
    separateDataSource() {
      const { type } = this.$props
      const { leftData, targetKeys = [], tabsDataTotal } = this;
      const leftDataSource = leftData;
      const newTargetKeys = targetKeys
      let rightDataSource = []
      const getData = (data) => {
        data.forEach(record => {
          if(newTargetKeys.includes(record[this.replaceFields['key']])) {
            rightDataSource.push(record)
          }
          if(record.children && record.children.length) {
            getData(record.children)
          }
        });
      }
      if(type === 'tabCollapse') {
        getData(tabsDataTotal)
      } else {
        getData(leftData)
      }
      return {
        leftDataSource,
        rightDataSource
      };
    },
    setState(state = {}, callback) {
      let newState = typeof state === 'function' ? state(this.$data, this.$props) : state;
      Object.assign(this.$data, newState);
      this.$forceUpdate();
      this.$nextTick(() => {
        callback && callback();
      });
    },
    leftSelect (e, p) {
      this.targetKeys = e
      if(this.type !== 'codeOpt') {
        this.emitChange(this.targetKeys, this.type)
      }
    },
    selectClick (data, e) {
      if(!(data)) {
        return
      }
      if(!this.targetKeys.includes(data[this.replaceFields['key']])) {
        this.targetKeys.push(data[this.replaceFields['key']])
        if(this.type !== 'codeOpt') {
          this.emitChange(this.targetKeys, this.type)
        }
      }
    },
    // 搜索栏下拉条件
    handleSelectData(treeData) {
      let newData = []
      if(['collapse', 'codeOpt', 'tabCollapse'].includes(this.type)) {
        treeData.forEach(item => {
          if(item.children && item.children.length) {
            item.children.forEach(el => {
              newData.push(el)
            })
          }
        })
      } else {
        treeData.forEach(item => {
          newData.push(item)
        })
      }
      this.selectData = newData
    },
    getExpandedNode (data) {
      const newExpande = []
      data.forEach(item => {
        newExpande.push(item[this.replaceFields['key']])
      })
      return newExpande
    },
    // 删除全部
    delAll() {
      if(this.type === 'table') {
        const rightData = this.rightData.concat()
        this.selectedRowKeys = []
        this.rightData = []
        this.tableEmitEvent(rightData,'del')
        return
      }
      // <!-- 2021-01-08 添加 (黎镖) -->
      if (this.type === 'codeOpt') {
        this.$refs.codeOpt.clearHandle()
      }
      this.targetKeys = []
      this.emitChange([], this.type)
    },
    rightDelItem(data) {
      const keyIndex = this.targetKeys.indexOf(data[this.replaceFields['key']])
      this.targetKeys.splice(keyIndex, 1)
      if(this.type !== 'codeOpt') {
        this.emitChange(this.targetKeys, this.type)
      }
    },
    rightSortItem(data) {
      this.$emit('change', data)
    },
    getTargetData(leftData, targetKey) {
      let newTargetAll = []
      const handleData = (data) => {
        data.forEach(item => {
          if(targetKey.includes(item[this.replaceFields['key']])) {
            newTargetAll.push(item)
          }
          if(item.children && item.children.length) {
            handleData(item.children)
          }
        })
      }
      handleData(leftData)
      return newTargetAll
    },
    emitChange (data, type) {
      let dataPool = this.leftData
      if(type === 'tabCollapse') {
        dataPool = this.tabsDataTotal
      }
      if(type === 'codeOpt') {
        if(!data) {
          return
        }
        const targetData = this.getTargetData(dataPool, data)
        this.selectedNum = data.length
        this.$emit('change', targetData)
      } else {
        const targetData = this.getTargetData(dataPool, this.targetKeys)
        this.selectedNum = this.targetKeys.length
        this.$emit('change', targetData)
      }
    },
    calcTargetKeys(n, leftData) {
      const normalData = []
      this.selectedNum = n.length
      const handleData = (data, pid) => {
        data.forEach(item => {
          if(n.includes(item[this.replaceFields['key']])) {
            normalData.push(pid)
          }
          if(item.children && item.children.length) {
            handleData(item.children, item[this.replaceFields['key']])
          }
        })
      }
      handleData(leftData)
      this.targetKeys = normalData
    },
    calcTargetSimpleKeys(n, leftData) {
      const normalData = []
      this.selectedNum = n.length
      leftData.forEach(item => {
        if(item.children && item.children.length) {
          item.children.forEach(el => {
            if(n.includes(el[this.replaceFields['key']])) {
              normalData.push(el[this.replaceFields['key']])
            }
          })
        }
      })
      this.targetKeys = normalData
    },
    calcTargetCheckboxListKeys(n, leftData) {
      const normalData = []
      this.selectedNum = n.length
      leftData.forEach(item => {
        if(n.includes(item[this.replaceFields['key']])) {
          normalData.push(item[this.replaceFields['key']])
        }
      })
      this.targetKeys = normalData
    },
    tabsSwitch(num) {
      this.tabsAcitveIndex = num
      this.leftData = this.tabsData[this.tabsAcitveIndex].dataSource
      this.handleSelectData(this.leftData)
    },
    // 单击行选中
    onClickRowMulti(record) {
      return {
        on: {
          click: () => {
            let recordKey = record[this.replaceFields.key]
            if(this.selectedRowKeys.includes(recordKey)) {
              let keyIndex = this.selectedRowKeys.indexOf(recordKey)
              this.selectedRowKeys.splice(keyIndex, 1)
              this.rightData.splice(keyIndex, 1)
            } else {
              this.selectedRowKeys.push(recordKey)
              this.rightData.push(record)
            }
            this.tableEmitEvent([record], 'add')
          }
        }
      }
    },
    // 选择操作
    onSelectChange (selectedRowKeys, selectedRows) {
      const oldSelectedRowKeys = this.selectedRowKeys.concat([])
      const oldSelectedRows = this.rightData.concat([])
      this.selectedRowKeys = selectedRowKeys
      this.rightData = selectedRows
      if(selectedRowKeys.length > oldSelectedRowKeys.length) {
        const calcData = []
        selectedRows.forEach(item => {
          if(!oldSelectedRowKeys.includes(item.key)) {
            calcData.push(item)
          }
        })
        this.tableEmitEvent(calcData,'add')
      } else if(selectedRowKeys.length < oldSelectedRowKeys.length) {
        const calcData = []
        console.log(oldSelectedRows, selectedRowKeys)
        oldSelectedRows.forEach(item => {
          if(!selectedRowKeys.includes(item.key)) {
            calcData.push(item)
          }
        })
        this.tableEmitEvent(calcData, 'del')
      }
    },
    // 删除操作
    deleteAction (text, record, index) {
      if(this.selectedRowKeys.includes(record.key)) {
        let keyIndex = this.selectedRowKeys.indexOf(record.key)
        this.selectedRowKeys.splice(keyIndex, 1)
        this.rightData.splice(keyIndex, 1)
      }
      this.tableEmitEvent([record], 'del')
    },
    // 删除全部操作
    deleteAllAction () {
      // const deleteKeys = []
      // this.selectedData.forEach(item => {
      //   deleteKeys.push(item.key)
      // })
      // this.selectedRowKeys = deleteKeys.filter(item => { return !this.selectedRowKeys.includes(item) })
      // this.selectedData = []
      // this.$emit('onChange', this.selectedData)
    },
    tableEmitEvent(data, eventName) {
      const obj = {
        del: [],
        add: []
      }
      obj[eventName] = data
      this.selectedNum = this.rightData.length
      this.$emit('change', this.rightData, obj)
    }
  },
  watch: {
    dataSource(n) {
      this.setDataSource(n)
    },
    dataTarget(n) {
      this.setDataTarget(n)
    },
    defaultTargetKeys(n) {
      this.setDefaultTargetKeys(n)
    },
    targetKeys(n) {

    },
    tabsData (n) {
      this.setTabsData(n)
    },
    columns(n) {
      this.setColumns(n)
    }
  }
}
</script>
