import {encrypt, genKey} from "./AES_ECB"
import {encryptedData} from './JSEncrypt'

const encryptedType = 'AES'

// 加密
export function encryptedParams(params) {
    if (encryptedType === 'ESA') {
        return {
            encryptedData: encryptedData(params)
        }
    } else {
        const aesKey = genKey(16)
        return {
            encryptedSecret: encryptedData(aesKey),
            encryptedData: encrypt(params, aesKey)
        }
    }
}
