<template>
    <div class="funnel-com-box">
        <over-table-condition :table-name="tableName" :defaultParams="defaultParams" @getOverPms="getOverPms" @searchFun="onSearch" ref="overParam"></over-table-condition>
        <div class="funnel-com-icon" @click="handleMenuClick2" v-show="searchCondition.length>0">
            <i class="iconhr" :class="condsum>0?'ico-loudou-hantiaojian':'ico-tiaojiansousuo'"
               style="position: absolute;left: 8px; font-size: 18px;line-height: 32px;"></i>
        </div>
        <transition>
            <template v-if='queryType == 1'>
                    <filter-info-org v-show="showModle2" @resetCdn="resetCdn" :req-url="reqUrl" :defaultParams="defaultParams" ref="searchList" :search-condition.sync="searchCondition"
                         :table-name="tableName" @search="funnelsearch" @getFunnelPms="getFunnelPms">

                <template slot="customParams">
                    <slot name="customParamsF"></slot>
                </template>
            </filter-info-org>
            </template>
            <template v-if='queryType == 2'>
                    <filter-info v-show="showModle2" @resetCdn="resetCdn" :req-url="reqUrl" :defaultParams="defaultParams" ref="searchList" :search-condition.sync="searchCondition"
                         :table-name="tableName" @search="funnelsearch" @getFunnelPms="getFunnelPms">

                <template slot="customParams">
                    <slot name="customParamsF"></slot>
                </template>
            </filter-info>
            </template>
        </transition>
<!--        closable-->
<!--        <a-tag class="funnel-com-tag" @close="closeTag" v-show="condsum>0" v-model="visiblequery">-->
<!--            包含{{condsum}}个查询条件-->
<!--        </a-tag>-->
    </div>
</template>

<script>
    import {cloneDeep} from 'lodash'
    import {req} from 'common/api/index'
    import filterInfo from './filterInfo'
    import filterInfoOrg from './filterInfoOrg'
    import overTableCondition from "./overTableCondition";

    export default {
        name: 'Funnel',
        props: {
            tableName: {// 必传根据tableName查询表格表头
                type: String,
                default: ''
            },
            reqUrl: {
                type: String,
                default: 'post_sys_getSearchcondition'
            },
            defaultParams: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            queryType: {
                type: [String, Number],
                default: 1
            }
        },
        components: {
            filterInfo,
            filterInfoOrg,
            overTableCondition
        },
        data() {
            return {
                overParams: {}, // 表格上方参数
                funnelValue: {}, // 小漏斗参数
                showTableList: [],
                searchCondition: [],
                filterInfo: {},
                visiblequery: true,
                showModle2: false,
                condsum: 0
            }
        },
        watch: {
            searchCondition: function (val) {
                // console.log(val)
                const avilidValue = []
                val.forEach(item => {
                    if (item.value) {
                        avilidValue.push(item)
                    }
                })
                this.condsum = avilidValue.length
            }
        },
        mounted() {
            this.$refs.searchList.initData()
        },
        methods: {
            resetCdn () {
                this.$emit('resetCdn')
            },
            onSearch(params) {
                const newPms = Object.assign(cloneDeep(this.funnelValue), params)
                this.$emit('searchEvnt', newPms)
            },
            getOverPms (params) {
                this.overParams = cloneDeep(params)
            },
            getFunnelPms (params) {
                this.funnelValue = cloneDeep(params)
            },
            funnelsearch(params) {
                const tempArr = Object.keys(params)
                this.condsum = tempArr.length
                this.$emit('searchEvnt', Object.assign(cloneDeep(this.overParams), params))
                this.handleMenuClick2()
            },
            handleMenuClick2() {
                this.showModle2 = !this.showModle2
            },
            closeTag(e) {
                console.log(e)
            }
        }
    }
</script>
<style lang="less" scoped>
    .funnel-com-box {
        display: flex;
    }

    .funnel-com-icon {
        /*margin-left: 8px;*/
        margin-right: 8px;
        position: relative;
        border: 1px solid #d9d9d9;
        width: 36px;
        height: 32px;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
    }

    .funnel-com-tag {
        height: 32px;
        line-height: 30px;
        text-align: center
    }
    .ico-tiaojiansousuo {
        /*color: #1E89FF*/
    }
</style>
