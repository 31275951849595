<template>
  <a-config-provider :locale="locale">
    <div id="portal">
      <router-view />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'
export default {
  name: 'App',
  data() {
    return {
      locale: zhCN
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function(val) {
        if (val.path === '/ss/hrWorkbench/hrHomePage' || val.path === '/ss/personalWorkbench/homePage' || val.path === '/ss/mgmtWorkbench/mgmtHomePage') {
          if (val.path === '/ss/personalWorkbench/homePage') {
            this.$store.state.isLimitss = true
            // this.$store.commit('setIsLimitss', true)
          } else {
            this.$store.state.isLimitss = false
            // this.$store.commit('setIsLimitss', false)
          }
        }
      }
    }
  },
  mounted() {
    this.$http('post_showSystemConfig').then(res=>{
      if(res.data.systemLogo) document.getElementById('favicon').href = res.data.systemLogo
      if(res.data.systemName) document.title = res.data.systemName
    })
    window.addEventListener('message', e => {
      console.log(e.data)
      if (typeof e.data === 'string' && !e.data.includes('webpackHotUpdate')) {
        this.$router.push(e.data)
      }
    })
  },
  methods: {}
}
</script>

<style lang="less" scoped>
#portal {
  color: #2c3e50;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
