<template>
    <div class="filter-ico-custom">
        <a-spin :spinning="loadingData">
            <div class="content">
                <div class="content-item" v-for="(item,index) in tj1left" :key="item.key">
                    <div class="explain">{{item.title}}</div>
                    <div class="explain-content" v-if="item.type === 1">
                        <a-tree-select
                                treeCheckStrictly
                                v-if="item.type === 1"
                                v-model="filterForm[item.name]"
                                style="width: 300px;"
                                :tree-data="item.value"
                                tree-checkable
                                :show-checked-strategy="SHOW_PARENT"
                                search-placeholder="Please select"
                        />
                        <span @click="tj1left.splice(index,1)" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                    <div class="explain-content" v-if="item.type === 2">
                        <a-checkbox-group
                                v-model="filterForm[item.name]"
                                name="checkboxgroup1"
                                :options="item.children"
                        />
                        <span @click="tj1left.splice(index,1)" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                    <div class="explain-content" v-if="item.type === 7 || item.type === 8">
                        <a-select v-model="item.valueData" style="width: 70px;cursor: pointer"
                                  @change="handleChangerq(item.name)">
                            <a-select-option value="jy">
                                介于
                            </a-select-option>
                            <a-select-option value="zy">
                                早于
                            </a-select-option>
                            <a-select-option value="wy">
                                晚于
                            </a-select-option>
                        </a-select>
                        <a-range-picker
                                :placeholder="['开始日期', '结束日期']"
                                format="YYYY-MM-DD" value-format="YYYY-MM-DD"
                                v-model="filterForm[item.name]"
                                @change="e => onChange(e, item.name)"
                                v-if="item.type === 7 && item.valueData === 'jy'"
                                class="DataPickerStyle"
                        />
                        <a-range-picker
                                :placeholder="['开始时间', '结束时间']"
                                format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
                                show-time
                                @change="e => onChange(e, item.name)" :show-time="{ hideDisabledOptions: true, defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')]}"
                                v-model="filterForm[item.name]"
                                v-if="item.type === 8 && item.valueData === 'jy'"
                                class="DataPickerStyle"
                        />
                        <a-date-picker
                                v-model="filterForm[item.name]"
                                class="DataPickerStyle" :format="['YYYY-MM-DD', 'YYYYMMDD', 'YYYYMM']" value-format="YYYY-MM-DD"
                                :placeholder="`请选择${item.title}`"
                                v-if="item.valueData !== 'jy' && item.type === 7"/>
                        <a-date-picker
                                v-model="filterForm[item.name]"
                                class="DataPickerStyle" format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
                                :placeholder="`请选择${item.title}`"
                                v-if="item.valueData !== 'jy' && item.type === 8"/>
                        <span @click="tj1left.splice(index,1)" v-if="item.editenable===1" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                    <div class="explain-content" v-if="item.type === 4">
                        <a-input v-model="filterForm[item.name].min"
                                 @input="filterForm[item.name].min = Number($event.target.value.replace(/\D+/, ''))"
                                 placeholder="请输入" style="width: 100px "/>
                        <a-checkbox
                                @change="$event.target.checked ? filterForm[item.name].minContain = true : filterForm[item.name].minContain = false"
                                style="margin-left:10px">
                            (含)
                        </a-checkbox>
                        <span style="margin-right: 10px">
              至
              </span>
                        <a-input v-model="filterForm[item.name].max"
                                 @input="filterForm[item.name].max = Number($event.target.value.replace(/\D+/, ''))"
                                 placeholder="请输入" style="width: 100px"/>
                        <a-checkbox
                                @change="$event.target.checked ? filterForm[item.name].maxContain = true : filterForm[item.name].maxContain = false"
                                style="margin-left:10px">
                            (含)
                        </a-checkbox>
                        <span @click="tj1left.splice(index,1)" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                    <div class="explain-content" v-if="item.type === 5">
                        <template v-for="(tag,index) in item.children">
                            <a-tag :key="tag" :closable="true" @close="item.children.splice(index,1)">
                                {{ tag }}
                            </a-tag>
                        </template>
                        <a-input
                                v-if="inputVisible"
                                ref="input1"
                                type="text"
                                size="small"
                                :style="{ width: '78px' }"
                                @blur="handleInputConfirm(item.children,$event)"
                                @keyup.enter="$event.target.blur"
                        />
                        <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
                            <a-icon type="plus"/>
                            新增
                        </a-tag>
                        <span @click="tj1left.splice(index,1)" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                    <!--输入框-->
                    <div class="explain-content" v-if="item.type === 6">
                        <a-input v-model="filterForm[item.name]"
                                 :max-length="32" allow-clear
                                 :placeholder="`请输入${item.title}`" class="form-width-common"/>
                        <span @click="tj1left.splice(index,1)" v-if="item.editenable===1" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                    <!--日期-->
                    <div class="explain-content" v-if="item.type === 7 && false">
                        <a-range-picker class="form-width-common" v-model:value="filterForm[item.name]"
                                        format="YYYY-MM-DD" value-format="YYYY-MM-DD"
                                        @change="e => onChange(e, item.name)"
                        />
                        <span @click="tj1left.splice(index,1)" v-if="item.editenable===1" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                    <!--日期时间-->
                    <div class="explain-content" v-if="item.type === 8 && false">
                        <!--                        <a-date-picker-->
                        <!--                                v-model="filterForm[item.name]" value-format="YYYY-MM-DD HH:mm:ss"-->
                        <!--                                :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }"-->
                        <!--                                class="form-width-common"-->
                        <!--                        />-->
                        <a-range-picker class="form-width-common" v-model:value="filterForm[item.name]"
                                        format="YYYY-MM-DD HH:mm:ss" value-format="YYYY-MM-DD HH:mm:ss"
                                        @change="e => onChange(e, item.name)" :show-time="{
        hideDisabledOptions: true,
        defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('11:59:59', 'HH:mm:ss')],
      }"
                        />
                        <span @click="tj1left.splice(index,1)" v-if="item.editenable===1" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                    <!--单选框-->
                    <div class="explain-content" v-if="item.type === 9 && item.dmgrp">
                        <a-radio-group name="radioGroup" v-model:value="filterForm[item.name]"
                                       v-if="dataSource[item.dmgrp]&&dataSource[item.dmgrp].length < 10">
                            <a-radio :value="item.id" v-for="(item,index) in dataSource[item.dmgrp]" :key="index">
                                {{item.dmcpt}}
                            </a-radio>
                        </a-radio-group>
                        <a-select v-else :default-value="item.defaultValue"
                                  @popupScroll="handlePopupScroll($event, item, dataSource[item.dmgrp],index)"
                                  :placeholder="`请选择${item.title}`" :allow-clear="true" v-model="filterForm[item.name]"
                                  class="form-width-common" show-search option-filter-prop="children"
                                  :filter-option="filterOption">
                            <a-select-option :value="item.id" v-for="(item,index) in item.frontDataZ" :key="index">
                                {{item.dmcpt}}
                            </a-select-option>
                        </a-select>
                        <span @click="tj1left.splice(index,1)" v-if="item.editenable===1" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                    <!--复选框-->
                    <div class="explain-content" v-if="item.type === 10">
                        <a-checkbox-group @change="onChange" v-model="filterForm[item.name]"
                                          v-if="dataSource[item.dmgrp]&&dataSource[item.dmgrp].length < 10">
                            <a-checkbox :value="item.id" v-for="(item,index) in dataSource[item.dmgrp]" :key="index">
                                {{item.dmcpt}}
                            </a-checkbox>
                        </a-checkbox-group>
                        <a-select v-else mode="multiple" :placeholder="`请选择${item.title}`"
                                  @popupScroll="handlePopupScroll($event, item, dataSource[item.dmgrp],index)"
                                  :default-value="item.defaultValue"
                                  v-model="filterForm[item.name]" class="form-width-common" show-search
                                  option-filter-prop="children" :filter-option="filterOption">
                            <a-select-option :value="item.id"
                                             v-for="(item,index) in item.frontDataZ" :key="index">
                                {{item.dmcpt}}
                            </a-select-option>
                        </a-select>
                        <span @click="tj1left.splice(index,1)" v-if="item.editenable===1" class="iconhr ico-shanchu"
                              style="position: absolute; right: 10px;cursor: pointer"></span>
                    </div>
                </div>
                <slot name="customParams"></slot>
            </div>
            <div class="filter-btn">
                <a-button @click="addCondition">添加条件</a-button>
                <a-button @click="resetPart">重置</a-button>
                <a-button @click="handleToSearch">查询</a-button>
            </div>
        </a-spin>
        <a-modal
                title="添加条件"
                :visible="isShowCondition"
                @ok="handleOk"
                @cancel="handleCancel" width="1200px"
        >
            <add-condition ref="addCondition" @getTableData="getTableData" :table-name="tableName"></add-condition>
        </a-modal>
    </div>
</template>
<script>
    import Vue from 'vue'
    import {TreeSelect} from 'ant-design-vue'
    import {cloneDeep} from 'lodash'
    import AddCondition from './filterInfoCom/AddConditionDial'
    import moment from 'moment'
    import {req} from "../../api";
    import CodeTable from 'common/components/ComCodeTable/index'
    // 代码表
    import codetable from 'common/components/ComCodeTable/CodTabl/index.js'


    // 数组中每一项的数据分为多种情况 type为1是树选择 type为2是多选 type为3是日期 type为4是输入框 type为5是新增输入框
    export default {
        name: 'FilterInfo',
        components: {
            // ComTransfer
            AddCondition,
            CodeTable
        },
        // 设置代码表
        mixins: [codetable],
        props: {
            reqUrl: String,
            showTableList: Array,
            searchCondition: Array,
            tableName: String,
            status: String,
            defaultParams: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                tempSearchCondition: [],
                loadingData: false,
                moment,
                tj1left: [],
                yearShowOne: false,
                filterForm: {},
                inputVisible: false,
                inputValue: '',
                // 树相关
                treeData: [
                    {
                        title: 'Node1',
                        value: '0-0',
                        key: '0-0',
                        children: [
                            {
                                title: 'Child Node1',
                                value: '0-0-0',
                                key: '0-0-0'
                            }
                        ]
                    },
                    {
                        title: 'Node2',
                        value: '0-1',
                        key: '0-1',
                        children: [
                            {
                                title: 'Child Node3',
                                value: '0-1-0',
                                key: '0-1-0',
                                disabled: true
                            },
                            {
                                title: 'Child Node4',
                                value: '0-1-1',
                                key: '0-1-1'
                            },
                            {
                                title: 'Child Node5',
                                value: '0-1-2',
                                key: '0-1-2'
                            }
                        ]
                    }
                ],
                SHOW_PARENT: TreeSelect.SHOW_PARENT,
                dataSource: [],
                targetKeys: [],
                isShowCondition: false
            }
        },
        created() {
        },
        watch: {
            tj1left: function (val) {
                this.toSearch()
                this.$emit('getFunnelPms', this.toSearch())
                const hasDmgrpArr = val.filter(item => item.dmgrp)
                const reMapArr = hasDmgrpArr.map(el => el.dmgrp)
                const dmgrpStr = reMapArr.join(',')
                this.$nextTick(() => {
                    // 加载代码表
                    if (dmgrpStr) {
                        this.getCodeDataList(dmgrpStr).then(res => {
                            let data = res.data
                            this.tj1left.forEach(e => {
                                if (e.dmgrp && data[e.dmgrp]) {
                                    e.dataZ = data[e.dmgrp]
                                    e.frontDataZ = data[e.dmgrp].slice(0, 100)
                                }
                            })
                        })
                    }
                })
                this.$emit('update:searchCondition', val)
            }
        },
        methods: {
            filterType(item) {
                if (item.queryType === 'F') {
                    return 7
                }
                if (item.queryType === 'T') {
                    return 8
                }
                if (item.dmgrp && item.ismultivalued === 0) {
                    return 9
                }
                if (item.dmgrp && item.ismultivalued === 1) {
                    return 10
                }
                return 6
            },
            onChange(checkedValues, KeyName) {
                // this.filterType()
                this.filterForm[KeyName] = checkedValues
            },
            getTableData(arr) {
                let temArr = []
                if (arr.length > this.tempSearchCondition.length) {
                    temArr = arr.slice(this.tempSearchCondition.length)
                }
                this.tj1left = this.tempSearchCondition
                temArr.forEach(el => {
                    this.tj1left.push({
                        title: el.queryName,
                        name: el.fldcod,
                        value: '',
                        defaultValue: el.defaultValue,
                        editenable: el.editenable === true ? 1 : 0,
                        type: this.filterType(el),
                        dmgrp: el.dmgrp,

                        dataZ: [],//总数据（不会改变）
                        frontDataZ: [], //存放前100的数据
                        sourceOwnerSystems: [],
                        SearchvalueData: '',
                        treePageSize: 100,
                        scrollPage: 1,
                    })
                })
            },
            initData() {
                const keys = Object.keys(this.defaultParams)
                // this.tj1left = []
                // 初始化搜索条件
                this.loadingData = true
                if (this.reqUrl === 'post_sys_getSearchcondition') {
                    req(this.reqUrl, {tableName: this.tableName}).then((res) => {
                        if (res.code === 200) {
                            this.loadingData = false
                            const tempArr = []
                            const tempObj = {}
                            res.data.forEach(item => {
                                const obj = keys.find(ele => ele === item.fldcod)
                                if (obj) {
                                    item.defaultValue = this.defaultParams[obj]
                                }
                                if (item.showQuery === 1) {
                                    tempArr.push({
                                        title: item.queryName,
                                        name: item.fldcod,
                                        value: (this.filterType(item) === 7 || this.filterType(item) === 8) ? (item.defaultValue === null ? undefined : item.defaultValue.split(',')) : (item.defaultValue === null ? undefined : item.defaultValue),
                                        // defaultValue: item.defaultValue,
                                        defaultValue: (this.filterType(item) === 7 || this.filterType(item) === 8) ? (item.defaultValue === null ? undefined : item.defaultValue.split(',')) : (item.defaultValue === null ? undefined : item.defaultValue),
                                        editenable: item.editenable,
                                        // editenable: 0,
                                        type: this.filterType(item),
                                        dmgrp: item.dmgrp,
                                        valueData: 'jy',

                                        dataZ: [],//总数据（不会改变）
                                        frontDataZ: [], //存放前100的数据
                                        sourceOwnerSystems: [],
                                        SearchvalueData: '',
                                        treePageSize: 100,
                                        scrollPage: 1,

                                    })

                                    tempObj[item.fldcod] = (this.filterType(item) === 7 || this.filterType(item) === 8) ? (item.defaultValue === null ? undefined : item.defaultValue.split(',')) : (item.defaultValue === null ? undefined : item.defaultValue)
                                }
                            })
                            this.tempSearchCondition = cloneDeep(tempArr)
                            this.tj1left = tempArr
                            this.filterForm = cloneDeep(tempObj)
                        } else {
                            this.loadingData = false
                            this.$message.error(res.message)
                        }
                    })
                } else {
                    this.$http(this.reqUrl).then((res) => {
                        if (res.code === 200) {
                            this.loadingData = false
                            const tempArr = []
                            res.data.forEach(item => {
                                const obj = keys.find(ele => ele === item.fldcod)
                                if (obj) {
                                    item.defaultValue = this.defaultParams[obj]
                                }
                                if (item.showQuery === 1) {
                                    tempArr.push({
                                        title: item.queryName,
                                        name: item.fldcod,
                                        value: '',
                                        // defaultValue: item.defaultValue,
                                        defaultValue: (this.filterType(item) === 7 || this.filterType(item) === 8) ? (item.defaultValue === null ? undefined : item.defaultValue.split(',')) : (item.defaultValue === null ? undefined : item.defaultValue),
                                        editenable: item.editenable,
                                        // editenable: 0,
                                        type: this.filterType(item),
                                        dmgrp: item.dmgrp,

                                        dataZ: [],//总数据（不会改变）
                                        frontDataZ: [], //存放前100的数据
                                        sourceOwnerSystems: [],
                                        SearchvalueData: '',
                                        treePageSize: 100,
                                        scrollPage: 1,

                                    })
                                }
                            })
                            this.tempSearchCondition = cloneDeep(tempArr)
                            this.tj1left = tempArr
                        } else {
                            this.loadingData = false
                            this.$message.error(res.message)
                        }
                    })
                }
            },
            setReq() {
                return new Promise((resolve, reject) => {
                    const treeData = [
                        {
                            title: '0-0',
                            key: '0-0',
                            children: [
                                {
                                    title: '0-0-0',
                                    key: '0-0-0',
                                    children: [
                                        {
                                            title: '0-0-0-0',
                                            key: '0-0-0-0'
                                        },
                                        {
                                            title: '0-0-0-1',
                                            key: '0-0-0-1'
                                        }
                                    ]
                                },
                                {
                                    title: '0-0-1',
                                    key: '0-0-1',
                                    children: [
                                        {
                                            title: '0-0-1-0',
                                            key: '0-0-1-0'
                                        },
                                        {
                                            title: '0-0-1-1',
                                            key: '0-0-1-1'
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            title: '0-1',
                            key: '0-1',
                            children: [
                                {title: '0-1-0-0', key: '0-1-0-0'},
                                {title: '0-1-0-1', key: '0-1-0-1'},
                                {title: '0-1-0-2', key: '0-1-0-2'}
                            ]
                        },
                        {
                            title: '0-2',
                            key: '0-2'
                        }
                    ]
                    resolve(treeData)
                })
            },
            handleToSearch() {
                const filterFormTem = this.toSearch()
                this.$emit('getFunnelPms', filterFormTem)
                this.$emit('search', filterFormTem)
            },
            // 点击查询的回调
            toSearch() {
                // 整理日期相关
                const dateField = []
                const filterFormTem = {}
                this.tj1left.forEach(item => {
                    if (item.type === 3) {
                        dateField.push(item.name)
                    }
                })

                Object.keys(this.filterForm).forEach(item => {
                    if (this.filterForm[item] !== null && this.filterForm[item] !== undefined) {
                        Object.assign(filterFormTem, {[item]: cloneDeep(this.filterForm[item])})
                    }
                })

                Object.keys(filterFormTem).forEach(item => {
                    for (const dateName of dateField) {
                        if (item === dateName) {
                            const type = this.judgeType(filterFormTem[item])
                            if (type === 1) {
                                filterFormTem[item][0] = filterFormTem[item][0].format('YYYY-MM-DD')
                                filterFormTem[item][1] = filterFormTem[item][1].format('YYYY-MM-DD')
                            } else if (type === 2) {
                                filterFormTem[item] = filterFormTem[item].format('YYYY-MM-DD')
                            }
                        }
                    }
                })
                for (let k in filterFormTem) {
                    if ((typeof filterFormTem[k]) === 'object') {
                        if (filterFormTem[k].length > 0) {
                            filterFormTem[k] = filterFormTem[k].join(',')
                        } else {
                            delete filterFormTem[k]
                        }
                    }
                }
                return filterFormTem
            },
            judgeType(data) {
                if (Array.isArray(data)) {
                    return 1
                } else {
                    return 2
                }
            },
            // 模态框点击确定的回调
            handleOk() {
                this.$refs.addCondition.saveFn()
                this.handleCancel()
                // this.initData()
            },
            // 模态框点击取消的回调
            handleCancel(e) {
                this.isShowCondition = false
            },
            // 重置每一项
            resetPart() {
                this.$emit('resetCdn')
                this.$nextTick(() => {
                    for (let k in this.filterForm) {
                        this.$set(this.filterForm, k, undefined)
                    }
                })
            },
            // 添加条件回调函数
            addCondition() {
                this.isShowCondition = true
                this.setReq().then(res => {
                    this.dataSource = res
                    this.targetKeys = ['0-0-0-0']
                })
            },
            handleInputConfirm(dataList, e) {
                if (!e.target.value.trim()) {
                    this.$message.error('名字不能为空!')
                } else {
                    if (dataList.indexOf(e.target.value) === -1) {
                        dataList.push(e.target.value)
                    } else {
                        this.$message.error('名字重复!')
                    }
                }
                this.inputVisible = false
            },
            // 添加tag的回调
            showInput() {
                this.inputVisible = true
                this.$nextTick(function () {
                    this.$refs.input1[0].focus()
                })
            },
            handleChangerq(name) {
                this.filterForm[name] = null
                // const newData = [...this.setTHData]
                // const target = newData.filter(item => key === item.key)[0]
                // if (target) {
                //   target[column] = value
                //   this.setTHData = newData
                // }
            },
            change() {

            },
            handleChange(name) {
                this.filterForm[name] = null
            },
            // 点击用工类型中全选的回调函数
            useTypeChange(checked) {
                this.allUseType.forEach(item => {
                    item.checked = checked
                })
            },
            //下拉框下滑事件
            handlePopupScroll(e, item, dataSource, ind) {
                const {target} = e
                const scrollHeight = (target.scrollHeight - target.scrollTop).toFixed() - 0
                const clientHeight = target.clientHeight
                // 下拉框不下拉的时候
                if (scrollHeight === 250 && clientHeight === 250) {
                    this.tj1left[ind].scrollPage = 1
                } else {
                    // if (this.tj1left[ind].frontDataZ.length === 0) {
                    //     this.tj1left[ind].frontDataZ = dataSource.slice(0, 100)
                    // } else {
                    // }

                    // 当下拉框滚动条到达底部的时候
                    if (scrollHeight < clientHeight + 5) {
                        this.tj1left[ind].scrollPage = this.tj1left[ind].scrollPage + 1
                        const scrollPage = this.tj1left[ind].scrollPage// 获取当前页
                        const treePageSize = this.tj1left[ind].treePageSize * (scrollPage || 1)// 新增数据量
                        const newData = [] // 存储新增数据
                        let max = '' // max 为能展示的数据的最大条数
                        if (this.tj1left[ind].dataZ.length > treePageSize) {
                            // 如果总数据的条数大于需要展示的数据
                            max = treePageSize
                        } else {
                            // 否则
                            max = this.tj1left[ind].dataZ.length
                        }
                        // 判断是否有搜索
                        if (this.tj1left[ind].SearchvalueData) {
                            this.tj1left[ind].allDataZ.forEach((items, index) => {
                                if (index < max) { // 当data数组的下标小于max时
                                    newData.push(items)
                                }
                            })
                        } else {
                            this.tj1left[ind].dataZ.forEach((iteme, index) => {
                                if (index < max) { // 当data数组的下标小于max时
                                    newData.push(iteme)
                                }
                            })
                        }

                        this.tj1left[ind].frontDataZ = newData // 将新增的数据赋值到要显示的数组中
                    }
                }
            },
            filterOption(input, option) {
                return (
                    option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
            }
        }

    }
</script>
<style lang="less">
    .filter-ico-custom {
        position: absolute;
        top: 45px;
        z-index: 100;
        left: 0;
        right: 0;
        box-shadow: 0 0 3px 2px #ddd;
        border: 1px solid #ddd;
        background-color: #ffffff;
        margin: 5px 20px 10px 20px;

        .add-condition {
            background: #fff;
            position: absolute;
            top: 0;
            left: 400px;
            width: 500px;
            height: 80%;
        }

        .tagStyle {
            border: 1px solid #ddd;
            height: 30px;
            line-height: 30px;
        }

        .DataPickerStyle {
            margin-left: 10px;
            width: 350px;
            display: inline-block;
        }
        .form-width-common {
            width: 430px;
        }

        .content {
            width: 100%;
            padding: 20px;

            .content-item {
                display: flex;
                margin-bottom: 1px;

                .explain {
                    padding-left: 8px;
                    padding-right: 20px;
                    min-width: 160px;
                    max-width: 200px;
                    height: 42px;
                    font-size: 15px;
                    text-align: right;
                    line-height: 42px;
                    background-color: #ddd;
                }

                .explain-content {
                    box-sizing: border-box;
                    position: relative;
                    padding-left: 20px;
                    line-height: 34px;
                    width: 100%;
                    height: 42px;
                    padding-top: 2px;
                    padding-bottom: 2px;
                    border: 1px solid #ddd;
                    border-left: none;

                    & > .ant-input {
                        width: 100px;
                    }

                    /deep/ .ant-input-number {
                        margin-right: 10px;
                    }
                }
            }
        }

        .filter-btn {
            display: flex;
            justify-content: center;
            padding-bottom: 10px;
            margin: auto;

            .ant-btn {
                margin-left: 15px;
            }
        }

        /deep/ .ant-select-selection--multiple {
            height: 34px;
            overflow-y: auto;
        }
    }
</style>
