import Vue from 'vue'
import Menu from 'ant-design-vue/es/menu'
import Icon from 'ant-design-vue/es/icon'
import fastEqual from 'fast-deep-equal'
import iconFront from 'common/lib/fontIcon/iconfont.js'
const MenuIcon = Icon.createFromIconfontCN({
  scriptURL: iconFront
})
Vue.component('MenuIcon', MenuIcon)

const {Item, SubMenu} = Menu

export default {
  name: 'SiderMenu',
  props: {
    menuData: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    mode: {
      type: String,
      required: false,
      default: 'inline'
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    openKeys: Array
  },
  data () {
    return {
      selectedKeys: [],
      sOpenKeys: [],
      cachedOpenKeys: []
    }
  },
  computed: {
    menuTheme() {
      return this.theme == 'light' ? this.theme : 'dark'
    }
  },
  created () {
    // this.updateMenu()
    if (this.menuData.length > 0) {
      if(!this.menuData[0].fullPath){
        this.formatMenuData(this.menuData, '')
      }
    }
    /*
    * author: kangwj
    * 解决页面刷新后，菜单选中状态丢失问题
    * 由于首页属于自助服务模块，所以第一次加载会自动展开自助服务模块，后期可以添加筛选移除掉首页情况。
    */
    if(this.$route && this.$route.path){
      let path = this.$route.path;
      let rootPath = path.substring(0, path.indexOf('/', 1));
      this.selectedKeys.push(rootPath);
      this.selectedKeys.push(path);
      this.sOpenKeys.push(rootPath);
      this.sOpenKeys.push(path);
    }
  },
  watch: {
    menuData(val) {
      console.log('22222222222222', val)
      if (val.length > 0 && !val[0].fullPath) {
        this.formatMenuData(this.menuData, '')
      }
    },
    // i18n(val) {
    //   if(val && val.messages) {
    //     const messages = this.i18n.messages
    //     Object.keys(messages).forEach(key => {
    //       this.$i18n.mergeLocaleMessage(key, messages[key])
    //     })
    //   }
    // },
    // collapsed (val) {
    //   if (val) {
    //     this.cachedOpenKeys = this.sOpenKeys
    //     this.sOpenKeys = []
    //   } else {
    //     this.sOpenKeys = this.cachedOpenKeys
    //   }
    // },
    '$route': function () {
      this.updateMenu()
    },
    // sOpenKeys(val) {
    //   this.$emit('openChange', val)
    //   this.$emit('update:openKeys', val)
    // }
  },
  methods: {
    renderIcon: function (h, icon, key) {
      if (this.$scopedSlots.icon && icon && icon !== 'none') {
        const vnodes = this.$scopedSlots.icon({icon, key})
        vnodes.forEach(vnode => {
          vnode.data.class = vnode.data.class ? vnode.data.class : []
          vnode.data.class.push('anticon')
        })
        return vnodes
      }
      if(icon) {
        icon = icon
      }
      if (icon && icon.indexOf('.') !== -1) {
        icon = icon.substring(1)
      }
      if(icon == 'dot') return h('div', {attrs: {style: 'display:inline-block;width:7px;height:7px;margin-right:10px;border-radius:50%;background:#fff;vertical-align:middle'}})
      return !icon || icon == 'none' ? null : h('span', {attrs: {class: 'iconhr ' + icon}})
    },
    renderMenuItem: function (h, menu) {
      let tag = 'router-link'
      let config = {props: {to: menu.fullPath}, attrs: {style: 'overflow:hidden;white-space:normal;text-overflow:clip;'}}
      if (!menu && !menu.link) {
        tag = 'a'
        config = {attrs: {style: 'overflow:hidden;white-space:normal;text-overflow:clip;', href: menu.link, target: '_blank'}}
      }
      return h(
        Item, {key: menu.fullPath},
        [
          h(tag, config,
            [
              this.renderIcon(h, menu ? menu.iconPath : 'none', menu.fullPath),
              menu.title
            ]
          )
        ]
      )
    },
    renderSubMenu: function (h, menu) {
      let this_ = this
      let subItem = [h('span', {slot: 'title', attrs: {style: 'overflow:hidden;white-space:normal;text-overflow:clip;'}},
        [
          this.renderIcon(h, menu ? menu.iconPath : 'none', menu.fullPath),
          menu.title
        ]
      )]
      let itemArr = []
      menu.children.forEach(function (item) {
        itemArr.push(this_.renderItem(h, item))
      })
      return h(SubMenu, {key: menu.fullPath},
        subItem.concat(itemArr)
      )
    },
    renderItem: function (h, menu) {
      const meta = menu
      // console.log('ssssssssssssssssss', meta)
      // if (!meta || !meta.invisible) {
      if (!meta || !menu.hidden) {
        let renderChildren = false
        const children = menu.children
        if (children != undefined) {
          for (let i = 0; i < children.length; i++) {
            const childMeta = children[i]
            if (!childMeta || !children[i].hidden) {
              renderChildren = true
              break
            }
          }
        }
        return (menu.children && renderChildren) ? this.renderSubMenu(h, menu) : this.renderMenuItem(h, menu)
      }
    },
    renderMenu: function (h, menuTree) {
      let this_ = this
      let menuArr = []
      menuTree.forEach(function (menu, i) {
        menuArr.push(this_.renderItem(h, menu, '0', i))
      })
      return menuArr
    },
    formatMenuData(menuData, parentPath) {
      menuData.forEach(route => {
        let isFullPath = route.path ? (route.path.substring(0, 1) == '/') : false
        route.fullPath = isFullPath ? route.path : parentPath + '/' + route.path
        if (route.children) {
          this.formatMenuData(route.children, route.fullPath)
        }
      })
    },
    updateMenu () {
      const menuRoutes = this.$route.matched.filter(item => item.path !== '')
      this.selectedKeys = this.getSelectedKey(this.$route)
      let openKeys = menuRoutes.map(item => item.path)
      if (!fastEqual(openKeys, this.sOpenKeys)) {
        (!this.collapsed || this.mode === 'horizontal') ? this.cachedOpenKeys = openKeys : this.sOpenKeys = openKeys
      }
    },
    getSelectedKey (route) {
      return route.matched.map(item => item.path)
    }
  },
  render(h) {
    return h(
      Menu,
      {
        props: {
          theme: this.menuTheme,
          mode: this.$props.mode,
          selectedKeys: this.selectedKeys,
          /**
           * author: kangwj
           * 1、解决layout折叠是，子菜单弹出问题
           * 2、解决页面刷新后，菜单只能展开到二级选中问题
           * 3、mode=horizontal时，默认刷新页面不展开菜单
           */
          defaultOpenKeys: !this.collapsed && (this.$props.mode != 'horizontal') ? ((this.openKeys && this.openKeys.length > 0) ? this.openKeys : this.sOpenKeys) : []
        },
        on: {
          'update:openKeys': (openKeys) => {
            this.sOpenKeys = openKeys
          },
          click: (obj) => {
            obj.selectedKeys = [obj.key]
            this.$emit('select', obj)
          },
          openChange: (openKeys) => {
            this.$emit('openChange', openKeys)
          }
        }
      }, this.renderMenu(h, this.menuData)
    )
  }
}
