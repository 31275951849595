<template>
  <div class="com-trans-wrap">
    <div class="com-trans-left" :style="`width: ${tableWidth.left}`">
      <div class="trans-header">
        <slot name="header"></slot>
      </div>
      <div class="trans-body">
        <a-table
          :row-selection="{ selectedRowKeys: selectedRowKeys, onSelect: onSelectChange, onSelectAll: onSelectAll, columnTitle: this.isRadio ? ' ' : undefined }"
          :columns="leftColumns"
          :customRow="onClickRowMulti"
          :pagination="{current: leftPag.current, pageSize: leftPag.pageSize, position: 'top'}"
          :data-source="leftData"
          :rowKey="tableRowKey"
          :loading="tableLeftLoading"
          :scroll="{ x: tableScrollX, y: 'calc(100% - 62px)' }"
          @expandedRowsChange="expandedRowsChange($event)"
          @change="tableChange($event, 'left')">
          <template :slot="item" slot-scope="text, record, index" v-for="(item) in leftSlots">
            <slot :name="`${item}`" :text="text" :record="record" :index="index"></slot>
          </template>
        </a-table>
        <com-pagination
            :simple="true"
            class="transfer-pages"
            v-if="showLeftPagination"
            @change="tableChange($event, 'left')"
            :pagination.sync="leftPag"
        >
        </com-pagination>
      </div>
    </div>
    <div class="com-trans-right" :style="`width: ${tableWidth.right}`">
      <div class="trans-header">
        <div class="selected-num">已选{{selectedNum}}项</div>
        <a-button class="selected-alldel" @click="delAll">删除全部</a-button>
      </div>
      <div class="trans-body">
        <a-table
          :columns="rightColumns"
          :pagination="false"
          :data-source="rightPageData"
          :rowKey="tableRowKey"
          :loading="tableRightLoading"
          :scroll="{ x: tableScrollX, y: 'calc(100% - 62px)' }"
          @change="tableChange($event, 'right')"
        >
          <template :slot="item" slot-scope="text, record, index" v-for="(item) in rightSlots">
            <slot :name="`${item}`" :text="text" :record="record" :index="index"></slot>
          </template>
          <template slot="action" slot-scope="text, record, index">
            <span class="btn-del" @click="deleteAction(text, record, index)"><i class="iconhr ico-shanchu" /></span>
          </template>
        </a-table>
        <com-pagination
            :simple="true"
            class="transfer-pages"
            v-if="showRightPagination"
            @change="tableChange($event, 'right')"
            :pagination.sync="rightPag">
        </com-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import ComPagination from 'common/components/ComPagination'
import { cloneDeep } from 'lodash';
export default {
  components: {
    ComPagination
  },
  props: {
    isFixedRight:  {
        default: 'right'
    },
    NeedDelePop: {
        default: false
    },
    columns: {
      default: () => ([])
    },
    tableWidth: {
      default: () => ({
        left: '49%',
        right: '49%'
      })
    },
    viceColumns: {
      default: () => ([])
    },
    dataSource: {
      default: () => ([])
    },
    dataTarget: {
      default: () => ([])
    },
    tableScrollX: {
      default: '100%'
    },
    tableRowKey: {
      default: 'key'
    },
    leftPagination: {
      default: false
    },
    rightPagination: {
      default: false
    },
    showLeftPagination: {
      default: true
    },
    showRightPagination: {
      default: true
    },
    tableRightLoading: {
      default: false
    },
    tableLeftLoading: {
      default: false
    },
    replaceFields: {
      default: () => ({
        title:'title',
        key: 'key'
      })
    },
    isRadio: {
      default: false
    }
  },
  data () {
    return {
      // selectedNum: 0,
      leftSlots: [],
      rightSlots: [],
      leftColumns: [],
      rightColumns: [],
      leftData: [],
      rightData: [],
      selectedRowKeys: [],
      leftPag: false,
      rightPag: false,
      leftPageNum: 1,
      cacheSelectedRow: [],
      leftLoading: this.tableLeftLoading
    }
  },
  beforeCreate() {
  },
  created () {
    this.setColumns(this.columns)
    this.setRightData(this.dataTarget)
    this.setLeftData(this.dataSource)
    this.setPageCurrent(this.leftPag)
  },
  mounted () {
    this.tableLoading = true
    // this.targetKeys = targetKeys.concat(defaultTargetKeys || [])
  },
  methods: {
    expandedRowsChange(e) {
      this.$emit('expandedRowsChange', e)
    },
    onSelectAll(selected, selectedRows) {
      if(selected) {
        this.cacheSelectedRow[this.leftPageNum - 1] = selectedRows
      }
      this.calcSelectItem(this.cacheSelectedRow[this.leftPageNum - 1], selected)
    },
    calcSelectItem (data, selectedAll) {
      let addData = []
      data.forEach(record => {
        let recordKey = record[this.tableRowKey]
        if(selectedAll) {
          if(!this.selectedRowKeys.includes(recordKey)) {
            this.rightData.push(record)
            this.selectedRowKeys.push(recordKey)
            addData.push(record)
          }
        } else {
          if(this.selectedRowKeys.includes(recordKey)) {
            let keyIndex = this.selectedRowKeys.indexOf(recordKey)
            this.rightData.splice(keyIndex, 1)
            this.selectedRowKeys.splice(keyIndex, 1)
          }
        }
      })
      if(selectedAll) {
        this.tableEmitEvent(addData, 'add', 'left')
      } else {
        this.tableEmitEvent(data, 'del', 'left')
      }
    },
    onSelectChange(record) {
      if (this.isRadio) {
          this.selectedRowKeys = [record[record.length - 1]];
        this.rightData = [record];
        this.tableEmitEvent([record], 'add', 'left');
        return;
      }
      let recordKey = record[this.tableRowKey]
      let keyIndex = this.selectedRowKeys.indexOf(recordKey)
      if(!this.selectedRowKeys.includes(recordKey)) {
        this.rightData.push(record)
        this.selectedRowKeys.push(recordKey)
        this.tableEmitEvent([record], 'add', 'left')
      } else {
        this.rightData.splice(keyIndex, 1)
        this.selectedRowKeys.splice(keyIndex, 1)
        this.tableEmitEvent([record], 'del', 'left')
      }
    },
    realDeleAction (record) {
      const that = this
      let recordKey = record[that.tableRowKey]
      if(that.selectedRowKeys.includes(recordKey)) {
        let keyIndex = that.selectedRowKeys.indexOf(recordKey)
        that.selectedRowKeys.splice(keyIndex, 1)
        that.rightData.splice(keyIndex, 1)
      }
      that.tableEmitEvent([record], 'del', 'right')
    },
    deleteAction (record) {
        if (this.NeedDelePop) {
            this.$emit('rowDelete', record)
        } else {
            this.realDeleAction(record)
        }
    },
    realDelAll () {
      const rightData = this.rightData.concat()
      this.selectedRowKeys = []
      this.rightData = []
      this.tableEmitEvent(rightData,'del', 'right')
    },
    delAll () {
      if (this.NeedDelePop) {
        this.$emit('allDelete', this.rightData)
      } else {
        this.realDelAll()
      }
    },
    onClickRowMulti(record) {
      return {
        on: {
          click: () => {
            if (this.isRadio) {
              this.selectedRowKeys = [record[this.tableRowKey]];
              this.rightData = [record];
              this.tableEmitEvent([record], 'add', 'left');
              return;
            }
            let recordKey = record[this.tableRowKey]
            let keyIndex = this.selectedRowKeys.indexOf(recordKey)
            if(this.selectedRowKeys.includes(recordKey)) {
              this.rightData.splice(keyIndex, 1)
              this.selectedRowKeys.splice(keyIndex, 1)
              this.tableEmitEvent([record], 'del', 'left')
            } else {
              this.selectedRowKeys.push(recordKey)
              this.rightData.push(record)
              this.tableEmitEvent([record], 'add', 'left')
            }
          }
        }
      }
    },
    setColumns (data) {
      this.leftColumns = [...data]
      if(this.viceColumns && this.viceColumns.length ) {
        this.rightColumns = this.viceColumns.concat()
      } else {
        this.rightColumns = data.concat([
          {
            title: '操作',
            align: 'center',
            fixed: this.isFixedRight,
            width: 100,
            scopedSlots: { customRender: 'action' }
          }
        ])
      }
      if(this.leftColumns){
        this.leftColumns.forEach(column => {
          if(column.scopedSlots && column.scopedSlots.customRender) {
            this.leftSlots.push(column.scopedSlots.customRender)
          }
        })
      }
      if(this.rightColumns){
        this.rightColumns.forEach(column => {
          if(column.scopedSlots && column.scopedSlots.customRender) {
            this.rightSlots.push(column.scopedSlots.customRender)
          }
        })
      }
    },
    setLeftData (data) {
      this.leftData = data.concat()
      if (this.leftPagination) {
        const tempLPage = cloneDeep(this.leftPagination)
        if (!tempLPage.defaultCurrent) {
          tempLPage.defaultCurrent = tempLPage.current
        }
        if (!tempLPage.defaultPageSize) {
          tempLPage.defaultPageSize = tempLPage.pageSize
        }
        if (!tempLPage.total) {
          tempLPage.total = 0
        }
        const finalPage = {
          defaultCurrent: tempLPage.defaultCurrent || tempLPage.current,
          current: tempLPage.current || tempLPage.defaultCurrent,
          pageSize: tempLPage.pageSize || tempLPage.defaultPageSize,
          defaultPageSize: tempLPage.defaultPageSize  ||  tempLPage.pageSize,
          total: tempLPage.total,
        }
        this.leftPag = finalPage
      } else {
        this.leftPag = { total: data.length, defaultPageSize: 20,defaultCurrent: 1, size: 'small' }
      }
    },
    setRightData (data) {
      this.rightData = data.concat()
      this.selectedRowKeys = []
      data.forEach(item => {
        const key = item[this.tableRowKey]
        if(!this.selectedRowKeys.includes(key)) {
          this.selectedRowKeys.push(key)
        }
      })
      // this.selectedRowKeys.push('8d97d158-aa1e-4b4d-91e7-b2cd564e959c')
      if (this.rightPagination) {
        // this.rightPag = this.rightPagination
        const tempRPage = cloneDeep(this.rightPagination)
        if (!tempRPage.defaultCurrent) {
          tempRPage.defaultCurrent = tempRPage.current
        }
        if (!tempRPage.defaultPageSize) {
          tempRPage.defaultPageSize = tempRPage.pageSize
        }
          const finalPage = {
            defaultCurrent: tempRPage.defaultCurrent || tempRPage.current,
            current: tempRPage.current || tempRPage.defaultCurrent,
            pageSize: tempRPage.pageSize || tempRPage.defaultPageSize,
            defaultPageSize: tempRPage.defaultPageSize  ||  tempRPage.pageSize,
            total: data.length,
          }
        console.log(finalPage)
        this.rightPag = finalPage
      } else {
        this.rightPag = { total: data.length,defaultCurrent: 1, defaultPageSize: 20, current: 1, pageSize: 20   }
      }
    },
    tableEmitEvent(data, eventName, type) {
      // type 代表是左触发还是右侧触发的事件
      const obj = {
        del: [],
        add: []
      }
      obj[eventName] = data
      // this.selectedNum = this.rightData.length
      this.$emit('change', this.rightData, obj, this.selectedRowKeys, type)
    },
    tableChange (e, type) {
      if (type === 'left') {
        this.leftPageNum = e.current
        this.leftPag.defaultCurrent = e.current
        this.leftPag.current = e.current
        this.$emit('pageChange', e, type)
      } else {
        this.rightPag.defaultCurrent = e.current
        this.rightPag.current = e.current
        this.$emit('pageChange', e, type)
      }
      console.log(type)
    },
    setPageCurrent (data) {
      if(data) {
        this.leftPageNum = data.defaultCurrent
      }
    }
  },
  computed: {
        rightPageData () {
          // debugger
            const riPgedata = cloneDeep(this.rightData)
            const rPageSize = this.rightPag.pageSize
            const rPageIndex = this.rightPag.current
          if (riPgedata.length > rPageSize) {
            return riPgedata.splice(((rPageIndex-1)*rPageSize),rPageSize)
          } else {
            return riPgedata
          }
        },
        selectedNum () {
            return this.rightData.length
        }
    },
  watch: {
    'leftPagination.total': function (val) {
        this.leftPag.total = val
    },
    // 'rightPagination.total': function (val) {
    //   this.rightPag.total = val
    // },
    columns(n) {
      this.setColumns(n)
    },
    dataSource(n) {
      this.setLeftData(n)
    },
    dataTarget(n) {
      this.setRightData(n)
    },
    leftPag (n) {
      // console.log(n)
      this.setPageCurrent(n)
    },
    rightData (n) {
      this.rightPag.total = n.length
    }
  }
}
</script>
<style lang="less" scoped>
/deep/.ant-table-body{
  min-height: 85% !important;
}
</style>
