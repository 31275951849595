import reqMapSet from './reqMapSet'
const VueAxios = {
  vm: {},
  // eslint-disable-next-line no-unused-vars
  install(Vue, request) {

    if (this.installed) {
      return
    }
    this.installed = true

    if (!request) {
      // eslint-disable-next-line no-console
      console.error('You have to install axios')
      return
    }
    Vue.axios = request
    Object.defineProperties(Vue.prototype, {
      axios: {
        get: function get() {
          return request
        }
      },
      $http: {
        get: function get() {
          return request
        }
      }
    })
  }
}
export {
  VueAxios
}

