const data = {
    columns: [
        {
            title:'序号',
            dataIndex: 'index',
            width: '80px',
            scopedSlots: {customRender: 'index'}
        },
        // {
        //     title: '表名',
        //     dataIndex: 'tblcod',
        //     width: '150px',
        //     align: 'center',
        //     scopedSlots: {customRender: 'tblcod'}
        // },
        {
            title: '字段显示名称',
            dataIndex: 'fldcodTitle',
            width: '160px',
            align: 'center',
            scopedSlots: {customRender: 'displayName'}
        },
        {
            title: '占位内容',
            dataIndex: 'placeholder',
            width: '120px',
            align: 'center',
            scopedSlots: {customRender: 'placeholder'}
        },
        // {
        //     title: '字段类型',
        //     dataIndex: 'fldtyp',
        //     width: '100px',
        //     align: 'center',
        //     scopedSlots: {customRender: 'fldtyp'}
        // },
        {
            title: '查询类型',
            dataIndex: 'queryType',
            width: '120px',
            align: 'center',
            scopedSlots: { customRender: 'queryType' }
        },
        {
            title: '查询参数名称',
            dataIndex: 'queryName',
            width: '160px',
            align: 'center',
            scopedSlots: {customRender: 'queryName'}
        },
        {
            title: '是否多选',
            dataIndex: 'ismultivalued',
            width: '160px',
            align: 'center',
            scopedSlots: {customRender: 'ismultivalued'}
        },
        {
            title: '默认值',
            dataIndex: 'defaultValue',
            align: 'center',
            width: '100px',
            scopedSlots: {customRender: 'defaultValue'}
        },
        {
            title: '是否可删除',
            dataIndex: 'editenable',
            width: '120px',
            align: 'center',
            scopedSlots: {customRender: 'maintainable'}
        },
        {
            title: '操作',
            dataIndex: 'delete',
            width: '100px',
            align: 'center',
            scopedSlots: {customRender: 'delete'}
        }
    ]
}
export default data
