import axios from 'axios'
import {VueAxios} from './useAxios'
import {message} from 'ant-design-vue'
import reqMapSet from './reqMapSet'
import {ACCESS_TOKEN, TOKEN_NAME} from 'common/confing'
import qs from 'common/lib/qs'
import store from '@/store'

// import globalStore from '@/micro/globalStore'
// setTimeout(() => {
//   console.log('请求接口 store', store.dispatch('global/openLogin', {toUrl: '24234' }))
// }, 2000)
// 创建 axios 实例
const request = axios.create({
  timeout: 0 // 请求超时时间
})
const ok = 200

const err = (error) => {
  // const token = sessionStorage.getItem(ACCESS_TOKEN)
  // if (error.response) {
  //   const data = error.response.data
  //   if (error.response.status === 403) {
  //   }
  //   if (error.response.status === 401 && !(data.result && data.result.isLogin)) {
  //     if (token) {
  //     }
  //   }
  // }
  console.log('[[err]]' + error) // for debug
  // 错误信息赋值在 window 上
  window.__axiosError__ = error

  try {
    /* 仅在预生产 或 商网
    * 单点登录过期
    */
    const networkType = ['nw', 'sw']
    if (
      networkType.includes(G.ENV.networkType) &&
      error.response
      && error.response.status === 401
    ) {
      try {
        console.log(JSON.stringify(error.response))
        message.error('身份验证未通过！')
        if (error.response.data.error.loginPage) {
          window.location.href = error.response.data.error.loginPage
          return Promise.reject(error)
        }
      } catch (e) {
        console.log('[[处理 401 信息错误]]', e, error.response)
      }
    }
  } catch (err) {
    console.log('[[catch]]', err)
  }
  let msg = ''
  if (error.message.indexOf('timeout') > -1) {
    msg = '请求超时，请刷新后重试！'
  } else if (error.message.indexOf('Network Error') > -1) {
    msg = '网络错误，请刷新后重试！'
  } else {
    msg = error.message
  }
  message.error(msg)
  return Promise.reject(error)
}

// request interceptor
request.interceptors.request.use(config => {
  const token = localStorage.getItem(ACCESS_TOKEN)

  if (token) {
    config.headers[TOKEN_NAME] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
  }
  // 单点登录解决方案 header 头 增加 X-Requested-With，会引发 401
  config.headers['X-Requested-With'] = 'XMLHttpRequest'
  if (config.headers['Content-Type'] == null) {
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=UTF-8'
    config.transformRequest = [obj => qs.stringify(obj, {indices: false})]// 解决post传输格式问题。
  }
  if (config.method === 'get') {
    // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
    config.paramsSerializer = function(params) {
      return qs.stringify(params, { arrayFormat: 'repeat' })
    }
  }
  return config
}, err)

//处理响应code
function handleResponseCode(response) {
  // return Promise.resolve(response)
  const code = response.code
  let msg = response.message || '未知错误！'
  //成功情况，直接返回
  if (code === ok || location.href.includes('smartTools')|| location.href.includes('GM_login')) {
    return Promise.resolve(response)
  }

  //  无权访问错误提示
  if (code === 403) {
    message.error('您无权限访问该功能', 5)
  }
  if (code === 401 || code === 501) {
    //todo 是否跳转到登录页面
    msg = '你已经登出或登录已超时，请重新登录。'
    message.error(msg, 5)
    // store.dispatch('user/userLogout')
    store.commit('user/setUserLogout')
    localStorage.setItem(ACCESS_TOKEN, '')
    // 如果为内网，预生产环境 则 执行 ticket 登录
    const networkType = ['nw', 'sw']
    // # 测试代码 START
    // if (process.env.NODE_ENV === 'development') networkType.push('beida')
    // # 测试代码 END
    // if (networkType.includes(G.ENV.networkType)) {
    //   store.dispatch(
    //     'global/openLogin',
    //     { toUrl: window.location.pathname + window.location.search }
    //   )
    // } else {
    //   // 如果不为内网、预生产环境则，走账密登录，保存现有 登录逻辑
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   window.location.href = "/login"
    // }
    if (process.env.VUE_APP_LOGIN_TYPE === 'sso') {
      location.href = '/login?back=' + encodeURIComponent(location.href);
    } else {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/login"
      location.href = '/login'
    }
    return
  } else if (response.type === 'application/octet-stream' || response.type === 'text/xml' || response.type === 'application/json') {
    // 流文件处理
    return Promise.resolve(response)
  } else if(code < 700 || code >= 800) {
    //通用错误提示
    message.error(msg)
  }
  return Promise.reject(response)
}
// response interceptor
request.interceptors.response.use((response) => {
  return handleResponseCode(response.data)
}, err)

const installer = {
  vm: {},
  install(Vue, pathMap) {
    Vue.use(VueAxios, request, pathMap || {})
  }
}

const reqMethods = (option) => {
  let req = reqMapSet(request, option)
  return {
    req,
    vm: {},
    install(Vue) {
      Vue.use(VueAxios, req)
    }
  }
}
export {
  installer,
  request,
  reqMethods
}
export default request

