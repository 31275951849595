/*
 * @file: 系统管理模块配置
 * @author:
 */
export default {
    // 角色配置
    roleConfig: {
        // 角色复制时默认为本单位 0否 1是
        roleCopyDefaultThisNode: false
    }
}
