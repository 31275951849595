<script>
import {Modal} from 'ant-design-vue'
// import dragModal from './dragModal'
import { eventBinding } from '../windowResize/libs/event-binding'
export default {
    inheritAttrs: false,
    model: {
        prop: 'visible',
        event: 'change'
    },
    props: {
        dragModal: Boolean,
        value: {
            default: false
        }
    },
    data() {
        return {}
    },
    render(h, context) {
        const { $listeners, $props, $slots, $scopedSlots } = this;
        const children = $slots.default;
        const slotsKey = Object.keys($slots);
        const scopedSlotsTemp = {};
        slotsKey.forEach(name => {
            scopedSlotsTemp[name] = () => $slots[name];
        });
        const cumEvent = {
            change: (val) => { this.$emit('change', val) }
        }
        const treeNodeProps = {
            on: { ...$listeners, ...cumEvent},
            // props: this.$attrs,
            attrs: this.$attrs,
            scopedSlots: {
                ...scopedSlotsTemp,
                ...$scopedSlots ,
            }
        };
        return (
            <Modal wrapClassName='ant-alert-wrap' v-drag-modal {...treeNodeProps}>{children}</Modal>
        )
    },
    directives: {
        'drag-modal': {
            bind(el, binding, vnode) {
                let _this = vnode.context
                if(!_this.dragModal) {
                    return
                }
                const customParams = binding.value; // 从指令绑定值取来参数
                eventBinding(el.querySelector('.ant-modal'), customParams);
            }
        }
    }
}
</script>
<style>
.ant-alert-wrap .ant-modal {
  padding-bottom: 0;
}
</style>
