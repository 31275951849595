<template>
    <div class="com-upload-box">
        <a-config-provider :locale="zhCN">
            <div class="com-upload-box">
                <div class="com-upload-isBatchDel" v-if="!readOnly&&batchDeleteAble">
                    <a-button @click="handleBatchDel">批量删除</a-button>
                </div>
                <div class="files-list-box" v-for="(item,index) in customFile">
                    <div class="files-list-left-text" :style="{ width: labelWidth, textAlign: labelAlign }">
                        {{item.ftype}}<span
                            class="required-star" v-if="item.required">*</span><span class="required-star"
                                                                                     v-else></span></div>
                    <div class="files-list-right-content">
                        <div class="files-l-r-upload">
                            <multfileupload :isNeedLevel.sync="isNeedLevel1" :DelePmt.sync="DelePmt" :DownLoadPmt.sync="DownLoadPmt" :ids.sync="item.fid"
                                            :files.sync="item.files" :key="index" :isShowTip="isShowTip"
                                            :limitCount="limitCount" :limitFileType="limitFileType" :readOnly="readOnly"
                                            :batchDeleteAble="batchDeleteAble"
                                            :isAutoLoad="isAutoLoad"
                                            :clickTextDown="clickTextDown"
                                            @fileList="getfileList($event, item.files, index)"
                                            @autoUpload="autoUpload"
                                            @openFileDialogOnClickEvt="openFileDialogOnClickEvt"></multfileupload>
                        </div>
                    </div>
                </div>
            </div>
        </a-config-provider>
    </div>
</template>

<script>
    import {cloneDeep} from 'lodash'
    import {req} from 'common/api/index'
    import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
    import multfileupload from './multfileupload'


    export default {
        props: {
            clickTextDown: {
                type: Boolean,
                default: false
            },
            isAutoLoad: {
                type: Boolean,
                default: false
            },
            labelWidth: {
                type: String,
                default: '100px'
            },
            labelAlign: {
                type: String,
                default: 'right'
            },
            customFile: {
                type: Array
            },
            isShowTip: {
                type: Boolean,
                default: true
            },
            limitCount: {
                type: Number,
                default: 8
            },
            limitFileType: {
                type: String,
                default: 'txt、pdf、doc、docx、xls、xlsx、ppt、jpg、png、bmp、gif、rar、zip'
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            batchDeleteAble: {
                type: Boolean,
                default: false
            },
            businessId: '',
            isNeedLevel: true,
            DelePmt: {
                type: String,
                default: 'null'
            },
            DownLoadPmt: {
                type: String,
                default: 'null'
            }

        },
        components: {
            multfileupload
        },
        data() {
            return {
                zhCN,
                StorageKey: "_ImgViewSrcCache",
                isNeedLevel1: false
            }
        },
        created() {
            if (this.businessId) {
                this.LoadDataMult(this.businessId)
            }
        },
        watch: {
            customFile: function (files) {
                // console.log(files)
            },
            businessId: function (businessId) {
                if (businessId) {
                    this.LoadDataMult(businessId)
                } else {
                    this.customFile.forEach(customItem => {
                        if (item.ftype === customItem.ftype) {
                            customItem.files = []
                        }
                    })
                }
            }
        },
        methods: {
            // 判断是否图片
            isImgType: function (filetype) {
                let ctypeArr = [
                    "png",
                    "image",
                    "jpeg",
                    "jpg",
                    "gif",
                    "tiff",
                    "x-icon",
                    "x-bmp",
                    "jiff",
                ];

                if (ctypeArr.indexOf(filetype) >= 0) {
                    return true;
                } else {
                    return false;
                }
            },
            // 加载附件
            LoadDataMult(businessId, isUploadRefresh) {
                const that = this
                // http://localhost:7000/dev-api/sym/customtableF/findCustomeList
                if (businessId.length > 0) {
                    req('post_common_upload_getfile_withPage', {
                        businessId: businessId,
                        currentPage: 1,
                        pageSize: 100
                    }).then(res => {
                        if (res.code === 200) {
                            const baseUrl = process.env.VUE_APP_BASE_API
                            let resfileList = res.data.list
                            this.customFile.forEach(customItem => {
                                customItem.files = []
                            })
                            resfileList.forEach(item => {
                                let url = that.isImgType(item.filetype) ? `${baseUrl}/platservice/fileupload/previewImage?fid=${item.fid}` : `http://192.168.124.25:8081/platservice/fileupload/download?ids=${item.fid}`
                                const fileObj = {
                                    uid: item.fid,
                                    fid: item.fid,
                                    name: item.filename,
                                    status: 'done',
                                    level: item.secretlevel,
                                    isSelect: false,
                                    url: url,
                                    thumbUrl: that.isImgType(item.filetype) ? url : null,
                                    originFileObj: {
                                        level: item.secretlevel,
                                        url: url,
                                        name: item.filename,
                                        size: item.filesize
                                    },
                                    size: item.filesize,
                                    filetype: item.filetype
                                }

                                if (this.isImgType(fileObj.filetype)) {
                                    this.AddSrcCache(fileObj); //添加到预览缓存
                                }
                                this.customFile.forEach(customItem => {
                                    if (item.ftype === customItem.ftype) {

                                        // customItem.files.push(fileObj)
                                        if (isUploadRefresh) {
                                            const sdfsadfsd = customItem.files.findIndex(item4 => !item4.fid)
                                            if (sdfsadfsd === -1) {
                                                customItem.files.push(fileObj)
                                            } else {
                                                this.$set(customItem.files[sdfsadfsd], 'fid', item.fid)
                                                // customItem.files[sdfsadfsd].fid = item.fid
                                            }
                                        } else {
                                            customItem.files.push(fileObj)
                                        }

                                    }
                                })
                            })
                            console.log(this.customFile)
                            // this.$emit('update:customFile', this.customFile)
                        }
                    })

                }
            },
            //添加到預覽緩存組中
            AddSrcCache: function (item) {
                let id = item.fid;
                let src = item.url;
                let that = this;
                let cacheData = JSON.parse(sessionStorage.getItem(that.StorageKey));
                if (!cacheData) {
                    cacheData = new Object();
                    cacheData[that.group] = {};
                }
                //保存
                let obj = cacheData[that.group];
                if (!obj) {
                    obj = new Object();
                }
                //判断存在不
                obj[id] = {src: src, title: item.name};
                cacheData[that.group] = obj;
                sessionStorage.setItem(that.StorageKey, JSON.stringify(cacheData));
            },
            getfileList(e, files, index) {
                const currenCustomFile = this.customFile
                currenCustomFile[index].files = e
                console.log(e)
                this.$emit('update:customFile', currenCustomFile)
                this.$emit('updateFile');
            },
            // 自动上传
            autoUpload() {
               this.uploadPost()
            },
            async uploadPost(businessId, isAdd) {
                const tempArr = cloneDeep(this.customFile)
                const formData = new FormData();
                const id = businessId || this.businessId;
                formData.append('businessId', id ? id : null);
                let controlType = false;
                console.log(tempArr)
                const avoidFiles = []
                tempArr.forEach((e, i) => {
                    if (e.files.length > 0) {
                        e.files.forEach(el => {
                            if (el.uid === el.fid) {

                            } else {
                                controlType = true;
                                avoidFiles.push({
                                    ftype: e.ftype,
                                    file: el,
                                    secretLevel: e.files[0].level,
                                })
                                // formData.append('files[' + i + '].files', el.originFileObj);
                            }
                        })
                        // if (formData.get('files[' + i + '].files')) {
                        //     formData.append('files[' + i + '].ftype', e.ftype);
                        //     formData.append('files[' + i + '].secretLevel', e.files[0].level);
                        // }
                    }
                })
                avoidFiles.forEach((file,index) => {
                    formData.append('files[' + index + '].files', file.file.originFileObj)
                    formData.append('files[' + index + '].ftype', file.ftype)
                    formData.append('files[' + index + '].secretLevel', file.secretLevel)
                })
                console.log(controlType, "controlType");
                // console.log(avoidFiles);
                return new Promise(async (resolve, reject) => {
                    if (controlType) {
                        try {
                            const res = await req('file_common_uploadbyType', formData, true);
                            if (res.code === 200) {
                                const baseUrl = process.env.VUE_APP_BASE_API
                                if (!isAdd) {
                                    this.$message.success('上传成功');
                                }
                                this.$emit('uploadFileResultList', res.data.uploadFileResultList);
                                this.LoadDataMult(this.businessId, true)
                                resolve(res.data.uploadFileResultList);
                                return res.data.uploadFileResultList;
                                // this.$emit('update:customFile', res.data.uploadFileResultList)
                            } else {
                                this.$message.error('上传失败');
                                this.LoadDataMult(this.businessId, true)
                                reject('上传失败');
                            }
                        } catch (e) {
                            reject(e);
                        }
                    } else {
                        reject('没有新增加的上传附件');
                    }
                });
                // if (controlType) {
                //     const res = await req('file_common_uploadbyType', formData, true);
                //     if (res.code === 200) {
                //         const baseUrl = process.env.VUE_APP_BASE_API
                //         this.$message.success('上传成功');
                //
                //         this.$emit('uploadFileResultList', res.data.uploadFileResultList);
                //         return res.data.uploadFileResultList;
                //         // this.$emit('update:customFile', res.data.uploadFileResultList)
                //     } else {
                //         this.$message.error('上传失败');
                //         return null;
                //     }
                // } else {
                //     console.log('dddd');
                //     return new Promise((resolve,reject) => {
                //         console.log("返回错误");
                //         reject('返回错误');
                //         resolve('成功');
                //     });
                // }
            },
            openFileDialogOnClickEvt() {
                this.$emit('openEvt')
            }

        }
    }
</script>

<style lang="less">
    .com-upload-box {
        .files-list-box {
            display: flex;
            line-height: 34px;
            margin-bottom: 15px;
        }

        .files-list-left-text {
            text-align: right;
            padding-right: 8px;
            padding-left: 8px;
            vertical-align: bottom;
            /*min-width: 150px;*/
        }

        .files-list-right-content {
            border: 1px solid #ccc;
            border-radius: 4px;
        }

        .files-l-r-upload {
            padding-right: 10px;
        }

        .required-star {
            display: inline-block;
            width: 5px;
            margin-left: 4px;
            color: #f5222d;
            font-size: 14px;
            font-family: SimSun, sans-serif;
            line-height: 1;
        }
    }

</style>
