export default {
  path: '/base',
  name: 'base',
  redirect: '/base/LawEleMgmt/LawOrg',
  meta: {
    module: 'base',
    title: '业务管理',
    roles: ['admin', 'group', 'user'],
    icon: 'ico-xinxiqun'
  },
  children: [
    {
      path: '/base/LawEleMgmt',
      name: 'LawEleMgmt',
      redirect: '/base/LawEleMgmt/LawOrg',
      meta: {
        module: '业务管理',
        title: '执法要素管理',
        roles: ['admin', 'group', 'user'],
        icon: ''
      },
      children: [
        {
            path: '/base/LawEleMgmt/LawOrg',
            name: 'LawOrg',
            meta: {
                module: '执法要素管理',
                title: '执法机构',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/base/LawEleMgmt/LawSubject',
            name: 'LawSubject',
            meta: {
                module: '执法要素管理',
                title: '执法主体',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/base/LawEleMgmt/LawStaff',
            name: 'LawStaff',
            meta: {
                module: '执法要素管理',
                title: '执法人员',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/base/LawEleMgmt/LawRegulation',
            name: 'LawRegulation',
            meta: {
                module: '执法要素管理',
                title: '法律法规',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/base/LawEleMgmt/PowersList',
            name: 'PowersList',
            meta: {
                module: '执法要素管理',
                title: '权力清单',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/base/LawEleMgmt/Test',
            name: 'Test',
            meta: {
                module: '执法要素管理',
                title: '测试流程',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
    ]
    },
  ]
}
