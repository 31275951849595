<!--权限托管-->
<template>
    <div class="qxtg">
        <div class="qxtg-form">
            <a-form-model
                    style="margin-left: 15%"
                    :colon="false"
                    ref="form"
                    labelAlign="left"
                    :rules="userGroupRules"
                    :model="trusteeship"
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol">
                <!--当前登录用户-->
                <a-form-model-item label="托管人"><a-input v-model="userName" disabled/></a-form-model-item>
                <a-form-model-item label="被托管人" prop="toTrusteeName">
                    <a-input v-model="trusteeship.toTrusteeName" ref="toTrusteeInput" placeholder="单击选择托管人！" @click="selectUser"/>
                </a-form-model-item>
                <a-form-model-item label="是否确定权限托管">
                    <a-radio-group v-model="trusteeship.permission" @change="onChange">
                        <a-radio :value="1">是</a-radio>
                        <a-radio :value="0">否</a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="生效日期" prop="effectiveTime">
                    <a-date-picker
                            v-model="trusteeship.effectiveTime"
                            :format="dateFormat"
                            :valueFormat="dateFormat"
                            :disabled-date="disabledStartDate"
                            @openChange="handleStartOpenChange"/>
                </a-form-model-item>
                <a-form-model-item label="失效日期" prop="failureTime">
                    <a-date-picker
                            v-model="trusteeship.failureTime"
                            :format="dateFormat"
                            :valueFormat="dateFormat"
                            :disabled-date="disabledEndDate"
                            :open="endOpen"
                            @openChange="handleEndOpenChange"/>
                </a-form-model-item>
            </a-form-model>
            <div class="qxtg-btn">
                <a-button style="margin-right: 10px" @click="cancel">取消</a-button>
                <a-button type="primary" @click="handleSubmit">保存</a-button>
            </div>
        </div>
        <user-list ref="userListComp" @selectedUser="selectedUser"/>
    </div>
</template>

<script>
    import UserList from './UserList'
    import { mapState } from 'vuex'
    import {req} from 'common/api/index'
    import moment from 'moment'

    export default {
        name: 'authTruModal',
        components: {
            UserList
        },
        data () {
            return {
                trusteeship: {
                    effectiveTime: null,
                    failureTime: null
                },
                endOpen: false,
                loginUserName: 'admin',
                dateFormat: 'YYYY-MM-DD',
                form: this.$form.createForm(this, { name: 'coordinated' }),
                value: 1,
                formItemLayout: {
                    labelCol: { span: 4 },
                    wrapperCol: { span: 8 }
                },
                userGroupRules: {
                    toTrusteeName: [{ required: true, message: '单位名称不能为空', trigger: 'blur' }],
                    effectiveTime: [{ required: true, message: '生效日期不能为空', trigger: 'change' }],
                    failureTime: [{ required: true, message: '失效日期不能为空', trigger: 'change' }]
                }
            }
        },
        created () {
            // Vue.use(api)
            this.getTrusteeship()
        },
        methods: {
            disabledStartDate (effectiveTime) {
                const failureTime = moment(this.trusteeship.failureTime)
                if (!effectiveTime || !failureTime) {
                    return false
                }
                return effectiveTime.valueOf() > failureTime.valueOf()
            },
            disabledEndDate (failureTime) {
                const effectiveTime = moment(this.trusteeship.effectiveTime)
                if (!failureTime || !effectiveTime) {
                    return false
                }
                return effectiveTime.valueOf() >= failureTime.valueOf()
            },
            handleStartOpenChange (open) {
                if (!open) {
                    this.endOpen = true
                }
            },
            handleEndOpenChange (open) {
                this.endOpen = open
            },
            getTrusteeship () {
                req('post_userTrusteeship_get').then(res => {
                    if (res.data) {
                        this.trusteeship = res.data
                        console.log('this.trusteeship', this.trusteeship)
                    } else {
                        this.trusteeship = {}
                    }
                })
            },
            doSaveTrusteeship () {
                const param = { ...this.trusteeship }
                req('post_userTrusteeship_save', param, true).then(res => {
                    this.$message.success('权限托管成功')
                    this.getTrusteeship()
                })
            },
            // 保存（提交）
            handleSubmit (e) {
                const that = this
                this.$refs.form.validate(valid =>{
                    if(valid){
                        that.doSaveTrusteeship()
                        that.$emit('cancleModal')
                    }
                })

            },
            selectUser (e) {
                this.$refs.userListComp.showUserDialog()
            },
            //  返回选择用户id
            selectedUser (user) {
                this.trusteeship.toTrusteeName = user.description
                console.log('被托管人', this.trusteeship.toTrusteeName)
                const id = user.id
                this.trusteeship.toTrustee = id
                this.$set(this.trusteeship, 'toTrustee', id)
                this.$refs.toTrusteeInput.blur()
            },
            onChange (e) {
                console.log('radio checked', e.target.value)
            },

            // 取消
            cancel () {
                this.getTrusteeship()
                this.$emit('cancleModal')
            }
        },
        computed: {
            // 通过global获取user的信息(从vuex获取用户信息)
            ...mapState('global', {
                userInfo: state => state.userInfo
            }),
            userName () {
                if (!this.userInfo) {
                    return ''
                }
                return this.userInfo.userName
            }

        }
    }
</script>
<style lang="less" scoped>
    .qxtg {
        width: 100%;
        height: 100%;
        background-color: white;
        .qxtg-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: 8px;
            border-top: 1px solid #D8D8D8;
        }
        /deep/ .ant-input:placeholder-shown {
            width: 240px;
            border-radius: 5px;
        }
        /deep/ .ant-input {
            border-radius: 0;
            width: 240px;
        }
        /deep/.ant-col-4 {
            width: 27%;
        }
    }
</style>
