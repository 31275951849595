import './treeList.less'
import fa from "../../lib/element/src/locale/lang/fa";
export default {
  props: {
    dataSource: {
      default: () => ([])
    },
    defaultTargetKeys: {
      default: () => ([])
    },
    replaceFields: {
      default: () => ({
        title: 'title',
        key: 'key'
      })
    }
  },
  data() {
    return {
      listData: [],
      targetKeys: [],
      expandedKeys: []
    }
  },
  methods: {
    // <!-- 2021-01-08 添加 clearHandle (黎镖) -->
    clearHandle () {
      this.targetKeys = []
      const setCheckFalse = (data) => {
        for (var ind in data) {
          var item = data[ind]
          item.checked = false
          if (item.children && item.children.length) {
            setCheckFalse(item.children)
          }
        }
      }
      setCheckFalse(this.listData)
    },
    getIndeterminate (itemData) {
      const newArr = []
      const getData = (data) => {
        data.forEach(item => {
          newArr.push(item[this.replaceFields.key])
          if(item.children && item.children.length) {
            getData(item.children)
          }
        })
      }
      getData(itemData)
      return newArr
    },
    childRender(data, childClass) {
      const { targetKeys, replaceFields, expandedKeys } = this;
      let list = []
      data.forEach(item => {
        const isChildren = item.children && item.children.length
        let indeterminate = false
        item.checked = targetKeys.includes(item[replaceFields.key]) || false
        if(isChildren) {
          let getKeyList = this.getIndeterminate(item.children)
          for (let i = 0; i < getKeyList.length; i++) {
            const itemKey = getKeyList[i]
            if(targetKeys.includes(itemKey)) {
              indeterminate = true
              break;
            }
          }
          const checkAll = []
          getKeyList.forEach(elKey => {
            checkAll.push(targetKeys.includes(elKey) || false)
          })
          if(!checkAll.includes(false)) {
            item.checked = true
            indeterminate = false
          }
        }
        let isExpanded = false
        if(expandedKeys.includes(item[replaceFields.key])) {
          isExpanded = true
        }
        const itemClick = () => {
          if(isChildren) {
            item.children.forEach(el => {
              if(targetKeys.includes(el[replaceFields.key])) {
                const keyIndex = targetKeys.indexOf(el[replaceFields.key])
                targetKeys.splice(keyIndex, 1)
              } else {
                targetKeys.push(el[replaceFields.key])
              }
            })
          } else {
            if(targetKeys.includes(item[replaceFields.key])) {
              const keyIndex = targetKeys.indexOf(item[replaceFields.key])
              targetKeys.splice(keyIndex, 1)
            } else {
              targetKeys.push(item[replaceFields.key])
            }
          }
          // 2021-01-08 添加 setTimeout (黎镖)
          setTimeout(() => {
            for (var keysInd in targetKeys) {
              var keysItem = targetKeys[keysInd]
              for (var ind in this.listData) {
                var i = this.listData[ind]
                if (i[replaceFields.key] === keysItem && i.loadChildren) {
                  targetKeys.splice(keysInd, 1)
                  expandedKeys.push(keysItem)
                  this.$emit('select', targetKeys)
                }
              }
            }
          }, 100)
          this.$emit('select', targetKeys)
        }
        const expandedClick = () => {
          if(expandedKeys.includes(item[replaceFields.key])) {
            let keyIndex = expandedKeys.indexOf(item[replaceFields.key])
            expandedKeys.splice(keyIndex, 1)
          } else {
            expandedKeys.push(item[replaceFields.key])
          }
          event.stopPropagation()
        }
        list.push(<li class='item'>
          <p class='item-text' onClick={itemClick}>
            {isChildren &&
            <a-icon
              type='caret-down'
              onClick={expandedClick}
              class={[
                'carte-ico',
                {
                  'ico_expanded': expandedKeys.includes(item[replaceFields.key])
                }
              ]}
            />}
            <a-checkbox onClick={itemClick} checked={item.checked} class={isChildren || 'no_child' } indeterminate={indeterminate}>
              {item[replaceFields.title]}
            </a-checkbox>
          </p>
          {
            (isChildren && isExpanded)
            && this.childRender(item.children, 'child')
          }
        </li>)
      })
      return (
        <ul class={['list', {'child': childClass}]}>
          {list}
        </ul>
      )
    },
    setEvenBgColor () {
      let itemText = this.$refs.customTree.querySelectorAll('.item-text')
      for(let i = 1; i < itemText.length; i+=2) {
        itemText[i].className += ' even-row'
      }
    }
  },
  watch: {
    dataSource(n) {
      this.listData = n
      this.$nextTick(() => {
        this.setEvenBgColor()
      })
    },
    defaultTargetKeys(n) {
      this.targetKeys = n.concat()
    }
  },
  render() {
    return (<div class='custom-tree' ref='customTree'>
      {this.childRender(this.listData)}
    </div>)
  }
};
