<template>
  <a-tree
    v-model="checkedKeys"
    :disabled="disable"
    checkable
    :checkStrictly="!this.isCascadeUp && !this.isCascadeDown"
    :expanded-keys.sync="expandedKeys"
    :replaceFields="replaceFields"
    :auto-expand-parent="autoExpandParent"
    :defaultExpandedKeys="defaultExpandedKeys"
    :selected-keys="selectedKeys"
    :tree-data="listData"
    :loadData="loadData"
    @expand="onExpand"
    @select="onSelect"
    @check="onCheck"
  />
</template>
<script>
  export default {
    props: {
      dataSource: {
        default: () => ([])
      },
      defaultChecked: {
        default: () => ([])
      },
      defaultExpandedKeys: {
        default: () => ([])
      },
      loadedKeys: {
        default: () => ([])
      },
      replaceFields: {
        default: () => ({
          title:'title',
          key: 'key'
        })
      },
      loadData: {
        type: Function,
        default: null
      },
      disable: {
        default: false
      },
      // 是否向上级联
      isCascadeUp: {
        type: Boolean,
        default: false
      },
      // 是否向下级联
      isCascadeDown: {
        type: Boolean,
        default: true
      },
      // 是否顶级节点有CheckBox框
      isCanCheckTopNode: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        expandedKeys: [],
        autoExpandParent: true,
        checkedKeys: [],
        selectedKeys: [],
        listData:[],
        selectKey: []
      };
    },
    created () {
      this.initCompoData()
    },
    // computed: {
    //     _loadData () {
    //         return this.loadData? this.loadData : null
    //     }
    // },
    watch: {
        checkedKeys(val) {
            this.selectKey = [...this.defaultChecked]
            // console.log('onCheck1', val, this.defaultSelectedKeys);
        },
        defaultChecked(n) {
            this.checkedKeys = JSON.parse(JSON.stringify(n))
        },
        dataSource(n) {
            const dataSource = JSON.parse(JSON.stringify(n))
            this.setCheckBox(dataSource)
            this.listData = [...dataSource]
            this.$nextTick(() => {
                this.setEvenBgColor()
            })
        },
        defaultExpandedKeys(val) {
          this.$nextTick(() => {
            this.expandedKeys = JSON.parse(JSON.stringify(val))
          })
        }
    },
    mounted() {
        this.checkedKeys = JSON.parse(JSON.stringify(this.defaultChecked))
        this.$nextTick(() => {
            this.expandedKeys = JSON.parse(JSON.stringify(this.defaultExpandedKeys))
        })
    },
    methods: {
      // 初始化组件数据
      initCompoData () {
        this.setCheckBox(this.dataSource)
        this.listData = [...this.dataSource]
      },
      // 设置复选框
      setCheckBox (tree) {
        if (tree && tree.length) {
          tree.forEach(tr => {
            if (this.loadData) {
              if (!tr.isLeaf) {
                tr.checkable = this.isCanCheckTopNode
                if (tr.children && (this.loadData ? true : tr.children.length)) {
                  this.setCheckBox(tr.children)
                }
              } else {
                tr.isLeaf = true
              }
            } else {
              if (tr.children && tr.children.length) {
                tr.checkable = false
                this.setCheckBox(tr.children)
              } else {
                tr.isLeaf = true
              }
            }
          })
        }
      },
      setEvenBgColor () {
        // console.log('树数据yayayya111', this.listData, this.defExpandedKeys)
      },
      onExpand(expandedKeys) {
          console.log('onExpand', expandedKeys);
          this.expandedKeys = expandedKeys;
          this.autoExpandParent = false;
      },
      onCheck(checkKeys, e) {
        console.log('onCheck', checkKeys, e);
        if(this.disable) return
        const checkNode = e.node.dataRef
        const key = checkNode[this.replaceFields.key]
        this.$emit('checked', key, checkNode, this.checkedKeys, e.checked)
      },
      onSelect(selectedKeys, info) {
          console.log('onSelect', info);
          this.selectedKeys = selectedKeys;
      }
    }
  };
</script>
