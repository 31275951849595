<template>
  <a-modal  v-model="visible" title="选择用户" width="900px" centered>
    <a-table   :scroll="{y: 300}"
               ref="userComp"
               rowKey="id"
               size="small"
               :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
               :columns="userColumns"
               :data-source="tableData"
               :customRow="customRow"
               :pagination="false" >
      <template slot="orgName" slot-scope="text">
        <span class="ellipsisText">{{ text }}</span>
      </template>
    </a-table>
    <div class="pagination">
      <com-pagination
        @change="handlePageChange"
        :pagination="pagination"
      ></com-pagination>
    </div>
    <template #footer>
      <a-button style="margin-left: 8px" @click="hideModal">
        取消
      </a-button>
      <a-button :disabled="disabled" type="primary" @click="submitForm()">
        确定
      </a-button>
    </template>
  </a-modal>
</template>

<script>
  import {req} from 'common/api/index'
  import { ComPagination } from 'common/components'
  import el from 'common/lib/element/src/locale/lang/el'
  export default {
    name: 'UserList',
    components: {
      ComPagination,
      el
    },
    data () {
      return {
        searchData: {
          currentPage: 1,
          pageSize: 10
        },
        selectedRowKeys: [],
        visible: false,
        disabled: true,
        tableData: [],
        selectedRows: [],
        userColumns: [
          {
            title: '序号',
            width: 70,
            dataIndex: 'num',
            customRender: (text, record, index) => {
              return (
                      index + 1
              )
            }
          },
          {
            title: '登录账号',
            dataIndex: 'userName'
          },
          {
            title: '姓名',
            dataIndex: 'description'
          },
          {
            title: '所属用户组',
            dataIndex: 'groupName'
          },
          {
            title: '所属单位',
            dataIndex: 'orgName',
            width: 180,
            scopedSlots: { customRender: 'orgName' }
          },
          {
            title: '角色',
            dataIndex: 'roleNames'
          }
        ],
        pagination: {}
      }
    },
    methods: {
      showUserDialog () {
        this.listUserPage()
        this.visible = true
      },
      hideModal () {
        this.visible = false
      },
      submitForm () {
        const user = this.selectedRows[0]
        this.$emit('selectedUser', user)
        this.hideModal()
        this.selectedRowKeys = []
      },
      handlePageChange (pagination) {
        const pager = { ...this.searchData }
        pager.currentPage = pagination.current
        pager.pageSize = pagination.pageSize
        this.searchData = pager
        this.listUserPage()
      },
      // 用户分页查询
      listUserPage () {
        const param = { id: this.selectGroupId, ...this.searchData }
          req('post_user_getUserList', param, true).then(res => {
            this.tableData = res.data.list
            this.pagination = {
              total: res.data.total,
              showTotal: total => `共 ${total} 条`,
              showSizeChanger: true,
              showQuickJumper: true,
              showLessItems: true,
              pageSizeOptions: ['10', '20', '50'],
              defaultPageSize: 1,
              defaultCurrent: res.data.pageNum,
              hideOnSinglePage: false
            }
        })
      },
      onSelectChange (selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        if (this.selectedRowKeys.length !==1){
          this.disabled = true
        }else {
          this.disabled = false
        }
        this.selectedRows = selectedRows
        console.log(selectedRowKeys, selectedRowKeys)
      },
      customRow (record) {
        return {
          on: {
            click: (event) => {
              this.selectedRows[0] = record
              console.log('this.selectedRows', this.selectedRows)
              const rowKeys = this.selectedRowKeys
              if (rowKeys.length > 0 && rowKeys.includes(record.id)) {
                rowKeys.splice(rowKeys.indexOf(record.id), 1)
              } else {
                rowKeys.push(record.id)
              }
              this.selectedRowKeys = rowKeys
              const len = this.selectedRowKeys.length
              if (len !== 1) {
                this.disabled = true
              } else {
                this.disabled = false
              }
            }
          }
        }
      }
    }
  }
</script>

<style scoped>
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
  }
  /deep/ td .ellipsisText{
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  /deep/.ant-btn-primary[disabled]{
    background-color: rgba(30,137,255,56%);
  }
  /deep/.ant-btn-primary[disabled]:hover{
    background-color: rgba(30,137,255,56%);
  }
</style>
