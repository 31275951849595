<template>
  <div>
    <div class="el-image-viewer__mask"></div>
    <!-- CLOSE -->
    <span class="el-image-viewer__btn el-image-viewer__close" @click="hide">
        <a-icon type="close" class="color-white" />
      </span>

    <com-dialog-viewer
            :on-close="hide"
            :z-index="zIndex"
            :title="title"
            :full-screen="fullScreen"
            :full-screen-close="fullScreenClose"
            :width="width"
            :height="height"
            @onYoZooming="handleYoZooming"
            @onYoMoveing="handleYoMoveing"
            @onYoZoomEnd="handleYoZoomEnd"
            @onYoMoveEnd="handleYoMoveEnd"
            @onYoModelChange="handleYoModelChange"
            :custosm-canvas-class="{'yo-pad-viewer-custom-canvas': true}"
            :custosm-mask-class="{'yo-pad-viewer-custom-mask': true}"
    >
      <a href ref="download_a" target="_blank" v-show="false"></a>
      <iframe v-if="edgeShow && isPdf" :src="realpdfSrc" :style="pdfStyle"
              class="el-image-viewer__img"></iframe>
<!--        <iframe-->
<!--                id="framePDF"-->
<!--                ref="printIframe"-->
<!--                :src="pdfStream">-->
<!--        </iframe>-->
    </com-dialog-viewer>
  </div>
</template>
<script type="text/javascript">
  import qs from 'common/lib/qs'
  // import {downFile} from 'common/utils/file/downFile'
  import {getUrlWithTokenParam} from 'common/utils/token/tokenUtil'
  import ComDialogViewer from "./ComPreviewDialog";
  // import {on, off} from "common/lib/element/src/utils/dom";
  // import {rafThrottle, isFirefox} from "common/lib/element/src/utils/util";
  import {console} from "../../vueDraggable/util/helper";
  import axios from 'axios'

  // const mousewheelEventName = isFirefox() ? "DOMMouseScroll" : "mousewheel";
  const Mode = {
    FullScreen: {
      name: "FullScreen"
    },
    Dialog: {
      name: "Dialog"
    }
  };
  function parseUrlParam(param) {
    let paramStr = ''
    if (!param) {
      return paramStr
    }
    if (param instanceof String) {
      return param
    } else if (param instanceof Object) {
      paramStr = qs.stringify(param, {indices: false})
    }
    return paramStr
  }
  export default {
    name: "YoPdfViewer",
    props: {
      urlList: {
        type: Array,
        default: () => []
      },

      src: {
        //pdf地址
        type: String,
        required: true
      },
      title: {
        type: String
      },
      zIndex: {
        type: Number,
        default: 2000
      },
      //多媒体预览模式 application/pdf
      mimeType: {
        type: String,
        default: "application/pdf"
      },
      //默认全屏
      fullScreen: {
        type: Boolean,
        default: false
      },
      //全屏模式立即关闭，false 是缩小到窗口模式
      fullScreenClose: {
        type: Boolean,
        default: false
      },
      //窗口宽度
      width: {
        type: Number,
        default: 1200
      },
      //窗口高度
      height: {
        type: Number,
        default: 600
      },
      //提供的关闭事件
      onClose: {
        type: Function,
        default: () => {
        }
      },
      //pdf打开页码，默认空也是第一页,正整数
      page: {
        type: Number,
        default: null
      },
      //显示模式，不建议改，现在默认是适用屏幕，全屏可以改成"FitH,top",或者空
      view: {
        type: String
        // default: "Fit"
      },
      fid: {
        type: String
      }
    },
    components: {ComDialogViewer},
    data: function () {
      return {
        isIE: window.ActiveXObject || "ActiveXObject" in window,
        edgeShow: false,
        isShow: false, //没什么作用 不显示也不糊加载
        realpdfSrc: "", //实际显示的PDF地址
        loading: true,
        mode: this.fullScreen ? Mode.fullScreen : Mode.Dialog,
        ismoving: false,
        iszooming: false,
        transform: {
          width: "100%",
          height: "100%",
          scale: 1,
          deg: 0,
          offsetX: 0,
          offsetY: 0,
          enableTransition: false
        },
        DialogStyle: {
          width: '80%',
          height: '80%',
          top: 0,
          left: 0
        }
      };
    },
    computed: {
      //pdf实际地址带显示控制，详见https://www.adobe.com/content/dam/acom/en/devnet/acrobat/pdfs/pdf_open_parameters.pdf
      pdfSrc: function () {
        let that = this;
        let requestUrl = getUrlWithTokenParam('/platservice/fileupload/getFileStream')
        let paramStr = parseUrlParam({fid: that.fid})
        if (paramStr) {
          requestUrl = requestUrl + '&' + paramStr
        }
        const baseUrl = process.env.VUE_APP_BASE_API

        let _src = baseUrl + requestUrl
        if (that.mimeType === "application/pdf") {
          //pdf模式增加控制参数
          if (!_src.endsWith("#")) {
            _src += "#";
          }
          if (that.page > 0) {
            _src += "page=" + that.page + "&";
          }
          if (that.view) {
            _src += "view=" + that.view + "&";
          } else {
            // _src += "view=Fit&";
            if (this.mode === Mode.FullScreen) {
              _src += "view=Fit&";
            } else {
              _src += "view=FitH,top&";
            }
          }
        }
        // window.open('https://view.officeapps.live.com/op/view.aspx?src=http://localhost:7000/' + _src)
        // return 'https://view.officeapps.live.com/op/view.aspx?src=' + location.origin + _src;
        return location.origin + _src;
      },
      pdfStyle() {
        const {
          scale,
          deg,
          offsetX,
          offsetY,
          enableTransition,
          width,
          height
        } = this.transform;
        const style = {
          transform:
                  this.ismoving || this.iszooming
                          ? ""
                          : `scale(${scale}) rotate(${deg}deg)`,
          transition: enableTransition ? "transform .3s" : "",
          "margin-left": `${offsetX}px`,
          "margin-top": `${offsetY}px`,
          width: `${width}`,
          height: `${height}`
        };
        return style;
      },
      isPdf() {
        // return this.mimeType === "application/pdf";
        return this.isCanPreviewPDFType(this.mimeType);
      }
    },
    methods: {
      // 判断是可做PDF预览的 .doc/.docx之类
      isCanPreviewPDFType(filetype) {
        var ctypeArr = [
          "application/pdf", //.pdf
          "application/msword", //.doc|.docx
          "application/vnd.ms-excel", //.xls|.xlsx
          "application/vnd.ms-powerpoint" //ppt
          // "text/plain" //.txt
        ];
        if (ctypeArr.indexOf(filetype) >= 0) {
          return true;
        } else {
          return false;
        }
      },
      //显示错误提示
      // showerr(msg) {
      //   var that = this;
      //   that.loading = false;
      //   that.$alert(msg, {
      //     showClose: false,
      //     callback: function (action, instance) {
      //       that.hide();
      //     }
      //   });
      // },
      initSrc() {
        //测试pdf地址 没问题才显示
        let that = this;
        if (that.isCanPreviewPDFType(that.mimeType)) {
          that.loading = true;
          this.edgeShow = false;
          axios.get(that.pdfSrc).then(resp => {
            // 'https://view.officeapps.live.com/op/view.aspx?src=' + location.origin + _src;
            if (resp.status === 200) {
              // 下面代码都是处理IE浏览器的情况
              if (window.ActiveXObject || "ActiveXObject" in window) {
                  let flag = false
                  //判断是否为IE浏览器，"ActiveXObject" in window判断是否为IE11
                  //判断是否安装了adobe Reader
                  for (let x = 2; x < 10; x++) {
                      try {
                          let oAcro = eval("new ActiveXObject('PDF.PdfCtrl." + x + "');");
                          if (oAcro) {
                              flag = true;
                          }
                      } catch (e) {
                          flag = false;
                      }
                  }
                  try {
                      let oAcro4 = new ActiveXObject('PDF.PdfCtrl.1');
                      if (oAcro4) {
                          flag = true;
                      }
                  } catch (e) {
                      flag = false;
                  }
                  try {
                      let oAcro7 = new ActiveXObject('AcroPDF.PDF.1');
                      if (oAcro7) {
                          flag = true;
                      }
                  } catch (e) {
                      flag = false;
                  }
                  if (flag) {//支持
                      that.realpdfSrc = that.pdfSrc;
                      that.edgeShow = true;
                      that.$nextTick(function () {
                      })
                  } else {//不支持
                      alert("尊敬的用户，对不起。您还没有安装PDF在线预览软件,为了方便在线预览PDF文档,请点击确定下载安装！");
                      location = "https://admdownload.adobe.com/bin/live/readerdc_cn_ha_crd_install.exe";
                  }
              } else {
                  // let sdfasdf = encodeURIComponent(that.pdfSrc)
                  // that.realpdfSrc = 'http://view.officeapps.live.com/op/view.aspx?src=' +  'http%3A%2F%2Flocalhost%3A7000%2Fdev-api%2Fplatservice%2Ffileupload%2FgetFileStream%3FBDSOFT-TOKEN%3Da8613149-e071-4521-9104-f4097e6b6d89%26fid%3D70c03b4e103b43319552894fd4f62ae5%23view%3DFitH%2Ctop%26'
                // that.realpdfSrc = 'http://ow365.cn/?i=1&furl=' + that.pdfSrc
                  console.log(that.pdfSrc)
                that.realpdfSrc = that.pdfSrc
                  that.edgeShow = true
              }
            }
            })



          // let instance = req.create();
          // instance.options(that.src)
          //         .then(resp => {
          //             if (resp.status == 200) {
          //                 // 下面代码都是处理IE浏览器的情况
          //                 if (window.ActiveXObject || "ActiveXObject" in window) {
          //                     let flag = false
          //                     //判断是否为IE浏览器，"ActiveXObject" in window判断是否为IE11
          //                     //判断是否安装了adobe Reader
          //                     for (let x = 2; x < 10; x++) {
          //                         try {
          //                             let oAcro = eval("new ActiveXObject('PDF.PdfCtrl." + x + "');");
          //                             if (oAcro) {
          //                                 flag = true;
          //                             }
          //                         } catch (e) {
          //                             flag = false;
          //                         }
          //                     }
          //                     try {
          //                         let oAcro4 = new ActiveXObject('PDF.PdfCtrl.1');
          //                         if (oAcro4) {
          //                             flag = true;
          //                         }
          //                     } catch (e) {
          //                         flag = false;
          //                     }
          //                     try {
          //                         let oAcro7 = new ActiveXObject('AcroPDF.PDF.1');
          //                         if (oAcro7) {
          //                             flag = true;
          //                         }
          //                     } catch (e) {
          //                         flag = false;
          //                     }
          //                     if (flag) {//支持
          //                         that.realpdfSrc = that.pdfSrc;
          //                         that.edgeShow = true;
          //                         that.$nextTick(function () {
          //                         })
          //                     } else {//不支持
          //                         alert("尊敬的用户，对不起。您还没有安装PDF在线预览软件,为了方便在线预览PDF文档,请点击确定下载安装！");
          //                         location = "https://admdownload.adobe.com/bin/live/readerdc_cn_ha_crd_install.exe";
          //                     }
          //                 } else {
          //                     that.realpdfSrc = that.pdfSrc;
          //                     that.edgeShow = true;
          //                     // that.$nextTick(function () {
          //                     //   let html = document.querySelector('#temppdf').innerHTML
          //                     //   document.querySelector('#temppdf').innerHTML = '<span></span>'
          //                     //   document.querySelector('#temppdf').innerHTML = html
          //                     //   that.edgeShow = true;
          //                     // })
          //                 }
          //             } else if (resp.status == 204) {
          //                 //接口主動返回的204 表示內容還沒有 友好提示
          //                 that.showerr("文件正在转码，请稍后再试...");
          //             } else {
          //                 that.showerr("预览失败!");
          //             }
          //         })
          //         .catch(err => {
          //           if (err && err.response && err.response.status == 404) {
          //             that.showerr("不支持预览!");
          //           } else {
          //             that.showerr("加载失败!");
          //           }
          //         });
        }
      },
      hide() {
        this.onClose();
        // this.deviceSupportUninstall();
      },
      // fullscreen() {
      //   //全屏
      //   this.mode = Mode.FullScreen;
      // },

      handlePdfLoad(e) {
        this.loading = false;
        // console.log("load");
        this.isShow = true; //加载完成显示
      },
      handleYoZooming(e) {
        this.iszooming = true;
      },
      handleYoMoveing(e) {
        this.ismoving = true;
      },
      handleYoZoomEnd(e) {
        this.iszooming = false;
      },
      handleYoMoveEnd(e) {
        this.ismoving = false;
      },
      //模式切换事件
      handleYoModelChange(e) {
        // console.log("模式切換：" + e.name);
        this.mode = Mode[e.name];
        // console.log("當前模式：" + this.mode.name);
        const {transform} = this;
        if (this.mode == Mode.FullScreen) {
          //切換到全屏模式
        } else if ((this.mode = Mode.Dialog)) {
          //切換會對話框模式 復位
          transform.offsetX = 0;
          transform.offsetY = 0;
          transform.scale = 1;
          transform.deg = 0;
        }
      },
      // handleActions(action, options = {}) {
      //   if (this.loading) return;
      //   const {zoomRate, rotateDeg, enableTransition} = {
      //     zoomRate: 0.2,
      //     rotateDeg: 90,
      //     enableTransition: true,
      //     ...options
      //   };
      //   const {transform} = this;
      //   switch (action) {
      //     case "zoomOut":
      //       if (transform.scale > 0.2) {
      //         transform.scale = parseFloat(
      //                 (transform.scale - zoomRate).toFixed(3)
      //         );
      //       }
      //       break;
      //     case "zoomIn":
      //       transform.scale = parseFloat((transform.scale + zoomRate).toFixed(3));
      //       break;
      //     case "clocelise":
      //       transform.deg += rotateDeg;
      //       break;
      //     case "anticlocelise":
      //       transform.deg -= rotateDeg;
      //       break;
      //   }
      //   transform.enableTransition = enableTransition;
      // },
    },
    created: function () {
    },
    mounted() {
      this.initSrc();
      // this.deviceSupportInstall();
    }
  };
</script>
<style scoped>

    .el-image-viewer__btn {
        position: fixed;
        z-index: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        border-radius: 50%;
        opacity: .8;
        cursor: pointer;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none
    }
    .el-image-viewer__close {
        top: 65px;
        right: 140px;
        width: 40px;
        height: 40px;
        font-size: 24px;
        color: #fff;
        background-color: #606266
    }
    .el-image-viewer__wrapper .el-image-viewer__btn {
        z-index: 2003;
    }
  .yo-pad-viewer-custom-canvas {
    position: initial !important;
    justify-content: center !important;
    display: flex !important;
    align-items: center !important;
  }

  .yo-pad-viewer-custom-mask {
    background: #000 !important;
    opacity: 0.5 !important;
  }

  .pdf-is-disabled {
    color: #7e7e7e;
    background-color: #eaeaea;
    border: 1px solid #eaeaea;
    cursor: default;
  }

  #app div.el-image-viewer__mask,
  #aic_body div.el-image-viewer__mask {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: .5;
    background: #000;
    z-index: 999;
  }

  #app span.el-image-viewer__close,
  #app span.el-image-viewer__close > i{
    z-index: 9999;
    position: fixed;
    color: #fff;
  }

</style>
