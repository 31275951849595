export default {
  props: {
    pageSize: {
      type: Number,
      default: 20
    }
  },
  data () {
    return {
      // 列表数据
      tableData: [],
      loading: false,
      searchData: {},
      pagination: {
        // 数据总数
        total: 0,
        // 用于显示数据总量和当前数据顺序
        showTotal: total => `共 ${total} 条`,
        // 是否可以改变pageSize
        showSizeChanger: true,
        // 是否可以快速跳转至某页
        showQuickJumper: true,
        // show less page items
        showLessItems: true,
        // 指定每页可以显示多少条
        pageSizeOptions: ['10', '20', '50'],
        // 默认的每页条数
        defaultPageSize: 10,
        // 默认的当前页数
        defaultCurrent: 1,
        // 只有一页时是否隐藏分页器
        hideOnSinglePage: false
      }
    }
  },
  created() {
    this.pagination.defaultPageSize = this.pageSize
  },
  methods: {
    /**
     * 重新计算当前的起始页码值
     */
    refreshSeqConfig () {
      this.seqConfig.startIndex = (this.tablePage.currentPage - 1) * this.tablePage.pageSize
    },
    /**
     * 列表数据查询成功成功之后返回的数据
     * @param list  接口返回的列表数据集合
     * @param total 总条数
     * @param pageSize  当前页显示的条数
     * @param pageNum 当前页
     */
    onGotPageData ({ list, total, pageSize, pageNum }) {
      this.tableData = list || []
      this.pagination.defaultCurrent = pageNum
      this.pagination.defaultPageSize = pageSize
      this.pagination.total = total
      // this.refreshSeqConfig()
      this.loading = false
    },
    /**
     * 当页面需自定义页大小的时候使用
     * @param pageSize  当前页显示的条数
     * @param currentPage 当前页
     */
    onSetPageDate ({ pageSize, currentPage }) {
      if (currentPage) {
        this.pagination.defaultCurrent = currentPage
      }
      if (pageSize) {
        this.pagination.defaultPageSize = pageSize
      }
    },
    /**
     * 当页面需自定义页大小的时候使用
     * @param pageSize  当前页显示的条数
     * @param currentPage 当前页
     */
    onSetPageData ({ pageSize, currentPage }) {
      if (currentPage) {
        this.pagination.defaultCurrent = currentPage
      }
      if (pageSize) {
        this.pagination.defaultPageSize = pageSize
      }
    },
    /**
     * 当页面页数变化调用和切换页数的时候最终调用的方法
     * @param currentPage 当前页
     * @param pageSize  当前页显示的条数
     */
    handlePageChange ({ current, pageSize }) {
      this.$emit('pageChange', { current, pageSize })
      this.pagination.defaultCurrent = current
      this.pagination.defaultPageSize = pageSize
      if (this.getData) {
        this.getData()
      }
    },
    /**
     * 当列表查询获取分页参数用于传入后台
     * @param params
     * @returns {{}}
     */
    addPageInfo (params = {}) {
      this.loading = true
      Object.assign(params, {
        pageSize: this.pagination.defaultPageSize,
        currentPage: this.pagination.defaultCurrent
      })
      return params
    },
    /**
     * 搜索
     */
    onSearch () {
      this.pagination.defaultCurrent = 1
      this.handlePageChange(this.pagination.defaultCurrent, this.pagination.defaultPageSize)
    },
    resetSearch () {
      this.pagination.defaultCurrent = 1
      this.searchData = {}
      this.handlePageChange(this.pagination.defaultCurrent, this.pagination.defaultPageSize)
    },
    resetPagination () {
      this.pagination.defaultCurrent = 1
    }
  }
}
