import { encryptedParams } from '../Encryption/EncryptUtils.js'
import { encryptedData } from '../Encryption/JSEncrypt'
import globalsApi from 'common/api/globals'

const errorReq = (msg) => {
  return new Promise(function (resolve, reject) {
    reject(msg)
  }).catch(err => {

  })
}
const isEcrypted = false // 是否加密开关 中继器方式

export default (axios, option) => {
  const reqTypeList = ['get', 'post', 'file', 'delete', 'put']
  const selectReqType = (url, reqType, parameter, isObo, reqName) => {
    let baseUrl = process.env.VUE_APP_BASE_API
    let params = {}
    if (typeof parameter === 'function') {
      params = parameter(encryptedData)
      // params = parameter
      // console.error(url, '暂时不支持单个加密方式')
    } else {
      params = parameter
    }
    if (isObo === false && reqType !== 'file') {
      if (params) {
        //如果是json字符串，不能继续序列化（多次序列化，json字符串有问题，后端反序列化会失败）
        //类型不是string，才能继续序列化
        if (typeof params !== 'string') {
          params = JSON.stringify(params)
        }
        params = encryptedParams(params)
        // params = encrypt1(JSON.stringify(params))
      }
    }
    if (typeof url === 'undefined' || !(typeof url === 'string' ? url : url.url)) {
      return errorReq(reqName + '请设置正确的请求url路径')
    }
    // 当url中带有http地址时
    if (/^((http|https):\/\/)/.test(typeof url === 'string' ? url : url.url)) {
      baseUrl = ''
    }
    let reqParams = {
      url: '',
      method: 'post'
    }
    if (typeof url === 'object') {
      reqParams = Object.assign(reqParams, url)
      reqParams.url = baseUrl + url.url
    } else if (typeof url === 'string') {
      reqParams.url = baseUrl + url
    } else {
      return errorReq(reqName + '请求映射类型不正确')
    }
    switch (reqType) {
      case 'post':
        if (typeof url === 'object') {
          if (url.paramType === 'params') {
            reqParams = Object.assign(reqParams, {
              method: 'post',
              params: params
            })
          } else {
            reqParams = Object.assign(reqParams, {
              method: 'post',
              data: params
            })
          }
        } else if (typeof url === 'string') {
          reqParams = Object.assign(reqParams, {
            method: 'post',
            data: params
          })
        }
        break
      case 'get':
        if (typeof url === 'object') {
          if (url.paramType === 'split') {
            reqParams.url += `/${params}`
            reqParams = Object.assign(reqParams, {
              method: 'get'
            })
          }else{
            reqParams = Object.assign(reqParams, {
              method: 'get',
              params: params
            })
          }
        }else{
          reqParams = Object.assign(reqParams, {
            method: 'get',
            params: params
          })
        }
        break
      case 'delete':
        reqParams = Object.assign(reqParams, {
          method: 'delete',
          params: params
        })
        break
      case 'put':
        reqParams = Object.assign(reqParams, {
          method: 'put',
          data: params
        })
        break
      case 'file':
        reqParams = Object.assign(reqParams, {
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          data: params
        })
        break
    }
    return axios(reqParams)
  }
  const req = (reqName, parameter, isObo) => {
    // if(!isEcrypted) {
    //   if(typeof isObo === 'undefined' &&  process.env.NODE_ENV === 'development' ) {
    //     isObo = true
    //   }
    // }
    // 当为开发环境时默认接口不加密，生产环境不管配不配置isObo都要强制加密
    if (process.env.NODE_ENV === 'development') {
      if (typeof isObo === 'undefined') {
        isObo = true
      }
    } else {
      isObo = false
    }
    // 设置开关是否加密
    if (isEcrypted === false) {
      isObo = true
    }
    isObo = true
    // 自定义请求方案
    if (typeof reqName === 'object') {
      if (!reqName.url) {
        return console.error('请输入自定义请求url')
      }
      if (!reqName.method) {
        reqName.method = 'post'
      }
      return selectReqType(reqName, reqName.method, parameter, isObo, '自定义请求')
    }
    // 合并全局api和当前系统的api
    const pathMap = Object.assign(option.pathMap, globalsApi)
    // 正则匹配下划线的内容
    let reg = new RegExp('^([^\\_\\-\\s]+)[\\_\\-\\s].*$')
    if (!reg.test(reqName)) {
      console.error(reqName + '请求映射名格式不对，前缀以post_|get_|file_|custom_ 开头')
      return;
    }
    if (typeof pathMap[reqName] !== 'undefined') { // 常规接口映射请求方式
      let reqType = reqName.match(reg)[1]
      if (reqTypeList.includes(reqType)) {
        return selectReqType(pathMap[reqName], reqType, parameter, isObo, reqName)
      } else {
        return selectReqType(pathMap[reqName], 'post', parameter, isObo, reqName)
      }
    } else if (reqName.match(reg)[1] === 'custom') { // 简易自定义请求
      let customUrl = reqName.replace('custom_', '')
      customUrl = customUrl.replace(/(^\s*)/, '')
      if (/^(\/)/.test(customUrl) || /^((http|https):\/\/)/.test(customUrl)) {
        return selectReqType(customUrl, 'post', parameter, isObo, reqName)
      }
      return errorReq(customUrl + '不符合请求地址要求')
    } else {
      return errorReq('没有找到映射的请求名称')
    }
  }
  return req
};

