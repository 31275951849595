<template>
  <div class="breadcrumb">
    <a-breadcrumb separator=">" class="breadcrumb-left">
      <a-breadcrumb-item v-for="item in breadcrumbList" :key="item.path">
        <router-link :to="item.path">{{ item.meta.title }}</router-link>
      </a-breadcrumb-item>
    </a-breadcrumb>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Breadcrumb',
  props: {
    nowWorkArea: {
      type: String,
      default: 'management'
    }
  },
  data() {
    return {
      breadcrumbList: [],
      breadcrumbHolder: '', // 面包屑不同子级的暂存名
      routerHolder: undefined // 面包屑不同的筛选空间的暂存数组
    }
  },
  computed: {
    // 通过 vuex 的 getters 获取存储的全部路由信息
    ...mapGetters([
      'allRoutes'
    ]),
    // 根据 props 传进来的 nowWorkArea 对全部路由信息进行筛选，找出不同工作台对应的路由
    routerObj() {
      return this.allRoutes.filter(item => item.path === `/${this.nowWorkArea}`)
    }
  },
  watch: {
    // 监听 route 变化重新刷新面包屑
    $route() {
      this.getRoute()
      // this.getBreadcrumb()
    }
  },
  created() {},
  mounted() {
    // DOM 初始化后在进行获取
    this.$nextTick(() => {
      this.getRoute()
      // this.getBreadcrumb()
    })
  },
  methods: {
    /**
     * 获取面包屑1
     * */
    getRoute() {
      // console.log(this.$route)
      this.breadcrumbList = this.$route.matched.filter(item => (item.meta.hidden === false || !item.meta.hidden))
    },
    /**
     * 获取面包屑2
     * */
    getBreadcrumb() {
      // 初始化数据，防止上次的数据残留
      this.breadcrumbList = []
      this.breadcrumbHolder = ''
      this.routerHolder = undefined
      // 对当前路由的 route 内的 fullPath 进行截取获取参数数组
      const fullPathItem = this.$route.fullPath.split('/')
      console.log(fullPathItem, 'fullPathItem')
      // 去除第一位的空值
      fullPathItem.splice(0, 1)
      // 通过 for 循环获取面包屑渲染所需的数组
      for (let i = 0; i < fullPathItem.length; i++) {
        this.filterRouters(fullPathItem[i])
      }
    },
    /**
     * 根据 pathItem 进行筛选，来获得每个 pathItem 对应的路由对象
     * */
    filterRouters(pathItem) {
      if (this.breadcrumbHolder === '') {
        // 如果为第一次筛选
        // 初始化面包屑子级的暂存名
        this.breadcrumbHolder = `/${pathItem}`
      } else {
        // 如果不为第一次筛选
        // 对面包屑子级的暂存名进行拼接
        this.breadcrumbHolder = this.breadcrumbHolder + `/${pathItem}`
      }
      // 通过遍历获得路由对象
      const newData = this.recursion(this.breadcrumbHolder, this.routerObj)
      // 将筛选出来的数据存入到 breadcrumbList 内
      this.breadcrumbList.push(newData)
    },
    /**
     * 路由对象的遍历方法
     * */
    recursion(path, array) {
      if (this.routerHolder === undefined) {
        // 如果是第一次进行遍历就用传递进来的参数 array 来处理
        const bcItem = array.filter(item => item.path === path)
        if (bcItem.length > 0) {
          this.routerHolder = bcItem[0].children
          return bcItem[0]
        }
      } else {
        // 如果不是第一次进入遍历则使用缓存的筛选空间 routerHolder 来处理
        const bcItem = this.routerHolder.filter(item => item.path === path)
        if (bcItem.length > 0) {
          this.routerHolder = bcItem[0].children
          return bcItem[0]
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .breadcrumb{
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    box-shadow: 0 5px 5px #DCDFE3;
    .breadcrumb-left{
      margin-left: 20px;
    }
    .breadcrumb-right{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      p{
        margin: 0;
      }
    }
  }
</style>
