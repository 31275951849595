<script>

import {
  deepClone,
  CustomQueryContainer,
  CustomCheckbox,
  CustomDatePicker,
  CustomInputNumber,
  CustomInput,
  CustomRadio,
  CustomSelect,
  CustomTreeSelect,
  CustomComponentNameMap,
  CustomTypeMap,
  listToArrayTree
} from './components'

import { reqMethods } from 'common/utils/request'
const req = reqMethods({
  pathMap: {
    post_sym_custom_query: '/sym/customtablequery/findByTable',
    post_sym_code_list_diction: '/sym/codeItemDefine/listDictionaryBatch'
  }
}).req

function _log (...arg) {
  console.log('%c[[CustomQuery]]', 'color: #caf', ...arg)
}

export default {
  name: 'ComCustomQuery',
  components: {
    CustomQueryContainer,
    CustomCheckbox,
    CustomDatePicker,
    CustomInputNumber,
    CustomInput,
    CustomRadio,
    CustomSelect,
    CustomTreeSelect
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    tableName: {
      type: String,
      default: ''
    },
    api: {
      type: String,
      default: 'post_sym_custom_query'
    }
  },
  data () {
    return {
      values: {},
      optionsData: []
    }
  },
  watch: {
    options: {
      async handler (n) {
        const _options = await this.formatOptions(deepClone(n))
        _log('options', n, _options)
        this.getDefaultValue(_options)
        this.optionsData = _options
      }
    }
  },
  methods: {
    change (e) {
      _log('[[[现在 打印的数据]]]', e)
      this.values = {
        ...this.values,
        ...e
      }
      _log(deepClone(this.values))
      this.$emit('change', { value: e, values: deepClone(this.values) })
    },
    getDefaultValue (options) {
      const values = {}
      options.forEach(v => {
        // _log('options', v.key, v.options.defaultValue)
        if (v.options && v.options.defaultValue) {
          values[v.key] = {
            value: v.options.defaultValue,
            label: v.options.defaultValue
          }
        }
      })
      this.values = values
      _log(this.values)
    },
    async formatOptions (arr) {
      let codeDiction
      const diction = arr.map(v => v.dmgrp || '').filter(v => v)
      const { _diction, _codeDiction } = this
      if (_diction && _diction === diction.join(",")) {
        codeDiction = _codeDiction
      } else {
        codeDiction = await req('post_sym_code_list_diction', {name: diction.join(",")})
        .then(e => Promise.resolve(e.data))
        this._diction = diction.join(",")
        this._codeDiction = codeDiction
      }
      const newArr = []
      arr.forEach(v => {
        const options = v.dmgrp ? codeDiction[v.dmgrp].map(v => ({
          id: v.id,
          parentid: v.parentid,
          label: v.dmcpt,
          key: v.dmcod,
          value: v.dmcod
        })) : []
        const type = CustomTypeMap(v, options.length)
        newArr.push({
          type,
          key: v.fldcod,
          label: v.fldcodTitle,
          options: {
            multiple: v.ismultivalued === 1 ? true : false,
            defaultValue: v.defaultValue,
            // dynamicKey: v.dmgrp,
            // dynamic: true,
            options: options.length && type === 'treeSelect' ? listToArrayTree(options, 'id', 'parentid') : options
          }
        })
      })
      _log('newArr', newArr)
      return newArr
    }
  },
  render (h) {
    return h(
      'CustomQueryContainer',
      {
        on: {
          reset: () => {
            const values = this.values
            const obj = {}
            for (const k in values) {
              obj[k] = {
                value: Array.isArray(values[k].value) ? [] : '',
                label: ''
              }
            }
            this.values = obj
            this.change({})
          },
          add: () => {
            this.$emit('event', { type: 'add'})
          },
          save: () => {
            this.$emit('event', { type: 'save', values: { ...this.values } })
          }
        }
      },
      [...this.optionsData.map(v => h(
        'a-form-item',
        {
          props: {
            label: v.label
          },
          style: {
            width: '100%',
            marginRight: 0,
            minHeight: '48px'
          }
        },
        [ h(
            CustomComponentNameMap(v.type),
            {
              props: {
                ...v.options,
                value: this.values[v.key]
              },
              on: {
                change: e => this.change({ [v.key]: e })
              }
            }
        ) ]
      )),
      this.$scopedSlots.default({
        text: 'default'
      })]
    )
  }
}
</script>