export default {
    props: {
        isPagination: {
            default: true
        },
        bordered: {
            default: false
        },
        loading: {
            default: false
        },
        pageSize: {
            default: 20
        },
        pageTotal: {
            default: 0
        },
        pageCurrent: {
            default: 0
        },
        columns: Array,
        dataSource: Array,
        maxHeight: {
            default: 'calc(100vh - 400px)'
        },
        checkbox: Boolean,
        rowKey: {
            default: 'key'
        },
        rowSelection: {
            default: () => ({})
        }
    },
    data() {
        return {
            opt: {},
            scroll: this.maxHeight
        }
    },
    computed: {
        pagination() {
            if (!this.isPagination) {
                return false
            }
            var paginationConf = {
                pageSize: this.pageSize,
                showSizeChanger: true,
                hideOnSinglePage: false,
                showTotal: this.showTotal,
                size: 'default',
                total: this.pageTotal,
                current: this.pageCurrent
            }
            if (this.pageCurrent === 0) delete paginationConf.current
            return paginationConf
        }
    },
    mounted() {
        this.resizeTable()
        window.addEventListener('resize', this.resizeTable.bind(this))
    },
    beforeDestroy() {
        window.addEventListener('resize', this.resizeTable.bind(this))
    },
    methods: {
        change(pagination, filters, sorter, currentDataSource) {
            this.$emit('change', pagination, filters, sorter, currentDataSource)
        },
        resizeTable() {
            // var tableEl = this.$refs.tableEl
            // if (!tableEl) {
            //     return
            // }
            // var wrapHeight = tableEl && tableEl.offsetHeight
            // var tableHeader = tableEl.getElementsByClassName('ant-table-thead')[0]
            // var tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 0
            // this.scroll = wrapHeight - tableHeaderHeight + 'px'
            var tableEl = this.$refs.tableEl
            var clientHeight = document.body.clientHeight
            if (!tableEl) {
                return
            }
            // 元素到页面顶部高度
            var wrapHeight = tableEl && tableEl.getBoundingClientRect().top
            var tableHeader = tableEl.getElementsByClassName('ant-table-thead')[0]
            // 分页高度
            var pagingHeight = tableEl.parentElement.nextElementSibling.offsetHeight
            var tableHeaderHeight = tableHeader ? tableHeader.offsetHeight : 0
            this.scroll = clientHeight - wrapHeight - tableHeaderHeight - pagingHeight
        },
        showTotal(total, range) {
            // console.log(total)
            return `每页${this.pageSize}条，共${total}条`
        },
        rowClassName(record, idx) {
            // if (idx % 2 === 1) {
            //     return 'bg-row'
            // }
            return ''
        }
    },
    render() {
        const { columns, dataSource, pagination, rowKey, rowSelection, rowClassName, bordered, loading, scroll } = this
        const { change } = this
        var props = {
            columns: columns,
            dataSource: dataSource,
            pagination: false,
            scroll: { y: scroll },
            rowKey: rowKey,
            rowClassName: rowClassName,
            bordered: bordered,
            loading: loading,
            size: 'middle'
        }
        if (Object.keys(rowSelection).length) {
            props.rowSelection = rowSelection
        }
        const on = {
            change
        }
        return (
        <div class="table-base" ref="tableEl">
            <a-table
                {...{ props, on }}
                class='table-wrap'
            />
        </div>
        )
    }
}
