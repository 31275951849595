import {getToken, getTokenKeyName} from 'common/utils/token/tokenUtil'
export default {
    props: {
        accept: {
            type: Object,
            default: null,
        },
        // 上传地址
        url: {
            type: String,
            default: '/platservice/fileupload/upload',
        },
        // 上传最大数量 默认为100
        fileNumLimit: {
            type: Number,
            default: 100,
        },
        // 大小限制 默认2M
        fileSingleSizeLimit: {
            type: Number,
            default: 2048000,
        },
        // 上传时传给后端的参数，一般为token，key等
        formData: {
            type: Object,
            default: null
        },
        // 生成formData中文件的key，下面只是个例子，具体哪种形式和后端商议
        keyGenerator: {
            type: Function,
            default(file) {
                const currentTime = new Date().getTime();
                const key = `${currentTime}.${file.name}`;
                return key;
            },
        },
        multiple: {
            type: Boolean,
            default: false,
        },
        // 上传按钮ID
        uploadButton: {
            type: String,
            default: '',
        },
    },
    data() {
        const ratio = window.devicePixelRatio || 1
        return {
            Token: null,
            uploader: null,
            // 缩略图大小
            thumbnailWidth: 110 * ratio,
            thumbnailHeight: 110 * ratio,
            // 所有文件的进度信息，key为file id
            percentages: {},
            $queue: null,
            $statusBar: null,
            $info: null,
            $upload: null,
            flashVersion: null,
            supportTransition: null,
            // 可能有pedding, ready, uploading, confirm, done.
            state: 'pedding',
            $placeHolder: null,
            fileCount: 0,
            fileSize: 0,
            UploaderData: null,
            container: null,
            $image: null,
            btn: null // 上传所选区域按钮
        }
    },
    methods: {
        // 初始化webuploader
        initWebUpload() {
            const that = this
            // 检测是否已经安装flash，检测flash的版本
            that.flashVersion = (function () {
                var version;

                try {
                    version = navigator.plugins['Shockwave Flash'];
                    version = version.description;
                } catch (ex) {
                    try {
                        version = new ActiveXObject('ShockwaveFlash.ShockwaveFlash')
                            .GetVariable('$version');
                    } catch (ex2) {
                        version = '0.0';
                    }
                }
                version = version.match(/\d+/g);
                return parseFloat(version[0] + '.' + version[1], 10);
            })()
            that.supportTransition = (function () {
                var s = document.createElement('p').style,
                    r = 'transition' in s ||
                        'WebkitTransition' in s ||
                        'MozTransition' in s ||
                        'msTransition' in s ||
                        'OTransition' in s;
                s = null;
                return r;
            })()

            that.uploader = WebUploader.create({
                auto: false, // 选完文件后，是否自动上传
                swf: '/static/lib/webuploader/Uploader.swf',  // swf文件路径
                server: process.env.VUE_APP_BASE_API + that.url,  // 文件接收服务端
                pick: {
                    id: '#filePicker',     // 选择文件的按钮
                    multiple: that.multiple,   // 是否多文件上传 默认false
                    label: '点击选择图片',
                },
                accept: that.getAccept(this.accept),  // 允许选择文件格式。
                threads: 3,
                fileNumLimit: that.fileNumLimit, // 限制上传个数
                //fileSingleSizeLimit: this.fileSingleSizeLimit, // 限制单个上传图片的大小
                formData: that.formData,  // 上传所需参数
                dnd: '#uploader .queueList',
                paste: '#uploader',
                chunked: true,          //分片上传
                chunkSize: 2048000,    //分片大小
                // chunkSize: 102400,    //分片大小
                duplicate: false,  // 重复上传
                // 禁掉全局的拖拽功能。这样不会出现图片拖进页面的时候，把图片打开。
                disableGlobalDnd: true
            });


            // 拖拽时不接受 js, txt 文件。
            that.uploader.on('dndAccept', function (items) {
                var denied = false,
                    len = items.length,
                    i = 0,
                    // 修改js类型
                    unAllowed = 'text/plain;application/javascript ';

                for (; i < len; i++) {
                    // 如果在列表里面
                    if (~unAllowed.indexOf(items[i].type)) {
                        denied = true;
                        break;
                    }
                }

                return !denied;
            });

            that.uploader.on('dialogOpen', function () {
                console.log('here');
            });

            // this.uploader.on('filesQueued', function() {
            //     uploader.sort(function( a, b ) {
            //         if ( a.name < b.name )
            //           return -1;
            //         if ( a.name > b.name )
            //           return 1;
            //         return 0;
            //     });
            // });

            // 添加“添加文件”的按钮，
            that.uploader.addButton({
                id: '#filePicker2',
                label: '继续添加'
            });
            that.uploader.on('ready', function () {
                window.uploader = this.uploader;
            });

            that.uploader.onUploadProgress = function (file, percentage) {
                var $li = $('#' + file.id),
                    $percent = $li.find('.progress span');

                $percent.css('width', percentage * 100 + '%');
                that.percentages[file.id][1] = percentage;
                that.updateTotalProgress();
            };

            that.uploader.onFileQueued = function (file) {
                that.fileCount++;
                that.fileSize += file.size;

                if (that.fileCount === 1) {
                    that.$placeHolder.addClass('element-invisible');
                    that.$statusBar.show();
                }

                that.addFile(file);
                that.setState('ready');
                that.updateTotalProgress();
            };

            that.uploader.onFileDequeued = function (file) {
                console.log('删除队列file')
                that.fileCount--;
                that.fileSize -= file.size;

                if (!that.fileCount) {
                    that.setState('pedding');
                }

                that.removeFile(file);
                that.updateTotalProgress();

            };

            that.uploader.on('all', function (type) {
                var stats;
                switch (type) {
                    case 'uploadFinished':
                        that.setState('confirm');
                        break;

                    case 'startUpload':
                        that.setState('uploading');
                        break;

                    case 'stopUpload':
                        that.setState('paused');
                        break;

                }
            })

            that.uploader.onError = function (code) {
                that.$message.error('')
                alert('Eroor: ' + code);
            };

            that.$upload.on('click', function () {
                if ($(this).hasClass('disabled')) {
                    return false;
                }

                if (that.state === 'ready') {
                    that.uploader.upload();
                } else if (that.state === 'paused') {
                    that.uploader.upload();
                } else if (that.state === 'uploading') {
                    console.log('暂停？')
                    that.uploader.stop();
                }
            });
            // 重新上传添加事件
            that.$info.on('click', '.retry', function () {
                that.uploader.retry();
            });
            // 忽略添加事件
            that.$info.on('click', '.ignore', function () {
                that.$message.warning('TODU')
            });

            that.$upload.addClass('state-' + that.state);

            // 当有文件被添加进队列的时候，添加到页面预览
            that.uploader.on('fileQueued', (file) => {
                that.$emit('fileChange', file);
            });

            that.uploader.on('uploadStart', (file) => {
                // 在这里可以准备好formData的数据
                //this.uploader.options.formData.key = this.keyGenerator(file);
            });

            that.uploader.on('uploadBeforeSend', (file, formData, headers) => {
                // 当某个文件的分块在发送前触发，主要用来询问是否要添加附带参数，大文件在开起分片上传的前提下此事件可能会触发多次
                headers[getTokenKeyName()] = that.Token
            });

            that.uploader.on('uploadAccept', (file, response) => {
                // 当某个文件上传到服务端响应后，会派送此事件来询问服务端响应是否有效。如果此事件handler返回值为false, 则此文件将派送server类型的uploadError事件。
                console.log(response)
                if (response.code === 401) {
                    return false
                }
            });

            // 文件上传过程中创建进度条实时显示。
            that.uploader.on('uploadProgress', (file, percentage) => {
                that.$emit('progress', file, percentage);
            });

            that.uploader.on('uploadSuccess', (file, response) => {
                if (response.code === 401) {
                    that.uploader.stop(file)
                }
                that.$emit('success', file, response);
            });

            that.uploader.on('uploadError', (file, reason) => {
                console.error(reason);
                that.$emit('uploadError', file, reason);
            });

            this.uploader.on('error', (type) => {
                let errorMessage = '';
                if (type === 'F_EXCEED_SIZE') {
                    errorMessage = `文件大小不能超过${this.fileSingleSizeLimit / (1024 * 1000)}M`;
                } else if (type === 'Q_EXCEED_NUM_LIMIT') {
                    errorMessage = '文件上传已达到最大上限数';
                } else {
                    errorMessage = `上传出错！请检查后重新上传！错误代码${type}`;
                }

                console.error(errorMessage);
                this.$emit('error', errorMessage);
            });

            this.uploader.on('uploadComplete', (file, response) => {

                this.$emit('complete', file, response);
            });
        },
        getAccept(accept) {
            switch (accept) {
                case 'text':
                    return {
                        title: 'Texts',
                        exteensions: 'doc,docx,xls,xlsx,ppt,pptx,pdf,txt',
                        mimeTypes: '.doc,docx,.xls,.xlsx,.ppt,.pptx,.pdf,.txt'
                    };
                    break;
                case 'video':
                    return {
                        title: 'Videos',
                        exteensions: 'mp4',
                        mimeTypes: '.mp4'
                    };
                    break;
                case 'image':
                    return {
                        title: 'Images',
                        exteensions: 'gif,jpg,jpeg,bmp,png',
                        mimeTypes: '.gif,.jpg,.jpeg,.bmp,.png'
                    };
                    break;
                default:
                    return accept
            }
        },
        // 当有文件添加进来时执行，负责view的创建
        addFile(file) {
            const that = this
            const $li = $('<li id="' + file.id + '">' +
                '<p class="title">' + file.name + '</p>' +
                '<p class="imgWrap"></p>' +
                '<p class="progress"><span></span></p>' +
                '</li>')

            const $btns = $('<div class="file-panel">' +
                '<span class="cancel">删除</span>' +
                '<span class="rotateRight">向右旋转</span>' +
                '<span class="rotateLeft">向左旋转</span></div>').appendTo($li)
            const $prgress = $li.find('p.progress span')
            const $wrap = $li.find('p.imgWrap')
            const $info = $('<p class="error"></p>')

            const showError = function (code) {
                let text = ''
                switch (code) {
                    case 'exceed_size':
                        text = '文件大小超出';
                        break;

                    case 'interrupt':
                        text = '上传暂停';
                        break;

                    default:
                        text = '上传失败，请重试';
                        break;
                }

                $info.text(text).appendTo($li);
            }

            if (file.getStatus() === 'invalid') {
                showError(file.statusText);
            } else {
                // @todo lazyload
                $wrap.text('预览中');
                that.uploader.makeThumb(file, function (error, src) {
                    let img;

                    if (error) {
                        $wrap.text('不能预览');
                        return;
                    }

                    if (that.isSupportBase64) {
                        img = $('<img src="' + src + '">');
                        $wrap.empty().append(img);
                    } else {
                        $.ajax('../../server/preview.php', {
                            method: 'POST',
                            data: src,
                            dataType: 'json'
                        }).done(function (response) {
                            if (response.result) {
                                img = $('<img src="' + response.result + '">');
                                $wrap.empty().append(img);
                            } else {
                                $wrap.text("预览出错");
                            }
                        });
                    }
                }, that.thumbnailWidth, that.thumbnailHeight);

                that.percentages[file.id] = [file.size, 0];
                file.rotation = 0;
            }

            file.on('statuschange', function (cur, prev) {
                if (prev === 'progress') {
                    $prgress.hide().width(0);
                } else if (prev === 'queued') {
                    $li.off('mouseenter mouseleave');
                    $btns.remove();
                }

                // 成功
                if (cur === 'error' || cur === 'invalid') {
                    console.log(file.statusText);
                    showError(file.statusText);
                    that.percentages[file.id][1] = 1;
                } else if (cur === 'interrupt') {
                    showError('interrupt');
                } else if (cur === 'queued') {
                    $info.remove();
                    $prgress.css('display', 'block');
                    that.percentages[file.id][1] = 0;
                } else if (cur === 'progress') {
                    $info.remove();
                    $prgress.css('display', 'block');
                } else if (cur === 'complete') {
                    $prgress.hide().width(0);
                    $li.append('<span class="success"></span>');
                }

                $li.removeClass('state-' + prev).addClass('state-' + cur);
            });

            $li.on('mouseenter', function () {
                $btns.stop().animate({height: 30});
            });

            $li.on('mouseleave', function () {
                $btns.stop().animate({height: 0});
            });

            $btns.on('click', 'span', function () {
                var index = $(this).index(),
                    deg;

                switch (index) {
                    case 0:
                        that.uploader.removeFile(file);
                        return;

                    case 1:
                        file.rotation += 90;
                        break;

                    case 2:
                        file.rotation -= 90;
                        break;
                }

                if (that.supportTransition) {
                    deg = 'rotate(' + file.rotation + 'deg)';
                    $wrap.css({
                        '-webkit-transform': deg,
                        '-mos-transform': deg,
                        '-o-transform': deg,
                        'transform': deg
                    });
                } else {
                    $wrap.css('filter', 'progid:DXImageTransform.Microsoft.BasicImage(rotation=' + (~~((file.rotation / 90) % 4 + 4) % 4) + ')');
                    // use jquery animate to rotation
                    // $({
                    //     rotation: rotation
                    // }).animate({
                    //     rotation: file.rotation
                    // }, {
                    //     easing: 'linear',
                    //     step: function( now ) {
                    //         now = now * Math.PI / 180;

                    //         var cos = Math.cos( now ),
                    //             sin = Math.sin( now );

                    //         $wrap.css( 'filter', "progid:DXImageTransform.Microsoft.Matrix(M11=" + cos + ",M12=" + (-sin) + ",M21=" + sin + ",M22=" + cos + ",SizingMethod='auto expand')");
                    //     }
                    // });
                }


            });

            $li.appendTo(that.$queue);
        },
        isSupportBase64() {
            const data = new Image();
            let support = true;
            data.onload = data.onerror = function () {
                if (this.width != 1 || this.height != 1) {
                    support = false;
                }
            }
            data.src = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///ywAAAAAAQABAAACAUwAOw==";
            return support;
        },
        removeFile(file) {
            const that = this
            var $li = $('#' + file.id)
            delete that.percentages[file.id]

            that.updateTotalProgress()
            $li.off().find('.file-panel').off().end().remove()
        },
        updateTotalProgress() {
            const that = this
            let loaded = 0
            let total = 0
            let spans = that.$progress.children()
            let percent = null

            $.each(that.percentages, function (k, v) {
                total += v[0];
                loaded += v[0] * v[1];
            });

            percent = total ? loaded / total : 0;


            spans.eq(0).text(Math.round(percent * 100) + '%');
            spans.eq(1).css('width', Math.round(percent * 100) + '%');
            this.updateStatus();
        },
        updateStatus() {
            const that = this
            let text = ''
            let stats = null

            if (that.state === 'ready') {
                text = '选中' + that.fileCount + '张图片，共' +
                    WebUploader.formatSize(that.fileSize) + '。'
            } else if (that.state === 'confirm') {
                stats = that.uploader.getStats()
                if (stats.uploadFailNum) {
                    text = '已成功上传' + stats.successNum + '张照片至XX相册，' +
                        stats.uploadFailNum + '张照片上传失败，<a class="retry" href="#">重新上传</a>失败图片或<a class="ignore" href="#">忽略</a>'
                }

            } else {
                stats = that.uploader.getStats()
                text = '共' + that.fileCount + '张（' +
                    WebUploader.formatSize(that.fileSize) +
                    '），已上传' + stats.successNum + '张'

                if (stats.uploadFailNum) {
                    text += '，失败' + stats.uploadFailNum + '张'
                }
            }

            this.$info.html(text)
        },
        setState(val) {
            const that = this
            let file = null
            let stats = null

            if (val === that.state) {
                return;
            }

            that.$upload.removeClass('state-' + that.state);
            that.$upload.addClass('state-' + val);
            that.state = val;

            switch (that.state) {
                case 'pedding':
                    that.$placeHolder.removeClass('element-invisible');
                    that.$queue.hide();
                    that.$statusBar.addClass('element-invisible');
                    that.uploader.refresh();
                    break;

                case 'ready':
                    that.$placeHolder.addClass('element-invisible');
                    $('#filePicker2').removeClass('element-invisible');
                    that.$queue.show();
                    that.$statusBar.removeClass('element-invisible');
                    that.uploader.refresh();
                    break;

                case 'uploading':
                    $('#filePicker2').addClass('element-invisible');
                    that.$progress.show();
                    that.$upload.text('暂停上传');
                    break;

                case 'paused':
                    that.$progress.show();
                    that.$upload.text('继续上传');
                    break;

                case 'confirm':
                    that.$progress.hide();
                    $('#filePicker2').removeClass('element-invisible');
                    that.$upload.text('开始上传');

                    stats = that.uploader.getStats();
                    if (stats.successNum && !stats.uploadFailNum) {
                        that.setState('finish');
                        return;
                    }
                    break;
                case 'finish':
                    stats = that.uploader.getStats();
                    if (stats.successNum) {
                        that.$message.success('上传成功')
                    } else {
                        // 没有成功的图片，重设
                        that.state = 'done';
                        location.reload();
                    }
                    break;
            }

            that.updateStatus();
        },
        // ---------------------------------
// ---------  Uploader -------------
// ---------------------------------
        Uploader() {
            let that = this
            // -------setting-------
            // 如果使用原始大小，超大的图片可能会出现 Cropers UI 卡顿，所以这里建议先缩小后再crop.
            var FRAME_WIDTH = 1600;


            var _ = WebUploader;
            var Uploader = _.Uploader;
            var uploaderContainer = $('.uploader-container');
            var uploader, file;

            if (!Uploader.support()) {
                alert('Web Uploader 不支持您的浏览器！');
                throw new Error('WebUploader does not support the browser you are using.');
            }

            // hook,
            // 在文件开始上传前进行裁剪。
            Uploader.register({
                'before-send-file': 'cropImage'
            }, {

                cropImage: function (file) {

                    var data = file._cropData,
                        image, deferred;

                    file = this.request('get-file', file);
                    deferred = _.Deferred();

                    image = new _.Lib.Image();

                    deferred.always(function () {
                        image.destroy();
                        image = null;
                    });
                    image.once('error', deferred.reject);
                    image.once('load', function () {
                        if (file._info) {
                            image.resize(file._info.width, file._info.height);
                        }
                    });

                    image.once('complete', function () {
                        image.crop(data.x, data.y, data.width, data.height, data.scale);
                        var blob, size;

                        // 移动端 UC / qq 浏览器的无图模式下
                        // ctx.getImageData 处理大图的时候会报 Exception
                        // INDEX_SIZE_ERR: DOM Exception 1
                        try {
                            blob = image.getAsBlob(file.type);
                            size = file.size;
                            file.source = blob;
                            file.size = blob.size;

                            file.trigger('resize', blob.size, size);

                            deferred.resolve();
                        } catch (e) {
                            console.log(e);
                            // 出错了直接继续，让其上传原始图片
                            deferred.resolve();
                        }
                    });

                    file._info && image.info(file._info);
                    file._meta && image.meta(file._meta);
                    image.loadFromBlob(file.source);
                    return deferred.promise();
                }
            });

            return {
                init: function (selectCb) {
                    uploader = new Uploader({
                        pick: {
                            id: '#filePicker',
                            multiple: false
                        },

                        runtimeOrder: 'flash',

                        // 设置用什么方式去生成缩略图。
                        thumb: {
                            quality: 70,

                            // 不允许放大
                            allowMagnify: false,

                            // 是否采用裁剪模式。如果采用这样可以避免空白内容。
                            crop: false
                        },

                        // 禁掉分块传输，默认是开起的。
                        chunked: false,

                        // 禁掉上传前压缩功能，因为会手动裁剪。
                        compress: false,

                        // fileSingleSizeLimit: 2 * 1024 * 1024,

                        server: '../../server/fileupload.php',
                        swf: '../../dist/Uploader.swf',
                        fileNumLimit: 1,
                        onError: function () {
                            var args = [].slice.call(arguments, 0);
                            alert(args.join('\n'));
                        }
                    });

                    uploader.on('fileQueued', function (_file) {
                        file = _file;

                        uploader.makeThumb(file, function (error, src) {

                            if (error) {
                                alert('不能预览');
                                return;
                            }

                            selectCb(src);

                        }, FRAME_WIDTH, 1);   // 注意这里的 height 值是 1，被当成了 100% 使用。
                    });
                },

                crop: function (data) {

                    var scale = that.Cropers.getImageSize().width / file._info.width;
                    data.scale = scale;

                    file._cropData = {
                        x: data.x1,
                        y: data.y1,
                        width: data.width,
                        height: data.height,
                        scale: data.scale
                    };
                },

                upload: function () {
                    uploader.upload();
                }
            }
        },

// ---------------------------------
// ---------  Cropers ---------------
// ---------------------------------
        Cropers() {
            let that = this
            // var container = $('.cropper-wraper')
            // var $image = container.find('.img-container img')
            // var btn = $('.upload-btn');
            var isBase64Supported, callback;

            that.$image.cropper({
                aspectRatio: 16 / 9,
                preview: ".img-preview",
                done: function (data) {
                    // console.log(data);
                }
            });

            function srcWrap(src, cb) {

                // we need to check this at the first time.
                if (typeof isBase64Supported === 'undefined') {
                    (function () {
                        var data = new Image();
                        var support = true;
                        data.onload = data.onerror = function () {
                            if (this.width != 1 || this.height != 1) {
                                support = false;
                            }
                        }
                        data.src = src;
                        isBase64Supported = support;
                    })();
                }

                if (isBase64Supported) {
                    cb(src);
                } else {
                    // otherwise we need server support.
                    // convert base64 to a file.
                    $.ajax('../../server/preview.php', {
                        method: 'POST',
                        data: src,
                        dataType: 'json'
                    }).done(function (response) {
                        if (response.result) {
                            cb(response.result);
                        } else {
                            alert("预览出错");
                        }
                    });
                }
            }

            that.btn.on('click', function () {
                callback && callback(that.$image.cropper("getData"));
                return false;
            });

            return {
                setSource: function (src) {

                    // 处理 base64 不支持的情况。
                    // 一般出现在 ie6-ie8
                    srcWrap(src, function (src) {
                        that.$image.cropper("setImgSrc", src);
                    });

                    container.removeClass('webuploader-element-invisible');

                    return this;
                },

                getImageSize: function () {
                    var img = that.$image.get(0);
                    return {
                        width: img.naturalWidth,
                        height: img.naturalHeight
                    }
                },

                setCallback: function (cb) {
                    callback = cb;
                    return this;
                },

                disable: function () {
                    that.$image.cropper("disable");
                    return this;
                },

                enable: function () {
                    that.$image.cropper("enable");
                    return this;
                }
            }

        }
    },
    mounted() {
        const that = this
        // this.container = $('.cropper-wraper')
        // this.$image = this.container.find('.img-container img')
        // this.btn = $('.upload-btn');
        // this.Cropers()
        // this.UploaderData = this.Uploader()
        //
        // that.UploaderData.init(function( src ) {
        //
        //     that.setSource( src );
        //
        //     // 隐藏选择按钮。
        //     $('.uploader-container').addClass('webuploader-element-invisible');
        //
        //     // 当用户选择上传的时候，开始上传。
        //     that.Croper.setCallback(function( data ) {
        //         that.Uploader.crop(data);
        //         that.Uploader.upload();
        //     });
        // });

        const $wrap = $('#uploader')
        const $queue = $('<ul class="filelist"></ul>')
            .appendTo($wrap.find('.queueList'))
        this.$queue = $queue

        // 状态栏，包括进度和控制按钮
        this.$statusBar = $wrap.find('.statusBar')
        // 文件总体选择信息。
        this.$info = this.$statusBar.find('.info')
        // 没选择文件之前的内容。
        this.$placeHolder = $wrap.find('.placeholder'),
            this.$progress = this.$statusBar.find('.progress').hide()
        // 上传按钮
        this.$upload = $wrap.find('.uploadBtn')
        this.initWebUpload();
        if (this.Token === null) {
            this.Token = getToken()
        }
    }
}
