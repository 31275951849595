import themeColor from 'common/utils/config/themeColor'
import { req } from '@/api'

const state = {
  isLogin: false,
  userRoles: [],
  userName: '',
  userStatus: -1,
  userThemeColor: '',
  menuTop: [],
  menuTopActive: 0,
  menuTab: [],
  menuTabActive: 0
}
const mutations = {
  /**
   * 用户登录修改 state 内的用户状态
   * */
  setUserLogin(state, data) {
    console.log(data)
    state.isLogin = true
    state.userStatus = data.userStatus
    state.userName = data.userName
  },
  /**
   * 用户登出修改 state 内的用户状态
   * */
  setUserLogout(state) {
    state.isLogin = false
    state.userStatus = -1
    state.userRoles = []
    state.userName = ''
  },
  /**
   * 设置用户权限 userRoles
   * */
  setUserRoles(state, roleArray) {
    state.userRoles = roleArray
  },
  /**
   * 设置用户的 themeColor 值
   * */
  setUserThemeColor(state, color) {
    state.userThemeColor = color
  },
  setIsLimitss(state, bool) {
    state.isLimitss = bool
  },
  setMenutop(state, data) {
    state.menuTop = data
    state.menuTopActive = 0
    // state.menuTab = []
    // state.menuTabActive = 0
  },
  setMenutab(state, data) {
    state.menuTab = data
    state.menuTabActive = 0
  },
  setMenutabActive(state, active) {
    sessionStorage.setItem('menuTabActive', active)
    state.menuTabActive = active
  },
  clearMenutab(state) {
    state.menuTab = []
    state.menuTabActive = 0
  },
  addtab(state, data) {
    if (state.menuTab.length > 0) {
      let flg = true
      state.menuTab.forEach((item, index) => {
        if (item.path === data.path) {
          state.menuTabActive = index
          flg = false
        }
      })
      if (flg) {
        state.menuTab.push(data)
        state.menuTabActive = state.menuTab.length - 1
      }
    } else {
      state.menuTab.push(data)
      state.menuTabActive = state.menuTab.length - 1
    }
    sessionStorage.setItem('menutab', JSON.stringify(state.menuTab))
  },
  removetab(state, index) {
    state.menuTab.splice(index, 1)
    sessionStorage.setItem('menutab', JSON.stringify(state.menuTab))
  }
}

const actions = {
  /**
   * 用户登录操作
   * */
  userLogin({ commit }, userInfo) {
    // const { userRoles, userName } = userInfo
    return new Promise((resolve) => {
      commit('setUserLogin', userInfo)
      resolve()
    })
  },
  /**
   * 获取用户权限
   * */
  getUserRoles({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.userStatus) {
        switch (state.userStatus) {
          case 1:
            commit('setUserRoles', ['admin'])
            break
          case 2:
            commit('setUserRoles', ['group'])
            break
          case 3:
            commit('setUserRoles', ['user'])
            break
          default:
            commit('setUserRoles', [])
            break
        }
        resolve(state.userRoles)
      } else {
        const errorMsg = '没有找到权限标识符，请重新登录'
        reject(errorMsg)
      }
    })
  },
  /**
   * 用户登出
   * */
  userLogout({ commit }) {
    return new Promise((resolve, reject) => {
      if (process.env.VUE_APP_LOGIN_TYPE === 'sso') {
        req('get_sso_logout').then(res => {
          commit('setUserLogout')
          resolve()
        }).catch(error => {
          console.error(error)
          reject()
        })
      } else {
        commit('setUserLogout')
        resolve()
      }
    })
  },
  /**
   * 设置用户的 themeColor
   * */
  getThemeColor({ commit }, color) {
    return new Promise((resolve) => {
      themeColor.changeColor(color).finally(t => {
        commit('setUserThemeColor', color)
        resolve()
      })
      // setTimeout(() => {
      //   window.less.modifyVars({
      //     '@primary-color': color,
      //     '@link-color': color,
      //     '@btn-primary-bg': color
      //   }).then(() => {
      //     commit('setUserThemeColor', color)
      //     resolve()
      //   }).catch(error => {
      //     console.log(error)
      //     reject(error)
      //   })
      // }, 0)
    })
  },
  setMenutop({ commit }, data) {
    return new Promise((resolve) => {
      commit('setMenutop', JSON.parse(data))
      resolve()
    })
  },
  setMenutab({ commit }, data) {
    return new Promise((resolve) => {
      commit('setMenutab', JSON.parse(data))
      resolve()
    })
  },
  setMenutabActive({ commit }, active) {
    return new Promise((resolve) => {
      commit('setMenutabActive', active)
      resolve()
    })
  },
  cleartab({ commit }, data) {
    return new Promise((resolve) => {
      commit('clearMenutab')
      resolve()
    })
  },
  addMenutab({ commit }, data) {
    return new Promise((resolve) => {
      commit('addtab', JSON.parse(data))
      resolve()
    })
  },
  removeMenutab({ commit }, index) {
    return new Promise((resolve) => {
      commit('removetab', index)
      resolve()
    })
  }
}

const getters = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
