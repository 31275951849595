<template>
  <div class="com-trans-wrap">
    <div class="com-trans-left">
      <div class="trans-header">
        <slot name="header"></slot>
      </div>
      <div class="trans-body">
        <leftTree
          :dataSource="leftData"
          :defaultChecked="targetKeys"
          :replaceFields="replaceFields"
          :defaultExpandedKeys="inddefaultExpandedKeys"
          :loadData="loadData"
          :disable="disable"
          :isCanCheckTopNode="isCanCheckTopNode"
          :isCascadeUp="isCascadeUp"
          :isCascadeDown="isCascadeDown"
          @checked="leftSelect"
          @itemExpand="leftExpand"
          @leftParentClick="(e) => {$emit('leftParentClick', e)}"
          checkable>
        </leftTree>
      </div>
    </div>
    <div class="com-trans-right">
      <div class="trans-header">
        <div class="selected-num">已选{{selectedNum}}项</div>
          <slot name="headerRight"></slot>
        <a-button class="selected-alldel" @click="delAll" :disabled="disable">删除全部</a-button>
      </div>
      <div class="trans-body">
        <simpleList
          type="collapse"
          :disable="disable"
          :checkedName="checkedName"
          @delItem="rightDelItem"
          @sortItem="rightSortItem"
          :replaceFields="replaceFields"
          :dataSource="rightData">
        </simpleList>
      </div>
    </div>
  </div>
</template>
<script>
  import leftTree from './leftTree.vue'
  import simpleList from '../simpleList'
  export default {
    components: {
      leftTree,
      simpleList
    },
    props: {
      dataSource: {
        default: () => ([])
      },
      dataTarget: {
        default: () => ([])
      },
      replaceFields: {
        default: () => ({
            title:'title',
            key: 'key'
        })
      },
      // leftLoadData: {
      //     default: Function
      // },
      loadData: {
        type: Function
      },
      rightLoadData: {
        default: ''
      },
      selectKeys: {
        default: () => ([])
      },
      disable: {
        default: false
      },
      // 默认展开行
      defaultExpandedKeys: {
        default: () => ([])
      },
      /**
       * author: kangwj
       * TODO:暂时a-tree仅支持checkStrictly属性，先这样吧
       */
      // 是否向上级联
      isCascadeUp: {
        type: Boolean,
        default: false
      },
      // 是否向下级联
      isCascadeDown: {
        type: Boolean,
        default: true
      },
      // 是否顶级节点有CheckBox框
      isCanCheckTopNode: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        leftNum: 0,
        leftData: [],
        rightData: [],
        selectVal: '',
        selectedNum: 0,
        selectData: [],
        targetKeys: [],
        checkedName: ''
      }
    },
    watch: {
      dataSource (n) {
        this.setLeftData(n)
      },
      dataTarget (n) {
        this.setRightData(n)
        this.setSelectedNum(n)
      }
    },
    computed: {
      inddefaultExpandedKeys () {
        return this.defaultExpandedKeys
      }
    },
    beforeCreate() {
    },
    created () {
      this.setLeftData(this.dataSource)
      this.setRightData(this.dataTarget)
      // this.setSelectedNum(this.selectKeys)
      this.setSelectedNum(this.dataTarget)
    },
    mounted () {
      this.tableLoading = true
      this.setRightData(this.dataTarget)
    },
    methods: {
      setLeftData(data) {
          // console.log(data)
          // const filterIsLeaf = function (d) {
          //     d.forEach(i => {
          //         if (i.children && i.children.length) {
          //             i.
          //         }
          //     })
          // }
          this.leftData = data
          // console.log('left', this.dataSource, data)
      },
      setRightData(data) {
          // this.rightData = data
          this.targetKeys = []
          this.rightData = []
          data.forEach(item => {
              const key = item[this.replaceFields.key]
              if(!this.targetKeys.includes(key)) {
                  this.targetKeys.push(key)
                  this.rightData.push(item)
              }
          })
      },
      leftSelect (key, obj, info, checked) {
          // 取消全选
          if(this.isCascadeUp || this.isCascadeDown){
            let clickNum = 0
            let clickTop = 0
            // 判断是不是最顶级节点
            this.leftData.forEach(item=>{
              if (item.id === key){
                if (item.children){
                  let i=0
                  item.children.forEach(items=>{
                    if (this.targetKeys.includes(items.id)) {
                      i++
                    }
                  })
                  if (i === item.children.length) {
                    clickNum ++
                    this.canleSeletBeg(item.children)
                  }
                  clickTop ++
                }
              }
            })
            // 不是点击顶级节点时
            if (clickNum === 0) {
              if (this.targetKeys.includes(key)) {
                if (clickTop === 0){
                  let keyIndex = this.targetKeys.indexOf(key)
                  this.targetKeys.splice(keyIndex, 1)
                  this.rightData.splice(keyIndex, 1)
                }
                //  取消子级的全选
                if (obj.children) {
                  this.canleSeletAll(info, obj.children)
                }
                // 取消父级的全选
                if (this.targetKeys.includes(obj.parentId)) {
                  let keyIndex = this.targetKeys.indexOf(obj.parentId)
                  this.targetKeys.splice(keyIndex, 1)
                  this.rightData.splice(keyIndex, 1)
                  // 判断顶点父级
                  this.judgeParent(obj.parentId)
                  // 取消顶点父级
                  this.canleParentSel(obj.parentId, this.leftData[this.leftNum].children, this.leftNum)
                  // if (this.targetKeys.includes(this.leftData[this.leftNum].id)) {
                  //     let keyIndexs = this.targetKeys.indexOf(this.leftData[this.leftNum].id)
                  //     this.targetKeys.splice(keyIndexs, 1)
                  //     this.rightData.splice(keyIndexs, 1)
                  // }
                }
              } else {
                if (clickTop === 0 ) {
                  this.targetKeys.push(key)
                  this.rightData.push(obj)
                }
                // 当点击一个判断父节点全选
                if (info.includes(obj.parentId)) {
                  // console.log('leftdata', this.leftData, info)
                  // 判断首个父节点
                  this.judgeParent(obj.parentId)
                  this.cirTree(obj.parentId, this.leftData[this.leftNum].children, info)
                  // 判断首节点
                  // if (info.includes(this.leftData[this.leftNum].id)) {
                  //     this.targetKeys.push(this.leftData[this.leftNum].id)
                  //     this.rightData.push(this.leftData[this.leftNum])
                  // }
                }
                //  全选
                if (obj.children) {
                  // debugger
                  this.selectAll(info, obj.children)
                }
              }
            }
          }
          if (!this.isCascadeUp && !this.isCascadeDown) {
            if(checked) {
              this.targetKeys.push(key)
              this.rightData.push(obj)
            } else {
              if (this.targetKeys.includes(key)) {
                let index = this.targetKeys.indexOf(key)
                this.targetKeys.splice(index, 1)
                this.rightData.splice(index, 1)
              }
            }
          }
          console.log('this.num', this.leftNum)
          this.emitChange(this.rightData)
          this.leftNum = 0
          // console.log('leftSelect', this.targetKeys, this.rightData)
      },
      // 判断首个父级
      judgeParent(parentId){
          if (this.leftData.length>1) {
              this.leftData.forEach((item, index) => {
                  if (item.id === parentId) {
                      this.leftNum = index
                      // this.canleParentSel(obj.parentId, this.leftData[index].children)
                      return
                  } else {
                      this.cycleLeftdata(parentId, item, index)
                  }
              })
          } else {
              this.leftNum = 0
          }
      },
      // 循环父级leftData
      cycleLeftdata(parentId, data, index){
          if (data.children){
              data.children.forEach(items=>{
                  if (items.id === parentId) {
                      this.leftNum = index
                      // this.canleParentSel(parentId, this.leftData[index].children)
                      return
                  } else {
                      this.cycleLeftdata(parentId, items, index)
                  }
              })
          }
      },
      // 取消父级全选
      canleParentSel(parentId, data, num) {
          // console.log('num', num)
          data.forEach(item=>{
              if (item.id === parentId) {
                  if (this.targetKeys.includes(item.parentId)) {
                      let keyIndex = this.targetKeys.indexOf(item.parentId)
                      this.targetKeys.splice(keyIndex, 1)
                      this.rightData.splice(keyIndex, 1)
                      this.canleParentSel(item.parentId, this.leftData[num].children, num)
                  }
              } else if (item.children){
                  this.canleParentSel(parentId, item.children, num)
              }
          })
      },
      // 全选
      selectAll(info, data){
          data.forEach(item=>{
              if(info.includes(item.id)&&!this.targetKeys.includes(item.id)) {
                  this.targetKeys.push(item.id)
                  this.rightData.push(item)
                  if (item.children) {
                      this.selectAll(info, item.children)
                  }
              }
          })
      },
      canleSeletBeg(data){
          data.forEach(item=>{
              let keyIndex = this.targetKeys.indexOf(item.id)
              this.targetKeys.splice(keyIndex, 1)
              this.rightData.splice(keyIndex, 1)
              if (item.children){
                  this.canleSeletBeg(item.children)
              }
          })
      },
      canleSeletAll(info, data){
          data.forEach(item=>{
              if(!info.includes(item.id)) {
                  let keyIndex = this.targetKeys.indexOf(item.id)
                  this.targetKeys.splice(keyIndex, 1)
                  this.rightData.splice(keyIndex, 1)
                  if (item.children){
                      this.canleSeletAll(info, item.children)
                  }
              }
          })
      },
      // 遍历左侧树
      cirTree(parentId, data, info){
          // console.log('info', info)
          data.forEach(i=>{
              if (i.id === parentId) {
                  if(info.includes(parentId)) {
                      // console.log('cir', i)
                      this.targetKeys.push(parentId)
                      this.rightData.push(i)
                      this.cirTree(i.parentId, this.leftData[0].children, info)
                  }
              } else if (i.children) {
                  this.cirTree(parentId, i.children, info)
              }
          })
      },
      delAll () {
          if(this.disable) return
          console.log('ok')
          this.targetKeys = []
          this.rightData = []
          this.emitChange(this.rightData)
      },
      emitChange (change) {
          this.setSelectedNum(change)
          console.log('emitChange', change)
          this.$emit('change', change)
      },
      leftExpand (e) {
          console.log(e)
      },
      setSelectedNum(data) {
          this.selectedNum = data.length
      },
      rightDelItem (e) {
          const key = e[this.replaceFields.key]
          this.leftSelect(key, e)
      },
      rightSortItem () {
          this.$emit('change', data)
      }
    }
  }
</script>
