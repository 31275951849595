<!--
 <flow-audit ref="flowAudit" />
 -->
<template>
  <div class="audit-flow" v-show="visible">
    <div class="audit-flow-header">
      <div class="workflow-step-title">办理审批</div>
      <a-button type="primary" @click="onSubmit" :disabled="isSubmitting" v-show="this.state.showSubmitBtn">
        办理
      </a-button>
    </div>
    <div class="audit-flow-form-room">
      <a-form-model v-bind="formItemLayout" :rules="formRules" :model="formModel" ref="form">
        <a-form-model-item label="当前步骤" :colon="false">
          <span class="audit-flow-current-step">{{state.prcsName}}</span>
        </a-form-model-item>
        <a-form-model-item label="办理结果" prop="approveResult" v-show="state.canBack" :colon="false">
          <a-radio-group v-model="state.selectedGoOrBack" @change="onApproveResChange">
            <a-radio :value="1">
              {{state.btnName}}
            </a-radio>
            <a-radio :value="2" v-if="state.canBack">
              退回
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="下步步骤" prop="nextStep" :colon="false">
          <a-radio-group v-if="state.selectedGoOrBack == 1" v-model="state.selectedStep.prcsId" @change="resetHandler">
            <a-radio
                    v-for="item in state.flowRunProcessModelList"
                    :key="item.prcsId"
                    :value="item.prcsId"
                    @change="onStep(item, $event)">
              {{item.prcsName}}
            </a-radio>
          </a-radio-group>
          <a-radio-group v-if="state.selectedGoOrBack == 2" v-model="state.selectedStep.frpSid" @change="resetHandler">
            <a-radio
                    v-for="item in state.flowRunProcessBackModel"
                    :key="item.frpSid"
                    :value="item.frpSid"
                    @change="onStep(item, $event)">
              {{item.prcsName}}
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item label="并行步骤" v-if="state.showParallelPrcs" :colon="false">
          <a-checkbox-group v-model="state.selectedParallelNextStepPrcsIds" @change="resetParallelNextStepHandler">
            <a-checkbox
                    v-for="item in state.parallelPrcs[state.selectedParallelNextStep.prcsId]"
                    :key="item.prcsId"
                    :value="item.prcsId"
                    @change="onParallelNextStep(item, $event)">
              {{item.prcsName}}
            </a-checkbox>
          </a-checkbox-group>
        </a-form-model-item>
        <a-form-model-item v-show="state.showAuditor" :label="getPrcsName(state.selectedStep.prcsId)" :colon="false" prop="handler" v-if="(!state.selectedStep.prcsType ||state.selectedStep.prcsType !== 4) && state.selectedGoOrBack == 1">
          <a-spin :spinning="loadingSelectUser" v-if="loadingSelectUser" style=""></a-spin>
          <a-checkbox-group v-model="state.selectedUsers" @change="e => { onHandlerChange(e) }" >
            <span v-for="item in state.users[state.selectedStep.prcsId]" :key="item.uuid" :value="item.uuid">
              <a-checkbox :value="item.uuid" >
                {{item.userName}}
              </a-checkbox>
            </span>
          </a-checkbox-group>
        </a-form-model-item>

        <div v-for="(id, index) in state.selectedParallelNextStepPrcsIds" :key="index">
          <a-form-model-item :label="getPrcsName(id)" :colon="false">
            <a-checkbox-group
                    v-model="state.selectParallelUsers[id]"
                    @change="onHandlerParallelUsersChange(id, $event)">
              <span v-for="item in state.users[id]" :key="item.uuid" :value="item.uuid">
                <a-checkbox :value="item.uuid" >
                  {{item.userName}}
                </a-checkbox>
              </span>
            </a-checkbox-group>
          </a-form-model-item>
        </div>
       <a-form-model-item label="意见" class="audit-suggest" v-show="state.showRemark" :colon="false">
          <a-textarea placeholder="请输入意见"  v-model="state.remark" :auto-size="{ minRows: 5, maxRows: 10 }" style="width:90%" />
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'flowAudit',
    props: {
      visible: {
        type: Boolean,
        default: false
      },
    },
    data () {
      return {
        loadingSelectUser: true,
        isSubmitting: false,
        formModel: { },
        formRules: {
          handler: [{ validator: this.verifyHandler, trigger: 'change', message: '办理人不能为空' }]
        },
        formItemLayout: {
          labelCol: { span: 6 },
          wrapperCol: { span: 14 }
        },
        formData: {},
        state: {
          selectParallelUsers: {},
          selectedParallelNextStepPrcsIds: [],
          flowRunProcessModelList: [],
          flowRunProcessBackModel: [],
          selectedGoOrBack: 1,
          selectedStep: { prcsName: '' },
          users: [],
          selectedUsers: [],
          remark: '',
          id: '',
          runName: '',
          showSubmitBtn: false,
          showRemark: false,
          canAudit: false,
          whyCanNotAuditMsg: '',
          canBack: false,
          prcsName: '',
          btnName: '同意',
          showParallelPrcs: false,
          parallelPrcs: {},
          extend: '',
          selectedParallelNextStep: {},
          prcsState: null,
          isBatchAudit: false, // 是否为批量审核
          batchAuditContexts: [],
          isHideAuditor: 0
        },
        onBeforeValid:'',
        onBeforeSubmit: '',
        onAfterSubmit: ''
      }
    },
    created () {
      // window.flowAudit = this
    },
    methods: {
      init (params) {
        if (!params.id) throw new Error('id不能为空')
        if(params.id.indexOf(',')){
          params.id = params.id.split(',')
        }

        this.isSubmitting = false
        if (params.onBeforeValid) {
          this.onBeforeValid = params.onBeforeValid
        }else{
          this.onBeforeValid = function (params) {
            return new Promise(function (resolve, reject) {
              resolve({ id: params.id, isPass: true, noPassMsg: '' })
            })
          }
        }
        if (params.onBeforeSubmit) {
          this.onBeforeSubmit = params.onBeforeSubmit
        }else{
          this.onBeforeSubmit = function (params) {
            return new Promise(function (resolve, reject) {
              resolve({ id: params.id, isPass: true, noPassMsg: '' })
            })
          }
        }
        if (params.onAfterSubmit) {
          this.onAfterSubmit = params.onAfterSubmit
        }
        if (params.hideSubmitBtn === true) {
          this.state.showSubmitBtn = false
        } else {
          this.state.showSubmitBtn = true
        }
        if (params.id) {
          this.state.id = params.id
        }
        if (params.flowAlias) {
          this.state.flowAlias = params.flowAlias
        }

        // 判断是否为批量审批
        if (Object.prototype.toString.call(params.id) === '[object Array]') {
            if(params.id.length === 1){
                // 单独提交
                this.state.isBatchAudit = false
                this.state.id = params.id[0]
            }else {
                // 批量提交
                this.state.isBatchAudit = true
                this.state.batchAuditContexts = []
                params.id.forEach(c => {
                    this.state.batchAuditContexts.push({ id: c })
                })
                this.state.id = params.id[0]
            }
        } else {
          // 单独提交
          this.state.isBatchAudit = false
          this.state.id = params.id
        }

        return new Promise((resolve, reject) => {
          if (this.state.isBatchAudit) {
            let idArr = []
            this.state.batchAuditContexts.forEach(c => { idArr.push(c.id) })
            const idsStr = idArr.join(",")
            this.$http('post_checkIsSameStep', { ids: idsStr }, true).then(res => {
              if (res.data.isSame === true) {
                this.otherIdsInitContext()
                this.initContext({ id: this.state.batchAuditContexts[0].id, flowAlias: this.state.flowAlias }, resolve, reject)
              } else {
                reject(new Error(res.message))
              }
            })
          } else {
            this.initContext({ id: this.state.id, flowAlias: this.state.flowAlias }, resolve, reject)
          }
        })
      },
      otherIdsInitContext () {
        if (this.state.batchAuditContexts.length > 1) {
          // 从第二个开始
          for (let i = 1; i < this.state.batchAuditContexts.length; i++) {
            const params = {
              id: this.state.batchAuditContexts[i].id,
              flowAlias: this.state.flowAlias
            }
            this.$http('post_flowAuditInit', params, true).then(res => {
              this.state.batchAuditContexts[i].context = res.data
              this.state.batchAuditContexts[i].context.id = this.state.batchAuditContexts[i].context.relationdId
            })
          }
        }
      },
      initContext (params, resolve, reject) {
        this.$http('post_flowAuditInit', params, true).then(res => {
          if (res.code === 200) {
            this.stateInit()
            if (!res.data) {
              this.state.canAudit = false
              this.state.prcsState = null
            } else {
              this.formData = res.data
              if (this.state.isBatchAudit) {
                this.state.batchAuditContexts[0].context = res.data
                this.state.batchAuditContexts[0].context.id = res.data.relationdId
              }
              this.state.canAudit = true

              if(this.formData.showRemark){
                this.state.showRemark = !!JSON.parse(this.formData.showRemark)
              }else{
                  this.state.showRemark = false
              }

              this.state.prcsName = this.formData.prcsName
              this.state.prcsState = this.formData.prcsState
              this.state.isHideAuditor = this.formData.isHideAuditor
              this.state.showAuditor = !(this.state.isHideAuditor === 1)
              if (this.formData.allNextPrcsNodes) {
                this.state.flowRunProcessModelList = []
                this.formData.allNextPrcsNodes.map(item => {
                  for (const oitem of this.formData.prcsNodeInfos) {
                    if (item === oitem.prcsId) {
                      this.state.flowRunProcessModelList.push(oitem)
                      break
                    }
                  }
                })
              }

              if (this.formData.flowRunProcessBackModel && this.formData.flowRunProcessBackModel.length > 0) {
                this.state.flowRunProcessBackModel = this.formData.flowRunProcessBackModel
              }

              // 初始化并行步骤
              if (this.formData.parallelChildNodes) {
                this.state.parallelPrcs = {}
                Object.keys(this.formData.parallelChildNodes).forEach(function (key) {
                  this.state.parallelPrcs[key] = []
                  for (const prcsId of this.formData.parallelChildNodes[key].nodes) {
                    for (const prc of this.formData.prcsNodeInfos) {
                      if (prcsId === prc.prcsId) {
                        this.state.parallelPrcs[key].push(prc)
                        break
                      }
                    }
                  }
                })
              }

              this.checkFirstStepAndInitUsers()
            }

            resolve()
          } else {
            this.state.canAudit = false
            this.state.whyCanNotAuditMsg = res.message
            reject(new Error(res.message))
          }
        })
      },
      getPrcsName (prcsId) {
        if (!this.formData.prcsNodeInfos) {
          return ''
        }
        for (const prcs of this.formData.prcsNodeInfos) {
          if (prcs.prcsId === prcsId) {
            // if (prcs.prcsName.length > 4) {
            //   return `(${prcs.prcsName.substr(0, 4)}\r\n${prcs.prcsName.substr(4)})`
            // } else {
            return `(${prcs.prcsName})`
            // }
          }
        }
        return ''
      },
      stateInit () {

      },
      verifyHandler (rule, value, callback) {
        // const val = this.state.selectedUsers
        // const checkStatus = this.state.selectedGoOrBack === 2
        // const checkHasUsers = this.state.users.length
        // const checkHasSelectedUser = val.length
        callback()
        // if (checkStatus || !checkHasUsers || (checkHasUsers && checkHasSelectedUser)) {
        //   callback()
        // } else if (this.formData.isRunBack === 1) {
        //   callback()
        // } else {
        //   callback(new Error('办理人不能为空'))
        // }
      },
      /**
       * 置空已选的办理人
       */
      resetHandler () {
        this.state.selectParallelUsers = {}
        this.state.selectedUsers = []
      },
      /**
       * 置空部分已选的办理人
       */
      resetParallelNextStepHandler (val) {
        if (!this.state.selectParallelUsers) {
          this.state.selectParallelUsers = {}
        }
        // this.state.selectParallelUsers[i] = {}
        // this.state.selectedParallelNextStepPrcsIds = val
      },
      /**
       * 初始化步骤
       */
      checkFirstStepAndInitUsers () {
        if (this.state.flowRunProcessModelList.length > 0) {
          this.state.selectedStep.prcsId = this.state.flowRunProcessModelList[0].prcsId
          this.onStep(this.state.flowRunProcessModelList[0], { target: { value: true } })
        }

        if (this.state.flowRunProcessBackModel && this.state.flowRunProcessBackModel.length > 0) {
          this.state.canBack = true
          this.state.selectedStep.frpSid = this.state.flowRunProcessBackModel[0].frpSid
        }
      },
      /**
       * 办理结论变更回调
       */
      onApproveResChange () {
        this.state.selectedStep = {}
        this.state.selectedParallelNextStepPrcsIds = []
        this.state.selectParallelUsers = {}
        this.checkFirstStepAndInitUsers()
      },
      /**
       * 办理人变更
       */
      onHandlerParallelUsersChange (id, val) {
        this.state.selectParallelUsers[id] = val
        this.$forceUpdate()
      },
      onHandlerChange (e) {
        // 这点没有理解意思，后期再改吧
        if(e.length > 0){
          this.formModel.selectedUsers = e.join(',');
        }else{
          this.formModel.selectedUsers = ''
        }
      },
      onParallelNextStep (data, event) {
        const checked = event ? event.target.value : false
        if (checked) {
          if (!data.prcsType || data.prcsType !== 4) {
            const paramsData = { prcsId: data.prcsId, frpSid: this.formData.frpSid };
            this.loadingSelectUser = true;
            this.$http('post_getFlowUsers', paramsData, true).then(res => {
              if (res.data && res.data.length === 1) {
                this.state.selectedUsers = [res.data[0].uuid]
              }
              if (!this.state.selectParallelUsers) {
                this.state.selectParallelUsers = {}
              }
              if (!this.state.selectParallelUsers[data.prcsId]) {
                this.state.selectParallelUsers[data.prcsId] = []
              }
              if ((!this.state.selectedStep.prcsType || this.state.selectedStep.prcsType !== 4) &&
                      res.data.length === 1) {
                this.state.selectParallelUsers[data.prcsId].push(res.data[0].uuid)
              }
              this.state.users[data.prcsId] = res.data
              this.loadingSelectUser = false;
              this.$forceUpdate()
            })
          } else {
            this.state.selectedParallelNextStep = data
            this.state.users[data.prcsId] = {}
          }
        } else {
          this.state.selectedParallelNextStep = {}
          this.state.users[data.prcsId] = {}
        }
      },
      onStep (data, event) {
        this.state.selectedStep = data
        this.state.selectedParallelNextStepPrcsIds = []
        if (this.state.selectedGoOrBack === 1) {
          if (this.state.selectedStep.prcsType && this.state.selectedStep.prcsType === 4) {
            this.state.showParallelPrcs = true
          } else {
            this.state.showParallelPrcs = false
          }

          this.onParallelNextStep(data, event)
        }
      },
      onSubmit () {
        console.log('FlowAudit-onSubmit')
        if (!this.state.canAudit) {
          // throw new Error(this.state.whyCanNotAuditMsg)
          this.$message.error('FlowAudit-onSubmit:' + this.state.whyCanNotAuditMsg)
          return
        }

        if (this.state.isBatchAudit) {
          console.log('FlowAudit-onSubmit-isBatchAudit')
          this.state.batchAuditContexts.forEach(i => {
            this.onBeforeValid(i.context).then(res => {
              if(res && res.isPass){ this.submit(res) }else{
                this.$message.error('FlowAudit-onBeforeValid:' + (res ? 'isPass未配置' : res.noPassMsg))
              }
            })
          })
        } else {
          console.log('FlowAudit-onSubmit-noBatchAudit')
          this.onBeforeValid(this.state).then(res => {
            if(res && res.isPass){ this.submit(res) }else{
              this.$message.error('FlowAudit-onBeforeValid:' + (res ? 'isPass未配置' : res.noPassMsg))
            }
          })
        }
      },
      submit: function (params) {
        if (params) {
          if (params.runName) {
            this.state.runName = params.runName
          }
          if (params.remark) {
            this.state.remark = params.remark
          }
          if (params.extend) {
            this.state.extend = params.extend
          }
        }

        this.$refs.form.validate(valid => {
          const checkHandler = true
          /**
           * 1. 存在非空的审核人列表，且没有选中办理人
           * 2. 当前选中为退回而非通过
           * 3. 选择了并行步骤，但是没有选择并行步骤的下步步骤
           */
                  // const checkStatus = this.state.selectedGoOrBack === 2
                  // const checkHasUsers = this.state.users.length
                  // const checkHasSelectedUser = this.state.selectedUsers.length
          const checkIsParallelStep = this.state.selectedStep.prcsType === 4 && this.state.selectedParallelNextStepPrcsIds.length <= 0
          if (checkIsParallelStep) {
            this.$message.error('并行步骤不能为空')
            return
          }
          // checkHandler = checkStatus || !checkHasUsers || (checkHasUsers && checkHasSelectedUser)
          if (valid && checkHandler) {
            let runId = this.formData.runId
            let flowId = this.formData.flowId
            let frpSid = this.formData.frpSid
            let id = this.state.id
            if (this.state.isBatchAudit) {
              if (!params.id) {
                const tip = '批量审批，缺少参数id（单据id）'
                console.error(tip)
                throw new Error(tip)
              }
              const context = this.state.batchAuditContexts.filter(c => { return c.id === params.id })[0].context
              runId = context.runId
              flowId = context.flowId
              frpSid = context.frpSid
              id = params.id
            }

            const turnModels = []
            if (this.state.selectedGoOrBack === 1) {
              if (this.state.selectedStep.prcsType && this.state.selectedStep.prcsType === 4) {
                for (const item of this.state.parallelPrcs[this.state.selectedStep.prcsId]) {
                  for (const prcsId of this.state.selectedParallelNextStepPrcsIds) {
                    if (prcsId === item.prcsId) {
                      if (this.state.selectParallelUsers[item.prcsId].length <= 0 && (item.prcsType && item.prcsType !== 2)) {
                        this.$message.error('请选择办理人')
                        return
                      }
                      turnModels.push({
                        parallelNode: this.state.selectedStep.prcsId,
                        prcsId: item.prcsId,
                        opFlag: item.opFlag,
                        prcsUser: this.state.selectParallelUsers[item.prcsId],
                        timeout: '0'
                      })
                      break
                    }
                  }
                }
              } else {
                if (this.state.selectedUsers.length <= 0 && (this.state.selectedStep.prcsType && this.state.selectedStep.prcsType !== 2)) {
                  this.$message.error('请选择办理人')
                  return
                }
                turnModels.push({
                  prcsId: this.state.selectedStep.prcsId,
                  opFlag: this.state.selectedStep.opFlag,
                  prcsUser: this.state.selectedUsers,
                  timeout: '0'
                })
              }
              this.onBeforeSubmit({id: id}).then(res => {
                if(res && res.isPass){
                  const params = {
                    runId: runId,
                    flowId: flowId,
                    frpSid: frpSid,
                    id: id
                  }
                  this.submitPass(turnModels, params)
                }else{
                  if(res && !res.isPass){
                    if(res.noPassMsg){
                      this.$message.error(res.noPassMsg)
                    }
                  } else {
                    this.$message.error('isPass未配置')
                  }
                }
              })
            } else {
              const params = {
                runId: runId,
                flowId: flowId,
                frpSid: frpSid,
                id: id
              }
              this.submitBack(params)
            }
          }
        })
      },
      // 提交-通过
      submitPass (turnModels, params) {
        this.isSubmitting = true
        const paramsData = {
          runId: params.runId,
          flowId: params.flowId,
          frpSid: params.frpSid,
          id: params.id,
          nextPrcsAlert: 0,
          beginUserAlert: 0,
          allPrcsUserAlert: 0,
          viewPerson: '',
          msg: '',
          prcsEvent: '',
          // [{ prcsId: '25170', opFlag: 4, prcsUser: '["C8B68921-FD1D-4AF8-ADE9-0DD9C973298F"]', timeout: '0' }],
          turnModel: JSON.stringify(turnModels),
          childFlowTurnModel: [],
          remark: this.state.remark,
          runName: this.state.runName, // '关于大学生的市外转任请示',
          extend: JSON.stringify(this.state.extend) // { GWY0200: formData.GWY0200 }
        }
        this.$http('post_flowSubmit', paramsData, true).then(res => {
          if (res.code !== 200) {
            this.isSubmitting = false
          }
          if (this.onAfterSubmit) {
            this.onAfterSubmit(res.code === 200, 1, res, paramsData)
          } else {
            console.log('post_flowSubmit', res);
            if (res.code === 200) {
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message)
            }
          }
        })
      },
      // 提交-退回
      submitBack (params) {
        // fixed
        // runId: 6203
        // flowId: 10150
        // frpSid: 10351
        // prcsTo: 10350
        // content: 退回
        // isPhoneRemind: 0
        // other
        const paramsData = {
          runId: params.runId,
          flowId: params.flowId,
          frpSid: params.frpSid,
          id: params.id,
          prcsTo: this.state.selectedStep.frpSid,
          content: this.state.remark,
          isPhoneRemind: 0
        }
        this.$http('post_backToFixed', paramsData, true).then(res => {
          if (res.code !== 200) {
            this.isSubmitting = false
          }
          if (res.code === 200) {
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
          if (this.onAfterSubmit) {
            this.onAfterSubmit(res.code === 200, 2, res, paramsData)
          } else {
            if (res.code === 200) {
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message)
            }
          }
        })
      }
    }
  }
</script>

<style scoped>
  .audit-flow {
    min-width: 340px;
    width: 100%;
    margin: 0 auto;
  }
  .audit-flow-header {
    width: 100%;
  }
  .audit-flow-current-step {
    padding-left: 10px;
    color: black;
    font-weight: bold;
  }
</style>

<style lang="less">
  .audit-suggest {
    display: flex;
    .ant-form-item-control-wrapper {
      flex: auto;
    }
  }
  .audit-flow-form-room {
    .ant-form-item-label {
      label {
        width: 7em;
        display: inline-block;
        line-height: 20px;
        white-space: pre-wrap;
        text-align: left;
      }
    }
  }
</style>