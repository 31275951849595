<!--
    <flow-steps ref="flowSteps" />
-->
<template>
  <div v-show="visible">
    <div class="workflow-step-title">审批流程</div>
    <div class="workflow-step-steps">
      <a-steps progress-dot :current="current" direction="vertical">
        <a-step v-for="(item, index) in steps" :key="index" :title="item.prcsName">
          <div slot="description">
<!--            <div><span class="text">{{item.opFlag === 1 ? '提交人' : '办理人'}}：</span>{{item.prcsUserName}}</div>-->
            <div><span class="text">办理人：</span>{{item.prcsUserName}}</div>
            <div v-show="item.flag === 2">接收时间：{{item.beginTimeDesc}}</div>
            <div v-show="item.flag === 2">办理用时：{{item.passedTime}}</div>
            <div v-show="item.flag !== 2">办理结果：
              {{item.resultFlag === 0 ? '' : item.resultFlag === 2 ? '退回' : item.resultFlag === 3 ? '反审' : item.prcsEventDef}}</div>
            <div v-show="item.flag !== 2">办结时间：{{item.endTimeDesc}}</div>
            <div v-show="item.flag !== 2">办结用时：{{item.passedTime}}</div>
            <div v-show="item.opFlag !== 1 && item.flag !== 2">办理意见：{{item.remark}}</div>
            <div v-show="item.opFlag === 1 && (item.resultFlag === 2 || item.resultFlag === 3)">办理意见：{{item.remark}}</div>
          </div>
        </a-step>
      </a-steps>
    </div>
  </div>
</template>

<script>
import { findLastIndex } from 'lodash'
import { ACCESS_USERINFO } from 'common/confing'
export default {
  name: 'flowSteps',
  props: {
    id: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      current: 0, // 第几项显示为选中状态，即正在处理的步骤
      steps: []
    }
  },
  watch: {
    id: {
      immediate: true,
      handler: function (val) {
        if(val && this.visible){
            this.getFlowRunList()
        }
      }
    },
    visible: {
      immediate: true,
      handler: function (val) {
        if(val && this.id){
          this.getFlowRunList()
        }
      }
    }
  },
  mounted () {
  },
  methods: {
    getFlowRunList () {
      const currentUserId = JSON.parse(sessionStorage.getItem(ACCESS_USERINFO) || '{}').id
      this.$http('post_getFlowRunList', { id: this.id }).then(res => {
        if(res.code === 200){
          if(res.data.rtData){
            const prcsList = res.data.rtData.prcsList.filter(item=>{
              // return item.resultFlag === 1 || item.prcsUserId === currentUserId
              return item
            })
            this.steps = prcsList

            // 倒着查，防止同一个人多次出现在流程中。让最后一个出现的作为现在正在进行中的步骤
            const index = findLastIndex(this.steps, (item) => {
              return item.prcsUserId === currentUserId
            })

            this.current = (index === -1) ? 0 : index
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  /deep/.ant-steps-dot .ant-steps-item-content,
  /deep/.ant-steps-dot.ant-steps-small .ant-steps-item-content{
    width: auto;
  }
  .text {
    letter-spacing:0.33em;
    margin-right:-0.33em;
  }
</style>
