export default {
  path: '/inspect',
  name: 'checkoBject',
  redirect: '/inspect/checkoBject',
  meta: {
    module: 'inspect',
    title: '行政检查',
    roles: ['admin', 'group', 'user'],
    icon: ''
  },
  children: [
    {
      path: '/inspect/checkoBject',
      name: 'checkoBject',
      meta: {
        module: '行政检查',
        title: '检查对象查询',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/inspect/personnelLocation',
      name: 'personnelLocation',
      meta: {
        module: '行政检查',
        title: '执法人员位置信息',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/inspect/checkCaseList',
      name: 'checkCaseList',
      meta: {
        module: '行政检查',
        title: '检查案件列表',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/inspect/officialSealRules',
      name: 'officialSealRules',
      meta: {
        module: '行政检查',
        title: '公章规则配置',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/inspect/checkTable',
      name: 'checkTable',
      meta: {
        module: '行政检查',
        title: '检查工作台',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/inspect/inspectionPlan',
      name: 'inspectionPlan',
      meta: {
        module: '行政检查',
        title: '检查计划',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/inspect/inspectionTask',
      name: 'inspectionTask',
      meta: {
        module: '行政检查',
        title: '检查任务',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/inspect/saveCheckList',
      name: 'saveCheckList',
      meta: {
        module: '行政检查',
        title: '检查任务',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    },
    {
      path: '/inspect/trackQuery',
      name: 'trackQuery',
      meta: {
        module: '行政检查',
        title: '轨迹查询',
        roles: ['admin', 'group', 'user'],
        icon: ''
      }
    }
  ]
}
