<template>
  <div class="com-trans-wrap">
    <div class="com-trans-left">
      <div class="trans-header">
        <slot name="header"></slot>
      </div>
      <div class="trans-body">
        <collapseList
            :dataSource="leftData"
            :defaultChecked="targetKeys"
            :replaceFields="replaceFields"
            :defaultExpandedKeys="defaultExpandedKeys"
            :loadData="leftLoadData"
            :disable="disable"
            @checked="leftSelect"
            @itemExpand="leftExpand"
            @excludeRepeatKey="excludeRepeatKey"
            @leftParentClick="(e) => {$emit('leftParentClick', e)}"
            checkable
        ></collapseList>
      </div>
    </div>
    <div class="com-trans-right">
      <div class="trans-header">
        <div class="selected-num">已选{{selectedNum}}项</div>
        <a-button class="selected-alldel" @click="delAll" :disabled="disable">删除全部</a-button>
      </div>
      <div class="trans-body">
        <simpleList
            type="collapse"
            :disable="disable"
            :checkedName="checkedName"
            @delItem="rightDelItem"
            @sortItem="rightSortItem"
            :replaceFields="replaceFields"
            :dataSource="rightData"
        ></simpleList>
      </div>
    </div>
  </div>
</template>
<script>
  import collapseList from './collapseList.vue'
  import simpleList from './simpleList'
  export default {
    props: {
      multiple: {
        type:Boolean,
        default: false
      },
      dataSource: {
        default: () => ([])
      },
      dataTarget: {
        default: () => ([])
      },
      replaceFields: {
        default: () => ({
          title:'title',
          key: 'key'
        })
      },
      leftLoadData: {
        default: ''
      },
      rightLoadData: {
        default: ''
      },
      selectKeys: {
        default: () => ([])
      },
      disable: {
        default: false
      }
    },
    components: {
      collapseList,
      simpleList
    },
    data () {
      return {
        leftData: [],
        rightData: [],
        selectVal: '',
        selectedNum: 0,
        selectData: [],
        targetKeys: [],
        defaultExpandedKeys: [],
        checkedName: ''
      }
    },
    beforeCreate() {
    },
    created () {
      this.setLeftData(this.dataSource)
      this.setRightData(this.dataTarget)
      this.setSelectedNum(this.selectKeys)
    },
    mounted () {
      this.tableLoading = true
      this.excludeRepeatKey()
    },
    methods: {
      excludeRepeatKey() {
        for (let i = 0; i<this.dataSource.length; i++) {
          if(this.dataSource[i].children){
            for (let j = 0; j<this.dataSource[i].children.length; j++) {
              this.dataSource[i].children[j].key = this.dataSource[i].key + j
            }
          }
        }
      },
      setLeftData(data) {
        // data.forEach(item => {
        //   if (item.children.length > 0) {
        //     console.log(1)
        //     for (var i = 0; i<item.children; i++) {
        //       console.log(2)
        //       console.log(item.children[i])
        //     }
        //   }
        // })
        // for (var i = 0; i<data.length; i++) {
        //     for (var j = 0; j< data[i].children.length; j++) {
        //       console.log(1)
        //     }
        // }
        this.excludeRepeatKey()
        this.leftData = data
      },
      setRightData(data) {
        // this.rightData = data
        this.targetKeys = []
        this.rightData = []
        data.forEach(item => {
          const key = item[this.replaceFields.key]
          if(!this.targetKeys.includes(key)) {
            this.targetKeys.push(key)
            this.rightData.push(item)
          }
        })
      },
      leftSelect (key, obj) {
        if(this.targetKeys.includes(key)) {
          let keyIndex = this.targetKeys.indexOf(key)
          this.targetKeys.splice(keyIndex, 1)
          this.rightData.splice(keyIndex, 1)
        } else {
          if (this.multiple) {
            this.targetKeys = []
            this.rightData = []
            this.targetKeys.push(key)
            this.rightData.push(obj)
          } else {
            this.targetKeys.push(key)
            this.rightData.push(obj)
          }
        }
        this.emitChange(this.rightData)
      },
      delAll () {
        if(this.disable) return
        console.log('ok')
        this.targetKeys = []
        this.rightData = []
        this.emitChange(this.rightData)
      },
      emitChange (change) {
        this.setSelectedNum(change)
        this.$emit('change', change)
      },
      leftExpand (e) {
      },
      setSelectedNum(data) {
        this.selectedNum = data.length
      },
      rightDelItem (e) {
        const key = e[this.replaceFields.key]
        this.leftSelect(key, e)
      },
      rightSortItem () {
        this.$emit('change', data)
      }
    },
    watch: {
      dataSource (n) {
        this.setLeftData(n)
      },
      dataTarget (n) {
        this.setRightData(n)
      },
      selectKeys (n) {
        this.setSelectedNum(n)
      }
    }
  }
</script>
