<template>
  <div class="simple-list" ref="simpleList">
    <div class="list-item" v-if="type === 'single'">
      {{checkedName}}
    </div>
    <vueDraggable
      tag="ul"
      class="list-con"
      v-model="listData"
      handle=".drag-btn"
      @end="updateListData"
    >
      <li
        class="list-item hover"
        :class="showDisable"
        v-for="(item, index) in listData"
        :key="index"
        :title="item[replaceFields.title]"
      >
        <span>{{item[replaceFields.title]}}</span>
        <div class="opr-btn" style="position: absolute;right: 0px;top: 0px;">
          <span @click="delItem(item, index)" class="iconhr ico-8"></span>
          <span v-if="['single'].includes(type)" @click="delItem(item, index)" class="iconhr ico-huanhang drag-btn"></span>
        </div>
      </li>
    </vueDraggable>
  </div>
</template>
<script>
  import { vueDraggable } from '../vueDraggable'
  export default {
    props: {
      dataSource: {
        default: ''
      },
      defaultChecked: {
        default: () => ([])
      },
      defaultExpandedKeys: {
        default: () => ([])
      },
      replaceFields: {
        default: () => ({
          title:'title',
          key: 'key'
        })
      },
      type: {
        default: ''
      },
      checkedName: {
        default: ''
      },
      disable: {
        default: Boolean
      }
    },
    components: {
      vueDraggable
    },
    data() {
      return {
        listData: [],
        // 备份
        listDatas: [],
        showDisable:''
      }
    },
    created() {
      this.listData = [...this.dataSource]
      this.$nextTick(() => {
        this.setEvenBgColor()
      })
      if (this.disable === true) {
        this.showDisable ='disable'
      }
    },
    mounted() {
    },
    methods: {
      updateListData() {
        this.$emit('sortItem', this.listData)
      },
      delItem(item, index) {
        if (this.disable === true) {
          return
        }
        this.$emit('delItem', [item, index])
      },
      setEvenBgColor () {
        let itemText = this.$refs.simpleList.querySelectorAll('.list-item')
        for(let i = 1; i < itemText.length; i+=2) {
          itemText[i].className += ' even-row'
        }
        setTimeout(() => {
          var data = document.getElementsByClassName('list-con')[0].children
          if (data.length) {
            for (var i=0;i<data.length;i++) {
              var box = data[i]
              var zi = box.getElementsByTagName('span')[0]
              if (zi) {
                if (box.offsetHeight < zi.offsetHeight) {
                  var num = parseInt((box.offsetWidth - 54) /14) - 2
                  zi.innerHTML = zi.innerHTML.substring(0, num) + '...'
                }
              }
            }
          }
        }, 0);
      }
    },
    watch: {
      dataSource(n) {
        this.listData = [...n]
        this.$nextTick(() => {
          this.setEvenBgColor()
        })
      }
    }
  }
</script>
<style scoped>
  .simple-list {

  }

  .simple-list .list-item {
    position: relative;
    height: 44px;
    line-height: 44px;
    padding-left: 36px;
    padding-right: 20px;
  }
  .simple-list .list-item.even-row {
    background: #f6f6f6;
  }
  .simple-list .opr-btn .iconhr {
    padding: 10px
  }
  .simple-list .list-item.hover {
    cursor: pointer;
  }
  .simple-list .list-item.hover:hover {
    background: #e6f7ff;
  }
  .simple-list .list-item.disable,
  .simple-list .list-item.disable:hover,
  .simple-list .list-item.disable .iconhr,
  .simple-list .list-item.disable .iconhr:hover {
    color: rgba(0, 0, 0, 0.25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    box-shadow: none;
    cursor: not-allowed;
  }

</style>
