/*
 * @file: 应用平台管理模块功能配置
 * @author:
 */
export default {
    // 节点相关配置
    nodeConfig: {
        // 节点账号配置信息
        accountConfig: {
            // 是否启用节点账号设置 false时账号不显示
            enableAccount: true,
            // 节点账号类型 1节点管理员 2三员账号，账号维护不显示
            managerScope: 1,
            // 账号信息是否必填 true必填
            accountRequired: true,
            // 是否展示生效失效时间 false不显示
            birthFailureTime: true
        }
    },
    tableCustomizationConfig: {
        online_2_0: false
    }
}
