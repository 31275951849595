import transferMain from './transferMain'
import newTableTransfer from './newTableTransfer'
import singleTableTransfer from './singleTableTransfer'
import codeOptTransfer from './codeOptTransfer'
import collapseTransfer from './collapseTransfer'
import collapseTreeTransfer from './collapseTreeTransfer'
// 贺肖建新增 2021-4-28
import collapseRepeatTransfer from './collapseRepeatTransfer'
import treeTransfer from './treeTransfer'
import doubleTreeTransfer from './doubleTreeTransfer'
import './transfer.less'
export default {
  inheritAttrs: false,
  props: {
    type: {
      default: 'collapse'
    }
  },
  data() {
    return {}
  },
  methods: {
      deleteAction (record, isRowDele) {
        const props = {...this.$attrs}
        if (props.NeedDelePop) {
          if (isRowDele) {
            this.$refs.tableTransfer.realDeleAction(record)
          } else {
            this.$refs.tableTransfer.realDelAll(record)
          }
        }
      },
      // 树状数组指定位置增加子节点（必须标识为key）
      addChildren({ arr, value, item, key }) {
          let newArr = []
          const keyName = key ? key : 'key'
          arr.forEach(ele => {
              if (ele[keyName] === value) {
                  ele.children = item
                  newArr.push(ele)
              } else {
                  if (ele.children && ele.children.length > 0) {
                      ele.children = this.addChildren({
                          arr: ele.children,
                          value,
                          item,
                          key: keyName
                      })
                      newArr.push(ele)
                  } else {
                      newArr.push(ele)
                  }
              }
          })
          return newArr
      }
  },
  render() {
    const props = { ...this.$attrs, ...this.$props}
    const on = this.$listeners
    const slotsEl = []
    const slots = this.$slots
    const scopedSlots = this.$scopedSlots
    if(slots) {
      Object.keys(slots).forEach(key => {
        slotsEl.push(<template slot={key}>{slots[key]}</template>)
      })
    }

    if(props.type === 'table') {
      return (<newTableTransfer ref='tableTransfer' {...{ props, on, scopedSlots }}>{slotsEl}</newTableTransfer>)
    }
    if(props.type === 'codeOpt') {
      return (<codeOptTransfer {...{ props, on }}>{slotsEl}</codeOptTransfer>)
    }
    if(props.type === 'collapse') {
      return (<collapseTransfer {...{ props, on }}>{slotsEl}</collapseTransfer>)
    }
    if(props.type === 'collapseTree') {
      return (<collapseTreeTransfer {...{ props, on }}>{slotsEl}</collapseTreeTransfer>)
    }
    // 贺肖建新增 2021-4-28
    if(props.type === 'collapseRepeat') {
      return (<collapseRepeatTransfer {...{ props, on }}>{slotsEl}</collapseRepeatTransfer>)
    }
    if(props.type === 'single') {
      return (<singleTableTransfer {...{ props, on }}>{slotsEl}</singleTableTransfer>)
    }
    // 2021/06/21 创建
    if (props.type === 'multLvTree') return (<treeTransfer {...{ props, on }}>{slotsEl}</treeTransfer>)
    // 2021/06/21 创建
    if (props.type === 'doubleMultLvTree') return (<doubleTreeTransfer {...{ props, on }}>{slotsEl}</doubleTreeTransfer>)
    return (
      <transferMain {...{ props, on }}></transferMain>
    )
  }
}
