<!--
  <flow-submit-modal ref="flowSubmitModal" /> 工作流弹窗（提交、审核）
-->
<template>
  <a-modal
    :visible="show"
    :closable="false"
    :mask="true"
    :maskClosable="true"
    @ok="modalOk"
    @cancel="modalCancel">
    <div style="min-height: 320px">
      <a-spin :spinning="!visibleFlowAudit" v-if="!visibleFlowAudit" class="spin-loading"></a-spin>
      <flow-audit ref="refFlowAudit" :visible="visibleFlowAudit"></flow-audit>
    </div>
  </a-modal>
</template>

<script>
  import FlowAudit from './components/FlowAudit'
  export default {
    name: 'flowSubmitModal',
    components: {
      FlowAudit
    },
    props: {
      visible: {
        type: Boolean,
        default: false
      },
      flowId: {
        type: String,
        default: ''
      },
      flowAlias: {
        type: String,
        default: ''
      }
    },
    computed: {
      show: {
        get () {
          return this.visible && this.innerShow
        },
        set () {}
      }
    },
    data () {
      return {
        innerShow: true,
        visibleFlowAudit: false,
        submitWorkFlowData: () => {}
      }
    },
    watch: {
      visibleFlowAudit: {
        immediate: true,
        handler: function (n) {
          console.log('visibleFlowAudit' + n)
        }
      }
    },
    methods: {
      init () {
        this.innerShow = true
        this.visibleFlowAudit = false
        this.$refs.refFlowAudit.init({
          id: this.flowId,
          flowAlias: this.flowAlias,
          hideSubmitBtn: true, // 隐藏组件中的提交按钮
          onBeforeValid: this.onBeforeValid, // 内置表单验证前操作（本项目无，所以需要在这里验证提交的表单数据）
          onBeforeSubmit: this.onBeforeSubmit, // 提交前执行的操作（此处推荐进行业务数据提交）
          onAfterSubmit: this.onAfterSubmit, // 提交后执行的操作 （此处推荐进行提交完成后的操作eq:页面跳转）
        }).then(res => {
          this.visibleFlowAudit = true
          this.submitWorkFlowData = this.$refs.refFlowAudit.onSubmit
        })
      },
      onBeforeValid (params) {
        const that = this
        return new Promise((resolve, reject) => {
          let paramsData = { isPass: true, noPassMsg: ''}
          if(params && params.id){
            paramsData.id = params.id
          }
          if(that.$listeners.beforeValid){
            that.$emit('beforeValid', returnData => {
              paramsData = returnData
              // 此处建议返回{ isPass: false, noPassMsg: '错误消息' }、或者不返回
              resolve(paramsData)
            }, params);
          } else {
            resolve(paramsData)
          }
        })
      },
      onBeforeSubmit (params) {
        const that = this
        return new Promise((resolve, reject) => {
          let paramsData = { isPass: true, noPassMsg: '' }
          if(that.$listeners.beforeSubmit){
            that.$emit('beforeSubmit', returnData => {
              paramsData = returnData
              // 此处建议返回{ isPass: false, noPassMsg: '错误消息' }、或者不返回
              resolve(paramsData)
            }, params);
          } else {
            resolve(paramsData)
          }
        })
      },
      onAfterSubmit (isSuccess, goOrBackType, res, params) {
        this.$emit('afterSubmit', isSuccess, goOrBackType, res, params)
      },
      // 供父组件调用
      submitData () {
        return new Promise((resolve, reject) => {
          this.submitWorkFlowData()
          resolve()
        })
      },
      modalOk () {
        this.submitWorkFlowData()
      },
      modalCancel () {
        this.$emit('cancellationContract')
        this.innerShow = false
      }
    }
  }
</script>

<style scoped lang="less">
</style>
