import {JSEncrypt} from '../../lib/jsencrypt'
import Base64 from '../../lib/base64'

const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDGIkNT+SqTByhq//OvKOgLUDJRF3tc/l6GlPkPw2qjUk5uP2NESSvXkKwYYOhux8/u6NaAy5Aks4IabVB/2S/XPdz+g5qJFsBYVXhvvCEprc4ppftIiLKXDbO+w+sPX8XxYNbcrN5H8a+K2ZnXo4cPk6/NQ/nWwpEUwC+o35mElQIDAQAB'
/**
 * 加密方法
 * @param encryptedStr
 * @returns {string}
 */
export function encryptedData(encryptedStr) {
  encryptedStr = Base64.encode(encryptedStr)
  const encrypt = new JSEncrypt()
  encrypt.setPublicKey(publicKey) // 公钥
  // 将加密的数据转码为base64
  return encrypt.encryptLong(encryptedStr) // 加密后的数据
}

/**
 * 解密方法
 * @param encryptedStr
 * @returns {string|boolean|undefined}
 */
export function decryptData(encryptedStr) {
  const encrypt = new JSEncrypt()
  encrypt.setPrivateKey(publicKey) // 私钥
  // password要解密的数据 先转为byte数组在进行解码
  return encrypt.decryptLong(Base64.decrypt(encryptedStr)) // 解密后的数据
}
