const getters = {
  isLogin: state => state.user.isLogin,
  userName: state => state.user.userName,
  userStatus: state => state.user.userStatus,
  userRoles: state => state.user.userRoles,
  allRoutes: state => state.setRouter.routes,
  menuTop: state => state.user.menuTop,
  menuTopActive: state => state.user.menuTopActive,
  menuTab: state => state.user.menuTab,
  menuTabActive: state => state.user.menuTabActive,
  firstMenu(state) {
    const { menuData } = state
    return menuData.map(item => {
      const menuItem = { ...item }
      delete menuItem.children
      return menuItem
    })
  },
  subMenu(state) {
    const { menuData, activatedFirst } = state
    const current = menuData.find(menu => menu.fullPath === activatedFirst)
    return current && current.children ? current.children : []
  }
}

export default getters
