/**
 * 全局接口可以定义到每一个子系统使用
 * 如果发现res响应数据为undefined时，需要确认map里面的key是否正确
 */
// 工作流表单接口映射
const WorkflowForm = {
  post_getFieldCtrlModel:'/wf/TeeFlowProcessController/getFieldCtrlModel',
  post_getFieldCtrlModelByFrps:'/wf/TeeFlowProcessController/getFieldCtrlModelByFrps',
  post_saveOrUpdateFormItem: {
    url: '/wf/commonForm/saveOrUpdateFormItem',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }, // 工作流新增或更新工作流表单字段信息接口
  post_saveOrUpdateBusinessInfo: {
    url: '/wf/commonForm/saveOrUpdateBusinessInfo',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  },
  post_saveData: {
    url: '/wf/commonForm/saveData',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  },
  post_findItemInfo: {
    url: '/wf/commonForm/findItemInfo',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }
}
const symReq = {
  post_findFormTplByTplId: '/sym/templatedefine/getTemplateByTemplateId',
  post_findFormTplListByGrpId: '/sym/templatedefine/findTemplateListByGroupId'
}

const authorReq = {
  post_updateUserPhoto: '/author/SAuUser/updateUserPhoto' // 更新用户头像
}

const platserviceReq = {
  post_fileupload_upload: '/platservice/fileupload/upload', // 单附件上传
  post_fileupload_deleteFile: "/platservice/fileupload/deleteFileByFileId" // 单附件删除
}

const workFlowReq = {
  post_flowAuditInit: '/wf/TeeWorkFlowRunController/init', // 初始加载
  post_getFlowUsers: '/wf/TeeOrgSelectController/getSelectUserByFlowPrcs', // 获取流程的下一步审批人
  post_flowSubmit: '/wf/TeeWorkFlowRunController/turnNextHandler', // 提交、审核
  post_backToFixed: '/wf/TeeWorkFlowRunController/backToFixed', // 退回
  post_flowRevoke: '/wf/teeWorkflowManageController/takebackWorks', // 收回
  post_getFlowRunList: '/wf/TeeFlowInfoCharControllerUrls/getFlowRunViewGraphicsData', // 获取工作流审批记录
  post_checkIsSameStep: '/wf/TeeWorkFlowRunController/checkIsSameStep', // 检查是否相同流程
  post_findFlowBaseInfo: '/pbs/flow/findFlowBaseInfo' // 工作流获取数据
}

const pbsReq = {
  get_getResourcesFile: '/pbs/resources/getResourcesFile', // 获取资源文件
  get_findA01BaseInfo: '/pbs/a01/findA01BaseInfo'  // 获取当前账号A00相关基本信息
}

export default {
  ...WorkflowForm,
  ...symReq,
  ...authorReq,
  ...platserviceReq,
  ...workFlowReq,
  ...pbsReq,
  post_common_findFileListPage: '/platservice/fileupload/listPage',
  file_common_uploadFile: '/platservice/fileupload/multiFileUpload',
  post_common_getImageRange: '/ca/AllInfoMations/getImageRange', // 组件图片限制大小
  post_selectInfoGroupList: '/sym/infogroup/selectInfoGroupList', // 信息群列表查询
  post_listDictionary: '/sym/codeItemDefine/listDictionary', // 查询代码表数据-前台展示
  post_listDictionaryBatch: '/sym/codeItemDefine/listDictionaryBatch',  // 查询代码表数据(批量)
  post_selectInfoItem: '/sym/infoset/selectInfoItem', // 信息集列表查询
  post_findInfoItemList: '/sym/infoitem/findInfoItemList', // 列表-信息项查询
  post_lisFunctionVOTree: '/author/SAuFunction/lisFunctionVOTree', // 获取当前登录人权限内的功能数据
  post_getTemplateByTemplateId: '/sym/templatedefine/getTemplateByTemplateId', //模板列表-根据模板id查找模板
  post_sym_infoset_getList:'/sym/infoset/getList', //信息集列表查询
  post_sym_infoitem_getList:'/sym/infoitem/getList', //获取信息项列表
  post_author_saveOrEditConfigUserInfo: '/author/configUser/saveOrEditConfigUserInfo', // 保存或编辑用户配置信息
  post_author_listConfigUser: '/author/configUser/listConfigUser', // 查询用户配置信息
  post_author_delConfigUser: '/author/configUser/delConfigUser', // 删除用户配置信息
  post_author_logout: '/author/Logon/logout', // 删除用户配置信息
  post_author_sso_logout: '/sso/logout',
  post_codeTable_select: '/sym/codeItemDefine/listDictionary', // 代码表
  post_largeFile_queryMain: '/largeFile/queryMain', // 断点续传文件检查
  file_largeFile_saveSpliceFile: '/largeFile/saveSpliceFile', // 断点续传文件上传
  post_largeFile_mergeFile: '/largeFile/mergeFile', // 断点续传文件合并
}
