import { ACCESS_TOKEN, ACCESS_USERINFO, LOGIN_USER_TYPE } from 'common/confing'
import { reqMethods } from 'common/utils/request'
const req = reqMethods({
  baseUrl: 'http://192.168.2.76:8082', // 设置请求基础url路径 http://127.0.0.1:8081
  pathMap: {
    post_Logon_login: '/author/Logon/login',
    get_Logon_login: '/sso/login', // avic项目使用
    get_common_dyrouters: '/author/SAuFunction/getTreeByMin', // /author/SAuFunction/lisFunctionVOTree
    post_login_ticket: '',
    get_check_login: '/sso/getSsoServerUrl'
  }
}).req;

/**
 *
 * @param {vuex实例} store
 * @param {qiankun下发的props} props
 */
function registerGlobalModule (store, props = {}) {

  if (!store || !store.hasModule) {
    return;
  }
  if(localStorage.getItem(ACCESS_TOKEN)) {
    sessionStorage.setItem(ACCESS_TOKEN, localStorage.getItem(ACCESS_TOKEN));
  }
  // console.log(routerConf)
  // 获取初始化的state
  const initState = props.getGlobalState && props.getGlobalState() || {
    menu: [],
    user: {},
    loginUserType: localStorage.getItem(LOGIN_USER_TYPE) || '', // avic项目用户值为1
    token: localStorage.getItem(ACCESS_TOKEN) || '',
    userInfo: JSON.parse(localStorage.getItem(ACCESS_USERINFO)) || {}
  };

  // 将父应用的数据存储到子应用中，命名空间固定为global
  if (!store.hasModule('global')) {
    const globalModule = {
      namespaced: true,
      state: initState,
      mutations: {
        setGlobalState (state, payload) {
          // eslint-disable-next-line
          state = Object.assign(state, payload);
        },
        // 通知父应用
        emitGlobalState (state) {
          if (props.setGlobalState) {
            props.setGlobalState(state);
          }
        },
        SET_LOGINUSERTYPE (state, loginUserType) { // 1表示avic项目用户
          state.loginUserType = loginUserType;
          localStorage.setItem(LOGIN_USER_TYPE, loginUserType);
          sessionStorage.setItem(LOGIN_USER_TYPE, loginUserType);
        },
        SET_USERINFO (state, userInfo) {
          state.userInfo = userInfo;
          localStorage.setItem(ACCESS_USERINFO, JSON.stringify(userInfo));
          sessionStorage.setItem(ACCESS_USERINFO, JSON.stringify(userInfo));
        },
        SET_TOKEN (state, token) {
          state.token = token;
          localStorage.setItem(ACCESS_TOKEN, token);
          sessionStorage.setItem(ACCESS_TOKEN, token);
        },
        SET_MENU (state, menu) {
          state.menu = menu;
          localStorage.setItem('userRouters', JSON.stringify(menu));
          sessionStorage.setItem('userRouters', JSON.stringify(menu));
        }
      },
      actions: {
        // 子应用改变state并通知父应用
        setGlobalState ({ commit }, payload) {
          commit('setGlobalState', payload);
          commit('emitGlobalState', payload);
        },
        // 初始化，只用于mount时同步父应用的数据
        initGlobalState ({ commit }, payload) {
          commit('setGlobalState', payload);
        },
        login({ commit }, params) {
          return new Promise((resolve, reject) => {
            req('post_Logon_login', params).then(res => {
              if(res.code === 200) {
                commit('SET_TOKEN', res.data.token)
                commit('SET_USERINFO', res.data.user)
                resolve(res)
              } else {
                reject(res)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        loginNew({ commit }, params) { // avic项目使用
          return new Promise((resolve, reject) => {
            req('get_Logon_login', params).then(res => {
              if(res.code === 200) {
                if (res.message !== '登录成功') {
                  window.location.href = res.data
                } else {
                  commit('SET_TOKEN', res.data.token)
                  commit('SET_USERINFO', res.data.user)
                  commit('SET_LOGINUSERTYPE', '1')
                  resolve(res)
                }
              } else {
                reject(res)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        loginTicket({ commit }, params) { // avic项目使用
          return new Promise((resolve, reject) => {
            // post_login_ticket
            req('get_Logon_login', params).then(res => {
              if(res.code === 200) {
                if (res.data && res.data.ssoUrl) {
                  // window.location.href = res.data
                  window.location.href = res.data.ssoUrl || res.data
                } else {
                  commit('SET_TOKEN', res.data.token)
                  commit('SET_USERINFO', res.data.user)
                  commit('SET_LOGINUSERTYPE', '1')
                  resolve(res)
                }
              } else {
                reject(res)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        openLogin({ state }, params) {
          // param = { toUrl: '' }
          return new Promise((resolve, reject) => {
            req('get_check_login', params).then(res => {
              if(res.code === 200) {
                window.location.href = res.data
              } else {
                reject(res)
              }
            }).catch(error => {
              reject(error)
            })
          })
        },
        dyrouters({ commit }) {
            return new Promise((resolve, reject) => {
                req('get_common_dyrouters').then(res => {
                    if(res.code === 200) {
                      console.log('dyrouters', res)
                      // var index = res.data.findIndex(item=>item.name==='apm')
                      // res.data.splice(index,1)
                      commit('SET_MENU', res.data)
                      resolve(res)
                    } else {
                        reject(res)
                    }
                    
                }).catch(error => {
                    reject(error)
                })
            })
        }
      }
    };
    store.registerModule('global', globalModule);
  } else {
    // 每次mount时，都同步一次父应用数据
    store.dispatch('global/initGlobalState', initState);
  }
};

export default registerGlobalModule;
