<!--
 <flow-revoke ref="flowRevoke" />
 -->
<template>
  <div class="audit-flow" v-show="visible">
    <div class="audit-flow-form-room">
      <div>
        {{infoTips}}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'flowRevoke',
    props: {
      visible: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        infoTips: '',
        state: {id: ''},
        onBeforeRevoke:'',
        onAfterRevoke: ''
      }
    },
    created () {
      window.comp = this
    },
    watch: {
      visible: {
        immediate: true,
        handler: function (val) {
          this.infoTips = '确定收回本条申请吗?'
        }
      }
    },
    methods: {
      init (params) {
        const that = this
        this.isSubmitting = false
        if (params.onBeforeRevoke) {
          this.onBeforeRevoke = params.onBeforeRevoke
        }else{
          this.onBeforeRevoke = function () {
            return new Promise(function (resolve, reject) {
              resolve({ isPass: true, noPassMsg: '' })
            })
          }
        }
        if (params.onAfterRevoke) {
          this.onAfterRevoke = params.onAfterRevoke
        }

        if (params.id) {
          this.state.id = params.id
        }

        return new Promise((resolve, reject) => {
          this.visible = true
          resolve()
        })
      },
      onRevoke () {
        this.revoke()
      },
      revoke: function (params) {
        if (params) {
          if (params.runName) {
            this.state.runName = params.runName
          }
          if (params.remark) {
            this.state.remark = params.remark
          }
          if (params.extend) {
            this.state.extend = params.extend
          }
        }
        this.onBeforeRevoke().then(res => {
          if(res && res.isPass){
            this.revokeApply()
          }else{
            this.$message.error(((res && !res.isPass) ? res.noPassMsg : 'isPass未配置'))
          }
        })
      },
      revokeApply () {
        const paramsData = { id: this.state.id };
        this.$http('post_flowRevoke', paramsData, true).then(res => {
          if(res.code === 200){
            this.onAfterRevoke(res.code === 200, res)
          }else {
            this.$message.error(res.message)
          }
        }).catch(reason => {
          this.onAfterRevoke(false, reason)
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .audit-flow {
    min-width: 340px;
    width: 100%;
    margin: 0 auto;
  }
</style>