const pathMap = {
  post_showSystemConfig:'/fzzyk/sym/config/showSystemConfig',
  get_Logon_refreshCaptcha:'/author/Logon/refreshCaptcha',
  get_test_req: '/author/Logon/login',
  post_SAuFunction_listFunction: '/author/SAuFunction/listFunction',
  post_Logon_login: '/author/Logon/login',
  post_updatePassword: '/author/SAuUser/updatePassword',
  post_fzzyk_getHomeData: {
    url: '/fzzyk/home/getHomeData',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    method: 'post',
    paramType: 'params'
  },
  // 单点登录相关接口
  get_sso_doLoginByTicket: '/sso/doLoginByTicket',
  get_sso_getSsoAuthUrl: '/sso/getSsoAuthUrl',
  get_sso_logout: '/sso/logout'
}
export {
  pathMap
}
