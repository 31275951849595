<template>
  <div class="com-org-tree">
    <div class="top-search" v-if="treeSearch">
      <a-input
          v-if="inputSearch"
          ref="inputSearch"
          v-model="inputVal"
          :loading="searchFetching"
          placeholder="请输入搜索内容"
          allowClear
          @pressEnter="onSearch"
      >
        <template slot="suffix">
          <a-spin v-if="searchFetching">
            <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
          </a-spin>
          <a-icon v-else type="search" class="select-icon" style="cursor: pointer" @click="onSearch"></a-icon>
        </template>
      </a-input>
      <VirtualSelect
          v-else-if="enableVirtualTree"
          show-search
          :placeholder="placeholder"
          style="width: 100%"
          :filter-option="filterOption"
          :value="selectVal"
          :options="AllSelData"
          :replaceFields="replaceFields"
          @click="selectClick(item, $event)"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          @search="e=>search=e"
      ></VirtualSelect>
      <a-select
          v-else
          show-search
          :placeholder="placeholder"
          option-filter-prop="children"
          style="width: 100%"
          :filter-option="filterOption"
          :value="selectVal"
          @focus="handleFocus"
          @blur="handleBlur"
          @change="handleChange"
          @search="e=>search=e"
      >
      <a-icon slot="suffixIcon" type="search" class="select-icon" />
      <a-select-option
          v-for="item in selectData"
          :key="item.key"
          :value="item.key"
          @click="selectClick(item, $event)"
      >
        <span v-html="higHlight(item.title)"></span>
      </a-select-option>
    </a-select>
    </div>
    <div class="org-tree-con"
         :class="[treeSearch?'org-tree-con-withserach':'', enableVirtualTree? 'virtual-org-tree': '']">
      <a-spin :spinning="isLoading" style="width: 100%; height: 100%;">
        <template v-if="orgTreeData && orgTreeData.length">
          <ComVirtualTree
              ref="virtualTree"
              v-if="enableVirtualTree"
              :blockNode="blockNode"
              :treeData="orgTreeData"
              :replaceFields="replaceFields"
              :autoExpandParent="autoExpandParent"
              :checkable="checkable"
              :checkedKeys="checkedKeys"
              :checkStrictly="checkStrictly"
              :defaultCheckedKeys="defaultCheckedKeys"
              :defaultExpandAll="defaultExpandAll"
              :defaultExpandedKeys="defaultExpandedKeys"
              :defaultExpandParent="defaultExpandParent"
              :defaultSelectedKeys="defaultSelectedKeys"
              :disabled="disabled"
              :draggable="draggable"
              :expandedKeys="expandedKeys"
              :filterTreeNode="filterTreeNode"
              :loadData="loadData"
              :loadedKeys="loadedKeys"
              :multiple="multiple"
              :selectable="selectable"
              :selectedKeys="treeSelectedKeys.length ? treeSelectedKeys: defaultSelectedKeys"
              :showIcon="showIcon"
              :showLine="showLine"
              :expandMeth="expandMeth"
              :iconType="iconType"
              :typeField="typeField"
              :enableVirtualTree="enableVirtualTree"
              :height="height"
              :isParentNodeClickExpandable="isParentNodeClickExpandable"
              :isParentNodeSelectable="isParentNodeSelectable"
              :isParentNodeCheckable="isParentNodeCheckable"
              :isParentNodeDisable="isParentNodeDisable"
              :keywords="keywords"
              :searchRequest="searchRequest"
              :searchParams="searchParams"
              :searchHandler="searchHandler"
              @expand="expand"
              @select="select"
              @check="check"
              @searchStart="searchFetching = true"
              @searchEnd="searchFetching = false"
          ></ComVirtualTree>
          <com-tree
              class="com-tree-plain"
              ref="comTree"
              v-else
              :expandMeth="expandMeth"
              :treeData="orgTreeData1"
              :iconType="iconType"
              :checkable="checkable"
              :typeField="typeField"
              :replaceFields="replaceFields"
              :expandedKeys="treeExpandedKeys"
              :autoExpandParent="treeAutoExpandParent"
              :defaultExpandedKeys="defaultExpandedKeys"
              :defaultSelectedKeys="defaultSelectedKeys"
              :selectedKeys="treeSelectedKeys.length ? treeSelectedKeys: defaultSelectedKeys"
              :checkedKeys="treeCheckedKeys"
              :loadData="loadData1"
              :filterTreeNode="filterTreeNode"
              :showIcon="showIcon"
              :disabled="disabled"
              :multiple="multiple"
              :enableVirtualTree="enableVirtualTree"
              height="height"
              @expand="expand"
              @select="select"
              @check="check"
          ></com-tree>
        </template>
      </a-spin>
    </div>
  </div>
</template>
<script>
// 机构树
import ComTree from './ComTree';
import ComVirtualTree from './ComVirtualTree.jsx';
import { cloneDeep } from 'lodash';
import { VirtualSelect } from 'common/components/ComSelect';
import getDefaultProps from 'common/confing/defaultProps';

const proxyMethods = [
    'getNodesByKey'
]

export default {
  props: Object.assign(
      {},
      getDefaultProps('tree'),
      {
        searchRequest: {
          type: String
        },
        searchParams: {
          type: Object,
          default: () => ({})
        },
        enableVirtualTree: {
          type: Boolean,
          default: false
        },
        height: {
          type: String
        },
        orgTreeData: {
          default: () => []
        },
        iconType: {
          default: () => ({
            '1': 'ico-jianzhu03',
            'A': 'ico-danwei',
            '2': 'ico-bumenicon'
          })
        },
        typeField: {
          default: 'dwtype'
        },
        placeholder: {
          default: '请输入搜索内容'
        },
        treeSearch: Boolean,
        inputSearch: Boolean,
        expandMeth: {
          type: Boolean,
          default: false
        },
        // 点击label是否展开节点
        isParentNodeClickExpandable: {
          type: Boolean,
          default: false
        },
        // 父节点是否可选
        isParentNodeSelectable: {
          type: Boolean,
          default: false
        },
        // 父节点是否展示checkbox
        isParentNodeCheckable: {
          type: Boolean,
          default: false
        },
        // 父节点是否可用
        isParentNodeDisable: {
          type: Boolean,
          default: false
        },
        // 是否是接口懒加载
        isLazyLoad: {
            type: Boolean,
            default: false
        },
        // 懒加载接口地址
        LazyLoadUrl: String,
        getLazyLoadData: {
          type: Function,
          default: () => ({})
        },
        searchHandler: {
          type: Function,
          default: (res) => res
        },
        // 控制选中keys
        selectedKeys: {
          type: Array,
          default: () => []
        }
      }
  ),
  components: {
    ComTree,
    ComVirtualTree,
    VirtualSelect
  },
  data() {
    return {
      isStart: true,
      treeSelectedKeys: [],
      treeExpandedKeys: [],
      treeCheckedKeys: [],
      selectData: [],
      inputVal: '',
      selectVal: undefined,
      treeAutoExpandParent: false,
      orgTreeData1: [],
      // 缓存数据 不分级别数据
      AllSelData: [],
      search: undefined,
      expandList: null,
      isLoading: false,
      keywords: '',
      searchFetching: false
    };
  },
  created() {
    this.isLoading = true;
    if (this.orgTreeData) {
      this.isLoading = false;
    }
  },
  mounted() {
    if (this.orgTreeData && this.orgTreeData.length) {
      this.SetTempData(this.orgTreeData);
      this.hanleorgTreeData(this.orgTreeData);
      this.handleSelectData(this.orgTreeData1);
    }
  },
  watch: {
    orgTreeData: {
      deep: true,
      handler(n) {
        this.SetTempData(n);
        this.hanleorgTreeData(n);
        this.handleSelectData(this.orgTreeData1);
        if ((n && n.length) || n.length === 0) {
          this.isLoading = false;
        }
      }
    },
    defaultExpandedKeys: {
      deep: true,
      handler(value) {
        if (Array.isArray(value) && value.length) {
          this.treeExpandedKeys.push(...value);
        }
      }
    },
    inputVal(nv) {
      if (!nv) {
        this.onSearch();
      }
    },
    selectedKeys(keys) {
      this.treeSelectedKeys = keys;
    }
  },
  methods: {
    ...proxyMethods.reduce((pre, method) => {
      pre[method] = function (...args) {
        const virtualTree = this.$refs.virtualTree;
        if (!virtualTree) {
          console.error('virtualTree is not mounted');
          return;
        }
        return virtualTree.outputMethods()[method](...args);
      };
      return pre;
    }, {}),
    onSearch() {
      if (this.keywords === this.inputVal) return;
      this.keywords = this.inputVal;
      this.$emit('search', this.keywords);
    },
    higHlight(item) {
      if (this.search !== undefined) {
        var Html = item.replace(this.search, `<font style="color:#1996FF;">${this.search}</font>`);
        return Html;
      } else {
        return item;
      }

    },
    // 接口懒加载
    getDataByApi () {
      if (this.isLazyLoad) {
        // console.log('是懒加载')
        // console.log(this.LazyLoadUrl)
        this.getLazyLoadData()
      }
    },
    loadData1(treeNode) {
      const that = this;
      that.getDataByApi()
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve();
          return;
        } else if (!that.expandMeth) {
          if (that.expandList === 'ant-tree-title' || that.expandList === 'iconhr ico-jianzhu03' || that.expandList === 'ant-tree-iconEle ant-tree-icon__customize') {
            resolve();
            return;
          }
        }
        setTimeout(() => {
          let children = cloneDeep(that.AllSelData.find((item) => (item[that.replaceFields.title] === treeNode.dataRef[that.replaceFields.title] && item[that.replaceFields.key] === treeNode.dataRef[that.replaceFields.key])).children);
          if (children && children.length) {
            children.forEach(citem => {
              if (citem.children && citem.children.length) {
                delete citem.children;
                citem.isLeaf = false;
              } else {
                delete citem.children;
                citem.isLeaf = true;
              }
            });
            treeNode.dataRef.children = children;
          } else {
            treeNode.dataRef.children = [];
            treeNode.dataRef.isLeaf = true;
          }
          this.orgTreeData1 = [...that.orgTreeData1];
          this.handleSelectData(that.orgTreeData1);
          resolve();
        }, 200);
      });
    },
    hanleorgTreeData(treeData) {
      const tempArr = cloneDeep(treeData);
      let TempData = tempArr.map(item => {
        if (item.children && item.children.length) {
          item.isLeaf = false;
          delete item.children;
          return item;
        } else {
          delete item.children;
          item.isLeaf = true;
          return item;
        }
      });
      this.orgTreeData1 = TempData;
    },
    handleSelectData(treeData) {
      let newData = [];
      const clcaData = (data) => {
        data.forEach(item => {
          const newItem = Object.assign(item, {
            title: item[this.replaceFields.title],
            key: item[this.replaceFields.key]
          });
          newData.push(newItem);
          if (item.children && item.children.length) {
            clcaData(item.children);
          }
        });
      };
      clcaData(cloneDeep(treeData));
      // console.log(newData)
      this.selectData = newData;
    },
    expand(expandedKeys, e) {
      // console.log(1)
      const oldExpandedKeys = JSON.parse(JSON.stringify(this.treeExpandedKeys));
      this.expandList = e.nativeEvent.target.className;
      if (!this.expandMeth) {
        if (e.nativeEvent.target.localName === 'path' || e.nativeEvent.target.localName === 'svg') {
          this.treeAutoExpandParent = false;
          this.treeExpandedKeys = expandedKeys;
          this.$emit('expand', expandedKeys, e);
        } else if (e.nativeEvent.target.localName === 'span') {
          if (e.nativeEvent.target.className === 'ant-tree-switcher ant-tree-switcher_open' || e.nativeEvent.target.className === 'ant-tree-switcher ant-tree-switcher_close') {
            this.treeAutoExpandParent = false;
            this.treeExpandedKeys = oldExpandedKeys;
            this.$emit('expand', oldExpandedKeys, e);
          }
        }
      } else {
        this.treeAutoExpandParent = false;
        this.treeExpandedKeys = expandedKeys;
        this.$emit('expand', expandedKeys, e);
      }

      // if(e.nativeEvent.toElement.nodeName === 'SPAN') {
      //   if(e.expanded) {
      //     this.cacheExpandedKeys = expandedKeys
      //   }
      //   if(!e.node.selected) {
      //     // this.treeExpandedKeys = this.cacheExpandedKeys
      //   }
      // }
    },
    select(selectedKeys, e) {
      this.$emit('select', selectedKeys, e);
      this.treeSelectedKeys = selectedKeys;
      this.treeCheckedKeys = selectedKeys;
      this.selectVal = undefined;
    },
    handleChange(value, e) {
      const data = e.data.props;
      this.treeSelectedKeys = [value];
      this.selectVal = value;
      const event = {
        node: {
          dataRef: data
        }
      };
      this.$emit('select', [value], event);
    },
    selectClick(item, e) {
      this.treeAutoExpandParent = true;
      // this.treeExpandedKeys = [item.key]
      this.treeExpandedKeys.push(item.key);
      // console.log(this.treeExpandedKeys, 'this.treeExpandedKeys');
      // console.log(item.key, 'item.key');
      e.item.dataRef = item;
      this.select([item.key], {
        nativeEvent: e.domEvent,
        node: e.item
      });
    },
    handleBlur() {
      // console.log('blur');
    },
    handleFocus() {
      // console.log('focus');
    },
    filterOption(input, option) {
      return (option.componentOptions.children[0].data.domProps.innerHTML.toLowerCase().indexOf(input.toLowerCase()) >= 0);
      // return true
    },
    check(checkedKeys, e) {
      this.treeCheckedKeys = checkedKeys;
      this.$emit('check', checkedKeys, e);

    },
    //    缓存数据
    SetTempData(treeData) {
      let newData = [];
      const clcaData = (data) => {
        data.forEach(item => {
          newData.push(cloneDeep(item));
          if (item.children && item.children.length) {
            clcaData(item.children);
          }
        });
      };
      clcaData(cloneDeep(treeData));
      // newData.forEach((el) => {
      //     if (el.children && el.children.length) {
      //         el.children.forEach((els) => {
      //             delete els.children
      //         })
      //     }
      // })
      this.AllSelData = newData;
    }
  }
};
</script>
<style scoped>
    .com-org-tree {
      display: flex;
      flex: auto;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }

    .com-org-tree .top-search {
      display: flex;
      align-items: center;
      padding: 10px;
    }

    .com-org-tree .org-tree-con {
      width: 100%;
      height: 100%;
      overflow: auto;
      padding: 0 10px;
    }

    .com-org-tree .org-tree-con.virtual-org-tree {
      padding: 0 0 0 0;
    }

    .com-org-tree .org-tree-con.org-tree-con-withserach {
      height: calc(100% - 52px);
    }

    .com-org-tree .ant-tree.ant-tree-show-line li:not(:last-child)::before {
      display: none;
    }

    .com-org-tree .select-icon {
      font-size: 18px;
      margin-top: -2px;
    }

    .com-org-tree .ant-tree .anticon,
    .com-org-tree .ant-tree .iconhr {
      color: #6B85AC;
    }

    .com-org-tree .ant-tree-node-selected .anticon,
    .com-org-tree .ant-tree-node-selected .iconhr {
      color: #4293F4;
    }

    .com-org-tree .ant-tree.ant-tree-directory > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before,
    .com-org-tree .ant-tree.ant-tree-directory .ant-tree-child-tree > li.ant-tree-treenode-selected > span.ant-tree-node-content-wrapper::before {
      background: transparent;
    }

    .com-org-tree .org-tree-con .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected, .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected {
      color: #4293F4;
      font-weight: bold;
      /*font-weight: normal;*/
    }
    .com-org-tree .org-tree-con .ant-tree.ant-tree-directory > li span.ant-tree-node-content-wrapper.ant-tree-node-selected .ant-tree-title,
    .ant-tree.ant-tree-directory .ant-tree-child-tree > li span.ant-tree-node-content-wrapper.ant-tree-node-selected .ant-tree-title {
      /*color: rgba(0, 0, 0, 0.65);*/
      /*font-weight: normal;*/
    }
    .com-org-tree .ant-tree li .ant-tree-node-content-wrapper {
      padding: 0;
    }

    .com-org-tree .ant-select-open .ant-select-arrow-icon svg {
      transform: rotate(0deg);
    }

    .com-org-tree .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background: transparent;
      color: #4293F4;
      font-weight: bold;
    }

    .com-org-tree .ant-spin-container {
      width: 100%;
      height: 100%;
    }
    .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-select-switcher-icon, .ant-tree li span.ant-tree-switcher.ant-tree-switcher_open .ant-tree-switcher-icon,
    .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-select-switcher-icon, .ant-tree li span.ant-tree-switcher.ant-tree-switcher_close .ant-tree-switcher-icon{
      width: 100%;
      height: 100%;
      position: relative;
      top: 0;
      padding-top: 20%;
      font-size: 16px;
    }
</style>
