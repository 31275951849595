export const sessionSaveUser = store => {
  if (sessionStorage) {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (userInfo) {
      store.commit('user/setUserLogin', userInfo)
    }
  }
  store.subscribe((mutation, state) => {
    if (mutation.type.startsWith('user/')) {
      sessionStorage.setItem('userInfo', JSON.stringify(state.user))
      localStorage.setItem('userInfo', JSON.stringify(state.user))
    }
    if (mutation.type === 'user/setUserLogout') {
      sessionStorage.removeItem('userInfo')
    }
  })
}

export const localSavaColor = store => {
  if (localStorage) {
    const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
    if (userInfo) {
      const userThemeColor = JSON.parse(localStorage.getItem(`${userInfo.userName}-themeColor`))
      if (userThemeColor) {
        store.dispatch('user/getThemeColor', userThemeColor)
      }
    }
  }
  store.subscribe((mutation, state) => {
    if (mutation.type === 'user/setUserThemeColor') {
      localStorage.setItem(`${state.user.userName}-themeColor`, JSON.stringify(state.user.userThemeColor))
    }
  })
}
