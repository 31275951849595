

const pathMap = {
    get_test_req: '/baidu/dd',
    post_Logon_login: '/author/Logon/login',
    // post_common_upload: '/platservice/fileupload/upload',
    file_common_upload: '/platservice/fileupload/upload',
    file_common_uploadbyType: '/platservice/fileupload/multiFileUploadByType',
    // { // 上传接口
    //       url: '/platservice/fileupload/upload',
    //       headers: {
    //           'Content-Type': 'multipart/form-data'
    //       }
    //   },
    post_common_getImageRange: '/ca/AllInfoMations/getImageRange', // 组件图片限制大小
    post_common_filerename: '/platservice/fileupload/reNameFile',
    post_common_upload_delete: '/platservice/fileupload/deleteFile', // 删除附件
    post_common_upload_download: '/platservice/fileupload/download', // 下载附件
    post_common_upload_batchdelete: '/platservice/fileupload/deleteFileByFileIds', // 批量删除附件
    post_common_upload_getfile: '/platservice/fileupload/query', // 根据查询附件
    post_common_upload_getfile_withPage: '/platservice/fileupload/listPage', // 根据查询附件-分页查询
    post_userTrusteeship_get: '/author/userTrusteeship/getTrusteeship', // 查找用户权限托管
    post_userTrusteeship_save: '/author/userTrusteeship/saveTrusteeship', // 保存用户权限托管
    post_resetPassword: '/author/SAuUser/resetPassword', // 用户-重置密码
    post_user_getUserList: '/sym/userA01/getUser', // 获取已开通账号的人员
    post_sys_customtablecolumn: '/sym/customTabColUser/getColumnTableNameList', // 获取表格列头
    post_sys_customtabledata: '/sym/customtableF/listPageByTableName', // 数据列表
    post_sys_getSearchcondition: '/sym/customTabQueUser/selectMoreTabQuery', // 查询条件列表
    post_sys_getTableQuery: '/sym/customTabQueUser/getTableQuery', // 查询表格上方查询条件列表
    post_codeTable_select: '/sym/codeItemDefine/listDictionary', // 代码表
    post_common_listDictionaryBatch: '/sym/codeItemDefine/listDictionaryBatch', // 代码表批量
    post_sym_tablequery_findByTable: '/sym/customtablequery/findByTable', // ,,,,
    post_sym_qurycodtionlist: '/sym/customtablecondition/findByTable', // 查询条件定义 信息集列表
    post_sym_queryinfo_getlist: '/sym/infoitem/getList', // 设置表头列查询
    post_sym_getFieldTypeList: '/sym/infoitem/getFieldTypeList', // 获取信息项类型下拉数据
    post_dycustom_gettableHead: '/sym/customtablecolumn/findByTable', // ComSetHeader/setHeaderDial.vue 获取表头
    post_dycustom_delete: '/sym/customtablecolumn/deleteCustom',
    post_dycustom_updateCustom: {
        url: '/sym/customtablecolumn/updateCustom', // 列表-保存表头设置
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        }
    },
    post_dycustom_addList: '/sym/customFuction/findUserSet', // 穿梭框添加列列表
}

export {
    pathMap
}
