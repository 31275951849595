<!--权限托管-->
<template>
    <div class="qxtg">
        <div class="qxtg-form">
            <a-form
                    :label-col="formItemLayout.labelCol"
                    :wrapper-col="formItemLayout.wrapperCol"
                    :colon="false"
                    :form="formInfo"
            >
                <a-form-item label="托管人" prop="userName">
                    <a-input v-model="userName" disabled/>
                </a-form-item>
                <a-form-item label="被托管人" prop="toTrusteeName">
                    <a-input v-decorator="['toTrusteeName',{ initialValue: trusteeship.toTrusteeName, rules: [{ required: true, message: '被托管人不能为空'}]}]"
                             ref="toTrusteeInput" placeholder="单击选择托管人！" @click="selectUser"/>
                </a-form-item>
                <a-form-item label="生效日期" prop="effectiveTime">
                    <a-date-picker
                            v-decorator="['effectiveTime',{ initialValue: trusteeship.effectiveTime, rules: [{ required: true, message: '生效日期不能为空'}]}]"
                            valueFormat="YYYY-MM-DD"
                            :format="['YYYY-MM-DD', 'YYYYMMDD', 'YYYYMM']"
                            @change="changeEffect"
                            :disabled-date="disabledStartDate"
                            @openChange="handleStartOpenChange"/>
                </a-form-item>
                <a-form-item label="失效日期" prop="failureTime">
                    <a-date-picker
                            v-decorator="['failureTime',{ initialValue: trusteeship.failureTime, rules: [{ required: true, message: '失效日期不能为空'}]}]"
                            valueFormat="YYYY-MM-DD"
                            :format="['YYYY-MM-DD', 'YYYYMMDD', 'YYYYMM']"
                            :disabled-date="disabledEndDate"
                            @change="changeFailure"
                            :open="endOpen"
                            @openChange="handleEndOpenChange"/>
                </a-form-item>
            </a-form>
            <div class="qxtg-btn">
                <a-button style="margin-right: 10px" @click="cancel">取消</a-button>
                <a-button type="primary" @click="handleSubmit">保存</a-button>
            </div>
        </div>
        <user-list ref="userListComp" @selectedUser="selectedUser"/>
    </div>
</template>

<script>
    import UserList from './UserList'
    import { mapState } from 'vuex'
    import {req} from 'common/api/index'
    // import moment from 'moment'

    export default {
        name: 'authTruModal',
        components: {
            UserList
        },
        data () {
            return {
                userId: '',
                trusName: '', // 权限托管人名字
                trusteeship: {
                    effectiveTime: null,
                    failureTime: null
                },
                endOpen: false,
                loginUserName: 'admin',
                // form: this.$form.createForm(this, { name: 'coordinated' }),
                formInfo: this.$form.createForm(this, { name: 'coordinated' }),
                value: 1,
                formItemLayout: {
                    labelCol: { span: 4 },
                    wrapperCol: { span: 8 }
                },
                nodeId: ''
            }
        },
        created () {
            // Vue.use(api)
            this.nodeId = this.userInfo.nodeId
            this.getTrusteeship()
        },
        methods: {
            disabledStartDate (effectiveDateStringAll) {
                const failureDateString = new Date(Date.parse(this.trusteeship.failureTime))
                const effectiveDateString = new Date(Date.parse(effectiveDateStringAll))
                if (!effectiveDateString || !failureDateString) {
                    return false
                }
                return effectiveDateString.valueOf() > failureDateString.valueOf()
            },
            disabledEndDate (failureDateStringAll) {
                const effectiveDateString = new Date(Date.parse(this.trusteeship.effectiveTime))
                const failureDateString = new Date(Date.parse(failureDateStringAll))
                // console.log('结束时间', failureDateString, effectiveDateString)
                if (!failureDateString || !effectiveDateString) {
                    return false
                }
                return effectiveDateString.valueOf() >= failureDateString.valueOf()
            },
            changeEffect (data) {
                this.trusteeship.effectiveTime = data
            },
            changeFailure (data) {
                this.trusteeship.failureTime = data
            },
            handleStartOpenChange (open) {
                if (!open) {
                    this.endOpen = true
                }
            },
            handleEndOpenChange (open) {
                this.endOpen = open
            },
            getTrusteeship () {
                req('post_userTrusteeship_get').then(res => {
                    if (res.data) {
                        this.trusteeship = res.data
                        // this.trusteeship.toTrustee = this.trusteeship.
                        console.log('this.trusteeship', this.trusteeship)
                    } else {
                        this.trusteeship = {}
                    }
                })
            },
            doSaveTrusteeship () {
                const param = { ...this.trusteeship }
                req('post_userTrusteeship_save', param, true).then(res => {
                    this.$message.success('权限托管成功')
                    this.getTrusteeship()
                })
            },
            // 保存（提交）
            handleSubmit () {
                this.formInfo.validateFields((err, values) => {
                    if (!err) {
                        this.trusteeship = {
                            ...values,
                            ...this.trusteeship
                        }
                        this.trusteeship.trustee = this.userName
                        if (this.userId) {
                            this.trusteeship.toTrustee = this.userId
                            this.trusteeship.toTrusteeName= this.trusName
                        }
                        // console.log(this.trusteeship, 5665656)
                        const me = this
                        this.$confirm({
                            title: '提示：',
                            content: '确定保存？',
                            okText: '确认',
                            cancelText: '取消',
                            onOk () {
                                me.doSaveTrusteeship()
                                me.$emit('cancleModal')
                            }
                        })
                    }
                })
                // const that = this
                // this.$refs.form.validate(valid =>{
                //     if(valid){
                //         that.doSaveTrusteeship()
                //         that.$emit('cancleModal')
                //     }
                // })

            },
            selectUser (e) {
                this.$refs.userListComp.showUserDialog(this.nodeId)
            },
            //  返回选择用户id
            selectedUser (user) {
                // console.log(user, '托管人信息')
                this.formInfo.setFieldsValue({toTrusteeName: user.description})
                this.userId = user.id
                this.trusName = user.description
                // this.trusteeship.toTrusteeName = user.description
                const id = user.id
                this.trusteeship.toTrustee = id
                this.$set(this.trusteeship, 'toTrustee', id)
                this.$refs.toTrusteeInput.blur()
            },
            onChange (e) {
                console.log('radio checked', e.target.value)
            },

            // 取消
            cancel () {
                this.getTrusteeship()
                this.$emit('cancleModal')
            }
        },
        computed: {
            // 通过global获取user的信息(从vuex获取用户信息)
            ...mapState('global', {
                userInfo: state => state.userInfo
            }),
            userName () {
                if (!this.userInfo) {
                    return ''
                }
                return this.userInfo.userName
            }

        }
    }
</script>
<style lang="less" scoped>
    .qxtg {
        width: 100%;
        height: 100%;
        background-color: white;
        .qxtg-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding-top: 8px;
            border-top: 1px solid #D8D8D8;
        }
        /deep/ .ant-input:placeholder-shown {
            width: 240px;
            border-radius: 5px;
        }
        /deep/ .ant-input {
            border-radius: 0;
            width: 240px;
        }
        /deep/.ant-col-4 {
            width: 34%;
        }
    }
</style>
