// import aModal from './aModal'
import AntTable from '../../../components/ComAntTable'
import antdModal from 'common/coverCompLib/antdModal'
import AntTreeSelect from '../../../components/ComAntTreeSelect/index.jsx'
export default {
  install(Vue) {
    Vue.use(AntTable)
    Vue.use(antdModal)
    // console.log(AntTreeSelect)
    Vue.component('ATreeSelect', AntTreeSelect)
  }
};
