<template>
  <a-input
    allowClear
    :placeholder='placeholder'
    v-model='inValue'
    @input='change'
    style='width: 68%;'
  />
</template>

<script>
export default {
  name: 'ComCustomQueryInput',
  props: {
    placeholder: {
      type: [String, Number],
      default: '请输入'
    },
    defaultValue: {
      type: null,
      default: ''
    },
    value: {
      type: Object,
      default: () => ({
        value: '',
        label: ''
      })
    }
  },
  data () {
    return {
      inValue: ''
    }
  },
  watch: {
    value: {
      handler  (n) {
        this.inValue = n.value
      },
      immediate: true
    }
  },
  methods: {
    change (e) {
      this.$emit('change', { value: this.inValue, label: this.inValue})
    }
  }
}
</script>