import BaseMixin from 'ant-design-vue/es/_util/BaseMixin';
import { getOptionProps, initDefaultProps, getListeners } from 'ant-design-vue/es/_util/props-util';
import getTransitionProps from 'ant-design-vue/es/_util/getTransitionProps';
import { ConfigConsumerProps } from 'ant-design-vue/es/config-provider';
import { previewImage, isImageUrl } from './utils';
import Icon from 'ant-design-vue/es/icon';
import Tooltip from 'ant-design-vue/es/tooltip';
import Progress from 'ant-design-vue/es/progress';
import Popconfirm from 'ant-design-vue/es/popconfirm';
import classNames from 'classnames';
import { UploadListProps } from './interface';

export default {
    name: 'AUploadList',
    mixins: [BaseMixin],
    props: initDefaultProps(UploadListProps, {
        listType: 'text', // or picture
        progressAttr: {
            strokeWidth: 2,
            showInfo: false,
        },
        showRemoveIcon: true,
        showDownloadIcon: false,
        showPreviewIcon: true,
        previewFile: previewImage,
    }),
    inject: {
        configProvider: { default: () => ConfigConsumerProps },
    },
    updated() {
        this.$nextTick(() => {
            const { listType, items, previewFile } = this.$props;
            if (listType !== 'picture' && listType !== 'picture-card') {
                return;
            }
            (items || []).forEach(file => {
                if (
                    typeof document === 'undefined' ||
                    typeof window === 'undefined' ||
                    !window.FileReader ||
                    !window.File ||
                    !(file.originFileObj instanceof File || file.originFileObj instanceof Blob) ||
                    file.thumbUrl !== undefined
                ) {
                    return;
                }
                /*eslint-disable */
                file.thumbUrl = '';
                if (previewFile) {
                    previewFile(file.originFileObj).then(previewDataUrl => {
                        // Need append '' to avoid dead loop
                        file.thumbUrl = previewDataUrl || '';
                        this.$forceUpdate();
                    });
                }
            });
        });
    },
    methods: {
        handlePreview(file, e) {
            const { preview } = getListeners(this);
            if (!preview) {
                return;
            }
            e.preventDefault();
            return this.$emit('preview', file);
        },
        handleSelect (file, e) {
            const { selected } = getListeners(this);
            if (!selected) {
                return;
            }
            e.preventDefault();
            return this.$emit('selected', file);

        },
        handleDownload(file) {
            const { download } = getListeners(this);
            if (typeof download === 'function') {
                download(file);
            } else if (file.url) {
                // window.open(file.url);
                return this.$emit('download', file);
            }
            // /platservice/fileupload/download
        },

        handleClose(file) {
            this.$emit('remove', file);
        },
        handleRename(file, e) {
            const { rename } = getListeners(this);
            if (!rename) {
                return;
            }
            e.preventDefault();
            return this.$emit('rename', file);
        },
        filterLevel (key) {
            let str = ''
            switch (key) {
                case '1':
                    str = '机密'
                    break;
                case '2':
                    str = '秘密'
                    break;
                case '3':
                    str = '内部'
                    break;
                case '4':
                    str = '核心商密'
                    break;
                case '5':
                    str = '平台商密'
                    break;
                case '6':
                    str = '公开'
                    break;
            }
            return str
        }
    },
    render() {
        const {
            prefixCls: customizePrefixCls,
            items = [],
            listType,
            showPreviewIcon,
            showRemoveIcon,
            showDownloadIcon,
            locale,
            progressAttr,
        } = getOptionProps(this);
        const getPrefixCls = this.configProvider.getPrefixCls;
        const prefixCls = getPrefixCls('upload', customizePrefixCls);

        const list = items.map(file => {
            let progress;
            let icon = <Icon type={file.status === 'uploading' ? 'loading' : 'paper-clip'} />;

            if (listType === 'picture' || listType === 'picture-card') {
                if (listType === 'picture-card' && file.status === 'uploading') {
                    icon = <div class={`${prefixCls}-list-item-uploading-text`}>{locale.uploading}</div>;
                } else if (!file.thumbUrl && file.url) {
                    // icon = <Icon class={`${prefixCls}-list-item-thumbnail`} type="copy" />;
                    const thumbnail = <div>
                        <div class="file-options-top">
                            <Icon type="copy" class={`${prefixCls}-list-item-icon`}/>
                        </div>
                        <p class={`${prefixCls}-list-item-filename`}
                           onClick={e => this.handleSelect(file, e)}>{file.name}</p>
                    </div>
                    icon = (
                        <div
                            rel="noopener noreferrer"
                            class={`${prefixCls}-list-item-thumbnail`}
                        >
                            {thumbnail}
                        </div>
                    );
                } else {
                    const thumbnail = isImageUrl(file) ? (
                        <div>
                            <img
                                src={file.thumbUrl}
                                alt={file.name}
                                class={`${prefixCls}-list-item-image`}
                            />
                            <p class={`${prefixCls}-list-item-filename`} onClick={e => this.handleSelect(file, e)}>{file.name}</p>
                        </div>
                    ) : (
                        <div>
                            <div class="file-options-top">
                                <Icon type="copy" class={`${prefixCls}-list-item-icon`} />
                            </div>
                            <p class={`${prefixCls}-list-item-filename`}
                               onClick={e => this.handleSelect(file, e)}>{file.name}</p>
                        </div>
                    );
                    icon = (
                        // <a
                        //     class={`${prefixCls}-list-item-thumbnail`}
                        //     onClick={e => this.handlePreview(file, e)}
                        //     href={file.url || file.thumbUrl}
                        //     target="_blank"
                        //     rel="noopener noreferrer"
                        // >
                        //     {thumbnail}
                        // </a>
                        <div
                            rel="noopener noreferrer"
                            class={`${prefixCls}-list-item-thumbnail`}

                        >
                         {thumbnail}
                        </div>
                    );
                }
            }

            if (file.status === 'uploading') {
                const progressProps = {
                    props: {
                        ...progressAttr,
                        type: 'line',
                        percent: file.percent,
                    },
                };
                // show loading icon if upload progress listener is disabled
                const loadingProgress = 'percent' in file ? <Progress {...progressProps} /> : null;

                progress = (
                    <div class={`${prefixCls}-list-item-progress`} key="progress">
                        {loadingProgress}
                    </div>
                );
            }
            const infoUploadingClass = classNames({
                [`${prefixCls}-list-item`]: true,
                [`${prefixCls}-list-item-${file.status}`]: true,
                [`${prefixCls}-list-item-list-type-${listType}`]: true,
                [`${prefixCls}-list-item-list-isSelect`]: file.isSelect
            });
            const linkProps =
                typeof file.linkProps === 'string' ? JSON.parse(file.linkProps) : file.linkProps;
            const deleiconclassNmae = (listType === 'picture' || listType === 'picture-card') ? 'iconhr ico-8 custom-delete-pic' : 'iconhr ico-8 custom-delete'
            const removeIcon = showRemoveIcon ? (
                <Popconfirm title="确认删除？" onConfirm={() => this.handleClose(file)}>
                    {/*<Icon type="delete" title={locale.removeFile} />*/}
                    <span class={deleiconclassNmae}  title={locale.removeFile} />

        </Popconfirm>
            ) : null;
            const renameIcon = showRemoveIcon ? (
                <Icon type="edit" title={'重命名文件'} onClick={e => this.handleRename(file, e)} />
            ) : null;
            const downloadIcon =
                showDownloadIcon && file.status === 'done' ? (
                    <Icon
                        type="download"
                        title={locale.downloadFile}
                        onClick={() => this.handleDownload(file)}
                    />
                ) : null;

            const downloadOrDelete = listType !== 'picture-card' && (
                <span
                    key="download-delete"
                    class={`${prefixCls}-list-item-card-actions ${listType === 'picture' ? 'picture' : ''}`}
                >
          {downloadIcon && <a title={locale.downloadFile}>{downloadIcon}</a>}
                    {removeIcon && <a title={locale.removeFile}>{removeIcon}</a>}
        </span>
            );
            const listItemNameClass = classNames({
                [`${prefixCls}-list-item-name`]: true,
                [`${prefixCls}-list-item-name-icon-count-${
                    [downloadIcon, removeIcon].filter(x => x).length
                }`]: true,
            });
            // a标签的title title={file.name}
            const preview = file.url
                ? [

                    <Tooltip title={file.name} overlayClassName='upload-tooltip'><a
                        target="_blank"
                        rel="noopener noreferrer"
                        class={listItemNameClass}

                        {...linkProps}
                        href={file.url}
                        onClick={e => this.handlePreview(file, e)}
                    >
                        {file.name}
                    </a>
                    </Tooltip>,
                    downloadOrDelete,
                ]
                : [
                    <span
                        key="view"
                        class={`${prefixCls}-list-item-name`}
                        onClick={e => this.handlePreview(file, e)}
                        title={file.name}
                    >
              {file.name}
            </span>,
                    downloadOrDelete,
                ];
            const style =
                file.url || file.thumbUrl
                    ? undefined
                    : {
                        pointerEvents: 'none',
                        opacity: 0.5,
                    };
            const previewIcon = showPreviewIcon ? (
                <a
                    href={file.url || file.thumbUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={style}
                    onClick={e => this.handlePreview(file, e)}
                    title={locale.previewFile}
                >
                    <Icon type="eye-o" />
                </a>
            ) : null;
            const uploadsuccess = file.status === 'done'
                ? <Icon type="check-circle" />
                : <Icon type="close-circle" />;
            const style1 = showDownloadIcon ? {display: 'none'}: {display: 'flex'}
            const style2 = showDownloadIcon ? {display: 'flex'}: {display: 'none'}
            const actions = listType === 'picture-card' && file.status !== 'uploading' && (
                <div class={`${prefixCls}-list-item-actions`}>
                    <div style={style1} class={`${prefixCls}-list-item-editable`}>
                        <div class='item-edit'>{uploadsuccess}</div>
                        <div class='item-edit'>{removeIcon}</div>
                        <div class='item-edit'>{previewIcon}</div>
                        <div class='item-edit'>{file.status === 'done' && renameIcon}</div>
                    </div>
                    <div class={`${prefixCls}-list-item-onlyread`} style={style2}>
                        <div class='item-onlyread'>{uploadsuccess}</div>
                        <div class='item-onlyread'>{previewIcon}</div>
                        <div class='item-onlyread'>{file.status === 'done' && downloadIcon}</div>
                    </div>
        </div>
            );
            let message;
            if (file.response && typeof file.response === 'string') {
                message = file.response;
            } else {
                message = (file.error && file.error.statusText) || locale.uploadError;
            }
            // const icon2 = <span class='iconhr ico-11' />;
            const iconAndPreview = (
                <span>
          {icon}
                    {preview}
        </span>
            );
            const transitionProps = getTransitionProps('fade');
            const dom = (
                <div class={infoUploadingClass} key={file.uid}>
                    <div class={`${prefixCls}-list-item-info`}>{iconAndPreview}</div>
                    {actions}
                    <transition {...transitionProps}>{progress}</transition>
                </div>
            );
            const listContainerNameClass = classNames({
                [`${prefixCls}-list-picture-card-container`]: listType === 'picture-card',
            });
            return (
                <div key={file.uid} class={listContainerNameClass}>
                    <span class={`${prefixCls}-list-picture-card-sybol`}>{this.filterLevel(file.originFileObj.level)}</span>
                    {file.status === 'error' ? <Tooltip title={message}>{dom}</Tooltip> : <span>{dom}</span>}
                </div>
            );
        });
        const listClassNames = classNames({
            [`${prefixCls}-list`]: true,
            [`${prefixCls}-list-${listType}`]: true,
        });
        const animationDirection = listType === 'picture-card' ? 'animate-inline' : 'animate';
        const transitionGroupProps = getTransitionProps(`${prefixCls}-${animationDirection}`);
        return (
            <transition-group {...transitionGroupProps} tag="div" class={listClassNames}>
                {list}
            </transition-group>
        );
    },
};
