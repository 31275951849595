export default {
    path: '/wf',
    name: 'wf',
    redirect: '/wf/system/flowsort',
    meta: {
        module: 'Performance Management',
        title: '工作流管理',
        roles: ['admin', 'group', 'user'],
        icon: 'xitongguanli'
    },
    children: [
        {
          path: '/wf/system/formDesign',
          name: 'flowsort',
          hidden: true,
          meta: {
            module: '工作流管理',
            title: '',
            roles: ['admin', 'group', 'user'],
            icon: ''
          }
        },
        {
            path: '/wf/system/flowsort',
            name: 'flowsort',
            meta: {
                module: '工作流管理',
                title: '流程分类管理',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        }, {
            path: '/wf/system/flowtype',
            name: 'flowsort',
            meta: {
                module: '工作流管理',
                title: '流程设计',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },{
            path: '/wf/system/leaveMan',
            name: 'flowsort',
            meta: {
                module: '工作流管理',
                title: '离职办理',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
            path: '/wf/system/personTrans',
            name: 'personTrans',
            meta: {
                module: '人员变动',
                title: '人员调配',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },

        },
        {
            path: '/wf/system/personChangeApp',
            name: 'personChangeApp',
            meta: {
                module: '人员变动审批',
                title: '人员变动审批',
                roles: ['admin', 'group', 'user'],
                icon: ''
            },

        }, {
            path: '/wf/system/rolemanage',
            name: 'flowsort',
            meta: {
                module: '工作流管理',
                title: '流程角色维护',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        }, {
            path: '/wf/system/flowformManage',
            name: 'flowsort',
            meta: {
                module: '工作流管理',
                title: '流程表单管理',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        }, {
            path: '/wf/audit',
            name: 'flowsort',
            meta: {
                module: '工作流管理',
                title: '流程组件集成示例',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        }, {
            path: '/wf/flow/todo',
            name: 'flowtodo',
            meta: {
                module: '待办列表',
                title: '待办列表',
                roles: ['admin', 'group', 'user'],
                icon: ''
            }
        },
        {
          path: '/wf/flow/flowStart',
          name: 'flowStart',
          meta: {
              module: '工作流管理',
              title: '流程发起',
              roles: ['admin', 'group', 'user'],
              icon: ''
          }
        },
        {
          path: '/wf/flow/flowToDo',
          name: 'flowToDo',
          meta: {
              module: '工作流管理',
              title: '流程待办',
              roles: ['admin', 'group', 'user'],
              icon: ''
          }
        },
        {
          path: '/wf/flow/flowStartView',
          name: 'flowStartView',
          meta: {
              module: '工作流管理',
              title: '填写流程待办',
              roles: ['admin', 'group', 'user'],
              icon: ''
          }
        }
    ]
}
