<template>
  <div class="collapse-list">
    <ul class="select-list" ref="list">
      <li
        v-for="(item, index) in listData"
        :key="rowKey?item[rowKey]:index"
        class="list-item"
        :title="item[replaceFields.title]"
      >
        <a-spin :spinning="!!item.spinning" :indicator="indicator">
          <p
              id="addBox"
              class="item-text collapse">
            <a-icon
              @click="onExpand(item, index)"
              :type="'caret-down'"
              v-if="!item.isLeaf"
              :class="[
                'carte-ico',
                {
                  'ico_expanded': item.expanded
                }
              ]"
            />
            <span
              @click="onChecked(item)"
              :class="{
                'no-ico': item.isLeaf
              }"
            >{{item[replaceFields.title]}}</span>
            <span class="addIcon">
              <a-icon id="add" @click="onChecked(item)" :style="{ fontSize: '16px' }" type="plus-square" />
            </span>
          </p>
        </a-spin>
        <ol class="sub-list" v-show="item.expanded" v-if="item.children && item.children.length">
          <li
            v-for="(el, i) in item.children"
            :key="i"
            class="sub-list-item"
            :title="el[replaceFields.title]"
          >
            <p
                id="addBox"
                class="item-text sub-item-text" style="margin-left:20px">
                <span
                    @click="onChecked(el)">
                    {{el[replaceFields.title]}}
                </span>
                <span>
                  <a-icon id="add" @click="onChecked(el)" class="addIcon" :style="{ fontSize: '16px' }" type="plus-square" />
                </span>
            </p>
          </li>
        </ol>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    rowKey: {
        default: null
    },
    dataSource: {
      default: () => ([])
    },
    defaultChecked: {
      default: () => ([])
    },
    defaultExpandedKeys: {
      default: () => ([])
    },
    loadedKeys: {
      default: () => ([])
    },
    replaceFields: {
      default: () => ({
        title:'title',
        key: 'key'
      })
    },
    loadData: {
      default: ''
    },
    disable: {
      default: false
    }
  },
  data() {
    return {
      listData: [],
      autoExpandParent: true,
      checkedKeys: [],
      selectedKeys: [],
      defaultLoadedKeys: [],
      indicator: (<a-icon type="loading" style="font-size: 24px" spin />)
    }
  },
  created () {
    this.listData = [...this.dataSource]
  },
  mounted() {
    this.checkedKeys = JSON.parse(JSON.stringify(this.defaultChecked))
  },
  methods: {
    onExpand(data) {
      if(!data.expanded) {
        this.$set(data, 'expanded', true)
      } else {
        this.$set(data, 'expanded', false)
      }
      this.$emit('itemExpand', data)
      // 2021-01-08 添加 (黎镖)
      // this.$emit('leftParentClick', data)
      if(this.loadData) {
        const key = data[this.replaceFields.key]
        if(this.defaultLoadedKeys.includes(key)) {
          return
        }
        this.$set(data, 'spinning', true)
        this.loadData({
          dataRef: data
        }).then(res => {
          this.$emit('excludeRepeatKey')
          this.defaultLoadedKeys.push(key)
          this.$set(data, 'spinning', false)
          delete data.spinning
        }).catch(error => {
          this.$set(data, 'spinning', false)
          delete data.spinning
        })
      }
    },
    onChecked (e) {
      this.$emit('checked', e.key, e)
    },
    handleExpandIconClick() {

    },
    setExpandedNode() {
      this.listData.forEach(item => {
        if(this.defaultExpandedKeys.includes(item[this.replaceFields.key])) {
        }
      })
      this.listData = this.listData
    },
    setEvenBgColor () {
      let itemText = this.$refs.list.querySelectorAll('.item-text')
      for(let i = 1; i < itemText.length; i+=2) {
        itemText[i].className += ' even-row'
      }
      setTimeout(() => {
        for (var i=0;i<document.getElementsByClassName('select-list')[0].children.length;i++) {
          var data = document.getElementsByClassName('select-list')[0].children[i].getElementsByTagName('ol')[0]
          if (data && data.children.length) {
            for (var j=0;j<data.children.length;j++) {
              var box = data.children[j].getElementsByTagName('p')[0]
              var zi = box.getElementsByTagName('span')[0]
              if (zi) {
                if (box.offsetHeight < zi.offsetHeight) {
                  var num = parseInt((box.offsetWidth - 40) /14) - 2
                  zi.innerHTML = zi.innerHTML.substring(0, num) + '...'
                }
              }
            }
          }
        }
        this.loading = false
      }, 0);
    }
  },
  watch: {
    dataSource(n) {
      this.listData = [...n]
      this.$nextTick(() => {
        this.setEvenBgColor()
      })
    },
    defaultChecked(n) {
      this.checkedKeys = JSON.parse(JSON.stringify(n))

    },
    defaultExpandedKeys(n) {
      this.setExpandedNode()
    },
    loadedKeys (n) {
      this.defaultLoadedKeys = n
    }
  }
}
</script>
<style scoped>
.collapse-list {

}
.collapse-list .select-list {
}
.collapse-list .list-item .collapse {
  padding-left: 20px;
}
.collapse-list .list-item .carte-ico {
  padding-right: 10px;

  transform: scale(.83333333) rotate(0deg);
}
.collapse-list .list-item .carte-ico /deep/ svg {
  transition: transform .3s;
  transform: rotate(-90deg);
}
.collapse-list .list-item .carte-ico.ico_expanded /deep/ svg {
  transform: rotate(0deg);
}
.collapse-list .sub-list .sub-item-text {
  padding-left: 40px;
}
.collapse-list .select-list .item-text {
  cursor: pointer;
  margin: 0;
  height: 44px;
  line-height: 44px;
}
.collapse-list .select-list .item-text.even-row {
  background: #f6f6f6;
}
.collapse-list .select-list .item-text:hover {
  background: #e6f7ff;
}
.collapse-list .select-list .item-text .no-ico {
  padding-left: 24px;
}
.collapse-list /deep/ .ant-checkbox {
  top: 0;
}
.collapse-list /deep/ .ant-checkbox-wrapper {
  display: inline-block;
  line-height: 44px;
  height: 44px;
  width: 100%;
}
.collapse-list /deep/ .ant-checkbox + span {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: calc(100% - 26px);
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
}

/*.collapse-list .item-text .value-text {*/
/*  width: 100%;*/
/*  display: inline-block;*/
/*  overflow: hidden;*/
/*  white-space: nowrap;*/
/*  text-overflow: ellipsis;*/
/*}*/
#addBox {
    position: relative;
}
/* #add::after {
    background: olive;
    content: '111';
} */
#add {
	top: 50%;
	transform:translateY(-50%);
    position: absolute;
    right: 10px;
}
.addIcon .anticon svg{
  color: '#8d979c'
}
</style>