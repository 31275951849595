<template>
    <a-modal
            v-model="selfilelelModel"
            title="密级设置"
            width="600px"
            @cancel="handleCancel"
            @ok="handleSubmit"
    >
        <a-form-model
                ref="ruleForm"
                :model="singleFile"
                :colon="false"
                layout="horizontal"
                :rules="rules"
                :label-col="{ span: 5 }"
                :wrapper-col="{ span: 12 }"
        >
            <a-form-model-item
                    label="密级"
                    prop="level"
                    :label-col="{ span: 5 }"
                    :wrapper-col="{ span: 12 }"
            >
                <a-select
                        v-model="singleFile.level"
                        placeholder="请选择密级"
                        @change="handleSelectChange"
                >
                    <a-select-option v-for="v in secretLevelOptions" :key="v.value" :value="v.value">
                        {{ v.label }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
    import {LOGIN_USER_TYPE} from "common/confing"
    import {req} from "common/api"

    export default {
        props: {
            selfilelelVisible: {
                type: Boolean,
                default: false
            },
            file: {
                type: [Object, File]
            },
            currentPageBelong: "",
            LevelfileList: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        data() {
            return {
                selfilelelModel: false,
                singleFile: {
                    level: null
                },
                rules: {
                    level: [{required: true, message: "请选择密级!", trigger: "change"}]
                },
                securityLevelList: [
                    {
                        value: 1,
                        label: "公开"
                    },
                    {
                        value: 2,
                        label: "PTSM"
                    },
                    {
                        value: 3,
                        label: "JM"
                    },
                    {
                        value: 4,
                        label: "MM"
                    }
                ]
            };
        },
        watch: {
            file: function (val) {
                if (val) {
                    this.singleFile = {
                        lastModified: val.lastModified,
                        lastModifiedDate: val.lastModifiedDate,
                        level: val.level,
                        name: val.name,
                        size: val.size,
                        type: val.type,
                        uid: val.uid,
                        webkitRelativePath: val.webkitRelativePath
                    };
                }
            },
            selfilelelVisible: function (val) {
                this.selfilelelModel = val;
            }
        },
        computed: {
            secretLevelOptions() {
                const options = this.securityLevelList
                // console.log(window)
                if (window.G && window.G.ENV && window.G.ENV.networkType === 'nw') {
                    return options
                }
                if (localStorage.getItem(LOGIN_USER_TYPE) + '' === '1') { // avic项目使用
                    return [
                        {
                            value: '5',
                            label: "PTSM"
                        },
                        {
                            value: '6',
                            label: "公开"
                        }
                    ]
                }
                return options
            }
        },
        created() {
            if (this.currentPageBelong === "GBJD") {
                this.securityLevelList = [
                    {
                        value: '5',
                        label: "平台商密"
                    },
                    {
                        value: '6',
                        label: "公开"
                    }
                ];
            } else {
                this.getPageMj()
            }
            // this.$nextTick(() => {
            //     this.securityLevelList = arr;
            // });
        },
        mounted() {
        },
        methods: {
            getPageMj() {
                const securityLevelList = sessionStorage.getItem('securityLevelList')
                if (securityLevelList) {
                    this.securityLevelList = JSON.parse(securityLevelList)
                    return
                }
                req('post_listDictionary', {
                    dmgrp: 'DQYMMJ'
                }).then(res => {
                    if(res.data) {
                        this.securityLevelList = res.data.map(item => {
                            return {
                                ...item,
                                // value: item.dmcod,
                                value: item.id,
                                label: item.dmcpt
                            }
                        })
                        sessionStorage.setItem('securityLevelList', JSON.stringify(this.securityLevelList))
                    }
                })
            },
            // 关闭文件预览
            handleCancel() {
                this.$emit("update:selfilelelVisible", false);
            },
            handleSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        if (this.LevelfileList.length > 1) {
                            this.LevelfileList.forEach(item => {
                                const multItem = {
                                    lastModified: item.lastModified,
                                    lastModifiedDate: item.lastModifiedDate,
                                    level: this.singleFile.level,
                                    name: item.name,
                                    size: item.size,
                                    type: item.type,
                                    uid: item.uid,
                                    webkitRelativePath: item.webkitRelativePath
                                }
                                this.$emit("handleSelLel", {
                                    singleFile: item,
                                    file: multItem
                                });
                            })
                        } else {
                            this.$emit("handleSelLel", {
                                singleFile: this.file,
                                file: this.singleFile
                            });
                        }
                        this.handleCancel();
                        this.singleFile = {};
                    } else {
                        return false;
                    }
                });
            },
            handleSelectChange(value) {
                this.singleFile.level = value;
                this.file.level = value;
                if (this.LevelfileList.length > 1) {
                    this.LevelfileList.forEach(item => {
                        // item.level = value
                        this.$set(item, 'level', value)
                    })
                }
            }
        }
    };
</script>
