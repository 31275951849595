import Vue from 'vue'
import moment from 'moment'

/**
 * 时间处理，时间输出格式为 YYYY-MM-DD
 * */
Vue.filter('dealDate', function(value) {
  if (!value) return '----/--/--'
  return moment(value).format('YYYY-MM-DD')
})

/**
 * 时间处理，时间输出格式为 YYYY年MM月DD日
 * */
Vue.filter('dealDateYMD', function(value) {
  if (!value) return '---- 年 -- 月 -- 日'
  return moment(value).format('YYYY年MM月DD日')
})
