<template>
    <div class="div-drawer-sys-settings">
        <a-drawer
                title="个性设置"
                placement="right"
                :closable="false"
                :visible="drawerVisible"
                :get-container="false"
                :width="drawerWidth"
                :wrap-style="{ position: 'absolute' }"
                :maskStyle="{backgroundColor: 'transparent'}"
                @close="onClose"
        >
            <div class="item" v-if="!isLimitss">
                <p class="tit">
                    导航设置
                </p>
                <div class="cont" style="justify-content: space-around;">
                    <div @click="setLayout(index)" v-for="(item, index) in devList" :key="index" style="position: relative; cursor: pointer;">
                        <img :src="item.img" :alt="item.des">
                        <span>{{item.des}}</span>
                        <a-icon v-if="index === layoutIndex" type="check" style="font-size: 18px; position: absolute; top: 30%; left: 40%;"/>
                    </div>
                </div>
            </div>
            <a-divider  v-if="!isLimitss"/>
            <div class="item">
                <p class="tit">
                    语言文字
                </p>
                <div @click="m = index" v-for="(lang, index) in langList" :key="lang.key" style=" cursor: pointer;">
                    {{lang.key.toLowerCase() + ' ' + lang.name}}
                    <a-icon v-if="index === m" type="check" style="font-size: 18px;"/>
                </div>
            </div>
            <a-divider />
            <div class="item">
                <p class="tit">
                    默认首页设置
                </p>
                <div class="cont">
                    <div @click="setIndex(index)" v-for="(item, index) in indexMenu" :key="index" style="position: relative; cursor: pointer; width: 50%;">
                        <a-icon type="home" :style="{ color: defaultIndex === index ? '#1E89FF' : 'rgba(24, 31, 36, .24)' }" style="font-size: 18px; color: #1E89FF; margin-bottom: 10px;"/>
                        <span>{{item.name}}</span>
                        <a-icon v-if="index === defaultIndex" type="check" style="background-color: #1E89FF; border-radius: 50%; color: #fff; padding: 1px; border:1px solid #fff; font-size: 9px; font-weight: bold; position: absolute; top: 20%; left: 60%;"/>
                    </div>
                </div>
            </div>
        </a-drawer>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    export default {
        name: "MainDrawer",
        props: ['menuData'],
        data() {
            return {
                value: 'side',
                layoutIndex: 0,
                layoutIndexOld: 0,
                defaultIndex: 0,
                m: 0,
                radioStyle: {
                    display: 'block',
                    height: '30px',
                    lineHeight: '30px',
                },
                devList:[
                    {
                        img: require('./img/structure-blend.png'),
                        des: '左右结构',
                        val: 'side'
                    },
                    {
                        img: require('./img/structure-ud.png'),
                        des: '上下结构',
                        val: 'head'
                    },
                    {
                        img: require('./img/structure-lr.png'),
                        des: '混合结构',
                        val: 'mix'
                    }
                ],
                langList: [
                    {key: 'CN', name: '简体中文', alias: '简体'},
                    {key: 'HK', name: '繁體中文', alias: '繁體'},
                    {key: 'US', name: 'English', alias: 'English'}
                ]
            }
        },
        watch: {
            layoutIndex (n) {
                this.$store.state.layout = this.devList[n].val
            },
            defaultIndex (n) {
                // this.$store.state.defaultPage = this.indexMenu[n].path
            },
            drawerData: {
                immediate: true,
                handler: function (n) {
                    this.drawerWidth = n ? 256 : 0
                }
            },
            isLimitss: {
                immediate: true,
                handler: async function (n) {
                    const res = await this.initLayout()
                    this.layoutIndex = n ? 1 : res
                    this.$store.state.layout = this.devList[this.layoutIndex].val
				}
			}
        },
        created () {
            this.$http('post_author_listConfigUser', { configCode: 'defaultPage' }, true).then(res => {
                if (res.code === 200 && res.data.length > 0) {
                    this.defaultIndex = typeof res.data[0].configValue === 'string' && parseInt(res.data[0].configValue) ? parseInt(res.data[0].configValue) : 0
                    // 如果configValue存在且可用，高亮显示该值，否则默认第一个
                }
            })
        },
        computed: {
            ...mapState(['drawerVisible']),
            drawerData() {
                return this.$store.state.drawerVisible
            },
			      isLimitss () {
			      	return this.$store.state.isLimitss
			      },
            indexMenu () {
                let indexMenu = this.menuData.filter(item => item&&item.name === '首页')
                if ((indexMenu instanceof Array) && indexMenu.length > 0) {
                    return indexMenu[0].children
                } else {
                    return []
                }
            }
        },
        methods: {
            async initLayout () {
              // this.login()
                const res = await this.$http('post_author_listConfigUser', { configCode: 'layout' }, true).then(res => {
                    if (res.code === 200 && res.data.length > 0) {
                        return typeof res.data[0].configValue === 'string' && parseInt(res.data[0].configValue) ? parseInt(res.data[0].configValue) : 0
                    } else {
                        return 0
                    }
                })
                return res
            },
            login() {
              const userName = case1
              const userPwd = 12345
              this.$store.dispatch('global/login', (encrypted) => {
                // console.log(encrypted(userPwd))
                return {
                  userName: userName,
                  passWord: encrypted(userPwd),
                  verifyCode: ''
                }
              }).then(res => {
                console.log(res)
                if (res.code === 200) {
                  this.$message.success(res.message)
                  const global = this.$store.state.global
                  store.setGlobalState({
                    token: global.token,
                    userInfo: global.userInfo
                  })
                  this.$store.dispatch('global/dyrouters').then(res => {
                    this.$store.commit('global/SET_MENU', res.data)
                    // this.listConfig()
                  })
                } else {
                  this.openNotificationWithIcon('error', '登录失败', res.message)
                }
              }).catch(err => {
                console.log(err)
                this.$message.warning(err.message)
              })
            },
            setIndex (index) {
                // 设置默认值高亮，放入vuex用于点击房子，同时存入后台用于下次登录使用
                this.$http('post_author_saveOrEditConfigUserInfo', { configCode: 'defaultPage', configValue: String(index) }, true).then(res => {
                    if (res.code === 200) {
                        this.defaultIndex = index
                        this.$store.state.defaultPage = this.indexMenu[index].path
                        this.$message.success('默认首页设置成功~')
                        // this.$router.push({
                        //     path: this.$store.state.defaultPage
                        // })
                    }
                })
            },
            setLayout (index) {
                this.$http('post_author_saveOrEditConfigUserInfo', { configCode: 'layout', configValue: String(index) }, true).then(res => {
                    if (res.code === 200) {
                        this.layoutIndex = index
						this.layoutIndexOld = index
                        // this.$store.state.layout = index
                        this.$message.success('导航设置成功~')
                    }
                })
            },
            onClose() {
                this.$store.state.drawerVisible = false;
            },
            onChange(e) {
                this.$store.state.layout = e.target.value
            }
        }
    }
</script>

<style lang="less" scoped>
    // 阴影
    /deep/ .ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper{
        box-shadow: -5px 0 5px -5px rgba(24, 31, 36, .24) !important;
    }
    .item{
        display: flex;
        flex-direction: column;
        .tit{
            font-size: 16px;
            margin-bottom: 10px;
        }
        .cont{
            display: flex;
            flex-wrap: wrap;
            div{
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: 10px 0;
            }
        }
    }
</style>
