import AntDVue from 'ant-design-vue'
import { message } from 'ant-design-vue';
import vxeTable from './vxeTable'
// import pkuseHrUi from '@pkuse/hr-ui'
import extendAntVue from './extend'
import './styleCover.less'
import './fontIcon.less'
import ComVxeTable from 'common/components/ComVxeTable/index'
// import formDesign from '../FormDesign'
// import formDesign from '../../../subapp_apm/src/lib/formDesign'
let htmlEL = document.querySelector('html')
htmlEL.setAttribute('data-theme', 'darkBlue')
export default {
    install(Vue) {
        Vue.prototype.$eventBus = new Vue()
        Vue.use(AntDVue)
        Vue.use(vxeTable)
        // Vue.use(pkuseHrUi)
        Vue.use(extendAntVue)
        Vue.prototype.$message = message
        // Vue.use(formDesign)
        message.config({
            top: '118px',
            maxCount: 2
        });
        Vue.component(ComVxeTable.name, ComVxeTable)
    }
}
