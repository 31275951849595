<template>
    <div class="upload">
        <div id="wrapper">
            <div id="containerWebuploader">
                <!--头部，相册选择和格式选择-->

                <div id="uploader">
                    <div class="queueList">
                        <div id="dndArea" class="placeholder">
                            <div id="filePicker"></div>
                            <p>或将照片拖到这里，单次最多可选300张</p>
                        </div>
                    </div>
                    <div class="statusBar" style="display:none;">
                        <div class="progress">
                            <span class="text">0%</span>
                            <span class="percentage"></span>
                        </div>
                        <div class="info"></div>
                        <div class="btns">
                            <div id="filePicker2"></div>
                            <div class="uploadBtn">开始上传</div>
                        </div>
                    </div>
                    <div class="cropper-wraper webuploader-element-invisible">
                        <div class="img-container">
                            <img src="" alt="" />
                        </div>

                        <div class="upload-btn">上传所选区域</div>

                        <div class="img-preview"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import mixins from "./mixin/WebUploader"
    export default {
        name: 'vue-upload',
        mixins: [mixins],
        data() {
            return {};
        },
        methods: {
            upload(file) {
                this.uploader.upload(file)
            },
            stop(file) {
                this.uploader.stop(file)
            },
            // 取消并中断文件上传
            cancelFile(file) {
                this.uploader.cancelFile(file)
            },
            // // 在队列中移除文件
            // removeFile(file, bool) {
            //     this.removeFile(file, bool)
            // },

        }
    }
</script>
<style lang="less" scoped>
    /*直接把官方的css粘过来就行了*/
    .webuploader-container {
        position: relative;
    }

    .webuploader-element-invisible {
        position: absolute !important;
        clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
        clip: rect(1px, 1px, 1px, 1px);
    }
    .webuploader-pick-disable {
        opacity: 0.6;
        pointer-events: none;
    }

    @import "./webuploaderCss/style.css";
    @import "./webuploaderCss/cropper.css";
</style>
