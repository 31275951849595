<template>
    <div style="width: 100%;height: 100%;position: relative" :class="isMultTab ? 'mult-tab-style' : ''">
        <div :is="TplName" :class="{ 'hide-top-bar': hideTopBar, 'hide-bottom-bar': !isShowFooter}" >
            <!--                main-table-tpl-->
            <template slot="sideTop">
                <slot name="sideTop"></slot>
            </template>
            <template slot="sideMain">
                <slot name="sideMain"></slot>
            </template>
            <template slot="contentTopLeft">
                <!-- 如：表格上面左侧搜索框放置 --->
                <slot name="contentTopLeft"></slot>
                <!-- 搜索漏斗 --->
                <funnel :queryType='queryType' :table-name="tableName" @resetCdn="resetCdn" :defaultParams.sync="parentdefaultParams"
                        @searchEvnt="searchEvnt">
                    <template slot="customParamsF">
                        <slot name="customSlot"></slot>
                    </template>
                </funnel>
            </template>
            <template slot="contentTopRight">
                <!-- 如：表格上面右侧按钮放置 --->
                <i></i>
                <slot name="contentTopRight"></slot>
                <a-button @click="handelExport" v-if="ShowExport">导出全部</a-button>
            </template>
            <template slot="contentMain">
                <dynamic-table @rowClick="rowClick" :scrollY="scrollY" :indexCheckType="indexCheckType" :isNotMerge="isNotMerge" :table-name="tableName" :autoLoad="autoLoad" :columns="columns"
                               :defaultParams.sync="parentdefaultParams" @CustomColumns="CustomColumns"
                               ref="dynamictable" :pagination.sync="Pager" :nestMapStr.sync="childNestMapStr"
                               @selectRow="handleSelRow" :searchParams.sync="searchParams">
                    <template :slot="item + 'parent'"
                              v-for="item in ['alink0','alink1', 'alink2', 'alink3', 'alink4', 'alink5', 'alink6', 'alink7', 'alink8', 'alink9']"
                              slot-scope="{ text,record,index }">
                        <slot :name="item + 'child'" :text="text" :record="record" :index="index"></slot>
                    </template>
                    <template slot="opr" slot-scope="{ text,record,index }">
                        <slot name="costomation" :text="text" :record="record" :index="index"></slot>
                        <!--                        <a-popconfirm title="确认删除？" @confirm="() => onDelete(rowData)">-->
                        <!--                            <a href="javascript:;">-->
                        <!--                                <span class="iconhr ico-shanchu"></span>-->
                        <!--                            </a>-->
                        <!--                        </a-popconfirm>-->
                    </template>
                </dynamic-table>
                <!-- 表格 --->
            </template>
            <template slot="contentBottomLeft">
                <!-- 表格底部左侧放置插槽 --->
                <div class="setHeader-btn" v-permission="permitStr" @click="showSetHeaderFn()">
                    <a-icon class="setHeader-btn-icon" type="setting" theme="filled"/>
                    设置表头
                </div>
                <slot name="contentBottomLeft">

                </slot>
            </template>
            <template slot="contentBottomRight">
                <!-- 表格底部右侧放置插槽 --->
                <!-- 如：翻页的放置 -->

                <com-pagination
                        @change="handlePageChange"
                        :pagination="Pager"
                ></com-pagination>
                <!--                <slot name="contentBottomRight"></slot>-->
            </template>
            <template slot="default">
                <!-- 弹窗之类，布局以外的内容 --->
                <slot name="default"></slot>

                <!--设置表头-->
                <a-modal width="1300px" :forceRender="true" :visible="visibleSetHeader" title="设置表头"
                         @cancel="handleCancel($event)" @ok="handleOk($event)">
                    <set-header ref="tableSet" @saveData="saveDataFn" :table-name="tableName"></set-header>
                </a-modal>
            </template>
        </div>
    </div>
</template>

<script>
    import {getUrlWithTokenParam} from 'common/utils/token/tokenUtil'
    import ComPagination from 'common/components/ComPagination'
    import {TreeTableTpl, MainTableTpl} from 'common/components/ComTemplate'
    import Funnel from './Funnel'
    import DynamicTable from './DynamicTable'
    import qs from "../../lib/qs";
    import {cloneDeep} from 'lodash'
    import setHeader from 'common/components/ComSetHeader/setHeaderDial'

    function parseUrlParam(param) {
        let paramStr = ''
        if (!param) {
            return paramStr
        }
        if (param instanceof String) {
            return param
        } else if (param instanceof Object) {
            paramStr = qs.stringify(param, {indices: false})
        }
        return paramStr
    }

    export default {
        name: 'xinxitable',
        props: {
            isShowFooter: {
                type: Boolean,
                default: true
            },
            permitStr: {
                type: String,
                default: 'test'
            },
            // 是否显示导出按钮
            ShowExport: {
                type: Boolean,
                default: true
            },
            // 是否自动加载数据
            autoLoad: {
                type: Boolean,
                default: true
            },
            defaultParams: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            nestMapStr: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            isMultTab: {
                type: Boolean,
                default: false
            },
            columns: Array,
            tableName: String, // 必传根据tableName查询表格表头
            TplName: {
                type: String,
                default: 'tree-table-tpl'
            },
            hideTopBar: {
                type: Boolean,
                default: false
            },
            // 导出参数
            exportParams: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            scrollX: {
                default: 800
            },
            scrollY: {
                default: null
            },
            indexCheckType: {
                default: 'checkbox'
            },
            isNotMerge: {
                default: false
            },
            queryType: null
        },
        components: {
            ComPagination,
            TreeTableTpl,
            MainTableTpl,
            Funnel, // 漏斗
            DynamicTable, // 列表
            setHeader
        },
        data() {
            const that = this
            return {
                visibleSetHeader: false,
                params: {},
                Pager: {},
                searchParams: {},
                parentdefaultParams: {},
                childNestMapStr: null
            }
        },
        computed: {},
        created() {
            console.log(this.defaultParams)
            console.log(this.searchParams)
            this.childNestMapStr = cloneDeep(this.nestMapStr)
            this.parentdefaultParams = cloneDeep(this.defaultParams)
        },
        mounted() {
        },
        watch: {
            defaultParams: {
                // value 需要深度监听及默认先执行handler函数
                handler: function (val) {
                    // if (val) {
                    // }
                    console.log(val)
                    this.parentdefaultParams = cloneDeep(val)
                },
                immediate: true,
                deep: true
            },
            nestMapStr: {
                // this.childNestMapStr = val
                // value 需要深度监听及默认先执行handler函数
                handler: function (val) {
                    // if (val) {
                    // }
                    console.log(val)
                    this.childNestMapStr = cloneDeep(val)
                },
                immediate: true,
                deep: true
            }
        },
        methods: {
            rowClick (record) {
                this.$emit('rowClick',  record)
            },
            resetCdn() {
                this.$emit('resetCdn')
            },
            // 接收修改的数据并保存
            saveDataFn(data) {
                const ajaxDate = {
                    customTableColumnList: data.map(item => ({
                        ...item,
                        lockColumn: item.lockColumn === true ? 1 : 0,
                        editenable: item.editenable === true ? 1 : 0,
                        showOverflow: item.showOverflow === true ? 1 : 0
                    }))
                }
                console.log(ajaxDate)
                this.$http('post_updateCustom', ajaxDate, true).then(res => {
                    this.saveSuccess = true
                }).catch(() => {
                    this.saveSuccess = false
                })
            },
            handleOk(e) {
                console.log(e)
                this.visibleSetHeader = false
                this.ReSearch()
            },
            handleCancel() {
                this.visibleSetHeader = false
            },
            showSetHeaderFn() {
                this.visibleSetHeader = true
            },

            CustomColumns(columns) {
                this.$emit('CustomColumns', columns)
            },

            onDelete(key) {
                console.log(key)
            },
            handleSelRow(Rows) {
                console.log(Rows)
                this.$emit('handleSelRow', Rows)
            },
            // 搜索事件
            searchEvnt(params) {
                this.$refs.dynamictable.getData(params)
            },
            clearSelectKeys () {
                this.$refs.dynamictable.clearSelectKeys()
            },
            // 刷新数据
            ReSearch() {
                let searchPams = undefined
                if (this.searchParams.paramMapStr && this.searchParams.paramMapStr !== '{}') {
                    searchPams = JSON.parse(this.searchParams.paramMapStr)
                }
                if (searchPams === undefined) {
                    searchPams = cloneDeep(this.parentdefaultParams)
                }
                this.$refs.dynamictable.getData(searchPams, null, true)
                this.clearSelectKeys()
            },
            // 导出全部
            handelExport() {
                let tempsearchParams = cloneDeep(this.searchParams)
                if (Object.keys(this.exportParams).length > 0) {
                    if (tempsearchParams.paramMapStr === undefined) {
                        tempsearchParams.paramMapStr = JSON.stringify(this.exportParams)
                    } else {
                        tempsearchParams.paramMapStr = Object.assign(JSON.parse(tempsearchParams.paramMapStr), this.exportParams)
                    }
                }
                let requestUrl = getUrlWithTokenParam('/sym/customtableF/exportExcel')
                if (tempsearchParams.paramMapStr &&  typeof(tempsearchParams.paramMapStr) === 'string') {
                    tempsearchParams.paramMapStr = JSON.parse(tempsearchParams.paramMapStr)
                }
                let paramStr = parseUrlParam(tempsearchParams)
                if (paramStr) {
                    requestUrl = requestUrl + '&' + paramStr
                }
                const baseUrl = process.env.VUE_APP_BASE_API
                window.open(baseUrl + requestUrl)
            },

            // 处理分页
            handlePageChange(pagination) {
                const pager = {
                    pageSize: pagination.pageSize,
                    pageNum: pagination.current,
                    currentPage: pagination.current
                }
                this.$refs.dynamictable.getData(this.searchParams, pager)
            }
        }
    }
</script>
<style lang="less" scoped>
    .mult-tab-style /deep/ .ptp-layout {
        padding-left: 0;
        /*height: ~'calc(100% - 45px)';*/
    }

    .mult-tab-style /deep/ .ptp-layout.is-compose {
        padding-right: 0;
    }

    .mult-tab-style /deep/ .ptp-layout.is-compose .ptp-layout-content {
        border-radius: 0;
    }

    // /deep/ .hide-top-bar.pst-layout .ptp-layout-content .ptp-layout-top {
    //     height: 0px;
    //     overflow: hidden;
    // }

    /deep/ .hide-top-bar.ptp-layout .ptp-layout-content .ptp-layout-top {
        height: 0px;
        overflow: hidden;
    }

    /deep/ .ptp-layout .ptp-layout-content .ptp-layout-main {
        overflow: hidden;
    }

    // /deep/ .hide-bottom-bar.pst-layout .ptp-layout .ptp-layout-bottom {
    //     display: none;
    // }

    /deep/ .hide-bottom-bar.ptp-layout .ptp-layout-content .ptp-layout-bottom {
        display: none;
    }

    .setHeader-btn {
        border-radius: 5px;

        &:hover {
            cursor: pointer;
            font-weight: 600;

            .setHeader-btn-icon {
                color: #49658a;
            }
        }

        .setHeader-btn-icon {
            color: #6B85AC;
            font-size: 16px;
        }
    }
</style>
