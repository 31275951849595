<template>
<!-- 这个是树文件 -->
  <div class="com-trans-wrap">
    <div class="com-trans-left">
      <div class="trans-header">
        <slot name="header"></slot>
      </div>
      <div class="trans-body">
        <a-tree
          v-model="checkedKeys"
          :disabled="_disabled"
          :expanded-keys="expandedKeys"
          :replaceFields="replaceFields"
          :auto-expand-parent="autoExpandParent"
          :selected-keys="[]"
          :tree-data="_treeData"
          :loadData="loadData"
          @check='check'
          @expand='_expand'
          checkable
          blockNode
        />
      </div>
    </div>
    <div class="com-trans-right">
      <div class="trans-header">
        <div class="selected-num">已选{{ checkedKeys.length }}项</div>
        <a-button class="selected-alldel" @click="_delAll" :disabled="_disabled"
          >删除全部</a-button
        >
      </div>
      <div class="trans-body">
        <a-tree
          v-model="rightCheckedKeys"
          :tree-data='_rightTreeData'
          :replaceFields='replaceFields'
          :loadData='rightLoadData'
          :expandedKeys.sync='rightExpandedKeys'
          :loadedKeys='[]'
          :selected-keys="[]"
          @check='rightCheck'
          checkable
          blockNode
        >
          <template slot="title" slot-scope="v">
            <a-row type='flex' justify="space-between" align="middle">
              <a-col>{{v[replaceFields.title]}}</a-col>
              <span class="iconhr ico-8"
                style='font-size: 16px;margin-right: 15px;'
                v-if="checkedKeys.includes(v[replaceFields.key])"
                @click.stop="_deleteItem(v)"></span>
            </a-row>
          </template>
        </a-tree>
      </div>
    </div>
  </div>
</template>

<script>

const _log = function (...l) {
  // console.log('[ComTreeTransfer] ', ...l)
}
// import { cloneDeep } from 'lodash'
export default {
  name: "ComTreeTransfer",
  props: {
    disable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dataSource: {
      type: Array,
      default: () => []
    },
    dataTarget: {
      type: Array,
      default: () => []
    },
    loadData: null,
    rightLoadData: null,
    replaceFields: {
      type: Object,
      default: () => ({
        children: 'children',
        title: 'title',
        key: 'key'
      })
    },
    rightReplaceFields: null,
    defaultTargetKeys: {
      type: Array,
      default: () => []
    },
    rightDefaultCheckedKeys: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      expandedKeys: [],
      autoExpandParent: true,
      checkedKeys: [],
      rightTreeData: [],
      rightExpandedKeys: [],
      rightCheckedKeys: []
    }
  },
  watch: {
    defaultTargetKeys: {
      handler (n) {
        _log(n)
        this.checkedKeys = [...n]
      },
      immediate: true
    },
    rightDefaultCheckedKeys: {
      handler (n) {
        this.rightCheckedKeys = [...n]
      },
      immediate: true
    }
  },
  computed: {
    _disabled () {
      const { disable, disabled } = this
      return disable || disabled
    },
    _treeData () {
      const { dataSource, _setCheckable } = this
      return _setCheckable(dataSource)
    },
    _rightTreeData () {
      const { dataTarget, _setRightLeaf } = this
      _log('监听dataTarget', dataTarget)
      return _setRightLeaf(dataTarget)
    }
  },
  methods: {
    check (v, n) {
      const dataRef = JSON.parse(JSON.stringify(n.node.dataRef))
      const replaceFields = this.replaceFields
      const value = dataRef[replaceFields.key]
      _log('check', v, n, this.checkedKeys, this.checkedKeys.includes(value))
      const values = this.dataTarget.map( v => v[replaceFields.key])
      if (values.includes(value)) {
        let keyIndex = values.indexOf(value)
        const spliceTarget = this.dataTarget[keyIndex]
        const rightExpandedKeysValue = this._returnKeys([spliceTarget])
        this.$delete(this.dataTarget, keyIndex)
        let rightExpandedKeys = this.rightExpandedKeys.filter(
          v_1 => (!rightExpandedKeysValue.includes(v_1))
        )
        this.rightExpandedKeys = rightExpandedKeys
      } else {
        this.dataTarget.push({...dataRef})
      }
      const dataTarget = [...this.dataTarget]
      this.$emit('change', dataTarget, { add: [dataRef], del: []})
    },
    _expand (e) {
      _log('expand', e)
      this.expandedKeys = e
      this.autoExpandParent = false
    },
    _deleteItem (v) {
      _log(v)
      const replaceFields = this.replaceFields
      const value = v[replaceFields.key]
      const values = this.dataTarget.map( v => v[replaceFields.key])
      if (values.includes(value)) {
        let keyIndex = values.indexOf(value)
        const spliceTarget = this.dataTarget[keyIndex]
        const rightExpandedKeysValue = this._returnKeys([spliceTarget])
        this.$delete(this.dataTarget, keyIndex)
        this.checkedKeys = this.checkedKeys.filter(v => value != v)
        this.rightExpandedKeys = this.rightExpandedKeys.filter(
          v_1 => (!rightExpandedKeysValue.includes(v_1))
        )
        this.rightCheckedKeys = this.rightCheckedKeys.filter(
          v_1 => (!rightExpandedKeysValue.includes(v_1))
        )
        this.$emit(
          'rightCheck', 
          [...this.rightCheckedKeys], 
          { type: 'delete', checked: false, data: [spliceTarget]}
        )
      }
      const dataTarget = [...this.dataTarget]
      this.$emit('change', dataTarget, { add: [], del: [v]})
    },
    _delAll () {
      this.checkedKeys = []
      this.rightCheckedKeys = []
      this.rightExpandedKeys = []
      const dataTarget = [...this.dataTarget]
      this.dataTarget.splice(0, this.dataTarget.length)
      this.$emit('rightCheck', [], { type: 'delete', checked: false, data: dataTarget})
      this.$emit('change', [], { add: [], del: dataTarget})
    },
    _setCheckable (tree) {
      if (tree && tree.length) {
        tree.forEach(v => {
          if(!v.isLeaf) v.checkable = false
          if (v.children && v.children.length) this._setCheckable(v.children)
          else if (!this.loadData) {
            v.checkable = true
          }
        })
      }
      return tree
    },
    _setRightLeaf (tree) {
      if (tree && tree.length) {
        tree.forEach(v => {
          if(typeof v.isLeaf === 'boolean') v.isLeafCopy = v.isLeaf
          if(v.hasChildren) v.isLeaf = false
          // if (v.children && v.children.length) this._setRightLeaf(v.children)
          v.scopedSlots = { title: 'title' }
        })
      }
      return tree
    },
    _returnKeys (arr) {
      const replaceFields = this.replaceFields
      const keys = []
      arr.forEach(v => {
        keys.push(v[replaceFields.key])
        if (v.children && v.children.length) keys.push(...this._returnKeys(v.children))
      })
      return keys
    },
    rightCheck (e, v) {
      this.$emit(
        'rightCheck',
        e,
        { type: 'check', checked: v.checked, data: [v.node.dataRef] }
      )
    }
  }
}
</script>

<style scoped>
.delete-btn {
  float: right;
}
</style>
