<template>
  <div class="com-trans-wrap">
    <div class="com-trans-left">
      <div class="trans-header">
        <slot name="header"></slot>
      </div>
      <div class="trans-body">
        <collapseList
            :dataSource="leftData"
            :defaultChecked="targetKeys"
            :replaceFields="replaceFields"
            :defaultExpandedKeys="defaultExpandedKeys"
            :loadData="leftLoadData"
            :disable="disable"
            @checked="leftSelect"
            @itemExpand="leftExpand"
            @excludeRepeatKey="excludeRepeatKey"
            @leftParentClick="(e) => {$emit('leftParentClick', e)}"
            checkable
        ></collapseList>
      </div>
    </div>
    <div class="com-trans-right">
      <div class="trans-header">
        <div class="selected-num">已选{{selectedNum}}项</div>
          <slot name="headerRight"></slot>
        <a-button class="selected-alldel" @click="delAll" :disabled="disable">删除全部</a-button>
      </div>
      <div class="trans-body">
        <simpleList
            type="collapse"
            :disable="disable"
            :checkedName="checkedName"
            @delItem="rightDelItem"
            @sortItem="rightSortItem"
            :replaceFields="replaceFields"
            :dataSource="rightData"
        ></simpleList>
      </div>
    </div>
  </div>
</template>
<script>
  import collapseList from './collapseRepeatList.vue'
  import simpleList from './simpleRepeatList'
  export default {
    props: {
      multiple: {
        type:Boolean,
        default: false
      },
      dataSource: {
        default: () => ([])
      },
      dataTarget: {
        default: () => ([])
      },
      replaceFields: {
        default: () => ({
          title:'title',
          key: 'key'
        })
      },
      leftLoadData: {
        default: ''
      },
      rightLoadData: {
        default: ''
      },
      selectKeys: {
        default: () => ([])
      },
      disable: {
        default: false
      }
    },
    components: {
      collapseList,
      simpleList
    },
    data () {
      return {
        leftData: [],
        rightData: [],
        selectVal: '',
        selectedNum: 0,
        selectData: [],
        targetKeys: [],
        defaultExpandedKeys: [],
        checkedName: '',
        leftDatas: [],
        rightDatas: [],
        selectVals: '',
        selectedNums: 0,
        selectDatas: [],
        targetKeyss: [],
        defaultExpandedKeyss: [],
        checkedNames: ''
      }
    },
    beforeCreate() {
    },
    created () {
      this.setLeftData(this.dataSource)
      this.setRightData(this.dataTarget)
      // this.setSelectedNum(this.selectKeys)
    },
    mounted () {
      this.tableLoading = true
      this.excludeRepeatKey()
    },
    methods: {
      excludeRepeatKey() {
        for (var i = 0; i<this.dataSource.length; i++) {
          for (var j = 0; j<this.dataSource[i].children.length; j++) {
            this.dataSource[i].children[j].key = this.dataSource[i].key + j
          }
        }
      },
      setLeftData(data) {
        this.excludeRepeatKey()
        this.leftData = data
      },
      // 修改穿梭框右侧数据
      setRightData(data) {
        // this.rightData = data
        this.targetKeys = []
        this.rightData = []
        data.forEach(item => {
          const key = item[this.replaceFields.key]
          this.targetKeys.push(key)
          this.rightData.push(item.title)
        })
        this.setSelectedNum(data)
        this.selectedNums = data.length
      },
      leftSelect (key, obj, type, index) {
        if(type) {
          let keyIndex = this.targetKeys.indexOf(key)
          this.targetKeys.splice(keyIndex, 1)
          this.rightData.splice(keyIndex, 1)
        } else {
          if (this.multiple) {
            this.targetKeys = []
            this.rightData = []
            this.targetKeys.push(key)
            this.rightData.push(obj)
          } else {
            this.targetKeys.push(key)
            this.rightData.push(obj)
          }
        }
        this.emitChange(this.rightData)
        if (type) {
          this.$emit('delItem', [obj, index])
        } else {
          this.$emit('checked', obj)
        }
      },
      delAll () {
        if(this.disable) return
        this.targetKeys = []
        this.rightData = []
        this.emitChange(this.rightData)
        this.$emit('delAll')
      },
      emitChange (change) {
        this.setSelectedNum(change)
        this.$emit('change', change)
      },
      leftExpand (e) {
      },
      // 获取穿梭框长度
      setSelectedNum(data) {
        this.selectedNum = data.length
      },
      rightDelItem ([e, index]) {
        const key = e[this.replaceFields.key]
        this.leftSelect(key, e, true, index)
      },
      rightSortItem () {
        this.$emit('change', data)
      }
    },
    watch: {
      dataSource (n) {
        this.setLeftData(n)
      },
      dataTarget (n) {
        this.setRightData(n)
      }
      // selectKeys (n) {
      //   this.setSelectedNum(n)
      // }
    }
  }
</script>
