<template>
  <div>
    <a-tree-select
      allowClear
      v-if='!dynamic'
      :placeholder='placeholder'
      :treeData='options'
      style='width: 68%;'
      v-model='inValue'
      :multiple='multiple'
      :maxTagCount='2'
      @change='change'
      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
    />
    <CodeTable
      allowClear
      v-else
      style='width: 68%;'
      :placeholder='placeholder'
      :dmgrp='dynamicKey'
      :multiple='multiple'
      :maxTagCount='2'
      v-model='inValue'
      @change='change'
    />
  </div>
</template>

<script>
import CodeTable from 'common/components/ComCodeTable'
export default {
  name: 'ComCustomQueryTreeSelect',
  components: { CodeTable },
  props: {
    placeholder: {
      type: [String, Number],
      default: '请选择'
    },
    options: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: true
    },
    dynamicKey: {
      type: String,
      default: ''
    },
    dynamic: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => ({
        value: null,
        label: ''
      })
    }
  },
  data () {
    return {
      inValue: ''
    }
  },
  watch: {
    value: {
      handler  (n) {
        this.inValue = n.value
      },
      immediate: true
    }
  },
  methods: {
    change (e) {
      const value = Array.isArray(e) ? Array.from(e) : e
      this.$emit('change', { value, label: Array.isArray(e) ? value.join(',') : value })
    }
  }
}
</script>