<!--
    <flow-modal ref="flowModal" />
-->
<template>
  <a-modal
    title="流程表单"
    :width="1200"
    :visible="visible"
    dialogClass="mgr-operation-modal"
    @cancel="hide"
    :footer="null"
    :dialog-style="{ top: '30px' }"
  >
    <iframe v-if="renderFlag" ref="iframe" frameborder="0" style="height: 600px;width: 100%" :src="src"></iframe>
  </a-modal>
</template>

<script>
import { ACCESS_TOKEN } from 'common/confing'

export default {
  name: 'flowModal',
  data () {
    return {
      visible: false,
      src: '',
      renderFlag: false
    }
  },
  methods: {
    /**
     * 弹出对话框
     */
    show (uuid) {
      const urlPrefix = process.env.VUE_APP_BASE_API
      const token = sessionStorage.getItem(ACCESS_TOKEN)
      const env = process.env.NODE_ENV
      this.visible = true
      this.renderFlag = true
      this.src = `${urlPrefix}/wffront/system/core/workflow/flowrun/flowview/viewgraph.html?tokenid=${token}&id=${uuid}&_env=${env}`
    },
    /**
     * 弹出对话框
     */
    showDetail (uuid) {
      const urlPrefix = process.env.VUE_APP_BASE_API
      const token = sessionStorage.getItem(ACCESS_TOKEN)
      const env = process.env.NODE_ENV
      this.visible = true
      this.renderFlag = true
      this.src = `${urlPrefix}/wffront/system/core/workflow/flowrun/flowview/viewlist.html?tokenid=${token}&id=${uuid}&_env=${env}`
    },
    /**
     * 关闭对话框
     */
    hide () {
      this.visible = false
      this.renderFlag = false
    }
  }
}
</script>

<style scoped>
.mgr-operation-modal {
  height: 700px;
}
</style>
