<template>
        <a-modal v-model="selfilelelModel"  title="修改附件名称" width="600px" @cancel="handleCancel" @ok="handleSubmit">
            <a-form-model
                    ref="ruleForm"
                    :model="singleFile"
                    :colon="false"
                    layout="horizontal"
                    :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                <a-form-model-item label="附件名称" prop="name" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
                    <a-input v-model="singleFile.name"></a-input>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
</template>

<script>
    export default {
        props: {
            selfilelelVisible: {
                type: Boolean,
                default: false
            },
            file: {
                type: [Object,File]
            }
        },
        data() {
            return {
                selfilelelModel: false,
                singleFile: {
                    name: null
                },
                rules: {
                    name: [{ required: true, message: '请选择密级!', trigger: 'change' }]
                }
            }
        },
        watch: {
            file: function (val) {
                if(val){
                    this.singleFile = val
                }
            },
            selfilelelVisible: function (val) {
                this.selfilelelModel = val
            }
        },
        mounted() {
        },
        methods: {
            // 关闭文件预览
            handleCancel() {
                this.$emit('update:selfilelelVisible', false)
            },
            handleSubmit() {
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        this.$emit('handleRenamed', {singleFile:this.file,file:this.singleFile})
                        this.handleCancel()
                        this.singleFile = {}
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                })
            }
        }
    }
</script>
