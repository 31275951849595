import antTable from './mixins/antTable'
import ComPagination from '../ComPagination'
import AntTable from '../ComTable/AntTable'
import { MiddleConLayout } from '../BaseLayout'
import './TplStyle.css'
export default {
  props: {
    space: {
      type: Object,
      default: () => ({
        col: '8',
        row: '0'
      })
    },
    tableColumns: {
      default: () => ([])
    },
    tableReqUrl: {
      default: ''
    },
    tableDataReq: {
      default: ''
    },
    rowSelection: {
      default: () => ({})
    },
    tableRowKey: {
      default: 'key'
    },
    conTopHide: Boolean,
    manualTableReq: Boolean,
    noMainPadding: Boolean,
    noBottom: Boolean,
    noSpaceTop: Boolean
  },
  components: {
    ComPagination
  },
  mixins: [antTable],
  data() {
    return {
      // pagination: {
      //   total: 0,
      //   defaultPageSize: 10,
      //   showTotal: total => `共 ${total} 条`,
      //   showSizeChanger: true,
      //   pageSizeOptions: ['10', '20', '30', '40']
      //   // onShowSizeChange: (current, pageSize) => { return this.pageSize = pageSize }
      // }
    }
  },
  created() {
    console.log(this.manualTableReq)
    this.$emit('getInitFun', {
      tableInitFun: this.getData,
      resetPagination: this.resetPagination,
      onGotPageData: this.onGotPageData
    })
    if(!this.manualTableReq) {
      this.getData()
    }
  },
  mounted() {
  },
  methods: {
    getData () {
      // 拼装查询条件
      const params = {}
      this.addPageInfo(params)
      this.searchData.pageNum = params.currentPage // 页数
      this.searchData.pageSize = params.pageSize // 页大小
      console.log('分页')
      console.log(this.searchData)
      console.log(this.pagination)
      console.log(params)
      if(!this.tableDataReq) {
        return
      }
      this.tableDataReq(this.searchData).then(res => {
        this.onGotPageData(res.data)
        console.log(res)
      })
    },
  },
  render() {
    return (
    <MiddleConLayout
      space={this.space}
      conTopHide={this.conTopHide}
      class="page-left-side"
    >
      <template slot="contentTopLeft">
        {
          this.$slots.contentTopLeft
          &&  this.$slots.contentTopLeft
        }
      </template>
      <template slot="contentTopRight">
        {
          this.$slots.contentTopRight
          ?  this.$slots.contentTopRight
          : [ <a-button>
              新增
            </a-button>,
            <a-button>
              复制
            </a-button>
          ]
        }
      </template>
      <template slot="contentMain">
        {
          this.$scopedSlots.contentMain
          ? this.$scopedSlots.contentMain({columns: this.tableColumns, tableData: this.tableData})
          : <AntTable
            style='width: 100%; height: 98%;'
            scroll={{ x: 1300, y: 'calc(100% - 70px)' }}
            pagination={false}
            columns={this.tableColumns}
            dataSource={this.tableData}
            rowSelection={this.rowSelection}
            rowKey={this.tableRowKey}
            loading={this.loading}
          ></AntTable>
        }
      </template>
      <template slot="contentBottomLeft">
        {
          this.$slots.contentBottomLeft && this.$slots.contentBottomLeft
        }
      </template>
      {/* <template slot='contentBottomRight'>
        {
          this.$scopedSlots.contentBottomRight
            ? this.$scopedSlots.contentBottomRight()
            : <com-pagination
              onChange={this.handlePageChange}
              pagination={this.pagination}
            ></com-pagination>
        }
      </template> */}
      { this.$slots.default && this.$slots.default}
    </MiddleConLayout>)
  }
}


