import { render, staticRenderFns } from "./SelectLevel.vue?vue&type=template&id=dce66142&"
import script from "./SelectLevel.vue?vue&type=script&lang=js&"
export * from "./SelectLevel.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../portal/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports