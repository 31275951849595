<template>
    <div class="com-upload">
        <a-config-provider :locale="zhCN">
            <div>
                <div class="com-upload-box">
                    <div class="com-upload-isBatchDel">
                        <div class="com-upload-box-left">
                            <span v-if="!readOnly" class="com-upload-explain-text">上传说明 </span>
                            <a-tooltip placement="top" overlayClassName="upload-pop" v-if="!readOnly">
                                <template slot="title">
                                    <p class="pop-font-set">1.支持扩展名: {{limitFileType}}</p>
                                    <p class="pop-font-set">2.每个附件不超过 50 M</p>
                                </template>
                                <i class="iconhr ico-xinxi info-icon-color"></i>
                            </a-tooltip>
                        </div>
                        <div>
                            <div class="com-upload-btn-box" v-if="!readOnly">
                                <z-upload v-if="fileList.length>=0"
                                          :file-list="fileList"
                                          :accept="FileAccept"
                                          multiple
                                          @preview="handlePreview"
                                          @rename="handleRename"
                                          @change="handleChange"
                                          @selected="handleSelect"
                                          @download="handleDownload"
                                          :remove="handleRemove"
                                          :disabled="isDisabled"
                                          :before-upload="beforeUpload"
                                          :show-upload-list="false">
                                    <a-button type="primary" :disabled="isDisabled">
                                        上传附件
                                    </a-button>
                                </z-upload>
                            </div>
                            <a-button @click="handleDownload()" class="com-upload-btn-box" v-if="DownLoadPermit" :disabled="SelectItems.length === 0 || isDisabled">批量下载</a-button>
<!--                            <a-button @click="handleBatchDel" v-if="!readOnly&&fileList.length>0 && DeletePermit" :disabled="SelectItems.length === 0">删除</a-button>-->
                        </div>
                    </div>
                    <div class="com-upload-file" v-if="fileList.length>0">
                        <a-table rowKey="fid" :loading=loading :row-selection="rowSelection"
                                 :columns="columns" :data-source="fileList" :pagination="{ pageSize: 1000,current: 1, position: 'top'}">
                            <template slot="index" slot-scope="text, record ,index">
                                {{index + 1}}
                            </template>
                            <template slot="name" slot-scope="text, record">
                                <a-tooltip placement="topLeft" overlayClassName="upload-pop">
                                    <template slot="title">
                                        {{text}}
                                    </template>
                                    <p class="file-name-pop" @click="handlePreview(record)" >{{text}}</p>
                                </a-tooltip>
<!--                                <p v-else class="file-name-pop" @click="handlePreview(record)">{{record.name}}</p>-->
                            </template>
                            <template slot="level" slot-scope="text, record">
                                <p>{{record.level | filterLevel}}</p>
                            </template>
                            <template slot="size" slot-scope="text, record">
                                <p>{{record.size | filterSize}}</p>
                            </template>
                            <a slot="opr" slot-scope="text, record" v-if="!readOnly">
<!--                                <span class="iconhr ico-chakan" @click="handlePreview(record)"></span>-->
                                <span class="iconhr ico-shanchu" :disabled="isDisabled" @click="handleBatchDelete(record.fid, [record.fid])"></span>
<!--                                <span class="iconhr ico-chakan" v-if="isImgType(record.filetype)" @click="handlePreview(record)"></span>-->
<!--                                <span class="iconhr ico-xiazai" v-else @click="handlePreview(record)"></span>-->
                            </a>
                        </a-table>
                    </div>
                </div>
                <p class="upload-error-info" v-if="showTextTips?true:false">{{showTextTips}}</p>
            </div>
        </a-config-provider>

        <a-modal v-model="previewVisible" title="预览" width="600px" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
        <select-level :file.sync="singleFile" @handleSelLel="handleSelLel" :LevelfileList.sync="LevelfileList"
                      :selfilelelVisible.sync="selfilelelVisible" :currentPageBelong="currentPageBelong"></select-level>
        <rename :file.sync="singleFile" @handleRenamed="handleRenamed" :selfilelelVisible.sync="renameVisible"></rename>

        <com-img-viewer
                v-if="dialogVisible"
                :on-close="closeViewer"
                :url-list="GetSrcListByCache()"
                :initialIndex="PriviewStartIndex"
                :titles="dialogTitle"
        ></com-img-viewer>
        <com-pdf-viewer
                v-if="pdfdialogVisible"
                :title="pdfViewerTitle"
                :on-close="closePdfViewer"
                :src="pdfViewerSrc"
                :fid="singlefid"
                :mime-type="mimeType"
        ></com-pdf-viewer>
    </div>
</template>

<script>
    import mixins from './mixin/utls'
    import ZUpload from 'common/lib/Upload/Package/zUpload'
    import SelectLevel from './SelectLevel'
    import Rename from './RenameDialog'
    import {downFile} from 'common/utils/file/downFile'
    import ComPdfViewer from './preview/PdfViewer';
    import ComImgViewer from './preview/ImgViewer';

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    export default {
        mixins: [mixins],
        props: {
            isDisabled: {
                type: Boolean,
                default: false
            },
            // 是否显示底部提示框
            isShowTip: {
                type: Boolean,
                default: true
            },
            // 文件数量限制
            limitCount: {
                type: Number,
                default: 8
            },
            // 文件类型限制
            limitFileType: {
                type: String,
                default: 'txt、pdf、doc、docx、xls、xlsx、ppt、jpg、png、bmp、gif、rar、zip'
            },
            // 是否只读
            readOnly: {
                type: Boolean,
                default: false
            },
            // 是否批量删除
            batchDeleteAble: {
                type: Boolean,
                default: true
            },
            ids: '',
            currentPageBelong: '',
        //    列表文字宽度
            textWidth: {
                type: String,
                default: '45%'
            }
        },
        components: {
            ZUpload,
            SelectLevel,
            Rename,
            ComPdfViewer,
            ComImgViewer
        },
        data() {
            return {
                columns: [
                    {
                        title: '附件名称',
                        dataIndex: 'name',
                        width: this.textWidth,
                        align: 'left',
                        ellipsis: true,
                        scopedSlots: {customRender: 'name'}
                    },
                    {
                        title: '密级',
                        dataIndex: 'level',
                        width: '20%',
                        align: 'center',
                        scopedSlots: {customRender: 'level'}
                    },
                    {
                        title: '大小',
                        dataIndex: 'size',
                        width: '20%',
                        align: 'center',
                        scopedSlots: {customRender: 'size'}
                    },
                    {
                        title: '操作',
                        dataIndex: 'opr',
                        width: '15%',
                        align: 'center',
                        scopedSlots: {customRender: 'opr'}
                    }
                ],
                loading: false,
                showTextTips: '',
                LevelfileList: []
            }
        },
        filters: {
            filterLevel (level) {
                let str = ''
                switch (level) {
                    case '1':
                        str = '机密'
                        break;
                    case '2':
                        str = '秘密'
                        break;
                    case '3':
                        str = '内部'
                        break;
                    case '4':
                        str = '核心商密'
                        break;
                    case '5':
                        str = '平台商密'
                        break;
                    case '6':
                        str = '公开'
                        break;
                }
                return str
            },
            filterSize (size) {
                let str = (( size - 0 ) /1024 ).toFixed(0)
                return str + 'kb'
            }
        },
        created() {
            if (this.ids) {
                if (this.ids !== '[]') {
                    this.LoadData(this.ids)
                }
            } else {
                this.fileList = []
            }
        },
        watch: {
            ids: function (ids) {
                if (ids) {
                    this.LoadData(ids)
                } else {
                    this.fileList = []
                }
            }
        },
        computed: {
            // 允许的文件类型
            FileAccept() {
                let acceptstr = this.limitFileType.split('、').map(item => {
                    return '.' + item
                }).join(',')
                return acceptstr
            },
            rowSelection() {
                return {
                    onChange: (selectedRowKeys, selectedRows) => {
                        this.SelectItems = selectedRows
                    },
                    getCheckboxProps: record => ({
                        props: {
                            disabled: record.name === 'Disabled User', // Column configuration not to be checked
                            name: record.name
                        }
                    })
                }
            }
        },
        methods: {
            // 上传服务器之前的操作
            beforeUpload(file, fileList) {
                // if (file.size > 52428800) {
                if (file.size > 52428800) {
                    // this.$message.error('上传附件不能超过 50M！')
                    this.showTextTips = '上传附件不能超过 50M！'
                    return false
                } else if (file.size === 0) {
                    this.showTextTips = '不能上传空内容的文件'
                    return false
                }else {
                    this.showTextTips = ''
                }
                if (this.isNeedLevel === true || (window.G && window.G.ENV && window.G.ENV.networkType === 'nw')) {
                    // file.level = null
                    // 显示密级选择框
                    this.selfilelelVisible = true
                    // 传输给密级选择框
                    this.singleFile = file
                    // 传给级别选择 附件列表
                    this.LevelfileList = fileList
                } else {
                    const fileObj = {
                        file: file,
                        singleFile: file
                    }
                    fileObj.singleFile.level = '6'
                    this.handleUpload(fileObj)
                }
                // // 暂时去掉密级
                // this.LevelfileList = fileList
                // const fileObj = {
                //     file: file,
                //     singleFile: file
                // }
                // fileObj.singleFile.level = 1
                // this.handleUpload(fileObj)
                return false;
            },
            // 附件下载
            handleDownload(file) {
                const url = '/platservice/fileupload/download'
                const idsStr = this.SelectItems.map(item => { return item.fid})
                downFile(url, {ids: idsStr.join(',')})
            }
        }
    }
</script>

<style lang="less">
    .file-name-pop {
        /*max-width: 200px;*/
        /*text-overflow: ellipsis;*/
        /*white-space: nowrap;*/
        /*overflow: hidden;*/
        /*cursor: pointer;*/

    }
    .upload-pop .ant-tooltip-inner,
    .upload-pop .ant-tooltip-arrow:before {
        background-color: #fff;
        color: #333;
    }
    .com-upload-btn-box {
        display: inline-block;
        margin: 0 10px 0 0;
    }
    @-moz-document url-prefix() {
        .com-upload .com-upload-file {
            /*border-top: 1px solid #ccc;*/
            /*border-bottom: 1px solid #ccc;*/
            .ant-table-body colgroup col {
                min-width: unset !important;
            }
        }
    }

    .com-upload {
        .com-upload-box {
            border: 1px solid #ccc;
            border-bottom: 1px solid #ccc;
            border-top: none;
            border-radius: 4px;
            //padding: 15px;
        }
        .com-upload-isBatchDel
        /*.com-upload-file,*/
        {
            padding: 10px 15px;
        }
        .com-upload-isBatchDel {
            border-top: 1px solid #ccc;
            text-align: right;
            display: flex;
            justify-content: space-between;
        }
        .com-upload-box-left {
            text-align: left;
        }

    }
    .upload-pop .ant-tooltip-inner .pop-font-set {
        font-size: 12px;
        font-weight: 700;
    }
    .com-upload-isBatchDel .info-icon-color, .com-upload-isBatchDel .info-icon-color:hover {
        color: #C3C3C3;
        cursor: pointer;
    }
    .com-upload-explain-text {
        line-height: 32px;
        color: #6E7274;
    }
    .upload-error-info {
        padding: 4px 15px;
        color: #FF0202;
    }

</style>
