<template>
  <div class="com-opt-tree">
    <div class="top-search" v-if="treeSearch">
      <a-select
          show-search
          placeholder="请输入搜索内容"
          option-filter-prop="children"
          style="width: 100%"
          :value="selectVal"
      >
        <a-icon slot="suffixIcon" type="search" class="select-icon" />
        <a-select-option
            v-for="item in selectData"
            :key="item.key"
            :value="item.key"
            @click="selectClick(item, $event)"
        >{{ item.title }}</a-select-option>
      </a-select>
    </div>
    <div class="org-tree-con">
      <ComBdTree
          ref="comTree"
          v-if="treeData && treeData.length"
          :data="treeData"
          :showCheckbox="showCheckbox"
          :highest="highest"
          :specified-multi-choice="specifiedMultiChoice"
          :expandMeth:="expandMeth"
          :default-expanded-keys="treeExpandedKeys"
          @node-collapse="nodeCollapse"
          @node-expand="nodeExpand"
          @current-change="currentChange"
          @node-drag-start="nodeDragStart"
          @node-drag-end="handleDragEnd"
          @check="checkChange"
          @check-change="currentCheckChange"
          :default-checked-keys.sync="defaultCheckedKeys"
          :current-node-key="currentNodeKey"
          node-key="key"
          :allow-drop="allowDrop"
          :allow-drag="allowDrag"
          :draggable="true"
          :isLinkage="isLinkage"
          :check-on-click-node="checkOnClickNode"
          :node-key="replaceFields.key || 'id'"
          :props="{
          children: 'children',
          label: replaceFields.title
        }"
      >
        <div class="custom-tree-node" slot-scope="{ node, data }" :class="setTreeNodeCls(node, data)">
          <span v-if="data[replaceFields.prefix]" :class="['node-pre-icon icon iconhr', getIcon(data)]"></span>
          <p class="node-label" @click.stop v-if="data.editName">
            <a-input-search
                placeholder="input search text"
                enter-button="确认"
                size="small"
                v-model="data[replaceFields.title]"
                :key="data[replaceFields.title]"
            />
          </p>
          <p class="node-label" v-else>
            <a-tooltip placement="rightBottom" @visibleChange="showTooltipEvt($event, data)">
              <template slot="title">
                {{ RealTooltipText ? RealTooltipText : data[replaceFields.title] }}
              </template>
                {{ data[replaceFields.title] }}
            </a-tooltip>
<!--            <span>{{ data[replaceFields.title] }}</span>-->
          </p>
          <div class="tree-item-sort" v-if="showIcon && currentItem.includes(data)">
            <span class="iconhr ico-huanhang"></span>
          </div>
          <div class="operator-area" v-else>
            <span v-if="data.isRoot" class="iconhr ico-tianjia" @click.stop="addTree(data)"></span>
            <template v-if="!data.isRoot">
              <a-popover title="" placement="leftTop" overlayClassName="org-oprList" :get-popup-container="getPopupContainer">
                <template slot="content">
                  <slot v-if="$scopedSlots.menuCover" name="menuCover" :data="data"></slot>
                  <ul class="operator-list" @click.stop v-else>
                    <li
                            v-if="excludeMeth(data,'New') && isPermission('add')"
                            class="operator-item"
                            @click="addTree(data)"
                    >新增</li>
                    <li
                            v-if="excludeMeth(data,'edit') && isPermission('edit')"
                            class="operator-item"
                            @click="editTree(data)"
                    >编辑</li>
                    <li
                            v-if="excludeMeth(data,'Delete') && isPermission('delete')"
                            class="operator-item"
                            @click="remove(data)"
                    >删除</li>
                    <li
                            v-if="excludeMeth(data,'sort') && isPermission('sort')"
                            class="operator-item"
                            @click="sortTree(data, node)"
                    >排序</li>
                    <template>
                      <li
                              v-for="(item , index ) in addBtnList"
                              :key="index"
                              v-if="excludeMeth(data,item.type)"
                              class="operator-item"
                              @click="item.event(data)"
                      >{{item.title}}</li>
                    </template>
                  </ul>
                </template>
                <span class="iconhr ico-gengduo" @click.stop="() => showOpern(data)" v-if='!data.hideMenu'></span>
              </a-popover>
<!--              <ul class="operator-list" @click.stop>-->
<!--                <li-->
<!--                    v-if="excludeMeth(data,'New')"-->
<!--                    class="operator-item"-->
<!--                    @click="addTree(data)"-->
<!--                >新增</li>-->
<!--                <li-->
<!--                    v-if="excludeMeth(data,'edit')"-->
<!--                    class="operator-item"-->
<!--                    @click="editTree(data)"-->
<!--                >编辑</li>-->
<!--                <li-->
<!--                    v-if="excludeMeth(data,'Delete')"-->
<!--                    class="operator-item"-->
<!--                    @click="remove(data)"-->
<!--                >删除</li>-->
<!--                <li-->
<!--                    v-if="excludeMeth(data,'sort')"-->
<!--                    class="operator-item"-->
<!--                    @click="sortTree(data, node)"-->
<!--                >排序</li>-->
<!--                <template>-->
<!--                  <li-->
<!--                      v-for="(item , index ) in addBtnList"-->
<!--                      :key="index"-->
<!--                      v-if="excludeMeth(data,item.type)"-->
<!--                      class="operator-item"-->
<!--                      @click="item.event(data)"-->
<!--                  >{{item.title}}</li>-->
<!--                </template>-->
<!--              </ul>-->
            </template>
          </div>
        </div>
      </ComBdTree>
    </div>
  </div>
</template>
<script>
// 机构树
import ComBdTree from 'common/lib/element/packages/tree/index';
import fa from "../../lib/element/src/locale/lang/fa";
import {getPermission} from 'common/utils/directivers/permission'

export default {
  props: {
    defaultCheckedKeys: {
      default: () => []
    },
    orgTreeData: {
      default: () => []
    },
    loadData: Function,
    iconType: {
      default: () => ({
        '1': 'ico-jianzhu03',
        'A': 'ico-danwei',
        '2': 'ico-renyuanjieshao'
      })
    },
    replaceFields: {
      default: () => ({
        title: 'b0101',
        key: 'dmcod',
        prefix: 'icon'
      })
    },
    typeField: {
      default: 'dwtype'
    },
    defaultExpandedKeys: {
      default: () => []
    },
    defaultSelectedKey: {
      default: '00000000-0000-0000-0000-000000000000'
    },
    currentNodeKey: {
      default: '00000000-0000-0000-0000-000000000000'
    },

    treeSearch: Boolean,
    checkable: Boolean,
    showCheckbox: {
        type: Boolean,
        default: false
      },
    highest: {
      type: Boolean,
      default: false
    },
    specifiedMultiChoice:{
      type:String,
        default: undefined
    },
    customizatiBu: {
      type: Boolean,
      default:false
    },
    addBtnList: {
      default: () => {}
    },
    permission: {
      default: () => {
        // return {
        //   add: '',
        //   edit: '',
        //   delete: '',
        //   sort: '',
        // }
      }
    },
    isLinkage: {
        type: Boolean,
        default: true
    },
    filterTreeNode: {
      type: Function
    },
    // 点击文字是否勾选复选框
    checkOnClickNode: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    }
  },
  components: {
    ComBdTree
  },
  data() {
    return {

      selectedKeys: [],
      expandedKeys: [],
      checkedKeys: [],
      selectData: [],
      treeData: [],
      editName: false,
      expandMeth: false,
      selectVal: undefined,
      autoExpandParent: false,
      showIcon: false,
      treeExpandedKeys: [],
      currentItem: '',
      specifiedMulti: []
    };
  },
  created() {
    this.treeExpandedKeys = this.treeExpandedKeys.concat(this.defaultExpandedKeys);
  },
  mounted() {
    // this.$nextTick(() => {
    //   this.$refs.comTree.setCurrentKey(this.defaultSelectedKey)
    // })
    this.initData(this.orgTreeData);
    this.specifiedMulti = this.specifiedMultiChoice
  },
  watch: {
    defaultSelectedKey: function(val) {
      this.$nextTick(() => {
        this.$refs.comTree.setCurrentKey(val);
      })
    },
    orgTreeData: {
      deep: true,
      handler(n) {
        this.initData(n);
        // if(n && n.length) {
        //   this.initData(n)
        // this.handleSelectData(n)
        // this.treeData = this.setDataSlot(n)
        // this.currentChange(this.defaultSelectedKey)
        // }
      }
    }
  },
  computed: {
    RealTooltipText () {
      return this.tooltipText
    }
    // excludeMeth() {
    //   return function (excl,Type) {
    //     console.log(excl)
    //     console.log(Type)
    //   }
    // },
  },

  methods: {
    showTooltipEvt (e, treeNode) {
      this.$emit('showTltpEvt', e, treeNode)
    },
    checkChange (currentCheck, checkData, ischecked) {
      if (this.showCheckbox) {
        if (currentCheck) {
            // console.log(currentCheck)
            // console.log(checkData)
          this.$refs.comTree.setCurrentKey(currentCheck.key);

        }
        if (ischecked !== null) {
          this.$emit('currentCheckState', ischecked,currentCheck)
        }

        this.$emit('com-Checkbox',checkData.checkedNodes, currentCheck, checkData)
      }
    },
    currentCheckChange (nodeData, checked, indeterminate) {
      this.$emit('currentCheckChange', nodeData, checked, indeterminate)
    },
    ManualsetCheckedKeys() {
      this.$refs.comTree.ManuCheckKeys();
    },
    getPopupContainer(trigger) {
      return trigger.parentElement;
    },
    // 数据权限
    isPermission (type) {
      if(this.permission && this.permission[type]) {
        let permName = this.permission[type]
        return getPermission(permName)
      }
      return true
    },
    excludeMeth (excl,Type) {
      var excludeShow = false
      if (this.customizatiBu) {
        if (excl.hasOwnProperty('exclude')) {
          excl.exclude.forEach(item => {
            if (item === Type) {
              excludeShow = true
            }
          })
        } else {
          excludeShow = false
        }
      } else {
        excludeShow = true
      }
      return excludeShow
    },
    comCheckbox (data) {
      if (this.showCheckbox) {
        // this.$emit('com-Checkbox',data)
      }
    },
    getIcon(item) {
      const type = item[this.replaceFields.prefix];
      let icon = '';
      if (this.iconType[type]) {
        icon = this.iconType[type];
      }
      return icon;
    },
    initData(data) {
      const that = this
      if (data && data.length) {
        this.handleSelectData(data);
        this.treeData = this.setDataSlot(data);
        if (this.defaultSelectedKey) {
          this.currentChange({ key: this.defaultSelectedKey });
        }
        this.$nextTick(() => {
          console.log(this.treeData)
          console.log(this.treeData.length)
          if (this.treeData && this.treeData.length && this.treeData[0].children && this.treeData[0].children.length) {
            const timer = setTimeout(function () {
              that.ManualsetCheckedKeys()
              clearTimeout(timer)
            }, 200)
          }
        })
      } else {
          this.treeData = []
      }
    },
    showOpern(data) {
      this.$set(data, 'isOper', true);
    },
    setDataSlot(n) {
      const newData = JSON.parse(JSON.stringify(n));
      if (n.length === 1) {
        n[0].isRoot = true;
      }
      const handleData = (data, level, pid) => {
        data.forEach(item => {
          item.level = level;
          item.pid = pid;
          if (item.children && item.children.length) {
            handleData(item.children, level + 1, item[this.replaceFields.key] || '1');
          }
        });
      };
      handleData(newData, 1, '0');
      return newData;
    },
    handleSelectData(treeData) {
      let newData = [];
      const clcaData = (data) => {
        data.forEach(item => {
          const newItem = Object.assign(item, {
            title: item[this.replaceFields.title],
            key: item[this.replaceFields.key]
          });
          newData.push(newItem);
          if (item.children && item.children.length) {
            clcaData(item.children);
          }
        });
      };
      clcaData(treeData);
      this.selectData = newData;
    },
    selectClick(data, e) {
      this.currentChange(data);
      this.nodeExpand(data);
    },
    searchOption(option, arr, type) {
      for (let s = 0; s < arr.length; s++) {
        if (arr[s].zjgl0100 === option.zjgl0100) {
          if (type === 'remove') {
            arr.splice(s, 1);
          } else if (type === 'rename') {
            // 这是模拟数据编辑数据
            this.$set(arr, s, {
              children: option.children,
              info: option.info,
              zjgl0102: option[this.replaceFields.title],
              zjgl0100: option[this.replaceFields.key],
              slots: option.slots,
              scopedSlots: { title: 'custom' }
            });
            console.log(option, 'console.log(option)');
          }
          break;
        } else if (arr[s].children && arr[s].children.length > 0) { // 递归条件
          this.searchOption(option, arr[s].children, type);
        } else {
          continue;
        }
      }
    },
    getSameLevel(matchLevel, matchPid) {
      let matchArr = [];
      const getData = (data) => {
        data.forEach(item => {
          if (item.level === matchLevel && item.pid === matchPid) {
            matchArr.push(item);
          }
          if (item.children && item.children.length) {
            getData(item.children);
          }
        });
      };
      getData(this.treeData);
      return matchArr;
    },
    currentChange(data, node, el) {
      this.selectVal = undefined;
      this.$nextTick(() => {
        this.$refs.comTree.setCurrentKey(data.key);
      });
      this.$emit('selectItem', data);
    },
    // 打开节点
    nodeExpand(data, node, el) {
      console.log(data, node, el)
      if (this.treeExpandedKeys.includes(data[this.replaceFields.key])) {
        return;
      }
      this.treeExpandedKeys.push(data[this.replaceFields.key]);
      this.$emit('expand', this.treeExpandedKeys, el);
    },
    // 关闭节点
    nodeCollapse(data, node, el) {
      const keyIndex = this.treeExpandedKeys.indexOf(data[this.replaceFields.key]);
      this.treeExpandedKeys.splice(keyIndex, 1);
    },

    // 添加节点
    addTree(data) {
      this.$emit('addItem', data);
    },
    // 重命名
    rename(data) {
      this.$set(data, 'editName', true);
      // this.$nextTick(() => {
      //   const string = 'input' + data[this.replaceFields.key]
      //   this.$refs[string].focus()
      // })
    },
    // 删除树节点
    remove(data) {
      this.$emit('showModle', true);
      this.$emit('delItem', data);
      this.$emit('editType', 'remove');
    },
    // 编辑节点
    editTree(data) {
      this.$emit('editItem', data);
    },
    // 排序
    sortTree(data, node) {
      this.showIcon = true;
      const matchArr = this.getSameLevel(data.level, data.pid);
      // this.$set(node, 'draggable', true)
      this.currentItem = matchArr;
    },
    // ipnut光标离开变成不可编辑状态
    onRodeChange(data) {
      this.$set(data, 'editName', false);
      this.$emit('renameTree', data);
    },
    // 重命名名字数据变化
    onChange(data) {
      this.namedata = data;
      console.log(data);
    },
    onSearch(e) {
      console.log(e);
    },
    nodeDragStart(draggingNode) {
      this.cacheSortData = this.getSameLevel(draggingNode.data.level, draggingNode.data.pid);
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      this.currentItem = '';
      let matchArr = this.getSameLevel(draggingNode.data.level, draggingNode.data.pid);
      if (JSON.stringify(matchArr) !== JSON.stringify(this.cacheSortData)) {
        this.$emit('sortTree', matchArr);
      }
    },
    allowDrop(draggingNode, dropNode, type) {
      if (draggingNode.data.level === dropNode.data.level) {
        if (type === 'inner') {
          return false;
        }
        if (draggingNode.data.pid !== dropNode.data.pid) {
          return false;
        }
        return true;
      }
      return false;
    },
    allowDrag(draggingNode) {
      return this.currentItem.includes(draggingNode.data);
    },
    setTreeNodeCls(node, data) {
      if(this.filterTreeNode) {
        try {
          const filterd = this.filterTreeNode(data)
          console.log(filterd)
          if(filterd) {
            return 'filter-item'
          }
        } catch (e) {
          return ''
        }
      }
      return ''
    }
  }
};
</script>
<style>
  .com-opt-tree {
    display: flex;
    flex: auto;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }

  .com-opt-tree .top-search {
    display: flex;
    align-items: center;
    padding: 10px;
  }

  .com-opt-tree .org-tree-con {
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 0 10px;
  }

  .com-opt-tree .select-icon {
    font-size: 18px;
    margin-top: -2px;
  }

  .com-opt-tree .bdc-tree .anticon:not(.is-leaf) {
    color: #6B85AC;
    font-size: 12px;
    transform: scale(0.83333333) rotate(0deg);
  }
  .com-opt-tree .bdc-tree .anticon.is-leaf {
    color: transparent;
  }
  .com-opt-tree .bdc-tree .anticon:not(.is-leaf) svg {
    transition: transform 0.3s;
  }

  .com-opt-tree .bdc-tree .anticon.expanded:not(.is-leaf) svg {
    transform: rotate(90deg);
  }

  .com-opt-tree .bdc-tree .anticon.is-leaf:hover {
    color: transparent;
    cursor: default;
  }

  .bdc-tree .bdc-tree-node p {
    color: rgba(0, 0, 0, 0.65);
  }

  .com-opt-tree .custom-tree-node {
    display: flex;
    align-items: center;
    width: calc(100% - 45px);
  }
  .com-opt-tree .custom-tree-node.filter-item .node-label {
    color: red;
  }
  .com-opt-tree .bdc-tree-node.is-current > .bdc-tree-node__content .custom-tree-node.filter-item .node-label {
    color: red;
  }
  .com-opt-tree .custom-tree-node .node-pre-icon {
    padding-right: 4px;
  }

  .com-opt-tree .custom-tree-node .tree-item-sort {
    position: absolute;
    right: 0;
  }

  .com-opt-tree .bdc-tree-node__content:hover .operator-area {
    display: block;
  }
  /*暂时写*/
  .com-opt-tree .bdc-tree-node__content .operator-area {
      display: block;
  }

  .com-opt-tree .operator-area {
    position: absolute;
    right: 0;
    display: none;
  }
  .node-label {
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  /*.com-opt-tree .operator-area:hover .operator-list {*/
  /*  display: block;*/
  /*}*/

  /*.com-opt-tree  .operator-list {*/
  /*  position: fixed;*/
  /*  display: none;*/
  /*  width: 100px;*/
  /*  background: #FFFFFF;*/
  /*  border: 1px solid #d2d5d7;*/
  /*  z-index: 999;*/
  /*  transform: translateX(-50%);*/
  /*  margin-top: 4px;*/
  /*  margin-left: 4px;*/
  /*  border-radius: 4px;*/
  /*  text-align: center;*/
  /*  box-shadow: 0px 0 8px 1px rgba(0, 0, 0, 0.2);*/
  /*}*/

  /*.com-opt-tree .operator-list:before {*/
  /*  content: '';*/
  /*  position: absolute;*/
  /*  top: -10px;*/
  /*  left: 50%;*/
  /*  display: inline-block;*/
  /*  border-top: none;*/
  /*  border-left: 10px solid transparent;*/
  /*  border-right: 10px solid transparent;*/
  /*  border-bottom: 10px solid #d2d5d7;*/
  /*  margin-left: -5px;*/
  /*}*/

  /*.com-opt-tree .operator-list:after {*/
  /*  content: '';*/
  /*  position: absolute;*/
  /*  top: -10px;*/
  /*  left: 50%;*/
  /*  display: inline-block;*/
  /*  border-top: none;*/
  /*  border-left: 10px solid transparent;*/
  /*  border-right: 10px solid transparent;*/
  /*  border-bottom: 10px solid #FFFFFF;*/
  /*  margin-left: -5px;*/
  /*}*/
  /*暂时写*/
  /*.com-opt-tree .bdc-tree-node:nth-of-type(1) > .bdc-tree-node__content:nth-of-type(1) .operator-list:nth-of-type(1) {*/
  /*    display: block;*/
  /*}*/
  /*.com-opt-tree .operator-list .operator-item {*/
  /*  line-height: 32px;*/
  /*}*/

  /*.com-opt-tree .ant-select-open .ant-select-arrow-icon svg {*/
  /*  transform: rotate(0deg);*/
  /*}*/

  /*.com-opt-tree .operator-list .operator-item:hover {*/
  /*  color: #6b85ac;*/
  /*  font-weight: bold;*/
  /*}*/
  .org-oprList .ant-tooltip-inner {
    border: 1px solid #d2d5d7;
    box-shadow: 0px 0 8px 1px rgba(0, 0, 0 ,.2);
  }
  .org-oprList.ant-tooltip-placement-left .ant-tooltip-arrow {
    width: 15px;
    height: 20px;
  }
  .org-oprList .operator-list {
    width: 84px;
    text-align: center;
    margin-bottom: 0;
  }
  .org-oprList .operator-list .operator-item {
    line-height: 32px;
    color: #606266;
    cursor: pointer;
  }
  .org-oprList.ant-tooltip .ant-tooltip-arrow::before {
    background-color: #fff !important;
    width: 15px;
    height: 15px;
  }
  .org-oprList .operator-list .operator-item:hover {
    color: #6b85ac;
    font-weight: bold;
  }
  .com-opt-tree .org-tree-con .ant-popover-inner-content {
    padding: 0;
  }
</style>
