<template>
  <div class='com-sticky-header'
    :style='_style'
    ref="stickyHeader"
  >
    <div ref='stickyAffixBox'>
      <a-affix
        :offset-top='offsetTop'
        :target="target"
      >
        <a-row
          type='flex'
          align='middle'
          class="sticky-box-padding sticky-header-box"
        >
          <a-col flex='8px'>
            <i class='icon-vertical-line' />
          </a-col>
          <a-col flex='auto' class='sticky-title'>
            <slot name='title'>
              {{title}}
            </slot>
          </a-col>
          <a-col>
            <slot name='icon'>
              <template v-if="isShow">
                <template v-if='isEdit'>
                  <i class='iconhr ico-baocun sticky-right-icon'
                    @click="event('save')"
                    v-if="iconStatus" />
                  <i class='iconhr ico-chehui sticky-right-icon'
                    @click="event('back')"
                    v-if="iconStatus" />
                  <i class='iconhr sticky-right-icon'
                    :class='icon'
                    @click="event('edit')"
                    v-if="!iconStatus" />
                </template>
                <template v-else>
                  <i class='iconhr sticky-right-icon'
                    :class='icon'
                    @click="$emit('click', { type: 'click' })" />
                </template>
              </template>
            </slot>
          </a-col>
        </a-row>
      </a-affix>
    </div>
    <div class='sticky-box-padding'>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComStickyHeader',
  props: {
    radius: {
      type: String,
      default: '8px'
    },
    title: {
      type: null,
      default: '标题'
    },
    icon: {
      type: String,
      default: 'ico-xiugai2'
    },
    target: {
      type: Function,
      default: () => {
        return window
      }
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    isShow: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    _style () {
      const { radius } = this
      return `border-radius: ${radius};`
    },
    // _iconWidth () {
    //   return Boolean(this.$slots.icon) ? '' : '20px'
    // }
  },
  data () {
    return {
      offsetTop: 0,
      /* 状态为 true => 编辑，状态为 false => 保存 */
      iconStatus: false,
    }
  },
  updated () {
    const _this = this
    const tag = this.target()
    const scrollFn = () => {
      const stickyHeader = _this.$refs.stickyHeader
      const stickyAffixBox = _this.$refs.stickyAffixBox
      if (!stickyHeader || !stickyAffixBox) return
      const contentHeight = parseInt(window.getComputedStyle(stickyHeader).height)
      const headerHeight = parseInt(window.getComputedStyle(stickyAffixBox).height)
      // const topHeight = tag.offsetTop + contentHeight + headerHeight
      const topHeight = stickyAffixBox.offsetTop + contentHeight - headerHeight
      if (tag.scrollTop > topHeight) {
        if (_this.offsetTop === 0) {
          _this.offsetTop = -1000
          this.$emit('change', false)
        }
      } else {
        if (_this.offsetTop === -1000) {
          _this.offsetTop = 0
          this.$emit('change', true)
        }
      }
    }
    if (tag.onscroll) {
      const tagScroll = tag.onscroll
      tag.onscroll = e => {
        tagScroll(e)
        scrollFn()
      }
    } else {
      tag.onscroll = scrollFn
    }
  },
  methods: {
    event (type) {
      this.iconStatus = (type === 'edit')
      this.$emit('click', { type })
    }
  }
}
</script>

<style lang='less'>
.com-sticky-header{
  background: #fff;
  overflow: hidden;
  .icon-vertical-line{
    display: block;
    width: 3px;
    background: #1e89ff;
    height: 18px;
  }
  .sticky-{
    &header-box{
      background: #fff;
      position: relative;
      z-index: 10;
    }
    &box-padding{
      padding: 10px;
    }
    &title{
      font-size: 16px;
      color: #191f24;
      font-weight: bolder;
      line-height: 20px;
    }
    &right-icon{
      font-size: 18px;
      margin-right: 15px;
      cursor: pointer;
    }
  }
}
</style>
