import {ACCESS_TOKEN,TOKEN_NAME} from "../../confing";

export function getToken(){
    return  localStorage.getItem(ACCESS_TOKEN)
}

export function getTokenKeyName(){
    return  TOKEN_NAME
}

export function getUrlWithTokenParam(url){
    const tokenParam = getTokenParam();
    return url+"?"+tokenParam
}

export function getTokenParam(){
    const token = getToken();
    return TOKEN_NAME+"="+token
}

export function removeTokenParam() {
    localStorage.removeItem(ACCESS_TOKEN)
    sessionStorage.removeItem(ACCESS_TOKEN)
}
