<template>
  <div class='custom-date-picker'>
    <a-select
      style='margin-right: 10px;'
      class='custom-date-picker-select'
      placeholder='请选择'
      v-model='rangeValue'
    >
      <a-select-option
        v-for='(v, i) in range' :key='i'
        :value='v.value'>
        {{v.label}}
      </a-select-option>
    </a-select>
    <a-select
      style='margin-right: 10px;'
      class='custom-date-picker-select'
      placeholder='请选择'
      v-model='dateFormatValue'
    >
      <a-select-option
        v-for='(v, i) in dateFormat' :key='i'
        :value='v.value'>
        {{v.label}}
      </a-select-option>
    </a-select>
    <a-range-picker
      v-if="rangeValue === 'gtlt'"
      allowClear
      :mode='[mode, mode]'
      :format="[dateFormatValue, 'YYYY.MM.DD', 'YYYYMMDD', 'YYYYMM', 'YYYY-MM-DD', 'YYYY-MM', 'YYYY.MM.DD', 'YYYY.MM']"
      v-model='inValue'
      type='date'
      @change='change'
      @panelChange='handlePanelChange'
      :value-format='dateFormatValue'
      style='max-width: 220px;width: 40%;'
    />
    <a-date-picker
      v-else
      allowClear
      :mode='mode'
      :format="[dateFormatValue, 'YYYY.MM.DD', 'YYYYMMDD', 'YYYYMM', 'YYYY-MM-DD', 'YYYY-MM', 'YYYY.MM.DD', 'YYYY.MM']"
      v-model='inValue'
      show-time
      type='date'
      :value-format='dateFormatValue'
      placeholder='请选择'
      @change='change'
      @panelChange='handlePanelChange'
      style='max-width: 220px;width: 40%;'
      ref='datePicker'
    />
  </div>
</template>

<script>
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')
export default {
  label: 'ComCustomQueryDatePicker',
  props: {
    defaultValue: {
      type: null,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({
        value: '',
        label: ''
      })
    }
  },
  data () {
    return {
      range: [
        {
          value: 'lt',
          label: '早于'
        },
        {
          value: 'gt',
          label: '晚于'
        },
        {
          value: 'eq',
          label: '等于'
        },
        {
          value: 'neq',
          label: '不等于'
        },
        {
          value: 'gtlt',
          label: '介于'
        }
      ],
      rangeValue: 'eq',
      dateFormat: [
        {
          value: 'YYYY-MM-DD',
          label: '日期'
        },
        {
          value: 'YYYY-MM',
          label: '年月'
        },
        {
          value: 'MM-DD',
          label: '月日'
        },
        {
          value: 'YYYY',
          label: '年'
        },
        {
          value: 'MM',
          label: '月'
        },
        {
          value: 'DD',
          label: '日'
        }
      ],
      dateFormatValue: 'YYYY-MM-DD',
      inValue: ''
    }
  },
  watch: {
    value: {
      handler  (n) {
        const value = Array.isArray(n.value) ? n.value : [,,n.value]
        const [
          rangeValue = 'eq',
          dateFormatValue = 'YYYY-MM-DD',
          inValue = this.inValue === 'gtlt' ? [] : ''
        ] = value
        Object.assign(this, {
          rangeValue,
          dateFormatValue,
          inValue
        })
      },
      immediate: true
    },
    rangeValue (n) {
      this.inValue = n === 'gtlt' ? [] : ''
      this.change(this.inValue)
    },
    mode (n) {
      const rangeValue = this.rangeValue
      this.inValue = rangeValue === 'gtlt' ? [] : ''
      this.change(this.inValue)
    }
  },
  computed: {
    mode (n) {
      if (this.dateFormatValue === 'YYYY') return 'year'
      if (
        this.dateFormatValue === 'MM' ||
        this.dateFormatValue === 'YYYY-MM'
      ) return 'month'
      return 'date'
    }
  },
  methods: {
    change (e) {
      this.$emit('change', {
        value: [this.rangeValue, this.dateFormatValue, e],
        label: Array.isArray(e) ? e.join(',') : e
      })
    },
    handlePanelChange (e) {
      let value
      if (Array.isArray(e)) {
        value = e.map(v => v.format(this.dateFormatValue))
      } else {
        value = e.format(this.dateFormatValue)
      }
      this.inValue = value
      this.change(value)
    }
  }
}
</script>

<style lang='less'>
.custom-date-picker{
  display: flex;
  align-items: center;
  &-select{
    width: 100px !important;
  }
}
</style>