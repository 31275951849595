<template>
    <div class="com-upload com-upload-btn">
        <a-config-provider :locale="zhCN">
            <!--            <upload-list ></upload-list>-->
            <!--            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"-->
            <div class="com-upload-box-btn">
                <div class="com-upload-file-btn">
                    <z-upload
                            v-if="fileList.length>=0"
                            :file-list="fileList"
                            :accept="FileAccept"
                            @preview="handlePreview"
                            @rename="handleRename"
                            @change="handleChange"
                            @selected="handleSelect"
                            @download="handleDownload"
                            :remove="handleRemove"
                            :before-upload="beforeUpload"
                            :show-upload-list="{
                    showPreviewIcon: true,
                    showRemoveIcon: DeletePermit && !readOnly,
                    showDownloadIcon: DownLoadPermit
                }"
                    >
                        <div v-if="fileList.length < limitCount && !readOnly">
                            <a-button>上传附件</a-button> &nbsp;
                            <a-tooltip placement="top" overlayClassName="upload-pop-text" v-if="!readOnly">
                                <template slot="title">
                                    <p class="pop-font-set">1. 支持附件格式: {{limitFileType}}</p>
                                    <p class="pop-font-set">2. 每个附件不超过 50 M</p>
                                </template>
                                <i class="iconhr ico-xinxi info-icon-color2"></i>
                            </a-tooltip>
                        </div>
                    </z-upload>
                    <div v-if="readOnly && fileList.length===0" class="ant-file-no-data">
                        <!--                        <a-icon type="file-excel" /> -->
                        暂无数据
                    </div>
                </div>
            </div>
        </a-config-provider>

        <a-modal v-model="previewVisible" title="预览" width="600px" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage"/>
        </a-modal>
        <select-level :file.sync="singleFile" @handleSelLel="handleSelLel"
                      :selfilelelVisible.sync="selfilelelVisible"></select-level>
        <rename :file.sync="singleFile" @handleRenamed="handleRenamed" :selfilelelVisible.sync="renameVisible"></rename>

        <com-img-viewer
                v-if="dialogVisible"
                :on-close="closeViewer"
                :url-list="GetSrcListByCache()"
                :initialIndex="PriviewStartIndex"
                :titles="dialogTitle"
        ></com-img-viewer>
        <com-pdf-viewer
                v-if="pdfdialogVisible"
                :title="pdfViewerTitle"
                :on-close="closePdfViewer"
                :src="pdfViewerSrc"
                :fid="singlefid"
                :mime-type="mimeType"
        ></com-pdf-viewer>
    </div>
</template>

<script>
    import ZUpload from 'common/lib/Upload/Package/zUpload'
    import SelectLevel from './SelectLevel'
    import Rename from './RenameDialog'
    import {downFile} from 'common/utils/file/downFile'
    import mixins from './mixin/utls'
    import ComPdfViewer from './preview/PdfViewer'
    import ComImgViewer from './preview/ImgViewer'


    export default {
        mixins: [mixins],
        props: {
            isShowTip: {
                type: Boolean,
                default: true
            },
            limitCount: {
                type: Number,
                default: 8
            },
            limitFileType: {
                type: String,
                default: 'txt、pdf、doc、docx、xls、xlsx、ppt、jpg、png、bmp、gif、rar、zip'
            },
            readOnly: {
                type: Boolean,
                default: false
            },
            batchDeleteAble: {
                type: Boolean,
                default: false
            },
            ids: ''

        },
        components: {
            ZUpload,
            SelectLevel,
            Rename,
            ComPdfViewer,
            ComImgViewer
        },
        data() {
            return {
                LevelfileList: []
            }
        },
        created() {
            this.fileList = []
            if (this.ids) {
                this.LoadData(this.ids)
            }
        },
        mounted() {
        },
        watch: {
            ids: function (ids) {
                if (ids) {
                    this.LoadData(ids)
                } else {
                    this.fileList = []
                }
            }
        },
        computed: {
            // 允许的文件类型
            FileAccept() {
                let acceptstr = this.limitFileType.split('、').map(item => {
                    return '.' + item
                }).join(',')
                return acceptstr
            }
        },
        methods: {
            // 上传服务器之前的操作
            beforeUpload(file, fileList) {
                console.log(file)
                if (file.size > 52428800) {
                    this.$message.error('上传附件不能超过 50M！')
                    return false
                }
                // file.level = null
                // // 显示密级选择框
                // this.selfilelelVisible = true
                // // 传输给密级选择框
                // this.singleFile = file
                if (this.isNeedLevel === true || (window.G && window.G.ENV && window.G.ENV.networkType === 'nw')) {
                    file.level = null
                    // 显示密级选择框
                    this.selfilelelVisible = true
                    // 传输给密级选择框
                    this.singleFile = file
                } else {
                    // 暂时去掉密级
                    this.LevelfileList = fileList
                    const fileObj = {
                        file: file,
                        singleFile: file
                    }
                    fileObj.singleFile.level = 1
                    this.handleUpload(fileObj)
                }
                return false;
            },
            // 附件下载
            handleDownload(file) {
                const url = '/platservice/fileupload/download'
                downFile(url, {ids: file.fid})
            },
            // handleChange(info) {
            //     const status = info.file.status;
            //     if (status !== 'uploading') {
            //         console.log(info.file, info.fileList);
            //     }
            //     if (status === 'done') {
            //         this.$message.success(`${info.file.name} file uploaded successfully.`);
            //     } else if (status === 'error') {
            //         this.$message.error(`${info.file.name} file upload failed.`);
            //     }
            // }
        }
    }
</script>

<style lang="less">
    .com-upload {

        .ico-xinxi.info-icon-color2,
        .ico-xinxi.info-icon-color2:hover{
            color: #A2A2A2;
            cursor: pointer;
        }

        .ant-upload-list-item-info .anticon-copy {
            margin-top: 10px;
            font-size: 40px;
            color: #999 !important;
            --antd-wave-shadow-color: #999;
        }

        .item-onlyread .anticon,
        .item-edit {
            font-size: 24px;
            color: #F9F9F9;
        }

        .ant-file-no-data {
            text-align: center;
        }


        .item-onlyread .anticon:hover,
        .item-edit .anticon:hover {
            color: rgba(255, 255, 255, 0.85);
        }

        .com-upload-box-btn .ant-upload-list.ant-upload-list-text {
            display: flex;
            flex-wrap: wrap;
            .ant-upload-list-item {
                height: 24px;
            }

            .anticon.anticon-paper-clip {
                color: #A2A2A2;
            }
        }
        .com-upload-box-btn .ant-upload-list.ant-upload-list-text > div {
            width: 240px;
            margin-right: 15px;
            .ant-upload-list-item-name {
                color: #191F24;
            }
            .ant-upload-list-item-info:hover {
                background-color: rgba(24,144,255, 0.1);
            }
        }
        /*.com-upload-box-btn .ant-upload-list.ant-upload-list-text > div:nth-of-type(5n) {*/
        /*    margin-right: ;*/
        /*}*/

        .custom-delete {
            font-size: 14px;
        }

        .custom-delete.iconhr {
            color: #A2A2A2;
        }
        .custom-delete.iconhr:hover {
            color: #A2A2A2;
        }
        .upload-pop-text {
            color: #A2A2A2;
        }

        .ant-upload-list-item-card-actions {
            right: 6px;
        }
        //.ant-upload-list-item-info .anticon-copy

    }

    .upload-tooltip.ant-tooltip-placement-top .ant-tooltip-arrow::before,
    upload-pop-text.ant-tooltip-placement-top .ant-tooltip-arrow::before {
        background: white!important;
    }
    .com-upload-btn.com-upload .anticon-download,
    .com-upload-btn.com-upload .anticon-download:hover{
        color: #A2A2A2;
    }
    .com-upload-btn.com-upload .ant-upload-list-picture-card-sybol {
        display: none!important;
    }

</style>
