import XEUtils from 'xe-utils'
export { default as CustomQueryContainer } from './container'
export { default as CustomCheckbox } from './type/checkbox'
export { default as CustomDatePicker } from './type/date-picker'
export { default as CustomInputNumber } from './type/input-number'
export { default as CustomInput } from './type/input'
export { default as CustomRadio } from './type/radio'
export { default as CustomSelect } from './type/select'
export { default as CustomTreeSelect } from './type/tree-select'

const componentNameMap = {
  checkbox: 'CustomCheckbox',
  datePicker: 'CustomDatePicker',
  div: 'div',
  inputNumber: 'CustomInputNumber',
  input: 'CustomInput',
  radio: 'CustomRadio',
  select: 'CustomSelect',
  treeSelect: 'CustomTreeSelect'
}
export function CustomComponentNameMap (type) {
  return componentNameMap[type]
}

const typeMap = {
  '01': 'checkbox',
  '02': 'datePicker',
  '03': 'treeSelect',
  '04': 'inputNumber',
  '05': 'inputNumber',
  '06': 'CustomCheckbox',
  '07': 'input',
  '08': 'input',
  '09': 'treeSelect',
  '10': 'treeSelect',
  '11': 'treeSelect',
  '12': 'treeSelect',
  '13': 'treeSelect',
  '14': 'treeSelect',
  '15': 'input',
  '16': 'checkbox'
}
export function CustomTypeMap (v, len) {
  if (v.queryControlType) return typeMap[v.queryControlType]
  // 兼容 自定义 table 查询
  if (v.queryType === 'F' || v.queryType === 'T') return 'datePicker'
  if (v.dmgrp) {
    if (v.ismultivalued === 0 && len < 5) return 'radio'
    if (v.ismultivalued === 1 && len < 5) return 'checkbox'
    return 'treeSelect'
  }
  return 'input'
}

export function deepClone(target) {
  // 定义一个变量
  let result;
  // 如果当前需要深拷贝的是一个对象的话
  if (typeof target === 'object') {
    // 如果是一个数组的话
    if (Array.isArray(target)) {
      result = []; // 将result赋值为一个数组，并且执行遍历
      for (let i in target) {
        // 递归克隆数组中的每一项
        result.push(deepClone(target[i]))
      }
      // 判断如果当前的值是null的话；直接赋值为null
    } else if(target===null) {
      result = null;
      // 判断如果当前的值是一个RegExp对象的话，直接赋值
    } else if(target.constructor===RegExp){
      result = target;
    }else {
      // 否则是普通对象，直接for in循环，递归赋值对象的所有值
      result = {};
      for (let i in target) {
        result[i] = deepClone(target[i]);
      }
    }
    // 如果不是对象的话，就是基本数据类型，那么直接赋值
  } else {
    result = target;
  }
  // 返回最终结果
  return result;
}

/**
 * 列表转树形结构
 * */
export function listToArrayTree (listData, id, pid) {
  const treeData = XEUtils.toArrayTree(listData, { key: id, parentKey: pid })
  formatChildren(treeData)
  return treeData
}
/**
 * 处理空子节点
 * */
export function formatChildren (treeData) {
  treeData.forEach(item => {
    item.children === '' || item.children === undefined ||
    item.children === null || item.children.length === 0
      ? delete item.children : formatChildren(item.children)
  })
}
